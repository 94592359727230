import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';

import LeaguesTable from '../LeaguesTableWrapper';
import { StyledTableRow } from '../../Styled';
import { openClosedStatusTypes, sessionTypes, getTypeForId } from '../../../utils/types';
import { getAdjustedDate } from '../../../utils/display';

const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'type', label: 'Type' },
    { id: 'status', label: 'Status' },
    { id: 'startDate', label: 'Start Date' },
    { id: 'empty' },
    { id: 'view' },
];

const SessionsTable = ({ conferenceSessions = [], isLoading, onManageClick, onViewClick, onRemoveClick }) => {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        const formattedSessions = conferenceSessions.map((session) => {
            const { sessionTypeId, sessionStatusTypeId, startDateUtc, ...fetchedSession } = session;
            const sessionType = getTypeForId(sessionTypeId, sessionTypes);
            const sessionStatus = getTypeForId(sessionStatusTypeId, openClosedStatusTypes);

            return {
                ...fetchedSession,
                type: sessionType.description,
                status: sessionStatus.description,
                startDate: getAdjustedDate({ dateUtc: startDateUtc, format: 'MMMM Do YYYY' }),
            };
        });

        setSessions(formattedSessions);
    }, [conferenceSessions]);

    const SessionTableRow = ({ rowData, labelId }) => {
        const { id, name, type, status, startDate, hasRegisteredPlayers } = rowData;

        const handleManageClick = () => {
            onManageClick(id);
        };

        const handleOnViewClick = () => {
            onViewClick(id);
        };

        const handleOnRemoveClick = () => {
            onRemoveClick(id, hasRegisteredPlayers);
        };

        return (
            <StyledTableRow hover tabIndex={-1} key={id}>
                <TableCell component="th" id={labelId} scope="row" width="30%" padding="normal">
                    {name}
                </TableCell>
                <TableCell align="left">{type}</TableCell>
                <TableCell align="left">{status}</TableCell>
                <TableCell align="left">{startDate}</TableCell>
                <TableCell align="left">
                    <Button onClick={handleManageClick}>Manage</Button>
                </TableCell>
                <TableCell align="left">
                    <Button onClick={handleOnRemoveClick}>Remove</Button>
                </TableCell>
                <TableCell align="left">
                    <Button onClick={handleOnViewClick}>View</Button>
                </TableCell>
            </StyledTableRow>
        );
    };

    return <LeaguesTable sortBy="name" tableData={sessions} headCells={headCells} isLoading={isLoading} rowComponent={SessionTableRow} />;
};

export default SessionsTable;
