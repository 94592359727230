import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DateTimePicker from '../../Form/DateTimePicker';
import FormSubmitButton from '../../Form/FormSubmitButton';
import Input from '../../Form/Input';
import Location from '../../Form/Location';
import LoadingIndicator from '../../LoadingIndicator';
import Popup from '../../Popups/Popup';
import RadioGroup from '../../Form/RadioGroup';
import Select from '../../Form/Select';
import SessionMatchFormat from '../../SessionMatchFormat';
import { getTooManyMatchesPerTeamError } from '../../../utils/sessions';

import {
    FrequencyTypes,
    LeagueSessionTypes,
    MatchFormatTypes,
    SessionDrawTypes,
    SportTypeIds,
    SessionStandingsRules,
    SessionStandingsTypes,
    StatusTypes,
    TournamentTeamsCount,
    drawTypes,
    matchFrequencyTypes,
    openClosedStatusTypes,
    pickleballSessionStandingsOptions,
    sessionMatchFormatTypes,
    sessionTypes,
    tournamentTeamsCountOptions,
    tennisSessionStandingsOptions,
} from '../../../utils/types';
import { LeagueTabs, defaultGridSpacing, formPaperStyles } from '../../../utils/constants';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { getLocationData } from '../../../utils/location';
import { useCreateSessionMutation, useUpdateSessionMutation, useGetLeagueFeesQuery } from '../../../api/leaguesApi';
import { getAdjustedDate } from '../../../utils/display';
import { setSportTypeId } from '../../../redux/conferenceDetailsSlice';
import { createSessionFormPropTypes, defaultCreateSessionFormPropTypes } from '../../../utils/proptypes';

const minutesStep = 15;
const CUSTOM_MORE_MATCHES_TEXT = 'There are more matches per team than weeks in the session.';

const CreateSessionForm = ({ sessionDetails, isManaging, isLoadingSession, onSessionUpdated }) => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);

    const sessionId = params?.sessionId;
    const { OPEN } = StatusTypes;
    const { ONCE_A_WEEK } = FrequencyTypes;
    const { TOURNAMENT } = LeagueSessionTypes;
    const { TENNIS } = SportTypeIds;
    const { FIRST_MATCH_BACK_DRAW } = SessionDrawTypes;
    const { BOTH, DOUBLES, SINGLES } = MatchFormatTypes;
    const { FOUR, EIGHT, SIXTEEN } = TournamentTeamsCount;
    const { TENNIS_WIN_LOSS_RECORD, PICKLEBALL_WIN_LOSS_RECORD } = SessionStandingsTypes;
    const { hasRegisteredPlayers, hasPostedResults, hasPublishedSchedule, hasSchedule } = sessionDetails || {};
    const isTennisSession = sportTypeId === TENNIS;
    const buttonText = isManaging ? 'Save Changes' : 'Create Session';
    const isRestrictedSession = hasPostedResults && hasPublishedSchedule;
    const standingsRuleOptions = isTennisSession ? tennisSessionStandingsOptions : pickleballSessionStandingsOptions;

    const [startDate, setStartDate] = useState(moment());
    const [teamRegisterStartDate, setTeamRegisterStartDate] = useState(moment());
    const [totalPlayerCost, setTotalPlayerCost] = useState();
    const [matchFormat, setMatchFormat] = useState('');
    const [originalLocation, setOriginalLocation] = useState();
    const [isTournamentSession, setIsTournamentSession] = useState(false);
    const [conferenceId, setConferenceId] = useState(parseInt(params?.conferenceId) || skipToken);
    const [leagueVerifiedFee, setLeagueVerifiedFee] = useState(0);
    const [showRuleConfirmationModal, setShowRuleConfirmationModal] = useState(false);
    const [sessionStandingsRuleId, setSessionStandingsRuleId] = useState(isTennisSession ? TENNIS_WIN_LOSS_RECORD : PICKLEBALL_WIN_LOSS_RECORD);
    const [updatedStandingsRuleId, setUpdatedStandingsRuleId] = useState(null);

    const [createSession] = useCreateSessionMutation();
    const [updateSession, { isSuccess: isUpdated }] = useUpdateSessionMutation();
    const { data: leaguesFees, isLoading: isLoadingLeaguesFees, error } = useGetLeagueFeesQuery(conferenceId);

    if (error) {
        dispatch(showErrorPopup(error));
    }

    const defaultValues = {
        conferenceId: parseInt(params?.conferenceId),
        description: '',
        doublesMatchCount: '',
        doublesPointsPerMatch: '',
        doublesScoringFormatTypeId: '',
        doublesTimeAllowedTypeId: '',
        drawTypeId: FIRST_MATCH_BACK_DRAW,
        endDateUtc: moment().add(5, 'days'),
        matchesPerTeam: '',
        name: '',
        registrationFee: 0,
        registrationVerificationFee: 0,
        sessionStatusTypeId: OPEN,
        sessionMatchTypeId: SINGLES,
        sessionTypeId: '',
        singlesMatchCount: '',
        singlesPointsPerMatch: '',
        singlesScoringFormatTypeId: '',
        singlesTimeAllowedTypeId: '',
        startDateUtc: moment().add(3, 'days'),
        teamRegistrationEndDateUtc: moment().add(2, 'days').add(2, 'hour'),
        teamRegistrationStartDateUtc: moment().add(2, 'hour'),
        teamRequiredPlayersMin: 2,
        teamsMax: '',
        location: '',
        teamMatchFrequencyTypeId: ONCE_A_WEEK,
    };

    const {
        handleSubmit,
        formState: { errors, dirtyFields, isSubmitting },
        control,
        reset,
        getValues,
        setValue,
        setError,
        clearErrors,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (isManaging && sessionDetails) {
            const {
                conferenceId: sessionConferenceId,
                endDateUtc,
                startDateUtc,
                registrationFee,
                teamRegistrationStartDateUtc,
                teamRegistrationEndDateUtc,
                registrationVerificationFee,
                sessionMatchTypeId,
                sessionTypeId,
                description,
                sessionStandingsTypeId,
                drawTypeId,
                ...fetchedSession
            } = sessionDetails;

            setConferenceId(sessionConferenceId);
            setSessionStandingsRuleId(sessionStandingsTypeId);

            let sessionLocation = '';
            const isTournament = sessionTypeId === TOURNAMENT;
            if (isTournament) {
                sessionLocation = sessionDetails.location?.placeName || '';
                setOriginalLocation(sessionDetails.location);
                setIsTournamentSession(true);
            }

            let formData;

            if (sessionMatchTypeId !== BOTH) {
                formData = {
                    ...defaultValues,
                    ...fetchedSession,
                    sessionTypeId,
                    drawTypeId,
                    description: description || '',
                    registrationFee,
                    registrationVerificationFee,
                    sessionMatchTypeId,
                    location: sessionLocation,
                    endDateUtc: getAdjustedDate({ dateUtc: endDateUtc, format: null, adjustForDSL: false }),
                    startDateUtc: getAdjustedDate({ dateUtc: startDateUtc, format: null, adjustForDSL: false }),
                    teamRegistrationStartDateUtc: getAdjustedDate({ dateUtc: teamRegistrationStartDateUtc, format: null, adjustForDSL: false }),
                    teamRegistrationEndDateUtc: getAdjustedDate({ dateUtc: teamRegistrationEndDateUtc, format: null, adjustForDSL: false }),
                };
            } else {
                formData = {
                    ...fetchedSession,
                    drawTypeId,
                    registrationFee,
                    registrationVerificationFee,
                    sessionMatchTypeId,
                    sessionTypeId,
                    description: description || '',
                    location: sessionLocation,
                    endDateUtc: getAdjustedDate({ dateUtc: endDateUtc, format: null, adjustForDSL: false }),
                    startDateUtc: getAdjustedDate({ dateUtc: startDateUtc, format: null, adjustForDSL: false }),
                    teamRegistrationStartDateUtc: getAdjustedDate({ dateUtc: teamRegistrationStartDateUtc, format: null, adjustForDSL: false }),
                    teamRegistrationEndDateUtc: getAdjustedDate({ dateUtc: teamRegistrationEndDateUtc, format: null, adjustForDSL: false }),
                };
            }
            setTotalPlayerCost(parseInt(registrationFee) + parseInt(registrationVerificationFee));
            setLeagueVerifiedFee(registrationVerificationFee);
            reset(formData);
            setMatchFormat(sessionMatchTypeId);
            dispatch(setSportTypeId(sessionDetails?.sportTypeId));
        }
    }, [sessionDetails]);

    useEffect(() => {
        if (isUpdated) {
            onSessionUpdated();
        }
    }, [isUpdated]);

    useEffect(() => {
        if (leaguesFees && !isManaging) {
            setLeagueVerifiedFee(leaguesFees?.registrationVerificationFee);
            setTotalPlayerCost(leaguesFees?.registrationVerificationFee);
        }
    }, [leaguesFees]);

    const handleFormSubmit = async (formValues) => {
        const session = { ...formValues };

        session.startDateUtc = getAdjustedDate({ dateUtc: session.startDateUtc, format: null, adjustForDSL: true }).toISOString();
        session.endDateUtc = getAdjustedDate({ dateUtc: session.endDateUtc, format: null, adjustForDSL: true }).toISOString();
        session.teamRegistrationStartDateUtc = getAdjustedDate({ dateUtc: session.teamRegistrationStartDateUtc, format: null, adjustForDSL: true }).toISOString();
        session.teamRegistrationEndDateUtc = getAdjustedDate({ dateUtc: session.teamRegistrationEndDateUtc, format: null, adjustForDSL: true }).toISOString();
        session.description = session.description.trim().length === 0 ? null : session.description;
        session.sessionMatchTypeId = matchFormat;
        session.sessionStandingsTypeId = sessionStandingsRuleId;

        if (!isManaging) {
            session.registrationVerificationFee = leagueVerifiedFee;
        }

        if (matchFormat === SINGLES) {
            delete session.doublesMatchCount;
            delete session.doublesPointsPerMatch;
            delete session.doublesScoringFormatTypeId;
            delete session.doublesTimeAllowedTypeId;
        } else if (matchFormat === DOUBLES) {
            delete session.singlesMatchCount;
            delete session.singlesPointsPerMatch;
            delete session.singlesScoringFormatTypeId;
            delete session.singlesTimeAllowedTypeId;
        }

        const sessionType = getValues('sessionTypeId');

        if (sessionType === TOURNAMENT) {
            delete session.teamMatchFrequencyTypeId;

            if (dirtyFields.location) {
                session.location = await getLocationData(formValues.location);
            } else {
                session.location = originalLocation;
            }
        } else {
            delete session.location;
        }

        if (isManaging) {
            try {
                await updateSession({ sessionId, session, conferenceId }).unwrap();
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        } else {
            try {
                await createSession(session).unwrap();
                navigate(`/leagues/conference/${params?.conferenceId}/manage`, { state: { activeTab: LeagueTabs.SESSIONS } });
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        }
    };

    const handleEntryFeeChange = (e) => {
        const feeValue = parseInt(e.target.value);
        if (!Number.isNaN(feeValue)) {
            setTotalPlayerCost(parseInt(e.target.value) + leagueVerifiedFee);
        } else {
            setTotalPlayerCost(leagueVerifiedFee);
        }
    };

    const handleOnMatchFormatChange = (event) => {
        setMatchFormat(event.target.value);
    };

    const handleOnEventStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleOnRegistrationStartDateChange = (date) => {
        setTeamRegisterStartDate(date);
    };

    const handleOnSessionTypeChange = (event) => {
        const isTournament = event.target.value === TOURNAMENT;
        setIsTournamentSession(isTournament);
    };

    const handleMatchesPerTeamCheck = (event) => {
        clearErrors('matchesPerTeam');
        setValue('matchesPerTeam', event.target.value);
        const session = getValues();

        const hasErrors = getTooManyMatchesPerTeamError({
            ...session,
            matchesPerTeam: +event.target.value,
        });

        if (hasErrors) {
            setError('matchesPerTeam', { type: 'custom', message: CUSTOM_MORE_MATCHES_TEXT });
        }

        return;
    };

    const handleOnTournamentTeamsChange = (event) => {
        const teamsCount = event.target.value;
        switch (teamsCount) {
            case FOUR:
                setValue('matchesPerTeam', 2);
                break;
            case EIGHT:
                setValue('matchesPerTeam', 4);
                break;
            case SIXTEEN:
                setValue('matchesPerTeam', 8);
                break;
            default:
                break;
        }
    };

    const handleOnSessionRuleChange = (event) => {
        const ruleValue = event?.target?.value;

        if (ruleValue !== sessionStandingsRuleId) {
            setShowRuleConfirmationModal(true);
            setUpdatedStandingsRuleId(ruleValue);
        }
    };

    const handleOnConfirmRuleChange = () => {
        setShowRuleConfirmationModal(false);
        setSessionStandingsRuleId(updatedStandingsRuleId);
    };

    const handleOnCloseRuleModal = () => {
        setShowRuleConfirmationModal(false);
        setUpdatedStandingsRuleId(null);
    };

    return (
        <Container>
            <Grid justifyContent="center" mt={3}>
                {!isManaging && (
                    <Box mb={4}>
                        <Typography variant="h3">Create Session</Typography>
                        <Divider />
                    </Box>
                )}
                {isManaging && isLoadingSession && isLoadingLeaguesFees ? (
                    <LoadingIndicator />
                ) : (
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Paper sx={formPaperStyles}>
                            <Typography variant="h5">Details</Typography>
                            <Grid my={2} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    <Input name="name" label="Session name" isRequired hasError={!!errors.name} control={control} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Select
                                        isRequired
                                        disabled={isRestrictedSession}
                                        hasError={!!errors.sessionTypeId}
                                        name="sessionTypeId"
                                        label="Session type"
                                        labelId="session-type-label"
                                        options={sessionTypes}
                                        control={control}
                                        onSelectChange={handleOnSessionTypeChange}
                                        isFullWidth
                                    />
                                </Grid>
                            </Grid>
                            {isTournamentSession && (
                                <Grid my={2} container spacing={defaultGridSpacing}>
                                    <Grid item xs={12}>
                                        <Location
                                            name="location"
                                            label="Team Tournament Location"
                                            error={!!errors.location}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            disabled={hasSchedule}
                                            name="drawTypeId"
                                            label="Draw Type"
                                            labelId="draw-type-type-label"
                                            options={drawTypes}
                                            control={control}
                                            isFullWidth
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid my={2} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    <DateTimePicker
                                        name="startDateUtc"
                                        label="Session start date"
                                        minDate={isManaging ? null : moment()}
                                        minutesStep={minutesStep}
                                        onDateChange={handleOnEventStartDateChange}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <DateTimePicker
                                        name="endDateUtc"
                                        label="Session end date"
                                        minDate={isManaging ? null : startDate}
                                        minutesStep={minutesStep}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                            <Grid my={2} container spacing={defaultGridSpacing}>

                                {!isTournamentSession && (
                                    <>
                                        <Grid item xs={12} lg={6}>
                                            <Select
                                                disabled={isRestrictedSession}
                                                name="teamMatchFrequencyTypeId"
                                                label="Match frequency"
                                                labelId="match-frequency-type-label"
                                                options={matchFrequencyTypes}
                                                control={control}
                                                isFullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="session-standings-rules-label">Team standings rule</InputLabel>
                                                <MuiSelect
                                                    onChange={handleOnSessionRuleChange}
                                                    value={sessionStandingsRuleId}
                                                    label="Team Standings Rule"
                                                    labelId="session-standings-rules-label"
                                                >
                                                    {standingsRuleOptions.map((rule) => (
                                                        <MenuItem key={rule?.id} value={rule?.id}>
                                                            {rule?.description}
                                                        </MenuItem>
                                                    ))}
                                                </MuiSelect>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Input
                                control={control}
                                name="description"
                                multiline
                                minRows={3}
                                hasError={!!errors.description}
                                label="Description"
                                helperText="This message will appear publicly on your session page"
                            />
                        </Paper>
                        <Paper sx={formPaperStyles}>
                            <Grid container alignItems="center" spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="h5">Team match format</Typography>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="match-format-label">Format type</InputLabel>
                                        <MuiSelect
                                            disabled={isRestrictedSession}
                                            onChange={handleOnMatchFormatChange}
                                            value={matchFormat}
                                            label="Format Type"
                                            labelId="match-format-label"
                                        >
                                            {sessionMatchFormatTypes.map((session) => (
                                                <MenuItem key={session.id} value={session.id}>
                                                    {session.description}
                                                </MenuItem>
                                            ))}
                                        </MuiSelect>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <SessionMatchFormat
                                sportTypeId={sportTypeId}
                                isDisabled={isRestrictedSession}
                                matchFormat={matchFormat}
                                control={control}
                                errors={errors}
                            />
                        </Paper>
                        <Paper sx={formPaperStyles}>
                            <Typography variant="h5">Team registration</Typography>
                            <Grid my={2} container alignItems="center" spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    <Typography>Session status</Typography>
                                    <Typography variant="caption">Teams can be added to an open session</Typography>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <RadioGroup row name="sessionStatusTypeId" options={openClosedStatusTypes} control={control} />
                                </Grid>
                            </Grid>
                            <Grid my={2} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    <DateTimePicker
                                        name="teamRegistrationStartDateUtc"
                                        label="Registration start date"
                                        onDateChange={handleOnRegistrationStartDateChange}
                                        minutesStep={minutesStep}
                                        minDate={isManaging ? null : moment().add(1, 'hour')}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <DateTimePicker
                                        name="teamRegistrationEndDateUtc"
                                        label="Registration end date"
                                        minutesStep={minutesStep}
                                        minDate={isManaging ? null : teamRegisterStartDate}
                                        control={control}
                                    />
                                </Grid>
                            </Grid>
                            <Grid my={2} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    {isTournamentSession ? (
                                        <Select
                                            onSelectChange={handleOnTournamentTeamsChange}
                                            isRequired
                                            disabled={isRestrictedSession}
                                            hasError={!!errors.teamsMax}
                                            name="teamsMax"
                                            label="Max teams"
                                            labelId="tournament-max-teams-label"
                                            options={tournamentTeamsCountOptions}
                                            control={control}
                                            isFullWidth
                                        />
                                    ) : (
                                        <Input
                                            isRequired
                                            disabled={isRestrictedSession}
                                            name="teamsMax"
                                            label="Max teams"
                                            type="number"
                                            hasError={!!errors.teamsMax}
                                            inputProps={{ min: 0 }}
                                            control={control}
                                        />
                                    )}
                                </Grid>
                                {!isTournamentSession && (
                                    <Grid item xs={12} lg={6}>
                                        <Input
                                            disabled={isRestrictedSession}
                                            isRequired
                                            name="matchesPerTeam"
                                            label="Matches per team"
                                            type="number"
                                            hasError={!!errors.matchesPerTeam}
                                            inputProps={{ min: 0 }}
                                            control={control}
                                            onChange={handleMatchesPerTeamCheck}
                                        />
                                        {errors.matchesPerTeam && (
                                            <Typography variant="body1" className="text-danger">
                                                {errors.matchesPerTeam.message}
                                            </Typography>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                        <Paper sx={formPaperStyles}>
                            <Typography variant="h5">Player fees</Typography>
                            <Box mb={2} display="flex" justifyContent="flex-end">
                                <Box display="flex" alignItems="center" mr={2}>
                                    <Typography>Session player entry fee</Typography>
                                </Box>
                                <Controller
                                    name="registrationFee"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            disabled={hasRegisteredPlayers}
                                            value={value}
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            inputProps={{ style: { textAlign: 'right', width: 55 } }}
                                            onChange={(e) => {
                                                onChange(e);
                                                handleEntryFeeChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Typography mr={1.2} align="right">
                                {`League fee: $${leagueVerifiedFee}`}
                            </Typography>
                            <Typography mr={1.2} align="right">
                                {`Total per player: $${totalPlayerCost}`}
                            </Typography>
                        </Paper>
                        <FormSubmitButton label={buttonText} isSubmitting={isSubmitting} />
                    </form>
                )}
                <Popup
                    onAction={handleOnConfirmRuleChange}
                    actionLabel="Yes, change"
                    buttonLabel="No, cancel"
                    open={showRuleConfirmationModal}
                    onClose={handleOnCloseRuleModal}
                    title="Confirm standings rule"
                    message={`Are you sure you want to change the team standings rule to ${SessionStandingsRules[updatedStandingsRuleId]}?`}
                />
            </Grid>
        </Container>
    );
};

CreateSessionForm.propTypes = {
    ...createSessionFormPropTypes
};

CreateSessionForm.defaultProps = {
    ...defaultCreateSessionFormPropTypes
};

export default CreateSessionForm;
