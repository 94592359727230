import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import LoadingIndicator from '../LoadingIndicator';
import ModalBaseWithHeader from './ModalBaseWithHeader';
import Select from '../Form/Select';

const MovePlayerModal = ({ availableTeams, onClose, open, onSubmit, isLoadingAvailableTeams }) => {
    const defaultValues = { teamId: '' };
    const {
        handleSubmit,
        formState,
        formState: { errors },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({ teamId: '' });
        }
    }, [formState, reset]);

    const handleFormSubmit = (values) => {
        onSubmit(values.teamId);
    };

    return (
        <ModalBaseWithHeader title="Move Player" onClose={onClose} open={open} onSubmit={onSubmit}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent dividers>
                    {isLoadingAvailableTeams && <LoadingIndicator />}
                    {!!availableTeams.length && (
                        <>
                            <Typography align="center" gutterBottom marginBottom="24px">
                                Select the team to move the player to.
                            </Typography>
                            <Select
                                name="teamId"
                                label="Team"
                                options={availableTeams}
                                labelId="team-label"
                                isFullWidth
                                control={control}
                                hasError={!!errors.team}
                                isRequired
                                helperText="Required"
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="dark" autoFocus type="submit">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </ModalBaseWithHeader>
    );
};

export default MovePlayerModal;
