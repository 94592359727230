import { combineReducers } from 'redux';
import user from './userSlice';
import auth from './authSlice';
import breadcrumbs from './breadcrumbSlice';
import ui from './uiSlice';
import genericPopup from './genericPopupSlice';
import errorPopup from './errorPopupSlice';
import conferenceDetails from './conferenceDetailsSlice';
import { leaguesApi } from '../api/leaguesApi';

export default combineReducers({
    user,
    auth,
    breadcrumbs,
    ui,
    genericPopup,
    errorPopup,
    conferenceDetails,
    [leaguesApi.reducerPath]: leaguesApi.reducer,
});
