const PackageJson = require('../package.json');

const buildId = process.env.BUILD_ID || 'default';
const appCodePath = `${PackageJson.version}-${buildId}`;

module.exports = {
    outputPath: 'leagues-client',
    buildId,
    applicationVersion: PackageJson.version,
    appCodePath,
    staticContentPath: 'public',
};
