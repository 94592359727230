import React, { useEffect, useState, useMemo } from 'react';

import { DrawCard } from '@universal-tennis/ui-shared';
import {
    FULL_PAGE_ROUNDS,
    ONE_ROUND,
    TBD_ROUND,
    getFormatedFields,
    getHideViewOnPublish,
    getDrawCardSelectOptions,
    getHideEditOnByes,
    getDataFormattedAndPublishedTeams,
} from './utils';
import { useIsTablet } from '../../hooks/useIsMobile';

const TBDLabel = {
    name: TBD_ROUND,
    points: 0,
};

export const useGetTableRowsData = ({
    selectableTeams,
    draw,
    totalRounds,
    onEditMatch,
    onViewMatch,
    onChangeClick,
    hasPublishedSchedule,
    isManaging,
    isMainDraw,
}) => {
    const { rounds } = draw;
    const isTablet = useIsTablet();
    const [initialRoundIndex, setInitialRoundIndex] = useState(0);
    const [selectedHeaderRows, setSelectedHeaderRows] = useState([]);
    const [selectedTableRows, setSelectedTableRows] = useState([]);

    const sortedRounds = useMemo(() => {
        return rounds?.map((round) => ({
            ...round,
            teamMatches: round.teamMatches?.slice().sort((a, b) => a?.teamMatchNumber - b?.teamMatchNumber)
        }));
    }, [rounds]);

    const getTableRows = () =>
        sortedRounds &&
            sortedRounds.map((round) => {
                const { number: roundNumber } = round;

                return round.teamMatches?.map((teamMatch) => {
                    const { matchDate, location, position1Team, position2Team } = teamMatch;
                    const { formatedDateLabel, formatedLocationLabel } = getFormatedFields({ teamMatch, location, matchDate });

                    const isDisabled = !hasPublishedSchedule && roundNumber !== 1;
                    const isScorelessMatch = !position1Team?.points && !position2Team?.points;

                    const options = getDrawCardSelectOptions({ teamMatch, selectableTeams, teamMatchesInRound: round.teamMatches });
                    const { dataFormatted, publishedPosition1Team, publishedPosition2Team } = getDataFormattedAndPublishedTeams({ dateLabel: formatedDateLabel, locationLabel: formatedLocationLabel, options, roundNumber, hasPublishedSchedule, isMainDraw, totalRounds, teamMatch });

                    const handleOnViewCard = () => {
                        onViewMatch(teamMatch);
                    };

                    const hideViewOnPublish = getHideViewOnPublish({ roundNumber, handleOnViewCard, hasPosition1Team: position1Team, hasPosition2Team: position2Team });
                    const hideEditOnPublish = getHideEditOnByes({ teamMatch, isDisabled, hasPublishedSchedule, onEditMatch, publishedPosition1Team, publishedPosition2Team, options: dataFormatted?.selectedOptions });

                    if (hasPublishedSchedule) {
                        return (
                            <DrawCard
                                isManaging={isManaging}
                                isFlexWidth={isTablet}
                                {...dataFormatted}
                                isScorelessMatch={isScorelessMatch}
                                position1Team={publishedPosition1Team}
                                position2Team={publishedPosition2Team}
                                emptyLabel="Select a team"
                                {...hideViewOnPublish}
                                {...hideEditOnPublish}
                            />
                        );
                    }

                    if (isDisabled) {
                        return (
                            <DrawCard
                                isManaging={isManaging}
                                isFlexWidth={isTablet}
                                isScorelessMatch
                                position1Team={TBDLabel}
                                position2Team={TBDLabel}
                                locationLabel={location?.placeName}
                                dateLabel={formatedDateLabel}
                                {...hideEditOnPublish}
                            />
                        );
                    }

                    return (
                        <DrawCard
                            isManaging={isManaging}
                            isFlexWidth={isTablet}
                            {...dataFormatted}
                            isSelectActive
                            onChange={(selectedTeam) => onChangeClick({ selectedTeam, teamMatch })}
                            emptyLabel="Select a team"
                            {...hideEditOnPublish}
                        />
                    );
                });
            });

    useEffect(() => {
        const tableRows = getTableRows();
        const initialRounds = rounds
            ?.map((round) => ({
                name: round.name,
            }))
            ?.slice(initialRoundIndex, initialRoundIndex + FULL_PAGE_ROUNDS);
        const takeFirstThree = tableRows?.slice(initialRoundIndex, FULL_PAGE_ROUNDS + initialRoundIndex);

        setSelectedHeaderRows(initialRounds);
        setSelectedTableRows(takeFirstThree);
    }, [selectableTeams, draw, isTablet, hasPublishedSchedule, rounds, initialRoundIndex]);

    const handleOnForwardRoundClick = () => setInitialRoundIndex(initialRoundIndex + ONE_ROUND);
    const handleOnBackwardRoundClick = () => setInitialRoundIndex(initialRoundIndex - ONE_ROUND);

    const isShowingForward =
        !(initialRoundIndex + FULL_PAGE_ROUNDS === rounds?.length) &&
        initialRoundIndex !== rounds?.length - ONE_ROUND &&
        rounds?.length > FULL_PAGE_ROUNDS;

    const isShowingBackward = initialRoundIndex !== 0;

    return {
        tableRows: selectedTableRows,
        headerRows: selectedHeaderRows,
        handleOnForwardRoundClick,
        handleOnBackwardRoundClick,
        isShowingForward,
        isShowingBackward,
    };
};
