import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import ManageSessionCaptainCard from '../Cards/ManageSessionCaptainCard';
import LoadingIndicator from '../LoadingIndicator';
import PlayerSearch from '../Form/PlayerSearch';
import Popup from '../Popups/Popup';
import { mapMembersForPlayerSearch } from '../../utils/helpers';
import { createTeamCaptainManagerPropTypes } from '../../utils/proptypes';

const CreateTeamCaptainManager = ({
    captains = [],
    isUpdatingCaptains = false,
    isLoadingTeam = false,
    onAddCaptain,
    onRemoveCaptain,
    isRequired = false,
}) => {
    const [originalCaptains, setOriginalCaptains] = useState([]);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [showCaptainSearch, setShowCaptainSearch] = useState(false);
    const [isRemovingCaptain, setIsRemovingCaptain] = useState(false);
    const [captainAwaitingAction, setCaptainAwaitingAction] = useState();
    const captainsArray = captains && Array.isArray(captains) ? captains : [captains];

    const maxCaptainsAdded = captainsArray?.length === 1 && captainsArray[0] !== null;

    useEffect(() => {
        if (captains && captainsArray?.length > 0) {
            const mappedMembers = mapMembersForPlayerSearch(captainsArray);
            setOriginalCaptains(mappedMembers);
        }
    }, [captains]);

    const handleOnAddCaptain = (captain) => {
        setCaptainAwaitingAction(captain);
        setIsRemovingCaptain(false);
        setShowConfirmationPopup(true);
        setShowCaptainSearch(false);
    };

    const handleOnRemoveCaptain = (captain) => {
        setCaptainAwaitingAction(captain);
        setIsRemovingCaptain(true);
        setShowConfirmationPopup(true);
    };

    const handleOnConfirmRemoval = () => {
        setShowConfirmationPopup(false);
        onRemoveCaptain(captainAwaitingAction);
        setOriginalCaptains([]);
    };

    const handleOnConfirmAddition = () => {
        setShowConfirmationPopup(false);
        onAddCaptain(captainAwaitingAction);
    };

    const handleOnClosePopup = () => {
        setShowConfirmationPopup(false);
    };

    const action = isRemovingCaptain ? 'Remove' : 'Add';
    const popupAction = isRemovingCaptain ? handleOnConfirmRemoval : handleOnConfirmAddition;
    const popupTitle = `${action} captain`;
    const popupMessage = `Are you sure you want to ${action?.toLowerCase()} ${captainAwaitingAction?.displayName} as a captain?`;
    const displayCaptainSearch = showCaptainSearch || !maxCaptainsAdded;

    const renderCaptains = () =>
        originalCaptains.map((captain) => (
            <ManageSessionCaptainCard key={captain.id} captain={captain} onRemoveClick={handleOnRemoveCaptain} />
        ));

    const renderCaptainSearch = () => (
        <Paper sx={{ p: 3, mb: 1, boxShadow: 'none' }}>
            <PlayerSearch
                onPlayerSelected={handleOnAddCaptain}
                searchMembersOnly
                selectedPlayers={originalCaptains}
                label="Add a captain"
                isRequired={isRequired}
            />
        </Paper>
    );

    return isLoadingTeam ? (
        <LoadingIndicator />
    ) : (
        <>
            <Box my={2}>{isUpdatingCaptains ? <LoadingIndicator /> : renderCaptains()}</Box>
            {displayCaptainSearch && renderCaptainSearch()}
            <Popup
                onAction={popupAction}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showConfirmationPopup}
                onClose={handleOnClosePopup}
                title={popupTitle}
                message={popupMessage}
            />
        </>
    );
};

CreateTeamCaptainManager.propTypes = {
    ...createTeamCaptainManagerPropTypes,
};

export default CreateTeamCaptainManager;
