import * as queryString from 'query-string';
import urljoin from 'url-join';
import srcset from 'srcset';
import config from '../config';

/* eslint no-param-reassign: ["error", { "props": false }] */

export function urlIsAbsolute(url) {
    const regex = /^https?:\/\//;
    const startsWithHttpOrHttps = regex.test(url);
    return startsWithHttpOrHttps;
}

export function formatUrlForCdn(cdnUrl, urlPath) {
    // urls that start with /v*/ need to have that stripped off, other urls need to be prefixed with the public path
    if (!urlIsAbsolute(urlPath)) {
        const regex = /^\/v(0?[1-9]|[1-9][0-9])\//;
        const isApiSource = regex.test(urlPath);

        if (isApiSource) {
            return urljoin(cdnUrl || '', urlPath);
        }

        return urljoin(config.publicPath, urlPath);
    }

    return urlPath;
}

export function updateSrcSetForCdn(srcSet, cdnUrl) {
    const parsedSrcSet = srcset.parse(srcSet);
    parsedSrcSet.forEach((src) => {
        src.url = formatUrlForCdn(cdnUrl, src.url);
    });
    return srcset.stringify(parsedSrcSet);
}
export function parseUrlParam(url, param) {
    const parsed = queryString.parse(url);
    if (param in parsed) {
        return parsed[param];
    }
    return '';
}
