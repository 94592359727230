import moment from 'moment-timezone';
import { SportTypeIds, weekdays, weekdayIds } from './types';
import { ageSliderDictionary, utrpSliderDictionary } from './constants';

const { TENNIS } = SportTypeIds;

export const getAgeKeys = (ages) => {
    return ages.map((age) => parseInt(Object.keys(ageSliderDictionary).find((key) => ageSliderDictionary[key] === age)));
};

export const ageDisplay = (value) => {
    const startRange = value[0];
    const endRange = value[1];
    if (startRange === 1 && endRange === Object.keys(ageSliderDictionary).length) {
        return 'All';
    }
    if (startRange === 1) {
        return `${ageSliderDictionary[endRange]} and Under`;
    }
    if (endRange === Object.keys(ageSliderDictionary).length) {
        return `${ageSliderDictionary[startRange]} and Over`;
    }
    return `${ageSliderDictionary[startRange]} to ${ageSliderDictionary[endRange]}`;
};

export const getAdjustedTimeOfDay = ({ timeOfDay, format = null }) => {
    const day = moment();
    const offset = moment().utcOffset();
    const splitTime = timeOfDay.split(/:/);

    day.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1]));
    day.add(offset, 'minutes');

    if (format) {
        return day.format(format);
    }

    const hours = day.get('hour');
    const minutes = day.get('minute');

    return `${hours}:${minutes}`;
};

export const getPlayerDisplayUtr = (player, isDoubles = false) => {
    const {
        utrDoubles,
        utrDoublesDisplay,
        utrDoublesNotVerified,
        utrDoublesNotVerifiedDisplay,
        utrSingles,
        utrSinglesDisplay,
        utrSinglesNotVerified,
        utrSinglesNotVerifiedDisplay,
        utrRange,
    } = player || {};

    if (isDoubles) {
        if (utrDoubles && utrDoubles !== 0) {
            return { isVerified: true, rating: utrDoublesDisplay };
        }
        if (utrDoublesNotVerified && utrDoublesNotVerified !== 0) {
            return { isVerified: false, rating: utrDoublesNotVerifiedDisplay };
        }
    } else {
        if (utrSingles && utrSingles !== 0) {
            return { isVerified: true, rating: utrSinglesDisplay };
        }
        if (utrSinglesNotVerified && utrSinglesNotVerified !== 0) {
            return { isVerified: false, rating: utrSinglesNotVerifiedDisplay };
        }
    }

    if (utrRange) {
        const { minUtrDisplay, maxUtrDisplay } = utrRange;
        return { isVerified: false, rating: `${minUtrDisplay}-${maxUtrDisplay}` };
    }

    return { isVerified: false, rating: '0.00' };
};

export const getPlayerDisplayPickleballRating = (player) => {
    const { utrRange } = player || {};
    const rating = utrRange?.pickleballRatingDisplay || 'UR';

    return { isVerified: false, rating };
};

export const getPlayerTableDisplayPickleballRating = (player) => {
    const { utrRange } = player || {};

    let rating;

    if (utrRange?.minUtrDisplay && utrRange?.maxUtrDisplay) {
        rating = `${utrRange?.minUtrDisplay} - ${utrRange?.maxUtrDisplay}`;
    } else if (utrRange?.pickleballRatingDisplay) {
        rating = `${utrRange?.pickleballRatingDisplay}`;
    } else {
        rating = 'UR';
    }

    return rating;
};

export const getPlayerRating = ({ player, isTennisMatch, isDoubles = false }) => {
    if (isTennisMatch) {
        return getPlayerDisplayUtr(player, isDoubles);
    }
    return getPlayerDisplayPickleballRating(player);
};

export const getDisplayUTRPRating = (player, isDoubles) => {
    const { singlesPkbRatingDisplay, doublesPkbRatingDisplay, utrRange } = player || {};

    if (isDoubles) {
        const rating = doublesPkbRatingDisplay?.pkbRating || utrRange?.pickleballRatingDisplay || '-';
        const reliability = doublesPkbRatingDisplay?.reliability || (utrRange?.pickleballRatingDisplay && 'Estimated') || '';
        const displayVerifiedIcon = doublesPkbRatingDisplay?.reliability === 'Reliable' || doublesPkbRatingDisplay?.reliability === 'Projected';
        return { rating, reliability, displayVerifiedIcon };
    }

    const rating = singlesPkbRatingDisplay?.pkbRating || utrRange?.pickleballRatingDisplay || '-';
    const reliability = singlesPkbRatingDisplay?.reliability || (utrRange?.pickleballRatingDisplay && 'Estimated') || '';
    const displayVerifiedIcon = singlesPkbRatingDisplay?.reliability === 'Reliable' || singlesPkbRatingDisplay?.reliability === 'Projected';
    return { rating, reliability, displayVerifiedIcon };
};

export const getFormattedDate = (startDateUtc, endDateUtc, format) => {
    const startDate = moment.utc(startDateUtc).local(true);
    const endDate = moment.utc(endDateUtc).local(true);
    const formattedStartDate = startDate.format(format);
    const formattedEndDate = endDate.format(format);
    const isOnSameDay = moment(startDate).isSame(endDate, 'day');

    return isOnSameDay ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`;
};

export const getDayOfWeekById = ({ dayOfWeekId }) => {
    console.log(`[getDayOfWeekById] inputs ${dayOfWeekId}`);
    return weekdays.find((weekday) => weekday.id === dayOfWeekId);
};

export const getDayOfWeekIdByDescription = ({ dayOfWeek }) => {
    console.log(`[getDayOfWeekIdByDescription] inputs ${dayOfWeek}`);
    const matchingWeekday = weekdays.find((weekday) => weekday.description === dayOfWeek);
    return matchingWeekday?.id;
};

export const getDayOfWeekIdForYesterday = ({ dayOfWeekId }) => {
    switch (dayOfWeekId) {
        case weekdayIds.Sunday:
            return weekdayIds.Saturday;
        case weekdayIds.Monday:
            return weekdayIds.Sunday;
        case weekdayIds.Tuesday:
            return weekdayIds.Monday;
        case weekdayIds.Wednesday:
            return weekdayIds.Tuesday;
        case weekdayIds.Thursday:
            return weekdayIds.Wednesday;
        case weekdayIds.Friday:
            return weekdayIds.Thursday;
        case weekdayIds.Saturday:
            return weekdayIds.Friday;
        default:
            return undefined;
    }
};

export const getDayOfWeekIdForTomorrow = ({ dayOfWeekId }) => {
    switch (dayOfWeekId) {
        case weekdayIds.Sunday:
            return weekdayIds.Monday;
        case weekdayIds.Monday:
            return weekdayIds.Tuesday;
        case weekdayIds.Tuesday:
            return weekdayIds.Wednesday;
        case weekdayIds.Wednesday:
            return weekdayIds.Thursday;
        case weekdayIds.Thursday:
            return weekdayIds.Friday;
        case weekdayIds.Friday:
            return weekdayIds.Saturday;
        case weekdayIds.Saturday:
            return weekdayIds.Sunday;
        default:
            return undefined;
    }
};

// The dayOfWeekUtc parameter should be the name of the day, e.g. Monday, Tuesday, etc...
// See the descriptions in the weekdays exported from /src/utils/types.js for valid values.
// If a value other than one of these is passed in, the function will return 'undefined' for the dayOfWeek.
export const getAdjustedDayOfWeekAndTimeOfDay = ({ dayOfWeekUtc, timeOfDayUtc, format, targetTimeZone }) => {
    // Determine target time zone
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (targetTimeZone && moment.tz.zone(targetTimeZone)) {
        timezone = targetTimeZone;
    }

    // Build UTC datetime with the given timeOfDayUtc
    const todayWithTimeOfDayUtc = moment.utc(timeOfDayUtc, 'HH:mm');
    const todayStartOfDayUtc = todayWithTimeOfDayUtc.startOf('day');

    // Convert to local datetime
    const localDateTime = moment.utc(timeOfDayUtc, 'HH:mm').tz(timezone);
    const localDateTimeStartOfDay = localDateTime.clone().format('YYYY-MM-DD');
    const todayStartOfDayLocal = moment.utc(localDateTimeStartOfDay, 'YYYY-MM-DD');

    // Determine if the date of the localized datetime is ahead of or behind the date of the utc datetime
    let adjustedDayOfWeekId = getDayOfWeekIdByDescription({ dayOfWeek: dayOfWeekUtc });
    if (todayStartOfDayUtc.isBefore(todayStartOfDayLocal)) {
        // Day of Week needs to be adjusted one day forward
        adjustedDayOfWeekId = getDayOfWeekIdForTomorrow({ dayOfWeekId: adjustedDayOfWeekId });
    } else if (todayStartOfDayUtc.isAfter(todayStartOfDayLocal)) {
        // Day of week needs to be adjusted one day back
        adjustedDayOfWeekId = getDayOfWeekIdForYesterday({ dayOfWeekId: adjustedDayOfWeekId });
    }

    const adjustedDayOfWeek = getDayOfWeekById({ dayOfWeekId: adjustedDayOfWeekId });
    let timeOfDayFormatted = localDateTime.format('HH:mm:ss');
    if (format) {
        timeOfDayFormatted = localDateTime.format(format);
    }

    return { dayOfWeek: adjustedDayOfWeek, timeofDay: timeOfDayFormatted };
};

export const getAdjustedDate = ({ dateUtc, format = null, adjustForDSL = false }) => {
    const isTodayDLS = moment().isDST();
    const isDateDLS = moment(dateUtc).isDST();

    const shouldAddHour = (isTodayDLS && !isDateDLS && !adjustForDSL) || (!isTodayDLS && isDateDLS && adjustForDSL);
    const shouldSubtractHour = (!isTodayDLS && isDateDLS && !adjustForDSL) || (isTodayDLS && !isDateDLS && adjustForDSL);

    let date;

    if (shouldAddHour) {
        date = moment.utc(dateUtc).local().add(1, 'hour');
    } else if (shouldSubtractHour) {
        date = moment.utc(dateUtc).local().subtract(1, 'hour');
    } else {
        date = moment.utc(dateUtc).local();
    }

    if (format) {
        return date.format(format);
    }

    return date;
};

export const getFormattedUtrRange = (minUtr, maxUtr) => {
    const isUtrMinInteger = Number.isInteger(minUtr);
    const isUtrMaxInteger = Number.isInteger(maxUtr);
    const minRange = isUtrMinInteger ? minUtr.toFixed(1) : minUtr;
    const maxRange = isUtrMaxInteger ? maxUtr.toFixed(1) : maxUtr;

    return `${minRange} - ${maxRange}`;
};

export const utrpSliderDisplay = (value) => {
    const startRange = value[0];
    const endRange = value[1];
    if (startRange && endRange) {
        return `${utrpSliderDictionary[startRange]} to ${utrpSliderDictionary[endRange]}`;
    }
    return '';
};

export const getConferenceTableHeadCells = (sportsTypeId) => {
    const rangeLabel = sportsTypeId === SportTypeIds.TENNIS ? 'UTR' : 'UTRP';
    return [
        { id: 'name', label: 'Conference Name' },
        { id: 'age', label: 'Age' },
        { id: 'gender', label: 'Gender' },
        { id: 'range', label: rangeLabel },
        { id: 'empty' },
    ];
};

export const getFormattedConferenceRange = ({ conferenceRatings, sportTypeId, showRatingPrefix = false }) => {
    const { ratingMin, ratingMax } = conferenceRatings || {};

    if (sportTypeId && ratingMin && ratingMax) {
        const prefix = sportTypeId === TENNIS ? 'UTR' : 'UTRP';
        const range = getFormattedUtrRange(ratingMin, ratingMax);
        return showRatingPrefix ? `${prefix} ${range}` : range;
    }

    return '';
};

export const splitDateAndTime = (dateUtc) => {
    const utcMoment = moment.utc(dateUtc).local();
    const date = utcMoment.clone().startOf('day');
    const time = utcMoment.clone();

    return { date, time };
};

export const combineDateAndTime = (date, time) => {
    const baseDate = moment(date);

    if (!baseDate.isValid() || !time.isValid()) {
        return moment.invalid();
    }

    const combinedDate = moment(date).set({
        hour: time?.hour(),
        minute: time?.minute(),
        second: 0,
        millisecond: 0
    });

    return combinedDate;
};
