import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@universal-tennis/ui-shared';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import PlayerAvatarWithUtr from '../../PlayerAvatarWithUtr';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';

const TeamMatchAlternatesCard = ({ positionAlternates = [] }) => {
    const theme = useTheme();
    const { alternatePlayers } = positionAlternates;

    return (
        <>
            <Typography color={theme.appColors.black} category={SharedUICategories.SECONDARY} size={TypographySizes.X_SMALL_MEDIUM_CAP} mb={1} noWrap>
                {positionAlternates?.teamName} Alternates
            </Typography>
            <Card sx={{ borderRadius: 0, boxShadow: 'none' }}>
                <CardContent>
                    <Grid container flexDirection="column" spacing="2px">
                        {alternatePlayers.map((alternate) => (
                            <Grid item key={alternate?.id}>
                                <PlayerAvatarWithUtr player1={alternate} />
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default TeamMatchAlternatesCard;
