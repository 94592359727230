import React, { useState, useEffect } from 'react';
import { Button } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

import FilterButton from '../FilterButton';
import { multiSelectFilterPropTypes } from '../../utils/proptypes';

const MultiSelectFilter = ({ selectOptions, onApplyFilter, hasResetFilters, label, currentValue }) => {
    const [filterData, setFilterData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    useEffect(() => {
        if (currentValue && !filterData.includes(currentValue)) {
            if (hasResetFilters) {
                setFilterData([currentValue]);
            } else {
                setFilterData([...filterData, currentValue]);
            }
            setIsFilterApplied(true);
        }

        if (!currentValue && hasResetFilters) {
            setFilterData([]);
            setIsFilterApplied(false);
        }
    }, [currentValue, hasResetFilters]);

    const handleOnChange = (event) => {
        const value = parseInt(event.target.value);

        if (filterData.includes(value)) {
            const updatedData = filterData.filter((status) => status !== value);
            setFilterData(updatedData);
        } else {
            setFilterData([...filterData, value]);
        }
    };

    const handleOnApplyClicked = () => {
        const hasNoSelections = !filterData.length;
        const appliedFilters = hasNoSelections ? null : filterData;
        onApplyFilter(appliedFilters);

        if (hasNoSelections) {
            setFilterData([]);
            setIsFilterApplied(false);
        } else {
            setIsFilterApplied(true);
        }
        setIsOpen(false);
    };

    const handleOnOpen = () => {
        setIsOpen(true);
    };

    const handleOnClose = () => {
        setIsOpen(false);
    };

    const getButtonLabel = () => {
        let buttonLabel = label;

        if (filterData.length) {
            const appliedFilters = selectOptions.filter((type) => filterData.includes(type.id)).map((status) => status.description);
            buttonLabel = `${appliedFilters.join(', ')}`;
        }
        return buttonLabel;
    };

    return (
        <FilterButton
            isOpen={isOpen}
            onOpen={handleOnOpen}
            onClose={handleOnClose}
            isFilterApplied={isFilterApplied}
            buttonTitle={getButtonLabel()}
        >
            <Box p={2} width={350}>
                <FormControl sx={{ mx: 3 }} component="fieldset" variant="standard">
                    <FormGroup>
                        {selectOptions.map((status) => {
                            const { id, description } = status;
                            const isChecked = filterData.includes(id);
                            return (
                                <FormControlLabel
                                    key={id}
                                    control={<Checkbox value={id} checked={isChecked} onChange={handleOnChange} name={description} />}
                                    label={description}
                                />
                            );
                        })}
                    </FormGroup>
                </FormControl>
                <Box mt={3} display="flex" justifyContent="center">
                    <Button onClick={handleOnApplyClicked} category="primary">
                        Apply
                    </Button>
                </Box>
            </Box>
        </FilterButton>
    );
};

MultiSelectFilter.propTypes = {
    ...multiSelectFilterPropTypes.propTypes
};

MultiSelectFilter.defaultProps = {
    ...multiSelectFilterPropTypes.defaultProps
};

export default MultiSelectFilter;
