import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { SinglePersonIcon, DoublePersonIcon } from '@universal-tennis/ui-shared';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import LoadingIndicator from '../../LoadingIndicator';
import { SortType } from '../../../utils/constants';
import { getComparator } from '../../../utils/helpers';
import { leaguesTablePropTypes } from '../../../utils/proptypes';

const LeaguesTable = (props) => {
    const theme = useTheme();
    const { headCells, tableData, isLoading, sortBy, rowComponent, title = null, isSortingDisabled = false } = props;
    const [sortOrder, setSortOrder] = useState(SortType.ASC);
    const [orderProperty, setOrderProperty] = useState(sortBy);
    const TableRow = rowComponent;

    const handleRequestSort = (property) => (event) => {
        event.preventDefault();
        const isAscending = orderProperty === property && sortOrder === SortType.ASC;
        setSortOrder(isAscending ? SortType.DESC : SortType.ASC);
        setOrderProperty(property);
    };

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            {title && (
                <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>
                </Toolbar>
            )}
            <TableContainer>
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <MuiTableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align="left"
                                        padding="normal"
                                        sortDirection={orderProperty === headCell.id ? sortOrder : false}
                                    >
                                        <TableSortLabel
                                            disabled={isSortingDisabled}
                                            hideSortIcon={!headCell.label}
                                            active={orderProperty === headCell.id}
                                            direction={orderProperty === headCell.id ? sortOrder : SortType.ASC}
                                            onClick={handleRequestSort(headCell.id)}
                                            sx={{ whiteSpace: 'nowrap' }}
                                        >
                                            <>
                                                {headCell.label}
                                                {headCell.id === 'utrp-numeric-single' && (
                                                    <SinglePersonIcon color={theme.appColors.black} size={14} />
                                                )}
                                                {headCell.id === 'utrp-numeric-double' && (
                                                    <DoublePersonIcon color={theme.appColors.black} width={24} height={14} />
                                                )}
                                            </>
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </MuiTableRow>
                        </TableHead>
                        <TableBody>
                            {tableData &&
                                tableData
                                    .slice()
                                    .sort(getComparator(sortOrder, orderProperty))
                                    .map((data, index) => {
                                        const labelId = `table-${index}`;
                                        return <TableRow key={data.id} rowData={data} labelId={labelId} />;
                                    })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Paper>
    );
};

LeaguesTable.propTypes = {
    ...leaguesTablePropTypes,
};

export default LeaguesTable;
