import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, SinglePersonIcon, DoublePersonIcon } from '@universal-tennis/ui-shared';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';

import LoadingIndicator from '../../LoadingIndicator';
import { SortType, SharedUICategories, TypographySizes } from '../../../utils/constants';
import { getComparator } from '../../../utils/helpers';
import { genericTablePropTypes } from '../../../utils/proptypes';

const GenericTable = (props) => {
    const { headCells, tableData, isLoading, sortBy, rowComponent, isSortingDisabled = false } = props;
    const theme = useTheme();
    const [sortOrder, setSortOrder] = useState(SortType.ASC);
    const [orderProperty, setOrderProperty] = useState(sortBy);
    const TableRow = rowComponent;
    const { SECONDARY } = SharedUICategories;
    const { XX_SMALL_MEDIUM_CAP } = TypographySizes;

    const handleRequestSort = (property) => (event) => {
        event.preventDefault();
        const isAscending = orderProperty === property && sortOrder === SortType.ASC;
        setSortOrder(isAscending ? SortType.DESC : SortType.ASC);
        setOrderProperty(property);
    };

    return (
        <Paper sx={{ mb: 2, boxShadow: 'none', backgroundColor: 'transparent' }}>
            <TableContainer>
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <Table
                        sx={{ minWidth: 750, borderCollapse: 'separate', borderSpacing: '0px 4px' }}
                        aria-labelledby="tableTitle"
                        size="large"
                    >
                        <TableHead>
                            <MuiTableRow>
                                {headCells.map((headCell, index) => {
                                    const isFirstLabel = index === 0;
                                    const widthOfSortIcon = '22px';
                                    return (
                                        <TableCell
                                            sx={{ border: 'none', py: '6px' }}
                                            key={headCell.id}
                                            align={isFirstLabel ? 'left' : 'center'}
                                            padding="normal"
                                            sortDirection={orderProperty === headCell.id ? sortOrder : false}
                                        >
                                            <TableSortLabel
                                                disabled={isSortingDisabled}
                                                hideSortIcon={!headCell.label}
                                                active={orderProperty === headCell.id}
                                                direction={orderProperty === headCell.id ? sortOrder : SortType.ASC}
                                                onClick={handleRequestSort(headCell.id)}
                                                sx={{ pl: !isFirstLabel && widthOfSortIcon }}
                                            >
                                                <>
                                                    <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP}>
                                                        {headCell.label}
                                                    </Typography>
                                                    {headCell.id === 'utrp-numeric-single' && (
                                                        <SinglePersonIcon color={theme.appColors.black} size={11} />
                                                    )}
                                                    {headCell.id === 'utrp-numeric-double' && (
                                                        <DoublePersonIcon color={theme.appColors.black} width={20} height={11} />
                                                    )}
                                                </>
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                })}
                            </MuiTableRow>
                        </TableHead>
                        <TableBody>
                            {tableData &&
                                tableData.sort(getComparator(sortOrder, orderProperty)).map((data, index) => {
                                    const labelId = `table-${index}`;
                                    return <TableRow key={data.id} rowData={data} labelId={labelId} />;
                                })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Paper>
    );
};

GenericTable.propTypes = {
    ...genericTablePropTypes,
};

export default GenericTable;
