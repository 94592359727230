import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';

import { StyledTableRow } from '../../Styled';
import LeaguesTable from '../LeaguesTableWrapper';
import { formatConferencesForTable } from '../../../utils/helpers';
import { getConferenceTableHeadCells } from '../../../utils/display';

const ConferencesTable = ({ leagueConferences = [], isLoading, onManageClicked }) => {
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);
    const [conferences, setConferences] = useState([]);
    const headCells = getConferenceTableHeadCells(sportTypeId);

    useEffect(() => {
        if (leagueConferences) {
            const formattedConferences = formatConferencesForTable(leagueConferences, sportTypeId);
            setConferences(formattedConferences);
        }
    }, [leagueConferences, sportTypeId]);

    const ConferenceTableRow = ({ rowData, labelId }) => {
        const { id, name, age, gender, range } = rowData;

        const handleOnManageConferenceClicked = (conferenceId) => {
            onManageClicked(conferenceId);
        };
        return (
            <StyledTableRow hover tabIndex={-1} key={id}>
                <TableCell component="th" id={labelId} scope="row" width="30%" padding="normal">
                    {name}
                </TableCell>
                <TableCell align="left">{age}</TableCell>
                <TableCell align="left">{gender}</TableCell>
                <TableCell align="left">{range}</TableCell>
                <TableCell align="left">
                    <Button onClick={() => handleOnManageConferenceClicked(id)}>Manage</Button>
                </TableCell>
            </StyledTableRow>
        );
    };

    return (
        <LeaguesTable
            tableData={conferences}
            headCells={headCells}
            isLoading={isLoading}
            sortBy="name"
            rowComponent={ConferenceTableRow}
        />
    );
};

export default ConferencesTable;
