import React from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { Typography, FullPageModal } from '@universal-tennis/ui-shared';
import { useForm, useFieldArray } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import { getPowerIndex } from '../../utils/helpers';
import { createDrawModalPropTypes, defaultCreateDrawModalPropTypes } from '../../utils/proptypes';

const CreateDrawModal = (props) => {
    const theme = useTheme();
    const { onClose, open, onSubmit, selectableTeams, onSkip } = props;

    const mappedSelectableTeams = selectableTeams?.map((team) => {
        return {
            teamId: team?.id,
            name: team?.name,
            seed: team?.seed,
            teamPowerIndex: team?.teamPowerIndex
        };
    });
    const sortDefaultvalues = mappedSelectableTeams?.slice().filter((team) => !!team.seed);
    const sortedArray = sortDefaultvalues?.sort((a, b) => parseFloat(a.seed) - parseFloat(b.seed)) || [];

    const defaultValues = sortedArray.length === selectableTeams.length ? {
        drawTeams: sortedArray
    } : {
        drawTeams: [...sortedArray, { name: '' }]
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        watch,
    } = useForm({ defaultValues, mode: 'all' });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'drawTeams',
        rules: { minLength: 1 }
    });

    const SEED_DESCRIPTION = `Provide up to ${selectableTeams?.length} seeds for the draw.`;

    const handleFormSubmit = (values) => {
        onSubmit(values.drawTeams);
    };

    const watchFieldArray = watch('drawTeams', defaultValues);
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const isShowingCTAButton = (controlledFields.length >= 1 && controlledFields[0].teamId);

    const getTeamNameForSelection = (teamId) => {
        const selectedTeam = mappedSelectableTeams?.find((team) => team?.teamId === teamId);
        return `${selectedTeam?.name}`;
    };

    return (
        <FullPageModal
            onClose={onClose}
            isOpen={open}
            pageTitle="Draw seeding"
            bottomCTAText={isShowingCTAButton ? 'SEED TEAMS' : 'SKIP'}
            onBottomCTAClick={isShowingCTAButton ? handleSubmit(handleFormSubmit) : () => onSkip()}
        >
            <Box sx={{ height: '100%', justifyContent: 'center', width: '100%', display: 'flex', overflowY: 'scroll' }}>
                <Box pt={3} sx={{ width: '600px' }}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <DialogContent>
                            <Typography category="secondary" size="medium-book">
                                {SEED_DESCRIPTION}
                            </Typography>

                            {controlledFields.map((item, index) => {
                                return (
                                    <Box key={item.id} pt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ paddingRight: '4px', width: '50px' }} category="secondary" size="medium">
                                            {index + 1}.
                                        </Typography>

                                        <FormControl fullWidth>
                                            <InputLabel id="draw-team-format-label">Select Team</InputLabel>
                                            <Select
                                                label="Select Team"
                                                error={!!errors.drawTeams}
                                                labelId="draw-team-format-label"
                                                id="draw-team-format-label"
                                                defaultValue={item.teamId}
                                                sx={{ width: '100%' }}
                                                renderValue={(value) => getTeamNameForSelection(value)}
                                                {...register(`drawTeams.${index}.teamId`)}
                                            >
                                                {mappedSelectableTeams
                                                    .filter((selectableTeam) => {
                                                        const isSelectableTeamSelected = !!controlledFields.find((selectedTeam) => selectedTeam.teamId === selectableTeam.teamId);
                                                        const isSelectableTeamIsInThisField = selectableTeam.teamId === item.teamId;

                                                        if (isSelectableTeamIsInThisField) return selectableTeam;
                                                        if (isSelectableTeamSelected) return;
                                                        return selectableTeam;
                                                    })
                                                    .map((team) => (
                                                        <MenuItem key={team.teamId} value={team.teamId}>
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <Typography category="secondary" size="small-book" noWrap overflow="hidden" textOverflow="ellipsis">{team.name}</Typography>
                                                                </Grid>
                                                                <Grid item xs={3} gap={0.5} display="flex">
                                                                    <Typography category="secondary" size="small-medium">TPI S: </Typography>
                                                                    <Typography category="secondary" size="small-book">{getPowerIndex(team?.teamPowerIndex?.singlesRating, team?.teamPowerIndex?.isSinglesEstimated)}</Typography>
                                                                </Grid>
                                                                <Grid item xs={3} gap={0.5} display="flex">
                                                                    <Typography category="secondary" size="small-medium">TPI D:</Typography>
                                                                    <Typography category="secondary" size="small-book">{getPowerIndex(team?.teamPowerIndex?.doublesRating, team?.teamPowerIndex?.isDoublesEstimated)}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                    )) }
                                            </Select>
                                        </FormControl>
                                        {selectableTeams?.length === controlledFields?.length ? null : (
                                            <button onClick={() => append({ name: '', teamId: null, seed: null })} style={{ background: 'none', border: 'none', marginLeft: '16px' }} type="button">
                                                <ControlPointIcon sx={{ color: theme.appColors.blue, fontSize: '32px' }} />
                                            </button>
                                        ) }
                                        {controlledFields?.length !== 1 ? (
                                            <button onClick={() => remove(index)} style={{ background: 'none', border: 'none' }} type="button">
                                                <RemoveCircleOutlineIcon sx={{ color: theme.appColors.blue, fontSize: '32px' }} />
                                            </button>
                                        ) : null}
                                    </Box>
                                );
                            })}
                        </DialogContent>
                    </form>
                </Box>
            </Box>
        </FullPageModal>
    );
};

CreateDrawModal.propTypes = {
    ...createDrawModalPropTypes
};

CreateDrawModal.defaultProps = {
    ...defaultCreateDrawModalPropTypes
};

export default CreateDrawModal;
