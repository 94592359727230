import React from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material';
import { Button, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import UtrIcon from '../Icons/UtrIcon';
import { getAgeKeys, ageDisplay, getFormattedConferenceRange } from '../../utils/display';
import { getTypeForId, genderTypes, RequestStatusTypes, StatusTypes, SportTypeIds, LeagueSanctionTypes } from '../../utils/types';
import { TypographySizes, SharedUICategories } from '../../utils/constants';
import { registrationInfoCardPropTypes } from '../../utils/proptypes';
import usapLogo from '../../assets/logos/usap-sanctioned-logo.png';

const RegistrationInfoCard = ({
    cost = '',
    format = '',
    sessionDates = '',
    sessionData = {},
    onRequestToCaptain,
    onRegisterButtonClick,
}) => {
    const theme = useTheme();
    const { USAP } = LeagueSanctionTypes;
    const { XX_SMALL_MEDIUM_CAP, MEDIUM_BOOK, X_SMALL_BOOK } = TypographySizes;
    const { PRIMARY, SECONDARY } = SharedUICategories;
    const { conference, session, captainRequest } = sessionData;
    const { conferenceRatings, sportTypeId } = conference || {};
    const { TENNIS } = SportTypeIds;
    const { currentRequest, isCaptainInSession } = captainRequest || {};
    const { PENDING } = RequestStatusTypes;
    const ageKeys = getAgeKeys([conference?.minAge, conference?.maxAge]);
    const ageRange = ageDisplay(ageKeys);
    const gender = getTypeForId(conference?.genderTypeId, genderTypes)?.description;
    const isTennis = sportTypeId === TENNIS;
    const rangeTitle = isTennis ? 'UTR Range' : 'UTR-P Range';
    const range = getFormattedConferenceRange({ conferenceRatings, sportTypeId, showRatingPrefix: false });
    const location = conference?.location?.placeName ? `${conference?.location?.placeName}` : '';
    const today = moment.utc();
    const isUSAPSanctionedSession = session?.sanctionTypeId === USAP;
    const isPlayerInSession = sessionData?.memberTeams?.length !== 0;
    const isCaptainRequestPending = currentRequest?.sessionRequestStatusTypeId === PENDING;
    const isTodayBeforeSessionEndDate = today.isBefore(session?.endDateUtc, 'day');
    const isTodayBeforeRegistrationEndDate = today.isBefore(session?.teamRegistrationEndDateUtc, 'day');
    const isNotAPlayerOrCaptainInSession = !isPlayerInSession && !isCaptainInSession;
    const showRequestToCaptainButton = isTodayBeforeRegistrationEndDate && !isCaptainRequestPending && isNotAPlayerOrCaptainInSession;
    const canRegister = isTodayBeforeSessionEndDate && isNotAPlayerOrCaptainInSession;
    const formattedStartDate = moment(session?.teamRegistrationStartDateUtc).format('MMMM Do');
    const formattedEndDate = moment(session?.teamRegistrationEndDateUtc).format('MMMM Do');
    const TEAMS_FORMING_TEXT = `Teams are forming now. Register between ${formattedStartDate} - ${formattedEndDate}.`;
    const TEAMS_FORMING_CAPTAIN_TEXT = `${TEAMS_FORMING_TEXT} Interested in being captain?`;
    const showTeamsFormingText =
        (isTodayBeforeRegistrationEndDate && isNotAPlayerOrCaptainInSession) || session?.sessionStatusTypeId === StatusTypes.CLOSED;

    return (
        <Card sx={{ borderRadius: 0, boxShadow: 'none' }}>
            <CardContent>
                <Box mb={1.5}>
                    <Grid container mb={1}>
                        <Grid item xs={4}>
                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.blueGrey}>
                                Cost
                            </Typography>
                            <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                {cost}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.blueGrey}>
                                Gender
                            </Typography>
                            <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                {gender}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.blueGrey}>
                                Age Range
                            </Typography>
                            <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                {ageRange}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container mb={1}>
                        <Grid item xs={4}>
                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.blueGrey}>
                                {rangeTitle}
                            </Typography>
                            <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                {range}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.blueGrey}>
                                Format
                            </Typography>
                            <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                {format}
                            </Typography>
                        </Grid>
                        {location && (
                            <Grid item xs={4}>
                                <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.blueGrey}>
                                    Location
                                </Typography>
                                <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                    {location}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP} color={theme.appColors.blueGrey}>
                                Session Dates
                            </Typography>
                            <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                {sessionDates}
                            </Typography>
                        </Grid>
                        {isUSAPSanctionedSession && (
                            <Grid item xs={4}>
                                <UtrIcon size={60} icon={usapLogo} alt="USAP sanctioned logo" />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Button category="primary-expanded" disabled={!canRegister} onClick={onRegisterButtonClick} sx={{ mb: 2 }}>
                        Register
                    </Button>
                    {showTeamsFormingText && showRequestToCaptainButton && (
                        <Typography category={SECONDARY} size={X_SMALL_BOOK} mb={0.5}>
                            {isCaptainRequestPending ? TEAMS_FORMING_TEXT : TEAMS_FORMING_CAPTAIN_TEXT}
                        </Typography>
                    )}
                    {isPlayerInSession && isTodayBeforeSessionEndDate && (
                        <Typography category={SECONDARY} size={X_SMALL_BOOK} mb={0.5}>
                            You&apos;re Registered for teams.
                        </Typography>
                    )}
                    {showRequestToCaptainButton && (
                        <Button onClick={onRequestToCaptain} category="link">
                            <Typography category={SECONDARY} size={X_SMALL_BOOK}>
                                Request to captain
                            </Typography>
                        </Button>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

RegistrationInfoCard.propTypes = {
    ...registrationInfoCardPropTypes,
};

export default RegistrationInfoCard;
