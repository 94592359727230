import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { AvatarWithName, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';

import { getPlayerDisplayUtr } from '../../utils/display';
import { SharedUICategories, TypographySizes } from '../../utils/constants';
import { SportTypeIds } from '../../utils/types';
import { getPlayerProfileUrl } from '../../utils/helpers';

const PlayerAvatarWithUtr = ({ player1 = {}, player2 = {}, isWinner = false, isDoubles = false }) => {
    const theme = useTheme();
    const fontSize = isWinner ? TypographySizes.MEDIUM_MEDIUM : TypographySizes.MEDIUM_BOOK;
    const player1FirstLast = player1 && `${player1.firstName} ${player1.lastName}`;
    const player2FirstLast = player2 && `${player2.firstName} ${player2.lastName}`;
    const player1Name = player1FirstLast || 'To Be Determined';
    const player2Name = player2FirstLast || 'To Be Determined';
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);
    const isTennisMatch = sportTypeId === SportTypeIds.TENNIS;

    const AvatarWithUtrTemplate = ({ player, playerName }) => {
        const href = player ? getPlayerProfileUrl(player?.playerId) : null;
        const rating = isTennisMatch ? getPlayerDisplayUtr(player, isDoubles).rating : player?.utrRange?.pickleballRatingDisplay;
        return (
            <>
                <AvatarWithName href={href} fontSize={fontSize} avatarWidth="26px" avatarHeight="26px" avatarFontSize={TypographySizes.SMALL_BOOK} imgSrc={player?.profileImage} playerName={playerName} />
                {player && <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.X_SMALL_BOOK} color={theme.appColors.blueGrey}>{rating}</Typography>}
            </>
        );
    };

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <AvatarWithUtrTemplate player={player1} playerName={player1Name} />
            </Box>
            {isDoubles && (
                <Box display="flex" alignItems="center" gap={1} mt="2px">
                    <AvatarWithUtrTemplate player={player2} playerName={player2Name} />
                </Box>
            )}
        </>
    );
};

export default PlayerAvatarWithUtr;
