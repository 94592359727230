import React, { useEffect, useState } from 'react';
import { Button } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import DistanceSlider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import FilterButton from '../../../components/FilterButton';
import Location from '../../../components/ControlledLocationAutoComplete';
import { DistanceSliderMarks } from '../../../utils/constants';
import { getBrowserLocationCoordinates, getLocationData, getLocationNameFromCoordinates } from '../../../utils/location';

const LocationFilter = ({
    hasResetFilters,
    onApplyFilter,
    onIsLoadingCurrentLocation,
    onClearLocation,
    onCurrentLocationClick,
    currentLocation,
}) => {
    const defaultSliderValue = 50;
    const defaultFilterData = {
        value: '',
        label: 'Location',
        sliderValue: defaultSliderValue,
    };
    const [filterData, setFilterData] = useState(defaultFilterData);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { value, label, sliderValue } = filterData;

    const getLocationName = async (lat, lng) => {
        const location = await getLocationNameFromCoordinates(lat, lng);
        setFilterData({ ...filterData, value: location, label: `Near ${location}` });
        setIsFilterApplied(true);
    };

    useEffect(() => {
        if (hasResetFilters) {
            setFilterData(defaultFilterData);
            setIsFilterApplied(false);
        }
    }, [hasResetFilters]);

    useEffect(() => {
        if (currentLocation) {
            getLocationName(currentLocation.lat, currentLocation.lng);
        } else {
            setIsFilterApplied(false);
            setFilterData(defaultFilterData);
        }
    }, [currentLocation]);

    const handleOnLocationChange = (newLocation) => {
        setFilterData({ ...filterData, value: newLocation });
    };

    const handleOnSliderChange = (event) => {
        setFilterData({ ...filterData, sliderValue: event.target.value });
    };

    const handleOnCurrentLocationClick = async () => {
        setIsOpen(false);
        if (navigator.geolocation) {
            onIsLoadingCurrentLocation(true);

            const { lat, lng, error } = await getBrowserLocationCoordinates();
            onCurrentLocationClick(lat, lng, sliderValue, error);

            if (lat && lng) {
                await getLocationName(lat, lng);
            }
        }
    };

    const handleOnApplyLocation = async () => {
        setIsOpen(false);
        if (value?.place_id) {
            const locationData = await getLocationData(value);
            const { lat, lng } = locationData;
            onApplyFilter(sliderValue, lat, lng);
            await getLocationName(lat, lng);
        } else {
            onApplyFilter(sliderValue);
        }

        setIsFilterApplied(true);
    };

    const handleOnOpenLocation = () => {
        setIsOpen(true);
    };

    const handleOnCloseLocation = () => {
        setIsOpen(false);
    };

    const handleOnClearLocation = () => {
        setFilterData(defaultFilterData);
        setIsOpen(false);
        setIsFilterApplied(false);
        onClearLocation();
    };

    return (
        <FilterButton
            isOpen={isOpen}
            onOpen={handleOnOpenLocation}
            onClose={handleOnCloseLocation}
            isFilterApplied={isFilterApplied}
            buttonTitle={label}
        >
            <Box p={4} width={450}>
                <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">Location</Typography>
                    <Button onClick={handleOnClearLocation} sx={{ my: 2 }}>
                        Clear
                    </Button>
                </Box>
                <Location value={value} onChange={handleOnLocationChange} variant="standard" />
                <Button onClick={handleOnCurrentLocationClick} sx={{ my: 2 }} startIcon={<LocationOnIcon />}>
                    Current Location
                </Button>
                <Box my={2}>
                    <Typography mb={2} variant="h5">
                        Distance
                    </Typography>
                    <DistanceSlider
                        value={sliderValue}
                        onChange={handleOnSliderChange}
                        max={125}
                        step={null}
                        min={10}
                        marks={DistanceSliderMarks}
                    />
                </Box>
                <Box display="flex" justifyContent="center">
                    <Button disabled={!value} onClick={handleOnApplyLocation} category="primary">
                        Apply
                    </Button>
                </Box>
            </Box>
        </FilterButton>
    );
};

export default LocationFilter;
