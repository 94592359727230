import config from '../config';
import { ageDisplay, getAgeKeys, getFormattedConferenceRange } from './display';
import { DefaultSliderValues, SortType } from './constants';
import { genderTypes, PlayerAvailabilityStatusTypes, LeagueTypes, LeagueTierTypes, SportTypeIds, SliderTypes } from './types';

const { PICKLEBALL, TENNIS } = SportTypeIds;
const { UT_ADULT_TEAM_PICKLEBALL, UT_ADULT_TEAM_TENNIS, UT_JUNIOR_TEAM_PICKLEBALL, UT_JUNIOR_TEAM_TENNIS } = LeagueTypes;

export const messageUser = (memberId, displayName) => {
    window.open(`${config.appHost}/inbox?rid=${memberId}&rname=${displayName}`, '_blank').focus();
};

export const formatImageSizesByType = ({ baseUrl, imageSrc, imageType }) => {
    return `${baseUrl}${imageSrc}?size=1X&type=${imageType} 1x,
     ${baseUrl}${imageSrc}?size=2X&type=${imageType} 2x,
     ${baseUrl}${imageSrc}?size=3X&type=${imageType} 3x`;
};

export const formatConferencesForTable = (conferences, sportTypeId) => {
    return conferences.map((conference) => {
        const { id, name, genderTypeId, minAge, maxAge, conferenceRatings } = conference;
        const genderType = genderTypes.find((gender) => gender.id === genderTypeId);
        const ageKeys = getAgeKeys([minAge, maxAge]);
        const age = ageDisplay(ageKeys);

        return {
            id,
            name,
            age,
            gender: genderType?.description,
            range: getFormattedConferenceRange({ conferenceRatings, sportTypeId })
        };
    });
};

export const getAlternateIds = (alternates) => {
    return alternates?.filter((alternate) => alternate?.teamMemberId).map((alternate) => alternate.teamMemberId);
};

export const getSinglesUtr = (utrRating) => {
    if (utrRating && typeof utrRating === 'string' && utrRating?.includes('-')) {
        const array = utrRating.split('-');
        const numArray = array?.map(Number);
        const numAverage = numArray?.reduce((accumulator, currentValue) => (accumulator + currentValue) / numArray.length);
        return numAverage;
    }
    return parseFloat(utrRating);
};

export const getPowerIndex = (powerRating, isEstimated) => {
    let rating;
    if (isEstimated && powerRating) {
        rating = `${powerRating} Est`;
    } else if (powerRating) {
        rating = `${powerRating}`;
    } else {
        rating = '—';
    }
    return rating;
};

export const getPlayerAvailabilityStatus = (status) => {
    const { NOT_SET } = PlayerAvailabilityStatusTypes;
    let availabilityStatus = status;
    if (!status) {
        availabilityStatus = NOT_SET;
    }
    return availabilityStatus;
};

export const sortPlayersByAvailability = (players) => [...players].sort(function (a, b) {
    return getPlayerAvailabilityStatus(a?.playerAvailabilityStatusTypeId) - getPlayerAvailabilityStatus(b?.playerAvailabilityStatusTypeId) ||
    getSinglesUtr(b?.singlesUtr) - getSinglesUtr(a?.singlesUtr) ||
    b?.utrSingles - a?.utrSingles;
});

export const validateLineups = (lineupData) => {
    let error = null;
    const { lineups, position1TeamAlternatePlayers, position2TeamAlternatePlayers } = lineupData;

    lineups.forEach((lineup) => {
        const { position1, position2 } = lineup || {};
        const { player1TeamMemberId: position1Player1Id, player2TeamMemberId: position1Player2Id } = position1 || {};
        const { player1TeamMemberId: position2Player1Id, player2TeamMemberId: position2Player2Id } = position2 || {};

        const playerIds = [position1Player1Id, position1Player2Id, position2Player1Id, position2Player2Id];
        const filteredIds = playerIds.filter((id) => id);
        const hasDuplicatePlayers = new Set(filteredIds).size !== filteredIds.length;

        if (hasDuplicatePlayers) {
            error = `The same player appears twice in ${lineup?.isDouble ? 'Doubles' : 'Singles'} Line Match ${lineup?.lineupPosition}`;
        }
    });

    const hasDuplicatePosition1Alternates = new Set(position1TeamAlternatePlayers?.teamMemberIds).size !== position1TeamAlternatePlayers?.teamMemberIds?.length;
    const hasDuplicatePosition2Alternates = new Set(position2TeamAlternatePlayers?.teamMemberIds).size !== position2TeamAlternatePlayers?.teamMemberIds?.length;

    if (hasDuplicatePosition1Alternates || hasDuplicatePosition2Alternates) {
        error = 'A player can only appear once as an alternate';
    }

    return error;
};

export const getPlayerAvatarImage = (profileImage) => {
    const { cdnUrl } = config;
    return `${cdnUrl}/v1/player/${profileImage}`;
};

export const getPlayerProfileUrl = (playerId) => {
    return `${config.appHost}/profiles/${playerId}`;
};

export const mapMembersForPlayerSearch = (members) => {
    return members.map((member) => {
        const { firstName, lastName, memberId, location, id } = member;
        return {
            id,
            displayName: `${firstName} ${lastName}`,
            location: {
                display: location?.placeName || location?.formattedAddress,
            },
            memberId,
            playerProfileImages: [],
        };
    });
};

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === SortType.DESC ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

export const getNewRoundNumberOptions = (rounds) => {
    const options = [];
    options.push({ description: 'Before Round 1', id: 1 });
    for (let i = 0; i < rounds.length; i += 1) {
        options.push({ description: `After Round ${i + 1}`, id: i + 2 });
    }
    return options;
};

export const getLeagueTypeIdForTier = (leagueTierTypes, sportTypeId) => {
    const { ADULT, JUNIOR } = LeagueTierTypes;

    if (leagueTierTypes?.length > 1 || !leagueTierTypes?.length) {
        if (sportTypeId === PICKLEBALL) {
            return [UT_ADULT_TEAM_PICKLEBALL, UT_JUNIOR_TEAM_PICKLEBALL];
        }
        if (sportTypeId === TENNIS) {
            return [UT_ADULT_TEAM_TENNIS, UT_JUNIOR_TEAM_TENNIS];
        }
    } else {
        if (leagueTierTypes[0] === ADULT && sportTypeId === TENNIS) {
            return [UT_ADULT_TEAM_TENNIS];
        }
        if (leagueTierTypes[0] === JUNIOR && sportTypeId === TENNIS) {
            return [UT_JUNIOR_TEAM_TENNIS];
        }
        if (leagueTierTypes[0] === ADULT && sportTypeId === PICKLEBALL) {
            return [UT_ADULT_TEAM_PICKLEBALL];
        }
        if (leagueTierTypes[0] === JUNIOR && sportTypeId === PICKLEBALL) {
            return [UT_JUNIOR_TEAM_PICKLEBALL];
        }
    }

    return [];
};

export const getRangeForSlider = (sliderType) => {
    const { AGE, UTR, UTRP } = SliderTypes;
    const UTPRRanges = {
        P1: 1,
        P2: 2,
        P3: 3,
        P4: 4
    };

    const { ageRange, utrRange } = DefaultSliderValues;
    switch (sliderType) {
        case AGE:
            return ageRange;
        case UTR:
            return utrRange;
        case UTRP:
            // slider value will be updated with DefaultSliderValues.utrpRange when search endpoint is updated with new conferenceRatings response
            return [UTPRRanges.P1, UTPRRanges.P4];
        default:
            return [];
    }
};

export const getSportTypeForLeagueId = (id) => {
    const isTennisLeague = id === UT_ADULT_TEAM_TENNIS || id === UT_JUNIOR_TEAM_TENNIS;
    const isPickleballLeague = id === UT_JUNIOR_TEAM_PICKLEBALL || id === UT_ADULT_TEAM_PICKLEBALL;

    if (isTennisLeague) {
        return TENNIS;
    }

    if (isPickleballLeague) {
        return PICKLEBALL;
    }
};
