import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AvailabilityYesIcon, AvailabilityNoIcon, AvailabilityMaybeIcon, AvailabilityNotSetIcon } from '@universal-tennis/ui-shared';

import IconButton from '@mui/material/IconButton';
import { PlayerAvailabilityStatusTypes } from '../../utils/types';

const PlayerAvailabilityIndicator = ({ availabilityStatus = null, onAvailabilityClick, isIconOnly = false }) => {
    const theme = useTheme();
    const { YES, MAYBE, NO } = PlayerAvailabilityStatusTypes;
    const iconSize = 24;

    const getButtonForAvailabilityStatus = (status) => {
        switch (status) {
            case YES:
                return <IconButton onClick={onAvailabilityClick} aria-label="Edit player availability"><AvailabilityYesIcon color={theme.appColors.green} size={iconSize} /></IconButton>;
            case MAYBE:
                return <IconButton onClick={onAvailabilityClick} aria-label="Edit player availability"><AvailabilityMaybeIcon color={theme.appColors.orange} size={iconSize} /></IconButton>;
            case NO:
                return <IconButton onClick={onAvailabilityClick} aria-label="Edit player availability"><AvailabilityNoIcon color={theme.appColors.red} size={iconSize} /></IconButton>;
            default:
                return <IconButton onClick={onAvailabilityClick} aria-label="Edit player availability"><AvailabilityNotSetIcon color={theme.appColors.black} size={iconSize} /></IconButton>;
        }
    };

    const getIconForAvailabilityStatus = (status) => {
        switch (status) {
            case YES:
                return <AvailabilityYesIcon color={theme.appColors.green} size={iconSize} />;
            case MAYBE:
                return <AvailabilityMaybeIcon color={theme.appColors.orange} size={iconSize} />;
            case NO:
                return <AvailabilityNoIcon color={theme.appColors.red} size={iconSize} />;
            default:
                return <AvailabilityNotSetIcon color={theme.appColors.black} size={iconSize} />;
        }
    };

    return (
        <>
            {isIconOnly ? (
                getIconForAvailabilityStatus(availabilityStatus)
            ) : (
                getButtonForAvailabilityStatus(availabilityStatus)
            )}
        </>
    );
};

export default PlayerAvailabilityIndicator;
