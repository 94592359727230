import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingIndicator = ({ size = 60, backgroundColor, ...props }) => (
    <Box py={5} display="flex" justifyContent="center" bgcolor={backgroundColor} alignItems="center">
        <CircularProgress size={size} {...props} />
    </Box>
);

export default LoadingIndicator;
