import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const Input = ({ control, name, hasError, label, isRequired = false, ...rest }) => (
    <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field }) => <TextField {...field} {...rest} error={hasError} fullWidth required={isRequired} label={label} />}
    />
);

export default Input;
