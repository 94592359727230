import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

import { SwapNoBorderIcon } from '@universal-tennis/ui-shared';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Location from '../Form/Location';
import Select from '../Form/Select';
import TimePicker from '../Form/TimePicker';
import FullPageModal from '../Modals/FullPageModal';

import { getLocationData } from '../../utils/location';
import { defaultGridSpacing } from '../../utils/constants';
import { addTeamMatchModalPropTypes } from '../../utils/proptypes';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { useGetSessionTeamsQuery, useAddTeamMatchMutation } from '../../api/leaguesApi';

const AddTeamMatchModal = ({
    sessionId = '',
    isOpen = false,
    onClose,
    roundId = '',
    onSaveClick,
    scheduleId = '',
    refetchSessionSchedule,
    session,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [matchStartDate, setMatchStartDate] = useState('');
    const [awayTeam, setAwayTeam] = useState('');
    const [homeTeam, setHomeTeam] = useState('');
    const [selectedHomeTeam, setSelectedHomeTeam] = useState('');
    const [selectedAwayTeam, setSelectedAwayTeam] = useState('');
    const [teams, setTeams] = useState([]);
    const [onAddTeamMatch] = useAddTeamMatchMutation({ scheduleId });

    const getDateTimeString = (date, time) => {
        const tIndex = time.indexOf('T');
        const formattedTime = time.slice(tIndex);
        return `${date}${formattedTime}`;
    };

    const { data: sessionTeams, isFetching: isLoadingSessionTeams, error: teamsError } = useGetSessionTeamsQuery(sessionId);

    if (teamsError) {
        dispatch(showErrorPopup(teamsError));
    }

    const defaultValues = {
        position1TeamId: '',
        position2TeamId: '',
        matchLocation: '',
        matchStartDate: '',
        matchStartTimeUtc: moment(),
    };

    const {
        handleSubmit,
        reset,
        formState: { errors, dirtyFields, isValid, isDirty },
        control,
    } = useForm({ defaultValues: { ...defaultValues }, mode: 'all' });

    const handleFormSubmit = async (formValues) => {
        const manualMultiweekRound = { ...formValues };
        manualMultiweekRound.matchStartTimeUtc = moment(manualMultiweekRound.matchStartTimeUtc, 'hh:mm:ss');

        const dateStr = matchStartDate.toISOString();
        const date = moment(dateStr).utc().format('YYYY-MM-DD');
        const time = manualMultiweekRound.matchStartTimeUtc.toISOString();

        if (dirtyFields.matchLocation) {
            const formattedLocation = await getLocationData(manualMultiweekRound.matchLocation);
            manualMultiweekRound.matchLocation = formattedLocation;
        }

        const payload = {
            roundId,
            position1TeamId: homeTeam,
            position2TeamId: awayTeam !== '' ? awayTeam : null,
            matchDateTime: getDateTimeString(date, time),
            matchLocation: manualMultiweekRound.matchLocation,
        };

        try {
            await onAddTeamMatch({
                scheduleId,
                newMatch: payload,
            }).unwrap();
            refetchSessionSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }

        reset(defaultValues);
    };

    const handleOnCustomStartDateChange = (date) => {
        setMatchStartDate(date);
    };

    const handleOnAwayTeamChange = (event) => {
        const selectedTeam = event?.target?.value;
        setSelectedAwayTeam(selectedTeam);
        setAwayTeam(selectedTeam);
    };

    const handleOnHomeTeamChange = (event) => {
        const selectedTeam = event?.target?.value;
        setSelectedHomeTeam(selectedTeam);
        setHomeTeam(selectedTeam);
    };

    const handleOnSwapTeams = () => {
        const tempHomeTeam = selectedHomeTeam;
        setSelectedHomeTeam(selectedAwayTeam);
        setSelectedAwayTeam(tempHomeTeam);

        // Update displayed teams in the dropdowns
        setHomeTeam(selectedAwayTeam);
        setAwayTeam(tempHomeTeam);
    };

    useEffect(() => {
        if (sessionTeams) {
            const teamSelections = sessionTeams?.teams.map((team) => {
                return {
                    id: team.id,
                    description: team.name,
                };
            });
            setTeams(teamSelections);
        }
    }, [sessionTeams]);

    const handleOnSave = () => {
        handleSubmit(handleFormSubmit)();
        onSaveClick();
    };

    const clearForm = () => {
        reset(defaultValues);
        setHomeTeam('');
        setAwayTeam('');
        setMatchStartDate('');
    };

    const handleOnClose = () => {
        clearForm();
        onClose();
    };

    const shouldDisableDate = (date) => {
        const sessionStartDate = moment(session?.startDateUtc);
        const sessionEndDate = moment(session?.endDateUtc);
        const currentDate = moment(date).startOf('day');
        return currentDate.isBefore(sessionStartDate?.startOf('day')) || currentDate?.isAfter(sessionEndDate.endOf('day'));
    };
    const isCtaButtonDisabled = matchStartDate === '' || !isValid || homeTeam === null || homeTeam === undefined || !isDirty;

    return (
        <FullPageModal
            title="Add a team match"
            onClose={handleOnClose}
            isOpen={isOpen}
            onCtaClick={handleOnSave}
            ctaTitle="Add a match"
            isCtaButtonDisabled={isCtaButtonDisabled}
        >
            <form>
                <Paper sx={{ mt: 5, p: 2 }}>
                    <Grid container spacing={defaultGridSpacing}>
                        <Grid item xs={12} lg={4}>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <DatePicker
                                    name="matchStartDate"
                                    label="Date"
                                    value={matchStartDate}
                                    onChange={handleOnCustomStartDateChange}
                                    control={control}
                                    renderInput={(inputProps) => <TextField required {...inputProps} fullWidth error={false} />}
                                    shouldDisableDate={shouldDisableDate}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <TimePicker name="matchStartTimeUtc" label="Time" control={control} isRequired />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Location name="matchLocation" label="Location" error={!!errors.location} control={control} isRequired />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{ p: 2 }}>
                    <Grid container spacing={defaultGridSpacing} alignItems="center">
                        <Grid xs={11} item>
                            <Box>
                                {teams?.length && !isLoadingSessionTeams && (
                                    <Select
                                        name="position2TeamId"
                                        label="Away Team"
                                        options={teams?.filter((team) => team.id !== selectedHomeTeam)}
                                        labelId="away-team-select"
                                        isFullWidth
                                        control={control}
                                        hasError={!!errors.position2TeamId}
                                        value={awayTeam}
                                        onSelectChange={handleOnAwayTeamChange}
                                        disabled={teams.length === 1}
                                        sx={{ mb: 3 }}
                                    />
                                )}
                                {teams?.length && !isLoadingSessionTeams && (
                                    <Select
                                        name="position1TeamId"
                                        label="Home Team"
                                        options={teams?.filter((team) => team.id !== selectedAwayTeam)}
                                        labelId="home-team-select"
                                        isFullWidth
                                        control={control}
                                        hasError={!!errors.position1TeamId}
                                        value={homeTeam}
                                        onSelectChange={handleOnHomeTeamChange}
                                        isRequired
                                        helperText="Required"
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid xs={1} item>
                            <IconButton aria-label="swap-teams" onClick={handleOnSwapTeams}>
                                <Box display="flex">
                                    <SwapNoBorderIcon size={22} color={theme.appColors.interactionBlue} />
                                </Box>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </FullPageModal>
    );
};

AddTeamMatchModal.propTypes = {
    ...addTeamMatchModalPropTypes,
};

export default AddTeamMatchModal;
