import React from 'react';

import { useTheme, Container } from '@mui/material';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { SharedUICategories, TypographySizes, SessionTabs } from '../../../utils/constants';
import { sessionPageHeaderPropTypes } from '../../../utils/proptypes';

const SessionPageHeader = ({ tab, sessionName = '', onTabChange, isMultiRoundSession, conferenceName }) => {
    const theme = useTheme();
    const { DISPLAY, SECONDARY } = SharedUICategories;
    const { LARGE_LIGHT, X_SMALL_MEDIUM_CAP } = TypographySizes;
    const { OVERVIEW, SCHEDULE, PLAYERS, DRAW, STANDINGS } = SessionTabs;
    const scheduleDrawLabel = isMultiRoundSession ? SCHEDULE : DRAW;

    return (
        <>
            <Box bgcolor={theme.appColors.black}>
                <Container maxWidth="lg">
                    <Box py={2}>
                        <Typography color={theme.appColors.white} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            {conferenceName}
                        </Typography>
                        <Typography color={theme.appColors.white} category={DISPLAY} size={LARGE_LIGHT} lineHeight={1}>
                            {sessionName}
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box bgcolor={theme.appColors.white}>
                <Container maxWidth="lg">
                    <Tabs variant="scrollable" value={tab} onChange={onTabChange} aria-label="session tabs">
                        <Tab label={OVERVIEW} value={OVERVIEW} />
                        <Tab label={scheduleDrawLabel} value={scheduleDrawLabel} />
                        <Tab label={PLAYERS} value={PLAYERS} />
                        <Tab label={STANDINGS} value={STANDINGS} />
                    </Tabs>
                </Container>
            </Box>
        </>
    );
};

SessionPageHeader.propTypes = {
    ...sessionPageHeaderPropTypes,
};

export default SessionPageHeader;
