import React from 'react';
import { Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { radioGroupPropTypes } from '../../../utils/proptypes';

const RadioGroup = ({ control, name, options, sx, ...rest }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <MuiRadioGroup {...field} {...rest}>
                {options.map((option) => (
                    <FormControlLabel key={option.id} value={option.id} label={option.description} control={<Radio />} sx={sx} />
                ))}
            </MuiRadioGroup>
        )}
    />
);

RadioGroup.propTypes = {
    ...radioGroupPropTypes,
};

export default RadioGroup;
