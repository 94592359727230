import { getAdjustedDate } from '../../utils/display';

export const ONE_ROUND = 1;
export const FULL_PAGE_ROUNDS = 3;
export const BYE_ROUND = 'Bye';
export const TBD_ROUND = 'TBD';
export const MAIN_DRAW_TABLE_NUMBER = 1;
export const BACK_DRAW_TABLE_NUMBER = 2;
export const SINGLE_ROUND_BYE = 1;
export const DOUBLE_ROUND_BYE = 2;
export const MANAGE_SESSION_PAGE_TYPE = 'MANAGE_SESSION_PAGE';

export const getSortedSelectableTeamsBySeed = ({ selectableTeams }) => {
    const sortOptions = selectableTeams.slice();
    return sortOptions.sort((a, b) => {
        const aSeed = a.seed;
        const bSeed = b.seed;

        if (aSeed === null) {
            return 1;
        }

        if (bSeed === null) {
            return -1;
        }

        if (aSeed === bSeed) {
            return 0;
        }

        return parseFloat(aSeed) - parseFloat(bSeed);
    });
};

export const getAllSelectedTeams = ({ teamMatches }) => {
    return teamMatches.reduce((acc, cur) => {
        const { position1Team, position2Team } = cur;
        const teamIDs = [position1Team?.teamId, position2Team?.teamId];
        return [...acc, ...teamIDs];
    }, []).filter((id) => !!id);
};

export const getAvailableTeams = ({ selectableTeams, teamMatchesInRound, teamMatch }) => {
    const { position1Team, position2Team } = teamMatch;
    const alreadySelectedTeamIDs = getAllSelectedTeams({ teamMatches: teamMatchesInRound });
    const currentTeamMatchIDs = [position1Team?.teamId, position2Team?.teamId].filter((id) => !!id);
    const sortedSelectableTeamsArray = getSortedSelectableTeamsBySeed({ selectableTeams });

    return sortedSelectableTeamsArray.filter((item) => {
        const isSelectableTeamIsInThisMatch = currentTeamMatchIDs?.includes(item.id);
        const isSelectableTeamSelected = alreadySelectedTeamIDs?.includes(item.id);

        if (isSelectableTeamIsInThisMatch) return item;
        if (!isSelectableTeamSelected) return item;
        return;
    });
};

export const getNameWithSeed = ({ team }) => {
    if (!team) return null;
    const seedNumberPos1 = team?.seed ? `${team?.seed}.` : null;
    return seedNumberPos1 ? `${seedNumberPos1} ${team?.name}` : `${team?.name}`;
};

export const getHideViewOnPublish = ({ handleOnViewCard, hasPosition1Team, hasPosition2Team }) => {
    if (!hasPosition1Team || !hasPosition2Team) return {};

    return { onViewClick: handleOnViewCard };
};

export const getHideEditOnByes = ({ teamMatch, onEditMatch, publishedPosition1Team, publishedPosition2Team, options }) => {
    const { optionOne, optionTwo } = options;
    const hasByes = optionOne === BYE_ROUND || optionTwo === BYE_ROUND;

    if (publishedPosition1Team?.name === BYE_ROUND || publishedPosition2Team?.name === BYE_ROUND || hasByes) {
        return {};
    }

    return { onEditClick: () => onEditMatch(teamMatch) };
};

export const getFormattedDrawCardSelectOptions = ({ roundNumber, hasPublishedSchedule, position1TeamId, position2TeamId }) => {
    const optionOne =
        roundNumber === 1 && hasPublishedSchedule && !position1TeamId && !!position2TeamId ? BYE_ROUND : position1TeamId;
    const optionTwo =
        roundNumber === 1 && hasPublishedSchedule && !position2TeamId && !!position1TeamId ? BYE_ROUND : position2TeamId;

    return { optionOne, optionTwo };
};

export const getFormatedFields = ({ isUsingState, currentMatch, location, matchDate }) => {
    const locationLabel = location?.placeName;
    const dateFormat = 'ddd, MMM Do [at] h:mm A';
    const dateLabel = matchDate && getAdjustedDate({ dateUtc: matchDate, format: dateFormat });

    const currentStateDateLabel =
    currentMatch?.matchDate && getAdjustedDate({ dateUtc: currentMatch.matchDate, format: dateFormat });
    const formatedDateLabel = isUsingState && currentStateDateLabel ? currentStateDateLabel : dateLabel;
    const formatedLocationLabel =
    isUsingState && currentMatch?.location?.placeName ? currentMatch?.location?.placeName : locationLabel;

    return { formatedDateLabel, formatedLocationLabel };
};

export const getFormattedTeamPageDrawFields = ({ isUsingState, currentMatch, location, matchDate }) => {
    const locationLabel = location?.placeName;
    const dateFormat = 'ddd, MMM';
    const timeFormat = 'h:mm A';
    const dateLabel = matchDate && getAdjustedDate({ dateUtc: matchDate, format: dateFormat });
    const timeLabel = matchDate && getAdjustedDate({ dateUtc: matchDate, format: timeFormat });

    const currentStateDateLabel =
    currentMatch?.matchDate && getAdjustedDate({ dateUtc: currentMatch.matchDate, format: dateFormat });
    const currentStateTimeLabel =
    currentMatch?.matchDate && getAdjustedDate({ dateUtc: currentMatch.matchDate, format: timeFormat });
    const formattedDateLabel = isUsingState && currentStateDateLabel ? currentStateDateLabel : dateLabel;
    const formattedTimeLabel = isUsingState && currentStateTimeLabel ? currentStateTimeLabel : timeLabel;
    const formattedLocationLabel =
    isUsingState && currentMatch?.location?.placeName ? currentMatch?.location?.placeName : locationLabel;

    return { formattedDateLabel, formattedLocationLabel, formattedTimeLabel };
};

export const getDrawCardSelectOptions = ({ selectableTeams, teamMatch, teamMatchesInRound }) => {
    if (!selectableTeams) return { options: [] };
    const availableTeams = getAvailableTeams({ selectableTeams, teamMatchesInRound, teamMatch });

    return availableTeams.map((team) => {
        const label = getNameWithSeed({ team });

        return {
            id: team.id,
            label,
        };
    });
};

const getFindTeamMatchID = ({ rounds, teamMatchId }) => {
    const flattenMatches = rounds.reduce((acc, cur) => {
        if (cur.teamMatches) {
            return [...acc, ...cur.teamMatches];
        }
        return acc;
    }, []);

    return flattenMatches.find((match) => match.teamMatchId === teamMatchId);
};

// Users can only select teams in round 1 of the main draw, this may change.
export const getRoundOneMainDrawTeamMatches = ({ draws }) => draws?.find((draw) => draw.number === MAIN_DRAW_TABLE_NUMBER)?.rounds?.find((round) => round.number === 1)?.teamMatches;

export const getTeamIDsFromTeamMatches = ({ teamMatches }) => teamMatches?.reduce((acc, cur) => {
    const teamIDs = [cur?.position1Team?.teamId, cur?.position2Team?.teamId];
    return [...acc, ...teamIDs];
}, []).filter((id) => !!id);

const getSingleByeDoubleNullLabels = ({ dataFormatted, rounds, teamMatch, tbdWinnerLabel = null, displayTournamentBracketStyle = false }) => {
    const position1FromTeamMatchId = teamMatch?.position1FromTeamMatchId;
    const position2FromTeamMatchId = teamMatch?.position2FromTeamMatchId;

    const position1FromTeamMatch = getFindTeamMatchID({ rounds, teamMatchId: position1FromTeamMatchId });
    const position2FromTeamMatch = getFindTeamMatchID({ rounds, teamMatchId: position2FromTeamMatchId });

    const getTbdWinnerLabel = (position) => {
        let label;
        if (tbdWinnerLabel && displayTournamentBracketStyle) {
            label = `Winner ${tbdWinnerLabel} ${position}`;
        } else {
            label = TBD_ROUND;
        }
        return label;
    };

    let publishedPosition1Team;
    let publishedPosition2Team;
    switch (true) {
        case position1FromTeamMatch?.byeStatusTypeId > position2FromTeamMatch?.byeStatusTypeId:
            publishedPosition1Team = { name: BYE_ROUND, points: null };
            publishedPosition2Team = { name: getTbdWinnerLabel(2), points: null };
            break;

        case position2FromTeamMatch?.byeStatusTypeId > position1FromTeamMatch?.byeStatusTypeId:
            publishedPosition1Team = { name: getTbdWinnerLabel(1), points: null };
            publishedPosition2Team = { name: BYE_ROUND, points: null };
            break;

        default:
            publishedPosition1Team = { name: getTbdWinnerLabel(1), points: null };
            publishedPosition2Team = { name: getTbdWinnerLabel(2), points: null };
            break;
    }

    return {
        dataFormatted,
        publishedPosition1Team,
        publishedPosition2Team
    };
};

export const getDataFormattedAndPublishedTeams = ({ dateLabel, locationLabel, options, roundNumber, hasPublishedSchedule, isMainDraw, totalRounds, teamMatch }) => {
    const { position1Team, position2Team, byeStatusTypeId } = teamMatch;
    const isMainFirstRoundPublished = isMainDraw && roundNumber === 1 && hasPublishedSchedule;

    const labelPost1 = getNameWithSeed({ team: position1Team });
    const labelPost2 = getNameWithSeed({ team: position2Team });

    const { optionOne, optionTwo } = getFormattedDrawCardSelectOptions({ roundNumber, hasPublishedSchedule, position1TeamId: position1Team?.teamId, position2TeamId: position2Team?.teamId });

    const optionOneNullOrBye = !optionOne && byeStatusTypeId === SINGLE_ROUND_BYE ? BYE_ROUND : '';
    const optionTwoNullOrBye = !optionTwo && byeStatusTypeId === SINGLE_ROUND_BYE ? BYE_ROUND : '';

    const dataFormatted = {
        options,
        selectedOptions: {
            optionOne: optionOne || optionOneNullOrBye,
            optionTwo: optionTwo || optionTwoNullOrBye,
        },
        dateLabel,
        locationLabel,
    };

    let emptyByeTbdLabel;
    switch (true) {
        case isMainFirstRoundPublished:
            emptyByeTbdLabel = BYE_ROUND;
            break;

        case byeStatusTypeId === DOUBLE_ROUND_BYE:
            emptyByeTbdLabel = BYE_ROUND;
            break;

        case byeStatusTypeId === SINGLE_ROUND_BYE:
            if (position1Team || position2Team) {
                emptyByeTbdLabel = BYE_ROUND;
            } else {
                return getSingleByeDoubleNullLabels({ dataFormatted, rounds: totalRounds, teamMatch });
            }
            break;

        default:
            emptyByeTbdLabel = TBD_ROUND;
            break;
    }

    const publishedPosition1Team = { name: position1Team ? labelPost1 : emptyByeTbdLabel, points: position1Team?.points };
    const publishedPosition2Team = { name: position2Team ? labelPost2 : emptyByeTbdLabel, points: position2Team?.points };

    return { dataFormatted, publishedPosition1Team, publishedPosition2Team };
};

export const getTeamPageDataFormattedAndPublishedTeams = ({ dateLabel, locationLabel, timeLabel, roundNumber, hasPublishedSchedule, isMainDraw, totalRounds, teamMatch, previousRoundName, currentMatchIndex }) => {
    const { position1Team, position2Team, byeStatusTypeId } = teamMatch;
    const isFirstRound = roundNumber === 1;
    const isMainFirstRoundPublished = isMainDraw && isFirstRound && hasPublishedSchedule;

    const dataFormatted = {
        dateLabel,
        timeLabel,
        locationLabel,
    };

    let emptyByeTbdLabel;
    switch (true) {
        case isMainFirstRoundPublished:
            emptyByeTbdLabel = BYE_ROUND;
            break;

        case byeStatusTypeId === DOUBLE_ROUND_BYE:
            emptyByeTbdLabel = BYE_ROUND;
            break;

        case byeStatusTypeId === SINGLE_ROUND_BYE:
            if (position1Team || position2Team) {
                emptyByeTbdLabel = BYE_ROUND;
            } else {
                return getSingleByeDoubleNullLabels({ dataFormatted, rounds: totalRounds, teamMatch, previousRoundName, displayTournamentBracketStyle: true });
            }
            break;

        default:
            emptyByeTbdLabel = TBD_ROUND;
            break;
    }

    const getEmptyByeTbdLabel = (positionTeam) => {
        let label;
        if (positionTeam) {
            label = positionTeam.name;
        } else {
            label = emptyByeTbdLabel;
        }
        return label;
    };

    const publishedPosition1Team = { name: getEmptyByeTbdLabel(position1Team), points: position1Team?.points, seed: position1Team?.seed, href: position1Team?.teamId && `/teams/${position1Team?.teamId}` };
    const publishedPosition2Team = { name: getEmptyByeTbdLabel(position2Team), points: position2Team?.points, seed: position2Team?.seed, href: position2Team?.teamId && `/teams/${position2Team?.teamId}` };

    return { dataFormatted, publishedPosition1Team, publishedPosition2Team };
};
