import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CreateConferencePage from './pages/CreateConferencePage';
import CreateSessionPage from './pages/CreateSessionPage';
import LeagueDeskWrapper from './pages/LeagueDesk/LeagueDeskWrapper';
import ManageConferencePage from './pages/ManageConferencePage';
import ManageSessionPage from './pages/ManageSessionPage';
import MemberTeamsPage from './pages/MemberTeamsPage';
import SearchWrapper from './pages/SearchPage/SearchWrapper';
import SessionPage from './pages/SessionPage';
import TeamMatchPage from './pages/TeamMatchPage';
import TeamPage from './pages/TeamPage';

const AppRoutes = () => (
    <Routes>
        <Route path="/leagues/:leagueId" element={<LeagueDeskWrapper />} />
        <Route path="/leagues/:leagueId/conference/create" element={<CreateConferencePage />} />
        <Route path="/leagues/conference/:conferenceId/manage" element={<ManageConferencePage />} />
        <Route path="/leagues/:conferenceId/session/create" element={<CreateSessionPage />} />
        <Route path="/leagues/session/:sessionId/manage" element={<ManageSessionPage />} />
        <Route path="/leagues/session/:sessionId" element={<SessionPage />} />
        <Route path="/teams/:teamId" element={<TeamPage />} />
        <Route path="/leagues/match/:teamMatchId" element={<TeamMatchPage />} />
        <Route path="/member-teams/sport/:sportId" element={<MemberTeamsPage />} />
        <Route path="/search/league/:leagueTypeId" element={<SearchWrapper />} />
    </Routes>

);

export default AppRoutes;
