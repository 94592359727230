import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ContactCard, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';

import PasswordInput from '../../Form/PasswordInput';
import { messageUser } from '../../../utils/helpers';
import FormSubmitButton from '../../Form/FormSubmitButton';
import { passwordConfirmationProptypes } from '../../../utils/proptypes';

const PasswordConfirmation = ({ onPinSubmitted, isInvalidPasscode, captains = [], isLoading }) => {
    const defaultValues = { pin: '' };
    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        setValue,
        setError,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (isInvalidPasscode) {
            setError('pin', isInvalidPasscode);
        }
    }, [isInvalidPasscode]);

    const handleOnPinSubmitted = (formValues) => {
        onPinSubmitted(formValues.pin);
        setValue('pin', '');
    };

    const handleOnMessageClick = (id, displayName) => {
        messageUser(id, displayName);
    };

    return (
        <Box pt={4}>
            <Typography category="secondary" size="medium-medium">
                Enter team passcode
            </Typography>
            <Box sx={{ padding: '16px 0' }}>
                <Typography category="secondary" size="small-book">
                    Registration for this team is password protected. Please enter the password or PIN you received from the team captain or
                    organizer.
                </Typography>
            </Box>
            <form onSubmit={handleSubmit(handleOnPinSubmitted)}>
                <PasswordInput
                    name="pin"
                    label="Enter Passcode"
                    control={control}
                    error={!!errors.pin}
                    helperText={!!errors.pin && 'The passcode you entered is invalid. Please try again or message the team captain'}
                />
                <Box sx={{ padding: '64px 0' }}>
                    <FormSubmitButton style={{ width: '100%' }} label="Validate" isSubmitting={isSubmitting || isLoading} />
                </Box>
            </form>
            <Typography category="secondary" size="medium-book">
                Don&apos;t have a passcode? Message the team captain.
            </Typography>
            {!!captains?.length && (
                <Box pt={1}>
                    {captains.map((item) => {
                        const displayName = `${item?.firstName} ${item?.lastName}`;
                        return (
                            <div key={item.id}>
                                <Box sx={{ paddingTop: '4px' }} />
                                <ContactCard onMessageClick={() => handleOnMessageClick(item.id, displayName)} name={displayName} phone={item?.phone} email={item?.email} />
                            </div>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

PasswordConfirmation.propTypes = {
    ...passwordConfirmationProptypes
};

export default PasswordConfirmation;
