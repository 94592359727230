import React, { useState, useEffect } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Button, SwapIcon } from '@universal-tennis/ui-shared';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import UTSet from './UTSet';
import { ScorePositionKeys, SharedUICategories, TypographySizes } from '../../../utils/constants';
import { ResultTypes, TennisScoringFormatTypes, tennisScoringTypes, getTypeForId } from '../../../utils/types';
import {
    SideKeys,
    createMatchResults,
    getMatchOutcomeOptions,
    getPositionsForMatch,
    switchMatchScores,
    createDefaultedMatchResults,
    hasDuplicatePlayersInMatch,
    hasScoresEntered,
    hasInvalidPlayerCount,
    getOutcomeForExistingMatch
} from '../../../utils/postScoreShared';

import {
    DefaultSets,
    getExistingMatchSets,
    getValidatedValueForSet,
    getWinnerForSets,
    isSetTied, switchSetScores
} from '../../../utils/postScore';

import MatchOutcomeSelect from '../MatchOutcomeSelect';
import PlayerSelect from '../PlayerSelect';

const _ = { cloneDeep, isEqual };

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PostTennisScoreModal = ({
    isOpen,
    onClose,
    position1Team,
    position2Team,
    position1Players,
    position2Players,
    selectedMatch,
    onSaveMatchScore,
    onDuplicatePlayersInMatch,
    onInvalidPlayerCount
}) => {
    const theme = useTheme();
    const { SINGLE_SET } = TennisScoringFormatTypes;
    const { SIDE_1, SIDE_2 } = SideKeys;
    const { DISPLAY, PRIMARY, SECONDARY } = SharedUICategories;
    const { MEDIUM_BOOK, X_SMALL_MEDIUM_CAP, } = TypographySizes;
    const { name: position1TeamName, teamId: position1TeamId } = position1Team || {};
    const { name: position2TeamName } = position2Team || {};
    const { DEFAULTED, MATCH_NOT_PLAYED, INCOMPLETE } = ResultTypes;
    const [currentMatch, setCurrentMatch] = useState();
    const [sets, setSets] = useState(DefaultSets);
    const [originalSets, setOriginalSets] = useState([]);
    const [currentSetId, setCurrentSetId] = useState(0);
    const [originalMatch, setOriginalMatch] = useState();
    const [scoringError, setScoringError] = useState();
    const [hasEnteredScores, setHasEnteredScores] = useState(false);
    const [matchOutcomeOptions, setMatchOutcomeOptions] = useState();
    const [matchOutcome, setMatchOutcome] = useState();
    const [originalMatchOutcome, setOriginalMatchOutcome] = useState();
    const [winningSideId, setWinningSideId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const hasUnchangedScores = _.isEqual(originalSets, sets);
    const hasUnchangedMatch = _.isEqual(originalMatch, currentMatch);
    const hasUnchangedMatchOutcome = _.isEqual(originalMatchOutcome, matchOutcome);
    const isUnchangedMatch = hasUnchangedMatch && hasUnchangedScores && hasUnchangedMatchOutcome;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isSide1Position1 = selectedMatch?.side1?.teamId === position1TeamId;

    useEffect(() => {
        if (selectedMatch) {
            setCurrentMatch(selectedMatch);
            setOriginalMatch(selectedMatch);

            const options = getMatchOutcomeOptions(selectedMatch, position1Team, position2Team, isSide1Position1);
            setMatchOutcomeOptions(options);

            if (!matchOutcome) {
                setMatchOutcome(options[0]);
                setOriginalMatchOutcome(options[0]);
            }

            if (selectedMatch?.resultCompletionTypeId) {
                const { resultCompletionTypeId, winningSideId: winnerSideId } = selectedMatch;
                const existingSets = getExistingMatchSets(selectedMatch, isSide1Position1);

                if (selectedMatch?.scoringFormatTypeId === SINGLE_SET) {
                    setSets([existingSets[0]]);
                    setOriginalSets([existingSets[0]]);
                } else {
                    setSets(existingSets);
                    setOriginalSets(existingSets);
                }

                setIsEditing(true);

                const outcome = getOutcomeForExistingMatch({ options, resultCompletionTypeId, winnerSideId });

                if (outcome?.winningSideId) {
                    setWinningSideId(outcome.winningSideId);
                }

                setMatchOutcome(outcome);
                setOriginalMatchOutcome(outcome);
            } else if (selectedMatch?.scoringFormatTypeId === SINGLE_SET) {
                setSets([DefaultSets[0]]);
            } else {
                setSets(DefaultSets);
            }
        }
    }, [selectedMatch]);

    useEffect(() => {
        if (!hasUnchangedMatch) {
            const options = getMatchOutcomeOptions(currentMatch, position1Team, position2Team, isSide1Position1);
            setMatchOutcomeOptions(options);
        }
    }, [currentMatch]);

    const handleOnClose = () => {
        setMatchOutcome(matchOutcomeOptions[0]);
        onClose();
        setScoringError(null);
    };

    useEffect(() => {
        const hasScores = hasScoresEntered(sets);
        setHasEnteredScores(hasScores);
    }, [sets]);

    const handleOnSetScoreChange = (set, positionKey, value, isTiebreaker) => {
        setScoringError(null);

        const { id: setId } = set;
        const validatedValue = getValidatedValueForSet(setId, value, currentMatch?.scoringFormatTypeId, isTiebreaker);
        const newSets = _.cloneDeep(sets);
        const setIndex = newSets.findIndex((newSet) => newSet.id === setId);
        const updatedSet = newSets[setIndex];
        updatedSet[positionKey] = validatedValue;

        if (validatedValue && !isTiebreaker && setId !== 1) {
            if (positionKey === ScorePositionKeys.POSITION_TWO_SCORE && !isSetTied(updatedSet, currentMatch?.scoringFormatTypeId)) {
                setCurrentSetId(setId + 1);
            }
        }
        setSets(newSets);
    };

    const handleOnSetFocus = (set) => {
        setCurrentSetId(set.id);
    };

    const handleOnSaveScore = () => {
        let results;
        let hasNoErrors = true;

        const hasDuplicatePlayers = hasDuplicatePlayersInMatch(currentMatch);
        const hasInvalidPlayerAmount = hasInvalidPlayerCount(currentMatch, matchOutcome?.outcomeId);

        if (hasDuplicatePlayers) {
            onDuplicatePlayersInMatch();
            return;
        }

        if (hasInvalidPlayerAmount) {
            onInvalidPlayerCount();
            return;
        }

        if (matchOutcome.outcomeId === MATCH_NOT_PLAYED || matchOutcome.outcomeId === DEFAULTED) {
            results = createDefaultedMatchResults(currentMatch, matchOutcome, isSide1Position1);
        } else {
            const { validationError, isWinnerPosition1 } = getWinnerForSets(
                sets,
                matchOutcome.outcomeId,
                currentMatch?.scoringFormatTypeId
            );

            if (validationError) {
                hasNoErrors = false;
                setScoringError(validationError);
            } else {
                let winnerId = null;

                if (!winningSideId && matchOutcome.outcomeId !== INCOMPLETE) {
                    winnerId = isSide1Position1 && isWinnerPosition1 ? currentMatch.side1.sideId : currentMatch.side2.sideId;
                }

                const winningSide = winningSideId || winnerId;
                results = createMatchResults(currentMatch, sets, winningSide, matchOutcome.outcomeId, isSide1Position1);
            }
        }

        if (hasNoErrors) {
            onSaveMatchScore(currentMatch.id, results, isEditing);
            setIsEditing(false);
            setMatchOutcome(null);
        }
    };

    const handleOnMatchOutcomeChange = (outcome) => {
        setScoringError(null);

        const outcomeIndex = matchOutcomeOptions.findIndex((option) => option.id === outcome?.id);
        setMatchOutcome(matchOutcomeOptions[outcomeIndex]);

        if (outcome?.winningSideId) {
            setWinningSideId(outcome.winningSideId);
        }
    };

    const handleOnSwitchScores = () => {
        const updatedSetScores = switchSetScores(sets);
        setSets(updatedSetScores);

        if (currentMatch?.resultCompletionTypeId) {
            const updatedMatch = switchMatchScores(currentMatch);
            setCurrentMatch(updatedMatch);
        }
    };

    const handleOnPlayerChange = (player, side, playerPosition) => {
        setCurrentMatch({
            ...currentMatch,
            [side]: { ...currentMatch[side], [playerPosition]: player },
        });
    };

    let position1Player1Id;
    let position1Player2Id;
    let position2Player1Id;
    let position2Player2Id;
    const isDoubles = currentMatch?.isDoubles;

    if (currentMatch) {
        const { position1, position2 } = getPositionsForMatch(currentMatch, isSide1Position1);
        position1Player1Id = position1?.player1?.playerId;
        position1Player2Id = position1.player2?.playerId;
        position2Player1Id = position2?.player1?.playerId;
        position2Player2Id = position2.player2?.playerId;
    }

    const matchName = `${isDoubles ? 'Doubles' : 'Singles'} Match #${currentMatch?.lineupPosition}`;
    const scoringType = getTypeForId(currentMatch?.scoringFormatTypeId, tennisScoringTypes);
    const position1PlayerSide = isSide1Position1 ? SIDE_1 : SIDE_2;
    const position2PlayerSide = isSide1Position1 ? SIDE_2 : SIDE_1;
    const nonScoringOutcomes = [ResultTypes.DEFAULTED, ResultTypes.MATCH_NOT_PLAYED, ResultTypes.WITHDREW];
    const hasNonScoringOutcome = nonScoringOutcomes.includes(matchOutcome?.outcomeId);

    return (
        <Dialog PaperProps={{ style: { backgroundColor: theme.appColors.coolGrey } }} fullScreen open={isOpen} onClose={handleOnClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative', backgroundColor: theme.appColors.black }}>
                <Toolbar>
                    <Container maxWidth="md">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography category={DISPLAY} size={MEDIUM_BOOK}>
                                {`${matchName} - ${scoringType?.description}`}
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={handleOnClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
            <Container maxWidth="md">
                <Box mt={5}>
                    <Box>
                        <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            Match Outcome
                        </Typography>
                        <Card sx={{ mb: 3 }} variant="outlined">
                            <CardContent>
                                <Grid
                                    p={1}
                                    container
                                    alignItems="center"
                                    m={0}
                                >
                                    <Grid item xs={12}>
                                        <Box>
                                            <MatchOutcomeSelect
                                                outcome={matchOutcome}
                                                options={matchOutcomeOptions}
                                                hasEnteredScores={hasEnteredScores}
                                                onMatchOutComeChange={handleOnMatchOutcomeChange}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            Scores
                        </Typography>
                        <Card>
                            <CardContent>
                                {isSmallScreen ? (
                                    <Box p={3}>
                                        <Box mb={3}>
                                            <Typography category={SECONDARY} size={X_SMALL_MEDIUM_CAP} mb={2} noWrap>
                                                {position2TeamName?.toUpperCase()}
                                            </Typography>
                                            <PlayerSelect
                                                isSmallScreen
                                                isDoubles={isDoubles}
                                                player1Id={position2Player1Id}
                                                player2Id={position2Player2Id}
                                                playerOptions={position2Players}
                                                side={position2PlayerSide}
                                                onPlayerChange={handleOnPlayerChange}
                                            />
                                        </Box>
                                        <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
                                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                                {sets?.map((set) => (
                                                    <UTSet
                                                        scoringFormatTypeId={currentMatch?.scoringFormatTypeId}
                                                        key={set.id}
                                                        isDisabled={hasNonScoringOutcome}
                                                        currentSetId={currentSetId}
                                                        set={set}
                                                        onFocus={handleOnSetFocus}
                                                        onScoreChange={handleOnSetScoreChange}
                                                    />
                                                ))}
                                            </Stack>
                                            <IconButton
                                                onClick={handleOnSwitchScores}
                                                aria-label="switch teams"
                                            >
                                                <SwapIcon color={theme.appColors.interactionBlue} size={38} />
                                            </IconButton>
                                        </Box>
                                        <Box mt={3}>
                                            <PlayerSelect
                                                isSmallScreen
                                                isDoubles={isDoubles}
                                                player1Id={position1Player1Id}
                                                player2Id={position1Player2Id}
                                                playerOptions={position1Players}
                                                side={position1PlayerSide}
                                                onPlayerChange={handleOnPlayerChange}
                                            />
                                            <Typography category={SECONDARY} size={X_SMALL_MEDIUM_CAP} mt={2} noWrap>
                                                {position1TeamName?.toUpperCase()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Grid container p={1} alignItems="center">
                                        <Grid item sm={7}>
                                            <Box>
                                                <Typography category={SECONDARY} size={X_SMALL_MEDIUM_CAP} mb={2} noWrap>
                                                    {position2TeamName?.toUpperCase()}
                                                </Typography>
                                                <PlayerSelect
                                                    isDoubles={isDoubles}
                                                    player1Id={position2Player1Id}
                                                    player2Id={position2Player2Id}
                                                    playerOptions={position2Players}
                                                    side={position2PlayerSide}
                                                    onPlayerChange={handleOnPlayerChange}
                                                />
                                            </Box>
                                            <Box mt={3}>
                                                <PlayerSelect
                                                    isDoubles={isDoubles}
                                                    player1Id={position1Player1Id}
                                                    player2Id={position1Player2Id}
                                                    playerOptions={position1Players}
                                                    side={position1PlayerSide}
                                                    onPlayerChange={handleOnPlayerChange}
                                                />
                                                <Typography category={SECONDARY} size={X_SMALL_MEDIUM_CAP} mt={2} noWrap>
                                                    {position1TeamName?.toUpperCase()}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                                {sets?.map((set) => (
                                                    <UTSet
                                                        scoringFormatTypeId={currentMatch?.scoringFormatTypeId}
                                                        key={set.id}
                                                        isDisabled={hasNonScoringOutcome}
                                                        currentSetId={currentSetId}
                                                        set={set}
                                                        onFocus={handleOnSetFocus}
                                                        onScoreChange={handleOnSetScoreChange}
                                                    />
                                                ))}
                                            </Stack>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Box ml={3}>
                                                <IconButton
                                                    onClick={handleOnSwitchScores}
                                                    aria-label="switch teams"
                                                >
                                                    <SwapIcon color={theme.appColors.interactionBlue} size={38} />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                {scoringError && (
                    <Box bgcolor={theme.appColors.red}>
                        <Typography category={SECONDARY} size={X_SMALL_MEDIUM_CAP} sx={{ p: 1 }} color={theme.appColors.white}>
                            {scoringError}
                        </Typography>
                    </Box>
                )}
            </Container>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: theme.appColors.white }}>
                <Toolbar>
                    <Container maxWidth="md">
                        <Box display="flex" justifyContent="flex-end">
                            <Button disabled={!!scoringError || isUnchangedMatch} onClick={handleOnSaveScore} category={PRIMARY}>
                                Save Scores
                            </Button>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </Dialog>
    );
};

export default PostTennisScoreModal;
