import React from 'react';
import { Typography } from '@universal-tennis/ui-shared';
import Link from '@mui/material/Link';

import { SharedUICategories, TypographySizes } from '../../../../utils/constants';
import { teamMatchLinkPropTypes } from '../../../../utils/proptypes';

const TeamMatchLink = ({ teamMatchLink, roundName, isMultiWeekByDate }) => {
    const { SECONDARY } = SharedUICategories;
    const { SMALL_BOOK } = TypographySizes;

    return (
        <Link href={teamMatchLink}>
            {!isMultiWeekByDate && (
                <Typography category={SECONDARY} size={SMALL_BOOK}>
                    {roundName}
                </Typography>
            )}
            <Typography category={SECONDARY} size={SMALL_BOOK}>
                Team Match
            </Typography>
        </Link>
    );
};

TeamMatchLink.propTypes = {
    ...teamMatchLinkPropTypes,
};

export default TeamMatchLink;
