import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Box from '@mui/material/Box';

import { TeamDrawCardTable } from '@universal-tennis/ui-shared';
import { IconButton } from '@mui/material';

import LoadingIndicator from '../../LoadingIndicator';
import { useGetTeamPageTableRowsData } from '../useGetTeamPageTableRowsData';

const TeamPageDrawTable = ({
    draw,
    hasPublishedSchedule,
    isLoading,
    isMainDraw,
    totalRounds,
    isMobile,
    showMainDraw = true
}) => {
    const { drawId } = draw;
    const { tableRows, headerRows, handleOnBackwardRoundClick, handleOnForwardRoundClick, isShowingForward, isShowingBackward } = useGetTeamPageTableRowsData({ draw, totalRounds, drawId, hasPublishedSchedule, isMainDraw });

    const TeamPageDrawCardTable = <TeamDrawCardTable isMobile={isMobile} tableRows={tableRows} headerRows={headerRows} />;

    const ForwardBackwardButtons = (
        !isMobile && (
            <Box>
                <Box position="absolute" left={0} top={8}>
                    {isShowingBackward ? (
                        <IconButton onClick={handleOnBackwardRoundClick}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    ) : null}
                </Box>
                <Box position="absolute" right={0} top={8}>
                    {isShowingForward ? (
                        <IconButton onClick={handleOnForwardRoundClick}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    ) : null}
                </Box>
            </Box>
        )
    );

    return (
        <Box position="relative">
            {isLoading ? (<LoadingIndicator />) : (
                <Box>
                    {
                        (showMainDraw && isMainDraw) || (!showMainDraw && !isMainDraw) ? (
                            <>
                                {ForwardBackwardButtons}
                                {TeamPageDrawCardTable}
                            </>
                        ) : null
                    }
                </Box>
            )}
        </Box>

    );
};

export default TeamPageDrawTable;
