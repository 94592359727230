import React from 'react';

import styles from './loading.module.scss';

const Loading = () => {
    return (
        <div className={styles.loadingPage}>
            <div className={styles.innerWrapper}>
                <div className={styles.iconWrapper}>
                    <i className="icon-utr-logo" />
                    <div className={styles.iconSpinner} />
                    <div className={`${styles.iconSpinner} ${styles.reverse}`} />
                </div>
                <p>LOADING</p>
            </div>
        </div>
    );
};

export default Loading;
