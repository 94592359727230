import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MuiListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const TeamMatchCardSelect = styled(Select)(({ theme }) => ({
    ':after': { borderBottomColor: theme.appColors.gradientBlue },
}));

export const PostScoreInput = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'showTiebreaker',
})(({ showTiebreaker = false, disable = false }) => ({
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        display: 'none',
    },
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
    '& .MuiOutlinedInput-root': {
        '& input': {
            textAlign: 'center'
        },
        '& fieldset': {
            borderRightWidth: showTiebreaker ? 0 : 1
        },
        borderRadius: 4,
        borderBottomRightRadius: showTiebreaker ? 0 : 4,
        borderTopRightRadius: showTiebreaker ? 0 : 4,

    },
    textAlign: 'center',
    width: 56,
    height: 56,
    disabled: disable,
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
    },
}));

export const PostScoreTieBreakInput = styled(TextField)(() => ({
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        display: 'none',
        WebkitAppearance: 'none',
    },
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
    '& .MuiOutlinedInput-root': {
        '& input': {
            textAlign: 'center'
        },
        '& fieldset': {
            borderLeftWidth: 1
        },
        textAlign: 'center',
        borderRadius: 4,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    outline: 'none',
    width: 56,
    height: 56,
}));

export const TiebreakerText = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    fontSize: 10,
    top: 6,
    right: 12,
    color: theme.appColors.darkText,
}));

export const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
    backgroundColor: theme.appColors.blue,
    color: theme.appColors.white,
}));

export const ScoreCardTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isLoser' && prop !== 'isWinner',
})(({ theme, isLoser, isWinner }) => {
    if (isLoser) {
        return {
            color: theme.appColors.grey,
        };
    }
    if (isWinner) {
        return {
            color: theme.appColors.black,
            fontWeight: theme.typography.fontWeightMedium,
        };
    }
    return {
        color: theme.appColors.black,
    };
});

export const ScoreCardTiebreakerTypography = styled(ScoreCardTypography)(() => ({
    fontSize: 12,
    marginLeft: 0.8,
    marginRight: 8,
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
    color: theme.palette.common.black,
    padding: theme.spacing(2),
}));

export const MemberTeamTableLink = styled(Link)(() => ({
    fontSize: '1rem',
}));

export const LinkStyledButton = styled(Button)(() => ({
    '&:hover': {
        background: 'none',
    },
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    fontWeight: 'normal',
    textTransform: 'none',
}));

export const PostScoreSubtitle = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1,
    fontWeight: 500,
    color: theme.appColors.darkText,
    textTransform: 'uppercase',
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 54,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(22px)',
            color: theme.appColors.white,
            '& + .MuiSwitch-track': {
                backgroundColor: theme.appColors.lighterGreen,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 28,
        height: 28,
    },
    '& .MuiSwitch-track': {
        borderRadius: 32 / 2,
        backgroundColor: theme.appColors.coolGrey600,
        opacity: 1,
    },
}));

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.appColors.blueGrey100,
        boxShadow: theme.shadows[1],
        color: theme.palette.common.black,
        padding: 7,
        fontSize: 14,
        fontWeight: 325,
        textAlign: 'center',
        fontFamily: theme.typography.secondary.fontFamily
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.appColors.blueGrey100,
        '&::before': {
            backgroundColor: theme.appColors.blueGrey100,
            border: '1px solid #999'
        }
    },
}));
