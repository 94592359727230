import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showErrorPopup } from '../redux/errorPopupSlice';
import { useGetTeamMatchRostersQuery } from '../api/leaguesApi';

export const useGetTeamMatchRosters = (teamMatchId) => {
    const dispatch = useDispatch();
    const [position1Team, setPosition1Team] = useState([]);
    const [position2Team, setPosition2Team] = useState([]);
    const [userAvailabilityData, setUserAvailabilityData] = useState();
    const [isUserOnBothTeams, setIsUserOnBothTeams] = useState(false);
    const [userTeamMemberIds, setUserTeamMemberIds] = useState(false);

    const { data: rosters, error, isLoading: isLoadingRosters } = useGetTeamMatchRostersQuery(teamMatchId);

    if (error) {
        dispatch(showErrorPopup(error));
    }

    useEffect(() => {
        if (rosters) {
            const { position1Team: position1, position2Team: position2 } = rosters;

            if (position1?.requestingMember?.teamMemberId && position2?.requestingMember?.teamMemberId === null) {
                setUserAvailabilityData(position1?.requestingMember);
            } else if (position2?.requestingMember?.teamMemberId && position1?.requestingMember?.teamMemberId === null) {
                setUserAvailabilityData(position2?.requestingMember);
            } else if (position1?.requestingMember?.teamMemberId && position2?.requestingMember?.teamMemberId) {
                setUserAvailabilityData(position1?.requestingMember);
                setIsUserOnBothTeams(true);
                setUserTeamMemberIds([position1?.requestingMember?.teamMemberId, position2?.requestingMember?.teamMemberId]);
            }

            setPosition1Team(position1);
            setPosition2Team(position2);
        }
    }, [rosters]);

    return { position1Team, position2Team, userAvailabilityData, userTeamMemberIds, isLoadingRosters, isUserOnBothTeams };
};
