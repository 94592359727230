import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';

import { Typography } from '@universal-tennis/ui-shared';
import Input from '../../Form/Input';
import FormSubmitButton from '../../Form/FormSubmitButton';

import { TypographySizes, SharedUICategories } from '../../../utils/constants';
import { defaultUsapValidationProptypes, usapValidationProptypes } from '../../../utils/proptypes';

const USAPValidation = ({ onMemberIdSubmitted, validationError = null, isLoading }) => {
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_BOOK, SMALL_MEDIUM } = TypographySizes;
    const usapUrl = 'https://usapickleball.sport80.com/';

    const defaultValues = {
        usapMemberId: ''
    };

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        setValue,
        setError
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (validationError) {
            setError('usapMemberId', '');
        }
    }, [validationError]);

    const handleFormSubmit = (values) => {
        onMemberIdSubmitted(values?.usapMemberId);
        setValue('usapMemberId', '');
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box padding={2} my={4} sx={{ borderRadius: 1, borderStyle: 'solid', borderWidth: 1, borderColor: theme.appColors.coolGrey500, }}>
                <Typography category={SECONDARY} size={SMALL_MEDIUM}>Enter your USAP member ID (required)</Typography>
                <Typography category={SECONDARY} size={X_SMALL_BOOK}>If you don&apos;t have an ID or need to renew, <Link rel="noopener" target="_blank" href={usapUrl}>click here</Link>.</Typography>
                <Box mt={2}>
                    <Input
                        fullWidth
                        name="usapMemberId"
                        isRequired
                        hasError={!!errors.usapMemberId}
                        id="usap-member-id"
                        label="USAP Member Id"
                        control={control}
                        variant="standard"
                    />
                    {validationError && (
                        <Typography mt={1} color="error.main" category={SECONDARY} size={X_SMALL_BOOK}>{validationError}</Typography>
                    )}
                </Box>
            </Box>
            <FormSubmitButton style={{ width: '100%' }} label="Continue" isSubmitting={isSubmitting || isLoading} />
        </form>
    );
};

USAPValidation.propTypes = {
    ...usapValidationProptypes
};

USAPValidation.defaultProps = {
    ...defaultUsapValidationProptypes
};

export default USAPValidation;
