import React from 'react';
import { Controller } from 'react-hook-form';
import LocationAutoComplete from '../LocationAutoComplete';

const Location = ({ control, name, hasError, label, isRequired = false }) => (
    <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field }) => <LocationAutoComplete label={label} field={field} error={hasError} isRequired={isRequired} />}
    />
);

export default Location;
