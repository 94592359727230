import PropTypes from 'prop-types';

export const scheduleMatchCardPropTypes = {
    match: PropTypes.shape({
        isSchedulePublished: PropTypes.bool,
        isMultiWeekByDate: PropTypes.bool,
        teamMatchId: PropTypes.number.isRequired,
        matchDate: PropTypes.string.isRequired,
        location: PropTypes.shape({
            placeName: PropTypes.string.isRequired,
            formattedAddress: PropTypes.string.isRequired
        }),
        position1Team: PropTypes.shape({
            teamId: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            points: PropTypes.number.isRequired,
            requestingMemberPlayerAvailability: PropTypes.shape({
                teamMemberId: PropTypes.number.isRequired
            }),
        }),
        position2Team: PropTypes.shape({
            teamId: PropTypes.number,
            name: PropTypes.string.isRequired,
            points: PropTypes.number.isRequired,
            requestingMemberPlayerAvailability: PropTypes.shape({
                teamMemberId: PropTypes.number.isRequired
            }),
        }),
        round: PropTypes.shape({
            name: PropTypes.string.isRequired,
            roundName: PropTypes.string,
        }),
        winnerTeamId: PropTypes.number
    }).isRequired,
    round: PropTypes.shape({
        roundName: PropTypes.string,
        name: PropTypes.string.isRequired
    }),
    isLeagueDeskSchedule: PropTypes.bool.isRequired,
    onEditMatch: PropTypes.func.isRequired,
    onDeleteMatch: PropTypes.func,
    matchNumber: PropTypes.number,
    isMultiWeekByDate: PropTypes.bool,
};

export const defaultScheduleMatchCardPropTypes = {
    round: null
};

export const locationCardPropTypes = {
    location: PropTypes.object.isRequired,
};

export const fontAwesomeSvgIconPropTypes = {
    icon: PropTypes.any.isRequired,
};

export const messageIconPropTypes = {
    memberId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    displayName: PropTypes.string,
    style: PropTypes.any,
};

export const defaultMessageIconPropTypes = {
    memberId: '',
    displayName: '',
    style: {},
};

export const utrIconPropTypes = {
    icon: PropTypes.any.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    style: PropTypes.object,
    clickable: PropTypes.any,
};

export const deafultUtrIconPropTypes = {
    icon: null,
    size: '',
};

export const createDrawModalPropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectableTeams: PropTypes.array,
    onSkip: PropTypes.func.isRequired,
};

export const defaultCreateDrawModalPropTypes = {
    open: false,
    selectableTeams: []
};

export const modalBaseSimplePropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    hideHeaderCloseIcon: PropTypes.bool,
};

export const defaultModalBaseSimplePropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    hideHeaderCloseIcon: PropTypes.bool,
};

export const bootstrapDialogTitlePropTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const modalBaseWithHeaderPropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const playerWithdrawModalPropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export const shareTeamModalPropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

export const defaultModalPropTypes = {
    open: false,
};

export const confirmPublishSchedulePopupPropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    errorMessage: PropTypes.string,
    message: PropTypes.string,
    buttonLabel: PropTypes.string,
};

export const defaultConfirmPublishSchedulePopupPropTypes = {
    open: false,
    title: null,
    buttonLabel: null,
    message: null,
    errorMessage: null,
};

export const popupPropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    errorMessage: PropTypes.string,
    message: PropTypes.string,
    buttonLabel: PropTypes.string,
};

export const defaultPopupPropTypes = {
    open: false,
    title: null,
    buttonLabel: null,
    message: null,
    errorMessage: null,
};

export const teamMatchSummaryPropTypes = {
    isLoading: PropTypes.bool.isRequired,
    onEditClicked: PropTypes.func.isRequired,
    summary: PropTypes.shape({
        canEditTeamMatch: PropTypes.bool.isRequired,
        sessionName: PropTypes.string.isRequired,
        position1Team: PropTypes.shape({
            name: PropTypes.string.isRequired,
            isWinner: PropTypes.bool.isRequired,
            teamId: PropTypes.number.isRequired
        }).isRequired,
        position2Team: PropTypes.shape({
            name: PropTypes.string.isRequired,
            isWinner: PropTypes.bool.isRequired,
            teamId: PropTypes.number.isRequired
        }).isRequired,
        matchDate: PropTypes.string.isRequired,
        sessionId: PropTypes.number.isRequired,
        sessionTypeId: PropTypes.number.isRequired
    }).isRequired,
    scores: PropTypes.shape({
        position1: PropTypes.number.isRequired,
        position2: PropTypes.number.isRequired,
    }).isRequired,
    isUpdating: PropTypes.bool,
    winningTeamId: PropTypes.number.isRequired
};

export const defaultTeamMatchSummaryPropTypes = {
    isUpdating: true
};

export const playerSearchPropTypes = {
    label: PropTypes.string.isRequired,
    onPlayerSelected: PropTypes.func.isRequired,
};

export const defaultPlayerSearchPropTypes = {
    isProPlayerSearch: false,
    searchMembersOnly: false,
    showPickleballContent: false,
    showTennisContent: false,
    selectedPlayers: []
};

export const addTeamsTablePropTypes = {
    sessionTeams: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        captains: PropTypes.arrayOf(PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        })).isRequired,
        sessionStartDateUtc: PropTypes.string.isRequired,
        sessionEndDateUtc: PropTypes.string.isRequired,
        wins: PropTypes.number.isRequired,
        losses: PropTypes.number.isRequired,
    })),
    maxTeams: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    sortByProperty: PropTypes.number.isRequired,
    sortOrder: PropTypes.string.isRequired,
    selectedTeamIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onTeamSelected: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
};

export const defaultAddTeamsTablePropTypes = {
    sessionTeams: []
};

export const defaultCreateTeamModalPropTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    sessionId: PropTypes.number,
    sessionTypeId: PropTypes.number,
};

export const teamProfileInfoPropTypes = {
    teamData: PropTypes.shape({
        conference: PropTypes.shape({
            minAge: PropTypes.number,
            maxAge: PropTypes.number,
            genderTypeId: PropTypes.number,
            conferenceRatings: PropTypes.object,
            sportTypeId: PropTypes.number,
        }),
        homeCourtLocation: PropTypes.shape({
            formattedAddress: PropTypes.string,
        }),
        sessionSummary: PropTypes.shape({
            endDateUtc: PropTypes.string,
        }),
    }),
    registrationFees: PropTypes.shape({
        sessionEntryFee: PropTypes.number,
        total: PropTypes.number,
    }),
    onJoinClick: PropTypes.func,
    onShareClick: PropTypes.func,
    isRegistrationAvailable: PropTypes.bool,
};

export const manageSessionTeamCaptainPropTypes = {
    captains: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        displayName: PropTypes.string,
    })),
    isUpdatingCaptains: PropTypes.bool,
    isLoadingTeam: PropTypes.bool,
    onAddCaptain: PropTypes.func.isRequired,
    onRemoveCaptain: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    maxCaptains: PropTypes.number,
};

export const createTeamCaptainManagerPropTypes = {
    captains: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string.isRequired,
    })),
    isUpdatingCaptains: PropTypes.bool,
    isLoadingTeam: PropTypes.bool,
    onAddCaptain: PropTypes.func.isRequired,
    onRemoveCaptain: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
};

export const addTeamsModalPropTypes = {
    isOpen: PropTypes.bool,
    isLoadingOptions: PropTypes.bool,
    isLoadingTeams: PropTypes.bool,
    onClose: PropTypes.func,
    teams: PropTypes.array,
    maxTeams: PropTypes.number,
    currentTeamCount: PropTypes.number,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    conferences: PropTypes.array,
    sessions: PropTypes.array,
    selectedConferenceId: PropTypes.number,
    selectedSessionIds: PropTypes.array,
    onConferenceChange: PropTypes.func,
    onSessionChange: PropTypes.func,
    onApplyClick: PropTypes.func,
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    onCloseSessionDropdown: PropTypes.func,
    onAddTeams: PropTypes.func,
    sortByProperty: PropTypes.number,
    sortOrder: PropTypes.string,
};

export const teamsTablePropTypes = {
    sessionTeams: PropTypes.array,
    onManageClicked: PropTypes.func,
    onRemoveClicked: PropTypes.func,
    hasSessionStarted: PropTypes.bool,
};

export const manageSessionPageHeaderPropTypes = {
    tab: PropTypes.string.isRequired,
    sessionName: PropTypes.string.isRequired,
    onTabChange: PropTypes.func.isRequired,
    isMultiRoundSession: PropTypes.bool,
};

export const manageTeamModalPropTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    teamId: PropTypes.number,
    onSaveClick: PropTypes.func.isRequired,
};

export const manageSessionCaptainCardPropTypes = {
    captain: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        memberId: PropTypes.number.isRequired,
        playerId: PropTypes.number,
        profileImage: PropTypes.string,
        location: PropTypes.shape({
            display: PropTypes.string,
        }),
    }).isRequired,
    onRemoveClick: PropTypes.func.isRequired,
};
export const addTeamMatchModalPropTypes = {
    sessionId: PropTypes.number,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    roundId: PropTypes.string,
    onSaveClick: PropTypes.func,
    scheduleId: PropTypes.number,
    refetchSessionSchedule: PropTypes.func,
    session: PropTypes.object
};

export const addScheduleRoundPopupPropTypes = {
    rounds: PropTypes.array,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    insertRoundMessage: PropTypes.string,
    teamStandingsMessage: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    buttonLabel: PropTypes.string,
    onAction: PropTypes.func,
    actionLabel: PropTypes.string,
    isTennis: PropTypes.bool,
    disableAddRoundButton: PropTypes.bool
};

export const multiWeekByDatePropTypes = {
    scheduleByDate: PropTypes.object,
    onEditMatch: PropTypes.func.isRequired,
    isLeagueDeskSchedule: PropTypes.bool.isRequired,
};

export const multiWeekByRoundPropTypes = {
    round: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        matches: PropTypes.arrayOf(
            PropTypes.shape({
                teamMatchId: PropTypes.number.isRequired,
                matchDate: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
    onEditMatch: PropTypes.func.isRequired,
    onDeleteMatch: PropTypes.func.isRequired,
    isLeagueDeskSchedule: PropTypes.bool.isRequired,
    onDeleteRound: PropTypes.func.isRequired,
    onAddTeamMatch: PropTypes.func.isRequired,
    teams: PropTypes.array,
    isManaging: PropTypes.bool.isRequired
};

export const manageSessionActionsPropTypes = {
    onAddRound: PropTypes.func.isRequired,
    onAddTeamClick: PropTypes.func.isRequired,
    onGenerateSchedule: PropTypes.func.isRequired,
    onCreateSchedule: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
    onUnpublishSchedule: PropTypes.func.isRequired,
    onScheduleChange: PropTypes.func.isRequired,
    onDeleteSchedule: PropTypes.func.isRequired,
    isCreatingManualSchedule: PropTypes.bool.isRequired,
    hasPostedScores: PropTypes.bool.isRequired,
    isLoadingSchedule: PropTypes.bool.isRequired,
    isScheduleCreated: PropTypes.bool.isRequired,
    isSchedulePublished: PropTypes.bool.isRequired,
    multiRoundScheduleType: PropTypes.number,
    teams: PropTypes.array.isRequired,
    maxTeamsCount: PropTypes.number,
    hasSessionEnded: PropTypes.bool.isRequired,
};

export const headerPropTypes = {
    auth: PropTypes.shape({
        user: PropTypes.shape({
            claimedPlayer: PropTypes.shape({
                playerProfileImages: PropTypes.object
            })
        })
    }).isRequired,
    featureFlags: PropTypes.shape({
        messaging: PropTypes.shape({
            isEnabled: PropTypes.func
        })
    }).isRequired,
    toggleSideMenu: PropTypes.func.isRequired
};

export const multiSelectFilterPropTypes = {
    propTypes: {
        selectOptions: PropTypes.array.isRequired,
        onApplyFilter: PropTypes.func.isRequired,
        hasResetFilters: PropTypes.bool.isRequired,
        label: PropTypes.bool.isRequired,
        currentValue: PropTypes.string
    },
    defaultProps: {
        currentValue: ''
    }
};

export const leagueSearchResultsTablePropTypes = {
    searchResults: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSortChange: PropTypes.func.isRequired,
    sortByProperty: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired
};

export const sessionTableRowProps = {
    rowData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        conference: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        age: PropTypes.string.isRequired,
        rating: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired
    }),
    labelId: PropTypes.string.isRequired
};

export const teamTableRowProps = {
    rowData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        teamMemberCount: PropTypes.number.isRequired,
        sessionName: PropTypes.string.isRequired,
        captainNames: PropTypes.arrayOf(PropTypes.string),
        sessionDates: PropTypes.string.isRequired,
        record: PropTypes.string.isRequired,
        rank: PropTypes.number.isRequired,
        teamPowerIndex: PropTypes.shape({
            singlesRating: PropTypes.number,
            isSinglesEstimated: PropTypes.bool,
            doublesRating: PropTypes.number,
            isDoublesEstimated: PropTypes.bool,
        }),
    }).isRequired,
    labelId: PropTypes.string.isRequired,
    onTeamSelected: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedTeamIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    theme: PropTypes.object.isRequired,
    maxTeamsReached: PropTypes.bool.isRequired,
};

export const teamsTableRowProps = {
    rowData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        homeCourtLocation: PropTypes.shape({
            formattedAddress: PropTypes.string,
        }),
        teamMemberCount: PropTypes.number.isRequired,
        captains: PropTypes.arrayOf(PropTypes.object),
        captainName: PropTypes.string,
        teamPowerIndex: PropTypes.shape({
            singlesRating: PropTypes.number,
            isSinglesEstimated: PropTypes.bool,
            doublesRating: PropTypes.number,
            isDoublesEstimated: PropTypes.bool,
        }),
    }).isRequired,
    labelId: PropTypes.string.isRequired,
    onManageClicked: PropTypes.func.isRequired,
    onRemoveClicked: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    hasSessionStarted: PropTypes.bool.isRequired,
};

export const statsigContextProviderPropTypes = {
    children: PropTypes.node.isRequired,
};

export const sliderFilterPropTypes = {
    onApplyFilter: PropTypes.func.isRequired,
    hasResetFilters: PropTypes.bool.isRequired,
    sliderType: PropTypes.string.isRequired
};

export const searchPagePropTypes = {
    propTypes: {
        userLocation: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number
        }),
        leagueTypeId: PropTypes.string.isRequired,
        sportType: PropTypes.number.isRequired,
        leagueTierType: PropTypes.number.isRequired,
    },
    defaultProps: {
        userLocation: {
            lat: null,
            lng: null
        }
    }
};

export const formSubmitButtonPropTypes = {
    label: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    style: PropTypes.any,
};

export const defaultFormSubmitButtonPropTypes = {
    style: {},
};

export const createConferenceFormPropTypes = {
    isManaging: PropTypes.bool.isRequired,
    conferenceDetails: PropTypes.shape({
        maxAge: PropTypes.number,
        minAge: PropTypes.number,
    })
};

export const teamMatchLinkPropTypes = {
    teamMatchLink: PropTypes.string.isRequired,
    roundName: PropTypes.string,
    isMultiWeekByDate: PropTypes.bool,
};

export const radioGroupPropTypes = {
    control: PropTypes.object,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    sx: PropTypes.object,
};

export const schedulePropTypes = {
    seasonRounds: PropTypes.arrayOf(PropTypes.object),
    isManaging: PropTypes.bool,
    onEditMatch: PropTypes.func,
    onDeleteMatch: PropTypes.func,
    isLeagueDeskSchedule: PropTypes.bool,
    drawSchedule: PropTypes.object,
    isSchedulePublished: PropTypes.bool,
    pageType: PropTypes.string,
    scheduleByDate: PropTypes.object,
    multiRoundScheduleType: PropTypes.number,
    displayTournamentBracketStyle: PropTypes.bool,
    sessionId: PropTypes.number,
    onDeleteRound: PropTypes.func,
    scheduleId: PropTypes.number,
    refetchSessionSchedule: PropTypes.func,
    teams: PropTypes.arrayOf(PropTypes.object),
    session: PropTypes.object
};

export const manageSessionScheduleTabPropTypes = {
    session: PropTypes.shape({
        startDateUtc: PropTypes.string,
        endDateUtc: PropTypes.string,
        id: PropTypes.number,
        sessionTypeId: PropTypes.number,
        matchesPerTeam: PropTypes.number,
        sportTypeId: PropTypes.number,
    }),
    isLoadingSession: PropTypes.bool,
    onAddTeamClick: PropTypes.func,
    teams: PropTypes.array,
    maxTeamsCount: PropTypes.number,
    hasSessionEnded: PropTypes.bool,
};

export const teamPageScheduleTabPropTypes = {
    teamId: PropTypes.number.isRequired,
    isTournament: PropTypes.bool.isRequired,
    session: PropTypes.shape({
        id: PropTypes.number,
    }),
};

const sessionDetailsPropTypes = {
    conferenceId: PropTypes.number,
    description: PropTypes.string,
    doublesMatchCount: PropTypes.number,
    doublesPointsPerMatch: PropTypes.number,
    doublesScoringFormatTypeId: PropTypes.number,
    doublesTimeAllowedTypeId: PropTypes.number,
    drawTypeId: PropTypes.number,
    endDateUtc: PropTypes.string,
    matchesPerTeam: PropTypes.number,
    name: PropTypes.string,
    registrationFee: PropTypes.number,
    registrationVerificationFee: PropTypes.number,
    sessionStatusTypeId: PropTypes.number,
    sessionDetails: PropTypes.string,
    sessionTypeId: PropTypes.number,
    sessionStandingsTypeId: PropTypes.number,
    singlesMatchCount: PropTypes.number,
    singlesPointsPerMatch: PropTypes.number,
    singlesScoringFormatTypeId: PropTypes.number,
    singlesTimeAllowedTypeId: PropTypes.number,
    startDateUtc: PropTypes.string,
    teamRegistrationEndDateUtc: PropTypes.string,
    teamRegistrationStartDateUtc: PropTypes.string,
    teamRequiredPlayersMin: PropTypes.number,
    teamsMax: PropTypes.number,
    location: PropTypes.string,
    teamMatchFrequencyTypeId: PropTypes.number,
};

export const manageSessionModalPropTypes = {
    sessionDetails: PropTypes.shape({
        ...sessionDetailsPropTypes
    }),
    isLoadingSession: PropTypes.bool,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
};

export const manageSessionDetailsTabPropTypes = {
    sessionDetails: PropTypes.shape({
        ...sessionDetailsPropTypes
    }),
};

export const matchFormatPropTypes = {
    matchCount: PropTypes.number.isRequired,
    pointsPerMatch: PropTypes.number.isRequired,
    scoringFormatTypeId: PropTypes.number.isRequired,
    timeAllowedTypeId: PropTypes.number.isRequired,
    isDoubles: PropTypes.bool.isRequired,
    sportTypeId: PropTypes.number.isRequired,
};

export const teamPageHeaderPropTypes = {
    tab: PropTypes.string.isRequired,
    teamName: PropTypes.string.isRequired,
    wins: PropTypes.number,
    losses: PropTypes.number,
    sessionId: PropTypes.number.isRequired,
    sessionName: PropTypes.string.isRequired,
    isCaptainView: PropTypes.bool,
    onTabChange: PropTypes.func.isRequired,
    isTournament: PropTypes.bool,
    teamPowerIndex: PropTypes.shape({
        singlesRating: PropTypes.number,
        doublesRating: PropTypes.number,
        isSinglesEstimated: PropTypes.bool,
        isDoublesEstimated: PropTypes.bool
    })
};

export const genericTablePropTypes = {
    headCells: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string,
        })
    ).isRequired,
    tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    sortBy: PropTypes.string.isRequired,
    rowComponent: PropTypes.elementType.isRequired,
    isSortingDisabled: PropTypes.bool,
};

export const leaguesTablePropTypes = {
    headCells: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string,
        })
    ).isRequired,
    tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    sortBy: PropTypes.string.isRequired,
    rowComponent: PropTypes.elementType.isRequired,
    title: PropTypes.string,
    isSortingDisabled: PropTypes.bool,
};

export const playersTablePropTypes = {
    teamPlayers: PropTypes.arrayOf(
        PropTypes.shape({
            memberId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            id: PropTypes.string,
            gender: PropTypes.string,
            playerId: PropTypes.number,
            teamId: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            wins: PropTypes.number,
            losses: PropTypes.number,
            location: PropTypes.oneOfType([
                PropTypes.shape({
                    placeName: PropTypes.string,
                    formattedAddress: PropTypes.string,
                })
            ]),
            hasPostedScores: PropTypes.bool,
            canBeMovedOrCopied: PropTypes.bool,
            isSubstitute: PropTypes.bool,
            canBeRefunded: PropTypes.bool,
        })
    ),
    isLoading: PropTypes.bool,
    onWithdrawPlayer: PropTypes.func,
    onMovePlayer: PropTypes.func,
    onCopyPlayer: PropTypes.func,
    onTogglePlayerStatus: PropTypes.func,
    isManaging: PropTypes.bool,
    hasSessionEnded: PropTypes.bool,
};

export const playersTableDefaultPropTypes = {
    teamPlayers: [],
    isManaging: false,
    hasSessionEnded: false,
    onWithdrawPlayer: () => {},
    onMovePlayer: () => {},
    onCopyPlayer: () => {},
    onTogglePlayerStatus: () => {},
};

export const playerTableRowPropTypes = {
    rowData: PropTypes.shape({
        doublesUtr: PropTypes.number,
        gender: PropTypes.string,
        id: PropTypes.string,
        isVerifiedDoubles: PropTypes.bool,
        isVerifiedSingles: PropTypes.bool,
        location: PropTypes.shape({
            placeName: PropTypes.string,
            formattedAddress: PropTypes.string,
        }),
        leagueId: PropTypes.number,
        sportTypeId: PropTypes.string,
        conferenceRatings: PropTypes.shape({
            utrRangeMin: PropTypes.number,
            utrRangeMax: PropTypes.number,
            pickleballRangeMin: PropTypes.number,
            pickleballRangeMax: PropTypes.number,
        }),
    }),
    onConferenceUpdated: PropTypes.func,
    isLoadingConference: PropTypes.bool,
};

export const defaultCreateConferenceFormPropTypes = {
    conferenceDetails: null,
    isLoading: false,
    onConferenceUpdated: null,
    matchesPlayed: PropTypes.number,
    name: PropTypes.string,
    profileImage: PropTypes.string,
    playerId: PropTypes.string,
    record: PropTypes.string,
    singlesUtr: PropTypes.number,
    teamId: PropTypes.number,
    hasPostedScores: PropTypes.bool,
    canBeMovedOrCopied: PropTypes.bool,
    isSubstitute: PropTypes.bool,
    canBeRefunded: PropTypes.bool,
    singlesUTRP: PropTypes.shape({
        rating: PropTypes.number,
        displayVerifiedIcon: PropTypes.bool,
        reliability: PropTypes.string,
    }),
    doublesUTRP: PropTypes.shape({
        rating: PropTypes.number,
        displayVerifiedIcon: PropTypes.bool,
        reliability: PropTypes.string,
    }),
    labelId: PropTypes.string.isRequired,
};

export const playerTablePropTypes = {
    teamPlayers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            playerId: PropTypes.number.isRequired,
            teamId: PropTypes.number,
            name: PropTypes.string,
            doublesUtr: PropTypes.number,
            isVerifiedDoubles: PropTypes.bool,
            singlesUtr: PropTypes.number,
            isVerifiedSingles: PropTypes.bool,
            hasPostedScores: PropTypes.bool.isRequired,
            canBeMovedOrCopied: PropTypes.bool.isRequired,
            isSubstitute: PropTypes.bool.isRequired,
            canBeRefunded: PropTypes.bool.isRequired,
            profileImage: PropTypes.string,
            singlesUTRP: PropTypes.shape({
                rating: PropTypes.number.isRequired,
                displayVerifiedIcon: PropTypes.bool.isRequired,
                reliability: PropTypes.string.isRequired,
            }),
            doublesUTRP: PropTypes.shape({
                rating: PropTypes.number.isRequired,
                displayVerifiedIcon: PropTypes.bool.isRequired,
                reliability: PropTypes.string.isRequired,
            }),
            singlesRecord: PropTypes.string,
            doublesRecord: PropTypes.string,
        })
    ),
    isLoading: PropTypes.bool,
    onWithdrawPlayer: PropTypes.func,
    onMovePlayer: PropTypes.func,
    onCopyPlayer: PropTypes.func,
    onTogglePlayerStatus: PropTypes.func,
    isManaging: PropTypes.bool,
    isSubstitutePlayers: PropTypes.bool,
    hasSessionEnded: PropTypes.bool,
};

export const playersTableRowPropTypes = {
    rowData: PropTypes.shape({
        doublesUtr: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        isVerifiedDoubles: PropTypes.bool.isRequired,
        isVerifiedSingles: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        profileImage: PropTypes.string.isRequired,
        playerId: PropTypes.string.isRequired,
        singlesUtr: PropTypes.number.isRequired,
        teamId: PropTypes.number.isRequired,
        hasPostedScores: PropTypes.bool.isRequired,
        canBeMovedOrCopied: PropTypes.bool.isRequired,
        isSubstitute: PropTypes.bool.isRequired,
        canBeRefunded: PropTypes.bool.isRequired,
        singlesUTRP: PropTypes.shape({
            rating: PropTypes.number.isRequired,
            displayVerifiedIcon: PropTypes.bool.isRequired,
            reliability: PropTypes.string.isRequired,
        }).isRequired,
        doublesUTRP: PropTypes.shape({
            rating: PropTypes.number.isRequired,
            displayVerifiedIcon: PropTypes.bool.isRequired,
            reliability: PropTypes.string.isRequired,
        }).isRequired,
        singlesRecord: PropTypes.string.isRequired,
        doublesRecord: PropTypes.string.isRequired,
    }).isRequired,
    labelId: PropTypes.string.isRequired,
};

export const submitPaymentFormPropTypes = {
    clientSecret: PropTypes.string.isRequired,
    onRegistrationSuccessful: PropTypes.func.isRequired,
    segmentTrackingBody: PropTypes.shape({
        league_session_id: PropTypes.number,
        member_id: PropTypes.number,
        order_type: PropTypes.string,
        currency: PropTypes.string,
        session_fee: PropTypes.number,
        league_fee: PropTypes.number,
        service_fee: PropTypes.number,
        amount_paid: PropTypes.number,
        revenue: PropTypes.number,
    }).isRequired,
};

export const errorBoundaryPropTypes = {
    main: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export const manageSessionTeamsTabPropTypes = {
    teams: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        })
    ),
    maxTeamsCount: PropTypes.number.isRequired,
    sessionTypeId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onRemoveTeam: PropTypes.func.isRequired,
    hasSessionStarted: PropTypes.bool.isRequired,
    hasSessionEnded: PropTypes.bool.isRequired,
    onAddTeamClick: PropTypes.func.isRequired,
};

export const createSessionFormPropTypes = {
    sessionDetails: PropTypes.shape({
        conferenceId: PropTypes.number,
        endDateUtc: PropTypes.string,
        startDateUtc: PropTypes.string,
        registrationFee: PropTypes.number,
        teamRegistrationStartDateUtc: PropTypes.string,
        teamRegistrationEndDateUtc: PropTypes.string,
        registrationVerificationFee: PropTypes.number,
        sessionStandingsTypeId: PropTypes.number,
        sessionMatchTypeId: PropTypes.string,
        sessionTypeId: PropTypes.string,
        description: PropTypes.string,
        drawTypeId: PropTypes.string,
        location: PropTypes.shape({
            placeName: PropTypes.string,
        }),
    }),
    isManaging: PropTypes.bool.isRequired,
    isLoadingSession: PropTypes.bool,
    onSessionUpdated: PropTypes.func,
};

export const defaultCreateSessionFormPropTypes = {
    sessionDetails: null,
    isLoading: false,
    onSessionUpdated: null,
};

export const registrationInfoCardPropTypes = {
    cost: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    sessionDates: PropTypes.string.isRequired,
    sessionData: PropTypes.shape({
        conference: PropTypes.shape({
            minAge: PropTypes.number.isRequired,
            maxAge: PropTypes.number.isRequired,
            genderTypeId: PropTypes.number.isRequired,
            location: PropTypes.shape({
                placeName: PropTypes.string,
            }),
            conferenceRatings: PropTypes.object,
            sportTypeId: PropTypes.number.isRequired,
        }),
        captainRequest: PropTypes.shape({
            currentRequest: PropTypes.shape({
                sessionRequestStatusTypeId: PropTypes.number.isRequired,
            }),
            isCaptainInSession: PropTypes.bool.isRequired,
        }),
        memberTeams: PropTypes.array.isRequired,
        session: PropTypes.shape({
            endDateUtc: PropTypes.string.isRequired,
            teamRegistrationEndDateUtc: PropTypes.string.isRequired,
            teamRegistrationStartDateUtc: PropTypes.string.isRequired,
            sessionStatusTypeId: PropTypes.number.isRequired,
            sanctionTypeId: PropTypes.number,
        }),
    }).isRequired,
    onRequestToCaptain: PropTypes.func.isRequired,
    onRegisterButtonClick: PropTypes.func.isRequired,
};

export const sessionPagePropTypes = {
    cost: PropTypes.string,
    format: PropTypes.string,
    sessionDates: PropTypes.string,
    sessionData: PropTypes.shape({
        conference: PropTypes.shape({
            minAge: PropTypes.number.isRequired,
            maxAge: PropTypes.number.isRequired,
            genderTypeId: PropTypes.number.isRequired,
            location: PropTypes.shape({
                placeName: PropTypes.string,
            }),
            conferenceRatings: PropTypes.object,
            sportTypeId: PropTypes.number.isRequired,
        }),
        captainRequest: PropTypes.shape({
            currentRequest: PropTypes.shape({
                sessionRequestStatusTypeId: PropTypes.number.isRequired,
            }),
            isCaptainInSession: PropTypes.bool.isRequired,
        }),
        memberTeams: PropTypes.array,
        session: PropTypes.shape({
            endDateUtc: PropTypes.string.isRequired,
            teamRegistrationEndDateUtc: PropTypes.string.isRequired,
            teamRegistrationStartDateUtc: PropTypes.string.isRequired,
            sessionStatusTypeId: PropTypes.number.isRequired,
        })
    })
};

export const managerCardPropTypes = {
    managers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            memberId: PropTypes.number.isRequired,
            phone: PropTypes.string,
            email: PropTypes.string,
            playerId: PropTypes.number,
            profileImage: PropTypes.string,
        })
    ),
    isUserLoggedIn: PropTypes.bool,
    isCaptainView: PropTypes.bool,
    onRemoveClick: PropTypes.func,
};

export const sessionOverviewTabPropTypes = {
    description: PropTypes.string,
    cost: PropTypes.string.isRequired,
    sessionDates: PropTypes.string.isRequired,
    matchFormat: PropTypes.string.isRequired,
    sessionData: PropTypes.object.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    managers: PropTypes.array.isRequired,
    onRegisterButtonClick: PropTypes.func.isRequired,
    onRequestToCaptain: PropTypes.func.isRequired,
};

export const teamPageOverviewTabPropTypes = {
    teamSummary: PropTypes.object.isRequired,
    isCaptainView: PropTypes.bool.isRequired,
    isRegistrationAvailable: PropTypes.bool.isRequired,
    isLoadingRegistration: PropTypes.bool,
    onRegisterClick: PropTypes.func.isRequired,
    onShareClick: PropTypes.func.isRequired,
    fees: PropTypes.object.isRequired,
    captains: PropTypes.array.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
};

export const defaultTeamPageOverviewTabPropTypes = {
    isLoadingRegistration: false,
};

export const sessionPageHeaderPropTypes = {
    tab: PropTypes.string.isRequired,
    sessionName: PropTypes.string,
    onTabChange: PropTypes.func.isRequired,
    isMultiRoundSession: PropTypes.bool.isRequired,
    conferenceName: PropTypes.string.isRequired,
};

export const createTeamFormPropTypes = {
    isManaging: PropTypes.bool.isRequired,
    teamDetails: PropTypes.shape({
        sessionId: PropTypes.number,
        timeOfDay: PropTypes.string,
        homeCourtLocation: PropTypes.shape({
            placeName: PropTypes.string,
            formattedAddress: PropTypes.string,
        }),
        registrationPin: PropTypes.string,
        sessionTypeId: PropTypes.number,
        dayOfWeek: PropTypes.string,
    }),
    isLoadingTeam: PropTypes.bool,
    isCaptainEditing: PropTypes.bool,
    onTeamUpdated: PropTypes.func,
};

export const defaultCreateTeamFormPropTypes = {
    teamDetails: {},
    isLoadingTeam: false,
    isCaptainEditing: false,
    onTeamUpdated: null
};

export const playersTabPropTypes = {
    teamId: PropTypes.number,
    isCaptainView: PropTypes.bool,
    onMessageAllPlayers: PropTypes.func,
};

export const playerTabPropTypes = {
    sessionId: PropTypes.string.isRequired,
    onWithdrawPlayer: PropTypes.func,
    onMovePlayer: PropTypes.func,
    onCopyPlayer: PropTypes.func,
    onTogglePlayerStatus: PropTypes.func,
    isManaging: PropTypes.bool,
    hasSessionEnded: PropTypes.bool,
    isUpdating: PropTypes.bool,
    displaySinglesAndDoublesRecord: PropTypes.bool.isRequired,
};

export const drawTablePropTypes = {
    onEditMatch: PropTypes.func.isRequired,
    onViewMatch: PropTypes.func.isRequired,
    onChangeClick: PropTypes.func,
    draw: PropTypes.shape({
        name: PropTypes.string.isRequired,
        drawId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    selectableTeams: PropTypes.array.isRequired,
    hasPublishedSchedule: PropTypes.bool.isRequired,
    isUpdatingTeamMatch: PropTypes.bool,
    isManaging: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    isMainDraw: PropTypes.bool.isRequired,
    totalRounds: PropTypes.array.isRequired,
    isMobile: PropTypes.bool.isRequired,
    displayTournamentBracketStyle: PropTypes.bool.isRequired,
    showMainDraw: PropTypes.bool.isRequired,
};

export const sessionStandingsTablePropTypes = {
    sessionStandings: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        teamName: PropTypes.string.isRequired,
        teamMatchWins: PropTypes.number.isRequired,
        teamMatchLosses: PropTypes.number.isRequired,
        playerMatchPoints: PropTypes.number.isRequired,
        playerMatchGameWinPercentage: PropTypes.number.isRequired,
        teamId: PropTypes.number.isRequired,
        rank: PropTypes.number.isRequired,
        teamPowerIndex: PropTypes.shape({
            singlesRating: PropTypes.string,
            isSinglesEstimated: PropTypes.bool,
            doublesRating: PropTypes.string,
            isDoublesEstimated: PropTypes.bool
        })
    })),
    isLoading: PropTypes.bool,
    sportTypeId: PropTypes.number
};

export const teamTableRowPropTypes = {
    rowData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        teamName: PropTypes.string.isRequired,
        teamMatchWins: PropTypes.number.isRequired,
        teamMatchLosses: PropTypes.number.isRequired,
        playerMatchPoints: PropTypes.number.isRequired,
        playerMatchGameWinPercentage: PropTypes.number.isRequired,
        teamId: PropTypes.string.isRequired,
        rank: PropTypes.number.isRequired,
        teamPowerIndex: PropTypes.shape({
            singlesRating: PropTypes.string,
            isSinglesEstimated: PropTypes.bool,
            doublesRating: PropTypes.string,
            isDoublesEstimated: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    labelId: PropTypes.string.isRequired,
};

export const standingsTabPropTypes = {
    sportTypeId: PropTypes.number.isRequired,
    teamStandings: PropTypes.array.isRequired
};

export const registrationDrawerPropTypes = {
    isOpen: PropTypes.bool.isRequired,
    isUsapSanctionedSection: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onFreeSessionRegistration: PropTypes.func.isRequired,
    onTeamSelectionClick: PropTypes.func,
    isSessionPage: PropTypes.bool,
    teamData: PropTypes.shape({
        hasRegistrationPin: PropTypes.bool.isRequired,
        teamId: PropTypes.number.isRequired,
        teamName: PropTypes.string.isRequired,
        sessionSummary: PropTypes.shape({
            id: PropTypes.number.isRequired
        }).isRequired
    }),
    registrationFees: PropTypes.object,
    teams: PropTypes.arrayOf(PropTypes.object)
};

export const defaultRegistrationDrawerPropTypes = {
    teams: [],
    teamData: {},
    registrationFees: {},
    isSessionPage: false,
    isUsapSanctionedSection: false,
    onTeamSelectionClick: () => {},
};

export const teamSelectionProptypes = {
    onTeamCardClick: PropTypes.func.isRequired,
    onNotifyOrgClick: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape({
        captains: PropTypes.arrayOf(PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired
        })).isRequired,
        hasRegistrationPin: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    canSendSessionJoinRequest: PropTypes.bool.isRequired
};

export const passwordConfirmationProptypes = {
    onPinSubmitted: PropTypes.func.isRequired,
    captains: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired
    })).isRequired,
    isInvalidPasscode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export const usapValidationProptypes = {
    onMemberIdSubmitted: PropTypes.func.isRequired,
    validationError: PropTypes.string,
    isLoading: PropTypes.bool.isRequired
};

export const defaultUsapValidationProptypes = {
    validationError: null
};

export const registrationConfirmationPropTypes = {
    registrationFees: PropTypes.shape({
        sessionEntryFee: PropTypes.number.isRequired,
        leagueFee: PropTypes.number.isRequired,
        serviceFee: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        currencySymbol: PropTypes.string.isRequired,
    }).isRequired,
    onRegistrationSuccess: PropTypes.func.isRequired,
    clientSecret: PropTypes.string.isRequired,
    sessionId: PropTypes.number.isRequired,
};

export const standardFormPropTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
};

export const defaultStandardFormPropTypes = {
    isRequired: false
};
