import React from 'react';
import { useForm } from 'react-hook-form';

import { Typography, Button } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

import RadioGroup from '../Form/RadioGroup';
import Select from '../Form/Select';

import { tennisSessionStandingsOptions, pickleballSessionStandingsOptions, SessionStandingsTypes } from '../../utils/types';
import { getNewRoundNumberOptions } from '../../utils/helpers';
import { addScheduleRoundPopupPropTypes } from '../../utils/proptypes';

const AddScheduleRoundPopup = ({
    rounds = [],
    open = false,
    onClose,
    title = '',
    insertRoundMessage = '',
    teamStandingsMessage = '',
    errorMessage = '',
    buttonLabel = '',
    onAction,
    actionLabel,
    isTennis = false,
    disableAddRoundButton,
}) => {
    const { TENNIS_WIN_LOSS_RECORD, PICKLEBALL_WIN_LOSS_RECORD } = SessionStandingsTypes;
    const radioGroupOptions = isTennis ? tennisSessionStandingsOptions : pickleballSessionStandingsOptions;
    const defaultSessionStandingType = isTennis ? TENNIS_WIN_LOSS_RECORD : PICKLEBALL_WIN_LOSS_RECORD;
    const newRoundNumberOptions = rounds && getNewRoundNumberOptions(rounds);
    const firstRound = 1;

    const defaultValues = {
        newRoundNumber: firstRound,
        sessionStandingTypeId: defaultSessionStandingType,
    };
    const { handleSubmit, control, reset } = useForm({ defaultValues, mode: 'all' });

    const handleOnAddRound = () => {
        handleSubmit(onAction)();
    };

    const handleOnClose = () => {
        reset(defaultValues);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleOnClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <form>
                <Box p={3}>
                    {!!title && (
                        <Typography category="display" size="medium-book">
                            {title}
                        </Typography>
                    )}
                    {rounds?.length > 0 && (
                        <Box mb={3}>
                            <Typography category="secondary" size="medium-book" pt={1} mb={1}>
                                {insertRoundMessage}
                            </Typography>
                            <Select
                                name="newRoundNumber"
                                options={newRoundNumberOptions}
                                labelId="rounds-label"
                                isFullWidth
                                control={control}
                            />
                        </Box>
                    )}
                    <Typography category="secondary" size="medium-book" pt={1}>
                        {teamStandingsMessage}
                    </Typography>
                    <RadioGroup name="sessionStandingTypeId" options={radioGroupOptions} control={control} sx={{ mb: 0, mr: 0 }} />
                    {!!errorMessage && (
                        <DialogContentText className="text-error" align="center" id="alert-dialog-error" pt={1}>
                            {errorMessage}
                        </DialogContentText>
                    )}
                    <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                        {buttonLabel && (
                            <Button category="secondary" onClick={onClose}>
                                {buttonLabel}
                            </Button>
                        )}
                        {onAction && (
                            <Button category="primary" onClick={handleOnAddRound} disabled={disableAddRoundButton}>
                                {actionLabel}
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </form>
        </Dialog>
    );
};

AddScheduleRoundPopup.propTypes = {
    ...addScheduleRoundPopupPropTypes,
};

export default AddScheduleRoundPopup;
