import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Container from '@mui/material/Container';
import Popup from '../../../components/Popups/Popup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Schedule from '../../../components/Schedule';
import SessionActions from './SessionActions';
import MatchEditModal from '../../TeamMatchPage/MatchEditModal';
import ConfirmPublishSchedulePopup from '../../../components/Popups/ConfirmPublishSchedulePopup';
import AddScheduleRoundPopup from '../../../components/Popups/AddScheduleRoundPopup';

import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { LeagueSessionTypes, SportTypeIds } from '../../../utils/types';
import { getRoundCount, getTooManyMatchesPerTeamError } from '../../../utils/sessions';
import {
    useUpdateTeamMatchSummaryMutation,
    useCreateEmptyScheduleMutation,
    useAddScheduleRoundMutation,
    useRemoveScheduleRoundMutation,
    useRemoveTeamMatchMutation,
} from '../../../api/leaguesApi';
import { getAdjustedDate } from '../../../utils/display';
import { MANAGE_SESSION_PAGE_TYPE } from '../../../components/DrawTab/utils';
import useSessionSchedule from '../../../hooks/useSessionSchedule';
import { manageSessionScheduleTabPropTypes } from '../../../utils/proptypes';

const SCHEDULE_CREATION_MORE_MATCHES_TEXT =
    'There are more matches per team than weeks in the session.  All match dates will default to the session start date.  Please be sure to update match times prior to publishing';

const ScheduleTab = ({ session, isLoadingSession, onAddTeamClick, teams, maxTeamsCount, hasSessionEnded }) => {
    const dispatch = useDispatch();
    const { startDateUtc, endDateUtc, id: sessionId, sessionTypeId, matchesPerTeam } = session;
    const { MULTI_ROUND } = LeagueSessionTypes;
    const { TENNIS } = SportTypeIds;
    const teamCount = teams?.length;
    const [isCreatingManualSchedule, setIsCreatingManualSchedule] = useState(false);
    const [showGeneratePopup, setShowGeneratePopup] = useState(false);
    const [showPublishPopup, setShowPublishPopup] = useState(false);
    const [showAddRoundPopup, setShowAddRoundPopup] = useState(false);
    const [showDeleteRoundPopup, setShowDeleteRoundPopup] = useState(false);
    const [showDeleteMatchPopup, setShowDeleteMatchPopup] = useState(false);
    const [showUnpublishPopup, setShowUnpublishPopup] = useState(false);
    const [roundIdToDelete, setRoundIdToDelete] = useState();
    const [roundName, setRoundName] = useState();
    const [matchNumber, setMatchNumber] = useState();
    const [matchIdToDelete, setMatchIdToDelete] = useState();
    const [showDeleteSchedulePopup, setShowDeleteSchedulePopup] = useState(false);
    const [matchToUpdate, setMatchToUpdate] = useState(null);
    const [showEditMatchModal, setShowEditMatchModal] = useState(false);

    const isMultiRoundSession = sessionTypeId === MULTI_ROUND;
    const dateFormat = 'll';
    const startDate = getAdjustedDate({ dateUtc: startDateUtc, format: dateFormat });
    const endDate = getAdjustedDate({ dateUtc: endDateUtc, format: dateFormat });
    const roundCount = getRoundCount(teamCount, matchesPerTeam);
    const schedulePopupMessage = `Using the ${teamCount} teams in this session, we will generate a schedule with ${roundCount} rounds from ${startDate} - ${endDate}.`;
    const publishPopupMessage =
        'Publishing will make the schedule viewable to the public. The schedule can be modified until the first match score is posted.';
    const insertRoundMessage = 'Where would you like to insert this round?';
    const teamStandingsMessage = 'How would you like team standings to be calculated?';
    const deletePopupMessage = 'Are you sure you want to delete this schedule?';
    const confirmPublishCheckboxMessage = 'Notify teams that the schedule has been published';
    const unpublishPopupMessage = 'Are you sure you want to Unpublish this Schedule?';

    const [updateTeamMatchSummary, { isLoading: isUpdatingSummary }] = useUpdateTeamMatchSummaryMutation();
    const [onCreateEmptySchedule] = useCreateEmptyScheduleMutation({ sessionId });
    const {
        scheduleId,
        scheduleRounds,
        scheduleByDate,
        drawSchedule,
        hasPostedScores,
        isLoadingSchedule,
        isScheduleCreated,
        isSchedulePublished,
        onCreateSchedule,
        onDeleteSchedule,
        onGetScheduleByDate,
        onPublishSchedule,
        onUnpublishSchedule,
        multiRoundScheduleType,
        refetchSessionSchedule,
    } = useSessionSchedule({ sessionId, showDraftSchedule: true, isMultiRoundSession });

    const [onAddScheduleRound, { isLoading: isAddingRound }] = useAddScheduleRoundMutation({ scheduleId });
    const [onDeleteRound] = useRemoveScheduleRoundMutation();
    const [onRemoveMatch] = useRemoveTeamMatchMutation();

    const isTennis = session.sportTypeId === TENNIS;
    const isLoading = isLoadingSession || isLoadingSchedule || isUpdatingSummary;

    const handleOnScheduleChange = (event) => {
        const scheduleType = event?.target?.value;
        onGetScheduleByDate(scheduleType);
    };

    const handleOnGenerateSchedule = () => {
        setShowGeneratePopup(true);
    };

    const handleOnCreateSchedule = async () => {
        setIsCreatingManualSchedule(true);
        try {
            await onCreateEmptySchedule(sessionId).unwrap();
            refetchSessionSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnConfirmGenerateSchedule = () => {
        setShowGeneratePopup(false);
        onCreateSchedule();
    };

    const handleOnConfirmPublishSchedule = ({ isNotifyingTeams }) => {
        setShowPublishPopup(false);
        onPublishSchedule({ notifyTeams: isNotifyingTeams });
    };

    const handleOnPublish = () => {
        setShowPublishPopup(true);
    };

    const handleOnUnpublish = () => {
        setShowUnpublishPopup(true);
    };

    const handleOnDeleteSchedule = () => {
        setShowDeleteSchedulePopup(true);
    };

    const handleOnConfirmDeleteSchedule = async () => {
        onDeleteSchedule();
        setShowDeleteSchedulePopup(false);
        setIsCreatingManualSchedule(false);
    };

    const handleOnCloseGeneratePopup = () => {
        setShowGeneratePopup(false);
    };

    const handleOnClosePublishPopup = () => {
        setShowPublishPopup(false);
    };

    const handleOnEditMatch = (match) => {
        setMatchToUpdate(match);
        setShowEditMatchModal(true);
    };

    const handleOnCloseEditModal = () => {
        setShowEditMatchModal(false);
    };

    const handleOnEditSubmit = async (teamMatch) => {
        setShowEditMatchModal(false);
        try {
            await updateTeamMatchSummary({ teamMatchId: matchToUpdate.teamMatchId, sessionId, scheduleId, teamMatch }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnDisplayAddRound = () => {
        setShowAddRoundPopup(true);
    };

    const handleOnAddRound = async (newRound) => {
        if (!isAddingRound) {
            try {
                await onAddScheduleRound({
                    scheduleId,
                    newRound,
                }).unwrap();
                refetchSessionSchedule();
            } catch (apiError) {
                dispatch(showErrorPopup(apiError));
            }
        }
        setShowAddRoundPopup(false);
    };

    const handleOnCloseAddRoundPopup = async () => {
        setShowAddRoundPopup(false);
    };

    const handleOnDeleteRound = (roundId, name) => {
        setRoundIdToDelete(roundId);
        setRoundName(name);
        setShowDeleteRoundPopup(true);
    };

    const handleOnConfirmDeleteRound = async () => {
        try {
            await onDeleteRound({ scheduleId, roundId: roundIdToDelete }).unwrap();
            refetchSessionSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
        setShowDeleteRoundPopup(false);
    };

    const handleOnDeleteMatch = (teamMatchId, round, matchIndex) => {
        setMatchIdToDelete(teamMatchId);
        setRoundName(round);
        setMatchNumber(matchIndex);
        setShowDeleteMatchPopup(true);
    };

    const handleOnConfirmDeleteTeamMatch = async () => {
        try {
            await onRemoveMatch({ teamMatchId: matchIdToDelete });
            refetchSessionSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
        setShowDeleteMatchPopup(false);
    };

    const handleOnUnpublishSchedule = async () => {
        try {
            await onUnpublishSchedule();
            refetchSessionSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
        setShowUnpublishPopup(false);
    };

    const hasErrors = getTooManyMatchesPerTeamError(session);

    return (
        <Container maxWidth="lg" disableGutters>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <SessionActions
                        onAddRound={handleOnDisplayAddRound}
                        onAddTeamClick={onAddTeamClick}
                        onGenerateSchedule={handleOnGenerateSchedule}
                        onCreateSchedule={handleOnCreateSchedule}
                        onPublish={handleOnPublish}
                        onUnpublishSchedule={handleOnUnpublish}
                        onScheduleChange={handleOnScheduleChange}
                        onDeleteSchedule={handleOnDeleteSchedule}
                        isCreatingManualSchedule={isCreatingManualSchedule}
                        hasPostedScores={hasPostedScores}
                        isLoadingSchedule={isLoadingSchedule}
                        isScheduleCreated={isScheduleCreated}
                        isSchedulePublished={isSchedulePublished}
                        multiRoundScheduleType={multiRoundScheduleType}
                        teams={teams}
                        maxTeamsCount={maxTeamsCount}
                        hasSessionEnded={hasSessionEnded}
                    />
                    <Schedule
                        isManaging
                        seasonRounds={scheduleRounds}
                        drawSchedule={drawSchedule}
                        scheduleByDate={scheduleByDate}
                        multiRoundScheduleType={multiRoundScheduleType}
                        onEditMatch={handleOnEditMatch}
                        onDeleteMatch={handleOnDeleteMatch}
                        onDeleteRound={handleOnDeleteRound}
                        isLeagueDeskSchedule
                        isSchedulePublished={isSchedulePublished}
                        pageType={MANAGE_SESSION_PAGE_TYPE}
                        sessionId={session?.id}
                        session={session}
                        scheduleId={scheduleId}
                        refetchSessionSchedule={refetchSessionSchedule}
                        teams={teams}
                    />
                </>
            )}
            <Popup
                onAction={handleOnConfirmGenerateSchedule}
                actionLabel="Generate Schedule"
                buttonLabel="Cancel"
                open={showGeneratePopup}
                onClose={handleOnCloseGeneratePopup}
                title="Schedule Creation"
                errorMessage={hasErrors ? SCHEDULE_CREATION_MORE_MATCHES_TEXT : ''}
                message={schedulePopupMessage}
            />
            <AddScheduleRoundPopup
                rounds={scheduleRounds}
                isTennis={isTennis}
                onAction={handleOnAddRound}
                actionLabel="Add Round"
                buttonLabel="Cancel"
                open={showAddRoundPopup}
                onClose={handleOnCloseAddRoundPopup}
                title="Add Rounds"
                insertRoundMessage={insertRoundMessage}
                teamStandingsMessage={teamStandingsMessage}
                disableAddRoundButton={isAddingRound}
            />
            <ConfirmPublishSchedulePopup
                onAction={handleOnConfirmPublishSchedule}
                actionLabel="Publish Schedule"
                buttonLabel="Cancel"
                open={showPublishPopup}
                onClose={handleOnClosePublishPopup}
                title="Confirm Publish"
                message={publishPopupMessage}
                checkboxLabel={confirmPublishCheckboxMessage}
            />
            <Popup
                onAction={handleOnConfirmDeleteSchedule}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showDeleteSchedulePopup}
                onClose={() => setShowDeleteSchedulePopup(false)}
                title="Delete Schedule"
                message={deletePopupMessage}
            />
            <MatchEditModal
                isOpen={showEditMatchModal}
                onClose={handleOnCloseEditModal}
                onSubmit={handleOnEditSubmit}
                match={matchToUpdate}
                allowTeamSwap
                isMultiRoundSession={isMultiRoundSession}
            />
            <Popup
                onAction={handleOnConfirmDeleteRound}
                actionLabel="Yes, Delete"
                buttonLabel="No, Cancel"
                open={showDeleteRoundPopup}
                onClose={() => setShowDeleteRoundPopup(false)}
                title="Delete Round"
                message={`Are you sure you wish to remove ${roundName} from the schedule? This could result in teams having different numbers of matches for the season.`}
            />
            <Popup
                onAction={handleOnConfirmDeleteTeamMatch}
                actionLabel="Yes, Delete"
                buttonLabel="No, Cancel"
                open={showDeleteMatchPopup}
                onClose={() => setShowDeleteMatchPopup(false)}
                title="Delete Team Match"
                message={`Are you sure you wish to remove team match ${matchNumber} from ${roundName}? This could result in teams having different numbers of matches for the season.`}
            />
            <Popup
                onAction={handleOnUnpublishSchedule}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showUnpublishPopup}
                onClose={() => setShowUnpublishPopup(false)}
                title="Unpublish Schedule"
                message={unpublishPopupMessage}
            />
        </Container>
    );
};

ScheduleTab.propTypes = {
    ...manageSessionScheduleTabPropTypes,
};

export default ScheduleTab;
