import React from 'react';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Button, Typography } from '@universal-tennis/ui-shared';

import { PlayerAvailabilityStatusTypes } from '../../../../utils/types';

const PlayerAvailabilityEditModal = ({ onStatusChange, isOpen, onClose }) => {
    const { YES, MAYBE, NO } = PlayerAvailabilityStatusTypes;

    const handleOnAvailabilityChange = (event) => {
        const status = event.currentTarget.value;
        onStatusChange(status);
    };

    return (
        <Dialog maxWidth="xl" sx={{ p: 5 }} open={isOpen}>
            <DialogTitle align="left">
                <Typography sx={{ textTransform: 'none' }} size="medium-book" category="display">Update player RSVP</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'black',
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={300}>
                    <Typography size="medium-book" category="secondary">
                        What would you like to update the player&apos;s RSVP to?
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                <Button category="secondary" value={YES} onClick={handleOnAvailabilityChange} variant="light">
                    Yes
                </Button>
                <Button category="secondary" value={NO} onClick={handleOnAvailabilityChange} variant="light">
                    No
                </Button>
                <Button category="secondary" value={MAYBE} onClick={handleOnAvailabilityChange} variant="light">
                    Maybe
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PlayerAvailabilityEditModal;
