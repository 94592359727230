import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import config from '../../config';
import { hideErrorPopup } from '../../redux/errorPopupSlice';

const ErrorPopup = () => {
    const dispatch = useDispatch();
    const { isOpen, error } = useSelector((state) => state.errorPopup);

    const handleOnClose = () => {
        dispatch(hideErrorPopup());
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleOnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ p: 2 }}
        >
            <DialogTitle align="center" color="inherit" id="alert-dialog-title">
                Unable to complete your request
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText align="center" id="alert-dialog-description">
                    {error?.message}
                </DialogContentText>
                <DialogContentText mt={3} align="center" id="alert-dialog-support">
                    For support, please submit a question <Link href={config.links.support}>here</Link>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                <Button variant="dark" onClick={handleOnClose}>
                    ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorPopup;
