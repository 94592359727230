import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import LeagueDeskPage from '..';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { setSportTypeId } from '../../../redux/conferenceDetailsSlice';
import { useGetLeagueSummaryQuery, useGetUserLeaguesQuery } from '../../../api/leaguesApi';

const LeagueDeskWrapper = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { leagueId } = useParams();
    const [organizerLeagues, setOrganizerLeagues] = useState([]);
    const [isLeagueOrganizer, setIsOrganizerLeague] = useState(false);

    const { data: leagueData, isLoading: isLoadingLeagueData, error: leagueDataError } = useGetLeagueSummaryQuery(parseInt(leagueId));
    const { data: userLeagues, isLoading: isLoadingOrganizerLeagues, error: organizerLeaguesError } = useGetUserLeaguesQuery();

    const isLoading = isLoadingLeagueData || isLoadingOrganizerLeagues;

    if (leagueDataError) {
        dispatch(showErrorPopup(leagueDataError));
    }

    if (organizerLeaguesError) {
        dispatch(showErrorPopup(organizerLeaguesError));
    }

    useEffect(() => {
        if (leagueData) {
            dispatch(setSportTypeId(leagueData?.sportTypeId));
        }

        if (userLeagues) {
            const tennisLeagues = userLeagues?.leagues?.tennis || [];
            const pickleballLeagues = userLeagues?.leagues?.pickleball || [];
            const formattedLeagues = [...tennisLeagues, ...pickleballLeagues].map((league) => {
                return (
                    { id: league.id, description: league.name }
                );
            });
            const organizerLeagueIds = formattedLeagues.map((league) => league.id);
            const isOrganizerLeague = organizerLeagueIds.includes(parseInt(leagueId));

            setIsOrganizerLeague(isOrganizerLeague);
            setOrganizerLeagues(formattedLeagues);
        }
    }, [leagueData, userLeagues]);

    if (leagueDataError) {
        dispatch(showErrorPopup(leagueDataError));
    }

    if (organizerLeaguesError) {
        dispatch(showErrorPopup(organizerLeaguesError));
    }

    const handleOnLeagueSelected = (id) => {
        navigate(`/leagues/${id}`);
    };

    return (
        <LeagueDeskPage
            leagueData={leagueData}
            organizerLeagues={organizerLeagues}
            leagueId={leagueId}
            isLoading={isLoading}
            isLeagueOrganizer={isLeagueOrganizer}
            onLeagueSelected={handleOnLeagueSelected}
        />
    );
};

export default LeagueDeskWrapper;
