import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import { Button, Typography, SquareAvatarWithName, DeleteIcon } from '@universal-tennis/ui-shared';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

import LoadingIndicator from '../LoadingIndicator';
import AddTeamsTable from '../Tables/AddTeamsTable';
import AddTeamsNotificationModal from './AddTeamsNotificationModal';

import { SharedUICategories, TypographySizes } from '../../utils/constants';
import { addTeamsModalPropTypes } from '../../utils/proptypes';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddTeamsModal = ({
    isOpen,
    isLoadingOptions,
    isLoadingTeams,
    onClose,
    teams,
    maxTeams,
    currentTeamCount,
    currentPage,
    pageCount,
    conferences = [],
    sessions = [],
    selectedConferenceId,
    selectedSessionIds = [],
    onConferenceChange,
    onSessionChange,
    onApplyClick,
    onPageChange,
    onSortChange,
    onCloseSessionDropdown,
    onAddTeams,
    sortByProperty,
    sortOrder,
}) => {
    const theme = useTheme();
    const { PRIMARY, DISPLAY, SECONDARY } = SharedUICategories;
    const { MEDIUM_BOOK, XX_SMALL_MEDIUM_CAP, MEDIUM_MEDIUM, SMALL_MEDIUM } = TypographySizes;
    const [selectedTeamIds, setSelectedTeamIds] = useState([]);
    const [teamsToAdd, setTeamsToAdd] = useState([]);
    const [teamCount, setTeamCount] = useState();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showSessionDropdown, setShowSessionDropdown] = useState(false);
    const [notifications, setNotifications] = useState({ notifyCaptains: false, notifyPlayers: false });

    const hasSessions = !!sessions?.length;
    const sessionLabelText = !hasSessions ? 'No sessions available' : 'Select Sessions';
    const isApplyDisabled = !selectedSessionIds.length;

    useEffect(() => {
        const teamsCount = currentTeamCount + teamsToAdd.length;
        setTeamCount(teamsCount);
    }, [teamsToAdd]);

    useEffect(() => {
        setTeamCount(currentTeamCount);
    }, [currentTeamCount]);

    const clearState = () => {
        setTeamsToAdd([]);
        setNotifications({ notifyCaptains: false, notifyPlayers: false });
        setSelectedTeamIds([]);
    };

    const handleOnConferenceChange = (event) => {
        onConferenceChange(event.target.value);
    };

    const handleOnSessionsChange = (event) => {
        onSessionChange(event.target.value);
    };

    const handleOnApplyClick = (event) => {
        event.preventDefault();
        setShowSessionDropdown(false);
        onApplyClick();
    };

    const handleOnTeamSelected = (id, team) => {
        if (selectedTeamIds.includes(id)) {
            const updatedSelectedTeamIds = selectedTeamIds.filter((teamId) => teamId !== id);
            const updatedTeamsToAdd = teamsToAdd.filter((addedTeam) => addedTeam.id !== team.id);

            setSelectedTeamIds(updatedSelectedTeamIds);
            setTeamsToAdd(updatedTeamsToAdd);
        } else {
            const selectedIds = [...selectedTeamIds, id];
            if (selectedIds.length <= maxTeams - currentTeamCount) {
                setSelectedTeamIds(selectedIds);
                setTeamsToAdd([...teamsToAdd, team]);
            }
        }
    };

    const handleOnTeamRemoved = (id, team) => {
        const updatedSelectedTeamIds = selectedTeamIds?.filter((teamId) => teamId !== id);
        const updatedTeamsToAdd = teamsToAdd?.filter((addedTeam) => addedTeam.id !== team.id);

        setSelectedTeamIds(updatedSelectedTeamIds);
        setTeamsToAdd(updatedTeamsToAdd);
    };

    const handleOnPageChange = (event, page) => {
        event.preventDefault();
        onPageChange(page);
    };

    const handleOnSortPropertyChange = (property) => {
        onSortChange(property);
    };

    const getOptionSelections = (selectedOptions, options) => {
        const optionNames = options.reduce((selectedOptionDescriptions, option) => {
            if (selectedOptions.includes(option.sessionId)) {
                selectedOptionDescriptions.push(option.sessionName);
            }
            return selectedOptionDescriptions;
        }, []);

        return optionNames.join(', ');
    };

    const handleOnAddTeamsClick = () => {
        setShowConfirmationModal(true);
    };

    const handleOnCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleOnNotificationChange = (event) => {
        setNotifications({
            ...notifications,
            [event.target.name]: event.target.checked,
        });
    };

    const handleOnSendClick = () => {
        onAddTeams(notifications, selectedTeamIds);
        setShowConfirmationModal(false);
        clearState();
    };

    const handleOnCloseSessionDropdown = () => {
        setShowSessionDropdown(false);
        onCloseSessionDropdown();
    };

    return (
        <Dialog PaperProps={{ style: { backgroundColor: theme.appColors.coolGrey } }} fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative', backgroundColor: theme.appColors.black }}>
                <Toolbar>
                    <Container maxWidth="xl">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography category={DISPLAY} size={MEDIUM_BOOK}>
                                Add an existing team
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
            <Box pt={4} pb={4} sx={{ backgroundColor: theme.appColors.white, borderBottom: `1px solid ${theme.appColors.lightGrey}` }}>
                <Container maxWidth="xl">
                    <Box display="flex" alignItems="center">
                        <FormControl sx={{ width: 300, mr: 3 }}>
                            <InputLabel id="conference-select">Select Conference</InputLabel>
                            <Select
                                disabled={isLoadingOptions}
                                value={selectedConferenceId || ''}
                                label="Select Conference"
                                labelId="conference-select"
                                onChange={handleOnConferenceChange}
                            >
                                {conferences?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id="session-select">{sessionLabelText}</InputLabel>
                            <Select
                                value={selectedSessionIds}
                                label={sessionLabelText}
                                open={showSessionDropdown}
                                onOpen={() => setShowSessionDropdown(true)}
                                onClose={handleOnCloseSessionDropdown}
                                multiple
                                disabled={!hasSessions || isLoadingOptions}
                                labelId="session-select"
                                onChange={handleOnSessionsChange}
                                renderValue={(selected) => getOptionSelections(selected, sessions)}
                            >
                                {sessions?.map((option) => (
                                    <MenuItem key={option.sessionId} value={option.sessionId}>
                                        <Checkbox checked={selectedSessionIds?.indexOf(option.sessionId) > -1} />
                                        <Typography>{option.sessionName}</Typography>
                                    </MenuItem>
                                ))}
                                <Box mt={1} boxShadow="0px -3px 3px #00000024" display="flex" justifyContent="center" pt={1} px={1}>
                                    <Button disabled={isApplyDisabled} fullWidth variant="dark" onClick={handleOnApplyClick}>
                                        Apply
                                    </Button>
                                </Box>
                            </Select>
                        </FormControl>
                    </Box>
                </Container>
            </Box>
            <Container maxWidth="xl">
                <Grid height="100vh" container>
                    <Grid item borderRight={1} lg={9} sx={{ borderColor: theme.appColors.lightGrey }}>
                        <Box pr={6} mt={4}>
                            {isLoadingTeams ? (
                                <LoadingIndicator />
                            ) : (
                                <>
                                    <AddTeamsTable
                                        maxTeamsReached={teamCount === maxTeams}
                                        sessionTeams={teams}
                                        isLoading={isLoadingTeams}
                                        sortByProperty={sortByProperty}
                                        sortOrder={sortOrder}
                                        onSortChange={handleOnSortPropertyChange}
                                        selectedTeamIds={selectedTeamIds}
                                        onTeamSelected={handleOnTeamSelected}
                                    />
                                    {pageCount > 1 && (
                                        <Pagination
                                            variant="text"
                                            color="primary"
                                            page={currentPage}
                                            count={pageCount || 0}
                                            onChange={handleOnPageChange}
                                        />
                                    )}
                                </>
                            )}
                            <Toolbar />
                        </Box>
                    </Grid>
                    <Grid item lg={3}>
                        <Box pl={6} mt={4}>
                            <Typography category={SECONDARY} size={XX_SMALL_MEDIUM_CAP}>{`Selected Teams (${teamCount}/${maxTeams})`}</Typography>
                            {teamsToAdd.map((team) => (
                                <Paper key={team.id} sx={{ my: 1 }}>
                                    <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
                                        <SquareAvatarWithName name={team?.name} href={`/teams/${team?.id}`} subtitle={`${team?.teamMemberCount} players`} fontSize={SMALL_MEDIUM} maxLinkWidth="200px" avatarWidth="36px" avatarHeight="36px" avatarFontSize={MEDIUM_MEDIUM} />
                                        <IconButton onClick={() => handleOnTeamRemoved(team?.id, team)} sx={{ '&:hover': { borderRadius: '50%' } }}>
                                            <DeleteIcon size={20} color={theme.appColors.red} />
                                        </IconButton>
                                    </Box>
                                </Paper>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
                <AddTeamsNotificationModal
                    open={showConfirmationModal}
                    selections={notifications}
                    teamsCount={selectedTeamIds?.length}
                    onClose={handleOnCloseConfirmationModal}
                    onChange={handleOnNotificationChange}
                    onSendClick={handleOnSendClick}
                />
            </Container>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: theme.appColors.white }}>
                <Toolbar>
                    <Container maxWidth="xl">
                        <Box display="flex" justifyContent="flex-end">
                            <Button disabled={!selectedTeamIds.length} onClick={handleOnAddTeamsClick} category={PRIMARY}>
                                Add teams
                            </Button>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </Dialog>
    );
};

AddTeamsModal.propTypes = {
    ...addTeamsModalPropTypes
};

export default AddTeamsModal;
