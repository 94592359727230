import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showErrorPopup } from '../redux/errorPopupSlice';
import { useGetPlayerMatchesQuery, useUpdateTeamMatchLineupStatusMutation } from '../api/leaguesApi';
import { LineupStatusType } from '../utils/constants';

const LineupStatusMessaging = {
    locked: {
        title: 'Lineups locked!',
        message: 'To make changes, unlock this lineup',
        notifyPlayersMessage: 'Notifications have been sent to all players. To make changes, unlock this lineup.'
    },
    published: {
        title: 'Lineups published!',
        message: 'Your lineup is live. Notifications will go out when both teams\' lineups are visible.'
    }
};

export const usePlayerMatches = (teamMatchId) => {
    const dispatch = useDispatch();
    const { LOCKED, PUBLISHED } = LineupStatusType;
    const [playerMatches, setPlayerMatches] = useState(null);
    const [lineupData, setLineupData] = useState(null);
    const [canUserViewLineups, setCanUserViewLineup] = useState(false);
    const [showCompletedSessionWarning, setShowCompletedSessionWarning] = useState();
    const [lineupStatusConfirmation, setLineupStatusConfirmation] = useState({ title: '', message: '', showConfirmation: false });

    const { data, isFetching: isLoadingPlayerMatches, error } = useGetPlayerMatchesQuery(teamMatchId);
    const [updateLineupStatus] = useUpdateTeamMatchLineupStatusMutation();

    if (error) {
        dispatch(showErrorPopup(error));
    }

    useEffect(() => {
        if (data) {
            setPlayerMatches(data);
            const { showVerifiedCompletedSessionWarning, lineups } = data || {};
            setShowCompletedSessionWarning(showVerifiedCompletedSessionWarning);

            if (lineups) {
                setLineupData(lineups);
                setCanUserViewLineup(lineups?.canViewLineups);
            }
        }
    }, [data]);

    const getSnackbarConfirmationNotification = (status, isNotifyingPlayers) => {
        const { locked, published } = LineupStatusMessaging;
        const isCurrentStatusPublished = lineupData?.statusId === PUBLISHED;

        if (isNotifyingPlayers && status === LOCKED && !isCurrentStatusPublished) {
            return { title: locked.title, message: locked.notifyPlayersMessage, showConfirmation: true };
        }
        if (!isNotifyingPlayers && status === LOCKED && !isCurrentStatusPublished) {
            return { title: locked.title, message: locked.message, showConfirmation: true };
        }
        if (status === PUBLISHED) {
            return { title: published.title, message: published.message, showConfirmation: true };
        }

        return lineupStatusConfirmation;
    };

    const handleOnLineupStatusChange = async ({ status, isNotifyingPlayers }) => {
        try {
            await updateLineupStatus({
                teamMatchId: parseInt(teamMatchId),
                lineupStatusId: status,
                notifyPlayersWhenLocked: isNotifyingPlayers
            }).unwrap();
            const confirmation = getSnackbarConfirmationNotification(status, isNotifyingPlayers);
            setLineupStatusConfirmation(confirmation);
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnCloseStatusConfirmation = () => {
        setLineupStatusConfirmation({ title: '', message: '', showConfirmation: false });
    };

    return {
        playerMatches,
        showCompletedSessionWarning,
        isLoadingPlayerMatches,
        lineupData,
        handleOnLineupStatusChange,
        handleOnCloseStatusConfirmation,
        lineupStatusConfirmation,
        canUserViewLineups
    };
};
