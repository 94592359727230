import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import LoadingIndicator from '../LoadingIndicator';
import PlayerSearch from '../Form/PlayerSearch';
import PlayerSearchResultCard from '../Cards/PlayerSearchResultCard';
import Popup from '../Popups/Popup';
import { showPopup } from '../../redux/genericPopupSlice';
import { LeagueRoles } from '../../utils/constants';
import { mapMembersForPlayerSearch } from '../../utils/helpers';

const MemberRoleManager = ({ members = [], isUpdatingRole, isLoadingTeam, memberRole, onAddMemberRole, onRemoveMemberRole }) => {
    const { TEAM_CAPTAIN } = LeagueRoles;
    const dispatch = useDispatch();
    const [originalMembers, setOriginalMembers] = useState([]);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [isRemovingMember, setIsRemovingMember] = useState(false);
    const [memberAwaitingAction, setMemberAwaitingAction] = useState();
    const minimumAmountOfMembersNeeded = 1;
    const maximumAmountOfCaptains = 3;

    useEffect(() => {
        if (members?.length) {
            const mappedMembers = mapMembersForPlayerSearch(members);
            setOriginalMembers(mappedMembers);
        }
    }, [members]);

    const isPermittedToRemoveMember = () => {
        if (members?.length === minimumAmountOfMembersNeeded) {
            dispatch(
                showPopup({
                    isError: true,
                    title: 'Error',
                    message: `There must always be at least one ${memberRole}`,
                })
            );
            return false;
        }

        return true;
    };

    const isPermittedToAddMember = () => {
        if (members?.length === maximumAmountOfCaptains && memberRole === TEAM_CAPTAIN) {
            dispatch(
                showPopup({
                    isError: true,
                    title: 'Error',
                    message: `Only 3 Captains are permitted per team.`,
                })
            );
            return false;
        }

        return true;
    };

    const handleOnAddMemberRole = (member) => {
        setMemberAwaitingAction(member);
        setIsRemovingMember(false);
        setShowConfirmationPopup(true);
    };

    const handleOnRemoveMemberRole = (member) => {
        setMemberAwaitingAction(member);
        setIsRemovingMember(true);
        setShowConfirmationPopup(true);
    };

    const handleOnConfirmRemoval = () => {
        setShowConfirmationPopup(false);

        const isAbleToRemoveMember = isPermittedToRemoveMember();
        if (isAbleToRemoveMember) {
            onRemoveMemberRole(memberAwaitingAction);
        }
    };

    const handleOnConfirmAddition = () => {
        setShowConfirmationPopup(false);

        const isAbleToAddMember = isPermittedToAddMember();
        if (isAbleToAddMember) {
            onAddMemberRole(memberAwaitingAction);
        }
    };

    const handleOnClosePopup = () => {
        setShowConfirmationPopup(false);
    };

    const action = isRemovingMember ? 'Remove' : 'Add';
    const popupAction = isRemovingMember ? handleOnConfirmRemoval : handleOnConfirmAddition;
    const popupTitle = `${action} ${memberRole}`;
    const popupMessage = `Are you sure you want to ${action?.toLowerCase()} ${memberAwaitingAction?.displayName} as a ${memberRole}?`;

    return isLoadingTeam ? (
        <LoadingIndicator />
    ) : (
        <>
            <Box my={2}>
                <PlayerSearch
                    onPlayerSelected={handleOnAddMemberRole}
                    searchMembersOnly
                    selectedPlayers={originalMembers}
                    label={`Add ${memberRole}`}
                />
            </Box>
            <Box mt={5}>
                <Typography variant="h5">{`Current ${memberRole}s`}</Typography>
                <Divider />
                <Box mt={3}>
                    {isUpdatingRole ? (
                        <LoadingIndicator />
                    ) : (
                        originalMembers.map((player) => (
                            <PlayerSearchResultCard
                                key={player.id}
                                player={player}
                                onRemovePlayer={handleOnRemoveMemberRole}
                                showRemoveButton
                            />
                        ))
                    )}
                </Box>
            </Box>
            <Popup
                onAction={popupAction}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showConfirmationPopup}
                onClose={handleOnClosePopup}
                title={popupTitle}
                message={popupMessage}
            />
        </>
    );
};

export default MemberRoleManager;
