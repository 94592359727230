import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from '@universal-tennis/ui-shared';

import { Box } from '@mui/material';
import Container from '@mui/material/Container';

import PlayerAvailabilityEditModal from './PlayerAvailabilityEditModal';
import PlayerAvailabilityReminderModal from './PlayerAvailabilityReminderModal';
import TeamTabTable from './TeamTabTable';
import SuccessSnackbar from '../../../components/SuccessSnackbar';

import { getPlayerDisplayUtr } from '../../../utils/display';
import { SportTypeIds } from '../../../utils/types';
import { sortPlayersByAvailability } from '../../../utils/helpers';
import {
    tennisTeamMatchHeadCells,
    pickleballTeamMatchHeadCells,
    tennisSubstituteTeamMatchHeadCells,
    pickleballSubstituteTeamMatchHeadCells,
    TypographySizes,
    SharedUICategories
} from '../../../utils/constants';

const TeamTab = ({
    teamPlayers = [],
    isLoading,
    teamName,
    onAvailabilityChange,
    onReminderChange,
    displaySuccessSnackbar,
    onCloseSuccessSnackbar,
    isUserCaptain,
    isUserTeamMember
}) => {
    const [players, setPlayers] = useState([]);
    const [showRemindersModal, setShowRemindersModal] = useState(false);
    const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
    const [availabilityIdToBeUpdated, setAvailabilityIdToBeUpdated] = useState();
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);
    const { PRIMARY, SECONDARY } = SharedUICategories;
    const { SMALL_BOOK, LARGE_BOOK } = TypographySizes;
    const isTennisMatch = sportTypeId === SportTypeIds.TENNIS;

    useEffect(() => {
        const formattedPlayers = teamPlayers.map((player) => {
            const { rating } = getPlayerDisplayUtr(player);
            const { rating: doublesUtr } = getPlayerDisplayUtr(player, true);
            const {
                memberId,
                firstName,
                lastName,
                playerAvailabilityStatusTypeId,
                record,
                profileImage,
                isSubstitute,
                utrRange,
                teamMemberId,
            } = player || {};
            return {
                id: memberId,
                name: `${firstName} ${lastName}`,
                playerAvailabilityStatusTypeId,
                singlesRecord: record ? `${record?.singlesWins} - ${record?.singlesLosses}` : '-',
                doublesRecord: record ? `${record?.doublesWins} - ${record?.doublesLosses}` : '-',
                doublesUtr,
                profileImage,
                isSubstitute,
                singlesUtr: rating,
                teamMemberId,
                utrp: utrRange?.pickleballRatingDisplay || 'UR'
            };
        });
        setPlayers(formattedPlayers);
    }, [teamPlayers]);

    const handleOnSendRemindersClick = () => {
        setShowRemindersModal(true);
    };

    const handleOnCloseRemindersModal = () => {
        setShowRemindersModal(false);
    };

    const handleOnCloseAvailabilityModal = () => {
        setShowAvailabilityModal(false);
    };

    const handleOnAvailabilityClick = (teamMemberId) => {
        setAvailabilityIdToBeUpdated(teamMemberId);
        setShowAvailabilityModal(true);
    };

    const handleOnAvailabilityStatusChange = (status) => {
        handleOnCloseAvailabilityModal();
        onAvailabilityChange({ teamMemberId: availabilityIdToBeUpdated, availabilityStatus: status });
    };

    const filteredRosteredPlayers = players?.filter((player) => !player.isSubstitute);
    const filteredSubstitutePlayers = players?.filter((player) => player.isSubstitute);
    const headCells = isTennisMatch ? tennisTeamMatchHeadCells : pickleballTeamMatchHeadCells;
    const substituteHeadCells = isTennisMatch ? tennisSubstituteTeamMatchHeadCells : pickleballSubstituteTeamMatchHeadCells;
    const remindersPlayerData = [...filteredRosteredPlayers, ...filteredSubstitutePlayers];

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography color="black" align="center" category={PRIMARY} size={LARGE_BOOK}>{teamName}</Typography>
                {isUserCaptain && !!players.length && <Button onClick={handleOnSendRemindersClick} category={PRIMARY}>Send Reminders</Button>}
            </Box>
            {!players.length && (
                <Box py={7}>
                    <Typography align="center" category={SECONDARY} size={SMALL_BOOK}>No players have been rostered on the team.</Typography>
                </Box>
            )}
            {!!filteredRosteredPlayers?.length && (
                <TeamTabTable
                    tableData={sortPlayersByAvailability(filteredRosteredPlayers)}
                    headCells={headCells}
                    isLoading={isLoading}
                    onAvailabilityClick={handleOnAvailabilityClick}
                    isUserCaptain={isUserCaptain}
                    isUserTeamMember={isUserTeamMember}
                />
            )}
            {!!filteredSubstitutePlayers?.length && (
                <TeamTabTable
                    tableData={sortPlayersByAvailability(filteredSubstitutePlayers)}
                    headCells={substituteHeadCells}
                    isLoading={isLoading}
                    onAvailabilityClick={handleOnAvailabilityClick}
                    isUserCaptain={isUserCaptain}
                    isUserTeamMember={isUserTeamMember}
                />
            )}
            <SuccessSnackbar isOpen={displaySuccessSnackbar} onClose={onCloseSuccessSnackbar} message="Reminders have been sent to the selected players." title="Reminders Sent!" />
            <PlayerAvailabilityReminderModal
                onReminderChange={onReminderChange}
                players={remindersPlayerData}
                isOpen={showRemindersModal}
                onClose={handleOnCloseRemindersModal}
            />
            <PlayerAvailabilityEditModal
                isOpen={showAvailabilityModal}
                onClose={handleOnCloseAvailabilityModal}
                onStatusChange={handleOnAvailabilityStatusChange}
            />
        </Container>
    );
};

export default TeamTab;
