import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { modalBaseSimplePropTypes, defaultModalBaseSimplePropTypes } from '../../utils/proptypes';

const DialogTitleSimple = (props) => {
    const { children, onClose, hideHeaderCloseIcon, ...other } = props;

    return (
        <DialogTitle align="center" sx={{ mt: 3, p: 2 }} {...other}>
            {children}
            {onClose && !hideHeaderCloseIcon ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const ModalBaseSimple = (props) => {
    const { open, onClose, title, children } = props;
    return (
        <Dialog open={open} keepMounted onClose={onClose}>
            <DialogTitleSimple {...props}>{title}</DialogTitleSimple>
            {children}
        </Dialog>
    );
};

ModalBaseSimple.propTypes = {
    ...modalBaseSimplePropTypes
};

ModalBaseSimple.defaultProps = {
    ...defaultModalBaseSimplePropTypes
};

export default ModalBaseSimple;
