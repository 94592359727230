import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Typography, CloseIcon } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Button, IconButton } from '@mui/material';

import TeamMatchSetScores from './TeamMatchSetScores';
import { ResultTypes, matchResultTypes, getTypeForId } from '../../../utils/types';
import { getPositionsForMatch } from '../../../utils/postScoreShared';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import PlayerAvatarWithUtr from '../../PlayerAvatarWithUtr';
import WinnerIndicator from '../../WinnerIndicator';

const TeamMatchScoreCard = ({ position1Team = {}, position2Team = {}, match = null, isManaging = false, onEditClicked, isDoubles = false, onClearMatchClick }) => {
    const { side1, side2, resultCompletionTypeId, winningSideId } = match || {};
    const { DEFAULTED, WITHDREW, INCOMPLETE, MATCH_NOT_PLAYED } = ResultTypes;
    const { name: position1TeamName, teamId: position1TeamId } = position1Team || {};
    const { name: position2TeamName } = position2Team || {};

    const isSide1Position1 = position1TeamId === side1?.teamId;
    const { position1, position2 } = getPositionsForMatch(match, isSide1Position1);
    const isPosition1Winner = position1.sideId === winningSideId;
    const isPosition2Winner = position2.sideId === winningSideId;

    const theme = useTheme();
    const isIncompleteOutcome = resultCompletionTypeId === INCOMPLETE;
    const isDefaultedOutcome = resultCompletionTypeId === DEFAULTED;
    const scorelessOutcomes = [DEFAULTED, WITHDREW];
    const isScorelessOutcome = scorelessOutcomes.includes(resultCompletionTypeId);
    const outcome = getTypeForId(resultCompletionTypeId, matchResultTypes);
    const showClearButton = !!resultCompletionTypeId || side1?.player1 || side1?.player2 || side2?.player1 || side2?.player2;

    const handleOnEditClicked = () => {
        onEditClicked(match, isDoubles);
    };

    const handleOnClearPlayerMatch = () => {
        onClearMatchClick(match);
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography color={theme.appColors.black} category={SharedUICategories.SECONDARY} size={TypographySizes.X_SMALL_MEDIUM_CAP} mb={1}>
                    {isDoubles ? 'Doubles' : 'Singles'} Line Match {match.lineupPosition}
                </Typography>
                {isManaging && (
                    <Box display="flex" alignItems="center">
                        <Button onClick={handleOnEditClicked} disabled={!isManaging} aria-label="Edit player lineup" sx={{ textTransform: 'none' }}>
                            <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.SMALL_MEDIUM} color={theme.appColors.interactionBlue}>Post score</Typography>
                        </Button>
                        {showClearButton && (
                            <>
                                <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.SMALL_MEDIUM} color={theme.appColors.grey}>|</Typography>
                                <IconButton onClick={handleOnClearPlayerMatch} aria-label="clear player match">
                                    <CloseIcon color={theme.appColors.interactionBlue} size={16} />
                                </IconButton>
                            </>
                        )}
                    </Box>
                )}
            </Box>
            {resultCompletionTypeId === MATCH_NOT_PLAYED ? (
                <Card sx={{ borderRadius: 0, boxShadow: 'none' }}>
                    <CardContent>
                        <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK} color={theme.appColors.blueGrey} mb="5px">Match not played</Typography>
                    </CardContent>
                </Card>
            ) : (
                <>
                    <Card sx={{ borderRadius: 0, boxShadow: 'none', position: 'relative' }}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box mb="6px">
                                                <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_BOOK} mb="2px" noWrap>{position2TeamName}</Typography>
                                                {isDefaultedOutcome && !isPosition2Winner ? (
                                                    <Typography color={theme.appColors.blueGrey} category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK}>
                                                        -
                                                    </Typography>
                                                ) : (
                                                    <Box display="flex" alignItems="center">
                                                        {isPosition2Winner && <WinnerIndicator isBlack sx={{ position: 'absolute', left: 0 }} />}
                                                        <Box>
                                                            <PlayerAvatarWithUtr isWinner={isPosition2Winner} player1={position2?.player1} player2={position2?.player2} isDoubles={isDoubles} />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box display="flex" flexDirection="column" alignItems="end">
                                                {isIncompleteOutcome && (
                                                    <Typography category={SharedUICategories.SECONDARY} position="absolute" top="16px" size={TypographySizes.XX_SMALL_BOOK} color={theme.appColors.red}>Incomplete</Typography>
                                                )}
                                                <TeamMatchSetScores position={position2} isPositionWinner={isPosition2Winner} outcomeDescription={outcome?.description} isScorelessOutcome={isScorelessOutcome} resultCompletionTypeId={resultCompletionTypeId} />
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box>
                                                <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_BOOK} mb="2px" noWrap>{position1TeamName}</Typography>
                                                {isDefaultedOutcome && !isPosition1Winner ? (
                                                    <Typography color={theme.appColors.blueGrey} category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK}>
                                                        -
                                                    </Typography>
                                                ) : (
                                                    <Box display="flex" alignItems="center">
                                                        {isPosition1Winner && <WinnerIndicator isBlack sx={{ position: 'absolute', left: 0 }} />}
                                                        <Box>
                                                            <PlayerAvatarWithUtr isWinner={isPosition1Winner} player1={position1?.player1} player2={position1?.player2} isDoubles={isDoubles} />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box>
                                                <TeamMatchSetScores position={position1} isPositionWinner={isPosition1Winner} outcomeDescription={outcome?.description} isScorelessOutcome={isScorelessOutcome} resultCompletionTypeId={resultCompletionTypeId} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
        </>
    );
};

export default TeamMatchScoreCard;
