import React from 'react';
import { Typography } from '@universal-tennis/ui-shared';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import RemoveIcon from '@mui/icons-material/HighlightOff';
import Select from '@mui/material/Select';

import LineupDropdownItem from '../../LineupDropdownItem';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';

const LineupAlternatesCard = ({
    teamName = '',
    roster = [],
    fields,
    register,
    name,
    teamId,
    error,
    onAddAlternate,
    onRemoveAlternate,
    isDisabled
}) => {
    const handleOnAddAlternateClick = () => {
        onAddAlternate(teamId);
    };

    const handleOnRemoveAlternateClick = (indexToRemove) => {
        onRemoveAlternate(indexToRemove, teamId);
    };

    const getPlayerNameForSelection = (teamMemberId) => {
        const player = roster?.find((teamPlayer) => teamPlayer?.teamMemberId === teamMemberId);
        return `${player?.firstName} ${player?.lastName}`;
    };

    return (
        <Box>
            <Typography mb={1} category={SharedUICategories.SECONDARY} size={TypographySizes.X_SMALL_MEDIUM_CAP}>
                {`${teamName} Alternates`}
            </Typography>
            <Card variant="outlined">
                <CardContent>
                    {fields?.map((field, index) => (
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} key={field?.id}>
                            <FormControl fullWidth>
                                <InputLabel id="alternate-dropdown-label">{`${teamName} alternate`}</InputLabel>
                                <Select
                                    label={`${teamName} alternate`}
                                    error={error}
                                    disabled={isDisabled}
                                    labelId="alternate-dropdown-label"
                                    id="alternate-dropdown-label"
                                    defaultValue={field?.teamMemberId}
                                    renderValue={(value) => getPlayerNameForSelection(value)}
                                    sx={{ width: '100%' }}
                                    {...register(`${name}.${index}.teamMemberId`)}
                                >
                                    {roster?.map((player) => (
                                        <MenuItem key={player?.teamMemberId} value={player?.teamMemberId}>
                                            <LineupDropdownItem player={player} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {fields?.length !== 1 && (
                                <IconButton onClick={() => handleOnRemoveAlternateClick(index)} aria-label="delete">
                                    <RemoveIcon />
                                </IconButton>
                            )}
                        </Box>
                    ))}
                    <Button onClick={handleOnAddAlternateClick} startIcon={<AddCircleOutlineIcon />}>
                        Add another alternate
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
};

export default LineupAlternatesCard;
