import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    error: null,
};

const errorPopupSlice = createSlice({
    name: 'errorPopup',
    initialState,
    reducers: {
        showErrorPopup(state, action) {
            state.isOpen = true;
            state.error = action.payload;
        },

        hideErrorPopup(state) {
            state.isOpen = false;
        },
    },
});

export const { showErrorPopup, hideErrorPopup } = errorPopupSlice.actions;
export default errorPopupSlice.reducer;
