import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddTeamMatchModal from '../AddTeamMatchModal';
import MultiWeekRound from '../MultiWeekRound';
import MultiWeekByDate from '../MultiWeekByDate';
import DrawTable from '../DrawTab/DrawTable';
import useIsMobile from '../../hooks/useIsMobile';
import { MAIN_DRAW_TABLE_NUMBER, MANAGE_SESSION_PAGE_TYPE } from '../DrawTab/utils';
import { MultiRoundScheduleTypes } from '../../utils/types';
import TeamPageDrawTableSelect from '../DrawTab/TeamPageDrawTable/TeamPageDrawTableSelect';
import { schedulePropTypes } from '../../utils/proptypes';

const Schedule = ({
    seasonRounds,
    isManaging = false,
    onEditMatch,
    onDeleteMatch,
    isLeagueDeskSchedule = false,
    drawSchedule,
    isSchedulePublished,
    pageType,
    scheduleByDate,
    multiRoundScheduleType,
    displayTournamentBracketStyle,
    sessionId,
    onDeleteRound,
    scheduleId,
    refetchSessionSchedule,
    teams,
    session,
}) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [showAddTeamMatchModal, setShowAddTeamMatchModal] = useState(false);
    const [roundId, setRoundId] = useState();
    const [showMainDraw, setShowMainDraw] = useState(true);
    const { BY_ROUND, BY_DATE } = MultiRoundScheduleTypes;
    const hasSeasonRounds =
        !!seasonRounds?.length && multiRoundScheduleType === BY_ROUND && (pageType === MANAGE_SESSION_PAGE_TYPE || isSchedulePublished);
    const hasDrawSchedule = drawSchedule?.draws.length && isSchedulePublished;
    const hasMultiRoundScheduleByDate = !!scheduleByDate?.teamMatches?.length && multiRoundScheduleType === BY_DATE;
    const totalRounds = drawSchedule?.draws.reduce((acc, cur) => {
        if (!cur.rounds) return acc;
        return [...acc, ...cur.rounds];
    }, []);

    const handleOnEditMatch = (match) => {
        onEditMatch(match);
    };

    const handleOnAddTeamMatch = (roundIdToSet) => {
        setRoundId(roundIdToSet);
        setShowAddTeamMatchModal(true);
    };

    const handleOnCloseAddTeamMatchModal = () => {
        setShowAddTeamMatchModal(false);
    };

    const handleOnViewDrawMatchClick = (match) => {
        navigate(`/leagues/match/${match?.teamMatchId}`);
    };

    return (
        <>
            {displayTournamentBracketStyle && hasDrawSchedule && (
                <TeamPageDrawTableSelect
                    selectedValue={showMainDraw}
                    onChange={(e) => {
                        setShowMainDraw(e.target.value);
                    }}
                />
            )}
            {hasSeasonRounds &&
                seasonRounds.map((round) => (
                    <MultiWeekRound
                        key={round?.id}
                        onEditMatch={handleOnEditMatch}
                        onAddTeamMatch={handleOnAddTeamMatch}
                        onDeleteMatch={onDeleteMatch}
                        onDeleteRound={onDeleteRound}
                        round={round}
                        isLeagueDeskSchedule={isLeagueDeskSchedule}
                        teams={teams}
                        isManaging={isManaging}
                    />
                ))}
            <AddTeamMatchModal
                sessionId={sessionId}
                scheduleId={scheduleId}
                roundId={roundId}
                isOpen={showAddTeamMatchModal}
                onClose={handleOnCloseAddTeamMatchModal}
                onSaveClick={handleOnCloseAddTeamMatchModal}
                refetchSessionSchedule={refetchSessionSchedule}
                session={session}
            />
            {hasMultiRoundScheduleByDate && (
                <MultiWeekByDate
                    onEditMatch={handleOnEditMatch}
                    scheduleByDate={scheduleByDate}
                    isLeagueDeskSchedule={isLeagueDeskSchedule}
                />
            )}
            {hasDrawSchedule &&
                drawSchedule?.draws.map((draw) => {
                    if (!isSchedulePublished) return;
                    return (
                        <DrawTable
                            key={draw.id}
                            draw={draw}
                            selectableTeams={[]}
                            schedule={drawSchedule}
                            onEditMatch={handleOnEditMatch}
                            onViewMatch={handleOnViewDrawMatchClick}
                            hasPublishedSchedule
                            isManaging={isManaging}
                            isMainDraw={draw.number === MAIN_DRAW_TABLE_NUMBER}
                            isMobile={isMobile}
                            totalRounds={totalRounds}
                            displayTournamentBracketStyle={displayTournamentBracketStyle}
                            showMainDraw={showMainDraw}
                        />
                    );
                })}
        </>
    );
};

Schedule.propTypes = {
    ...schedulePropTypes,
};

export default Schedule;
