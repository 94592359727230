import React, { useMemo } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { ResultTypes } from '../../../utils/types';

const MatchOutcomeSelect = ({ options, hasEnteredScores, onMatchOutComeChange, outcome }) => {
    const { DEFAULTED, MATCH_NOT_PLAYED, RETIRED, WITHDREW } = ResultTypes;
    const handleOnMatchOutcomeChange = (event) => {
        const { value } = event.target;
        onMatchOutComeChange(value);
    };

    const filteredOptions = useMemo(() => {
        if (outcome?.outcomeId === DEFAULTED || outcome?.outcomeId === MATCH_NOT_PLAYED) {
            return options.filter((option) => option.outcomeId !== RETIRED && option.outcomeId !== WITHDREW);
        }

        if (hasEnteredScores) {
            return options.filter((option) => option.outcomeId !== WITHDREW);
        }

        return options.filter((option) => option.outcomeId !== RETIRED);
    }, [options, hasEnteredScores]);

    return (
        <FormControl fullWidth>
            <InputLabel id="match-outcome-select-label">Match Outcome</InputLabel>
            <Select
                labelId="match-outcome-select-label"
                onChange={handleOnMatchOutcomeChange}
                value={outcome}
                id="grouped-select"
                renderValue={(selected) => `${selected.name}`}
                label="Match Outcome"
            >
                {filteredOptions?.map((option) => {
                    return (
                        <MenuItem key={option.id} id={option.id} value={option}>
                            {option.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default MatchOutcomeSelect;
