import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography, Button } from '@universal-tennis/ui-shared';
import ApprovedIcon from '../Icons/ApprovedIcon';
import { TypographySizes, SharedUICategories } from '../../utils/constants';
import { popupPropTypes, defaultPopupPropTypes } from '../../utils/proptypes';

const Popup = ({ open, onClose, title, message, isSuccessful, errorMessage, buttonLabel, onAction, actionLabel }) => (
    <Dialog maxWidth="xs" open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{ p: 2 }}>
        <DialogContent>
            {isSuccessful && (
                <ApprovedIcon />
            )}
            <Typography style={{ padding: '8px 0' }} category={SharedUICategories.DISPLAY} size={TypographySizes.MEDIUM_BOOK} id="alert-dialog-title">
                {title}
            </Typography>
            <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK} id="alert-dialog-description">
                {message}
            </Typography>
        </DialogContent>
        {!!errorMessage && (
            <DialogContent>
                <DialogContentText className="text-error" align="center" id="alert-dialog-error">
                    {errorMessage}
                </DialogContentText>
            </DialogContent>
        )}
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
            {buttonLabel && (
                <Button sx={{ width: '90%' }} category={SharedUICategories.SECONDARY} onClick={onClose}>
                    {buttonLabel}
                </Button>
            )}
            {onAction && (
                <Button sx={{ width: '90%' }} category={SharedUICategories.PRIMARY} onClick={onAction}>
                    {actionLabel}
                </Button>
            )}
        </DialogActions>
    </Dialog>
);

Popup.propTypes = {
    ...popupPropTypes
};

Popup.defaultProps = {
    ...defaultPopupPropTypes
};

export default Popup;
