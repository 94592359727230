import React from 'react';
import { AvatarWithName, Typography } from '@universal-tennis/ui-shared';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PlayerRating from '../PlayerRating';
import PlayerAvailabilityIndicator from '../PlayerAvailabilityIndicator';
import { SharedUICategories, TypographySizes } from '../../utils/constants';
import config from '../../config';

const LineupDropdownItem = ({ player }) => {
    const theme = useTheme();
    const { firstName, lastName, profileImage, record, playerAvailabilityStatusTypeId } = player || {};
    const { doublesWins, doublesLosses, singlesWins, singlesLosses } = record || {};
    const playerImage = `${config.cdnUrl}/v1/player/${profileImage}`;
    const name = `${firstName} ${lastName}`;
    const noRecord = '0 - 0';
    let totalPlayed = 0;
    let doublesRecord = noRecord;
    let singlesRecord = noRecord;

    if (record) {
        totalPlayed = doublesLosses + doublesWins + singlesLosses + singlesWins;
        doublesRecord = `${doublesWins} - ${doublesLosses}`;
        singlesRecord = `${singlesWins} - ${singlesLosses}`;
    }

    return (
        <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box mb={2} display="flex" alignItems="center">
                <AvatarWithName imgSrc={playerImage} avatarOnly playerName={name} />
                <Box display="flex" flexDirection="column">
                    <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.SMALL_MEDIUM} mr={1}>{name}</Typography>
                    <PlayerRating player={player} isAppended />
                    <Stack mt={0.5} direction="row" spacing={2}>
                        <Typography color={theme.appColors.grey} category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_MEDIUM_CAP}>{`Played: ${totalPlayed}`}</Typography>
                        <Typography color={theme.appColors.grey} category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_MEDIUM_CAP}>{`SGLS: ${singlesRecord}`}</Typography>
                        <Typography color={theme.appColors.grey} category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_MEDIUM_CAP}>{`DBLS: ${doublesRecord}`}</Typography>
                    </Stack>
                </Box>
            </Box>
            <PlayerAvailabilityIndicator isIconOnly availabilityStatus={playerAvailabilityStatusTypeId} />
        </Box>
    );
};

export default LineupDropdownItem;
