import React from 'react';
import { Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const getOptionSelections = (selectedOptions, options) => {
    const optionDescriptions = options.reduce((selectedOptionDescriptions, option) => {
        if (selectedOptions.includes(option.id)) {
            selectedOptionDescriptions.push(option.description);
        }
        return selectedOptionDescriptions;
    }, []);

    return optionDescriptions.join(', ');
};

const MultiSelect = ({ control, isFullWidth, isRequired = false, label, labelId, name, options, hasError }) => (
    <Controller
        name={name}
        rules={{ required: isRequired }}
        control={control}
        render={({ field }) => (
            <FormControl fullWidth={isFullWidth}>
                <InputLabel id={labelId}>{label}</InputLabel>
                <Select
                    {...field}
                    labelId={labelId}
                    id={`${name}-multiple-select-checkbox`}
                    multiple
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) => getOptionSelections(selected, options)}
                    MenuProps={MenuProps}
                    error={hasError}
                >
                    {options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            <Checkbox checked={field.value.indexOf(option.id) > -1} />
                            <Typography>{option.description}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )}
    />
);

export default MultiSelect;
