import React from 'react';
import { Controller } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const Select = (props) => {
    const {
        control,
        isFullWidth,
        isRequired = false,
        label,
        labelId,
        name,
        options,
        hasError,
        helperText,
        onSelectChange,
        ...rest
    } = props;

    return (
        <Controller
            name={name}
            rules={{ required: isRequired }}
            control={control}
            render={({ field, field: { onChange } }) => (
                <FormControl fullWidth={isFullWidth}>
                    <InputLabel id={labelId}>{label}</InputLabel>
                    <MuiSelect
                        {...field}
                        {...rest}
                        onChange={(value) => {
                            onChange(value);
                            if (onSelectChange) {
                                onSelectChange(value);
                            }
                        }}
                        fullWidth={isFullWidth}
                        label={label}
                        labelId={labelId}
                        error={hasError}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.description}
                            </MenuItem>
                        ))}
                    </MuiSelect>
                    {hasError && helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};

export default Select;
