import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    title: null,
    message: null,
};

const genericPopupSlice = createSlice({
    name: 'genericPopup',
    initialState,
    reducers: {
        showPopup(state, action) {
            state.isOpen = true;
            state.title = action.payload.title;
            state.message = action.payload.message;
        },

        hidePopup(state) {
            state.isOpen = false;
        },
    },
});

export const { showPopup, hidePopup } = genericPopupSlice.actions;
export default genericPopupSlice.reducer;
