import React from 'react';
import Img from '../Img';
import { formatImageSizesByType } from '../../utils/helpers';

import styles from './backgroundImage.module.scss';

const BackgroundImage = ({ baseUrl, defaultImage, imageType, images, extraCls, children, parentStylesClass, style, useRawSrcSet }) => {
    const imageSrc = images.default;
    const formattedImageSizes = formatImageSizesByType({ baseUrl, imageSrc, imageType });
    let imgSrcUrl;

    if (imageSrc) {
        imgSrcUrl = `${baseUrl}${imageSrc}`;
    } else {
        imgSrcUrl = defaultImage;
    }

    const handleUpdateImage = (imageRef) => {
        const img = imageRef;
        if (img) {
            const src = img.currentSrc ? img.currentSrc : img.src;
            img.parentNode.style.backgroundImage = `url("${src}")`;
        }
    };

    return (
        <div className={`${styles.backgroundImage} ${extraCls ? styles[extraCls] : ''} ${parentStylesClass || ''}`} style={style}>
            <Img
                src={imgSrcUrl}
                srcSet={formattedImageSizes}
                defaultImage={defaultImage}
                onImgLoad={handleUpdateImage}
                useRawSrcSet={useRawSrcSet}
            />
            {children}
        </div>
    );
};

export default BackgroundImage;
