import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Button, CalendarIcon, SessionIcon } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import LoadingIndicator from '../../../components/LoadingIndicator';
import WinnerIndicator from '../../../components/WinnerIndicator';
import { homeTeamIndicator } from '../../../utils/constants';
import { getAdjustedDate } from '../../../utils/display';
import { LeagueSessionTypes } from '../../../utils/types';
import { teamMatchSummaryPropTypes, defaultTeamMatchSummaryPropTypes } from '../../../utils/proptypes';
import { TBD_ROUND } from '../../../components/DrawTab/utils';

const TeamMatchSummary = ({ isLoading, onEditClicked, summary = {}, scores, isUpdating = true, winningTeamId }) => {
    const { canEditTeamMatch, sessionName, position1Team, position2Team, matchDate, sessionId, sessionTypeId } = summary;
    const { MULTI_ROUND } = LeagueSessionTypes;
    const isMultiRoundSession = sessionTypeId === MULTI_ROUND;
    const hasNoScoresPosted = scores?.position1 === 0 && scores?.position2 === 0;
    const homeTeam = position1Team?.name;
    const homeScore = hasNoScoresPosted ? '-' : scores?.position1;
    const awayTeam = position2Team?.name;
    const awayScore = hasNoScoresPosted ? '-' : scores?.position2;
    const date = matchDate ? getAdjustedDate({ dateUtc: matchDate, format: 'MMM Do, YYYY [\u2022] h:mm A' }) : TBD_ROUND;
    const theme = useTheme();
    const hasWinner = position1Team?.isWinner || position2Team?.isWinner;
    const isPosition1Winner = hasWinner && position1Team?.teamId === winningTeamId;
    const isPosition2Winner = hasWinner && position2Team?.teamId === winningTeamId;
    const sessionUrl = `/leagues/session/${sessionId}`;
    const awayTeamUrl = `/teams/${position2Team?.teamId}`;
    const homeTeamUrl = `/teams/${position1Team?.teamId}`;
    const homeTeamTextColor = hasWinner && isPosition2Winner ? theme.appColors.grey : theme.appColors.white;
    const awayTeamTextColor = hasWinner && isPosition1Winner ? theme.appColors.grey : theme.appColors.white;

    const handleOnEditClick = () => {
        onEditClicked();
    };

    const truncatedTextStyles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        minWidth: 0
    };

    return (
        <>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <Box p={3} bgcolor="black">
                    <Container maxWidth="lg">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography color={theme.appColors.white} category="display" size="large-light">Team match</Typography>
                            {canEditTeamMatch && (
                                <Button category="secondary-flipped" onClick={handleOnEditClick}>Edit</Button>
                            )}
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Link sx={truncatedTextStyles} href={awayTeamUrl}>
                                    <Typography color={awayTeamTextColor} category="primary" size="large-book" noWrap>
                                        {awayTeam}
                                    </Typography>
                                </Link>
                                <Box sx={truncatedTextStyles}>
                                    <Box display="flex" alignItems="baseline">
                                        <Link sx={truncatedTextStyles} href={homeTeamUrl}>
                                            <Typography color={homeTeamTextColor} category="primary" size="large-book" noWrap>
                                                {homeTeam}
                                            </Typography>
                                        </Link>
                                        {isMultiRoundSession && <Typography ml={1} color={homeTeamTextColor} category="secondary" size="x-small-book-cap">{homeTeamIndicator}</Typography>}
                                    </Box>

                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                {isUpdating ? (
                                    <CircularProgress sx={{ color: theme.appColors.gradientTurquoise }} />
                                ) : (
                                    <>
                                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                                            {isPosition2Winner && (<WinnerIndicator />)}
                                            <Typography
                                                ml={1}
                                                color={awayTeamTextColor}
                                                category="primary"
                                                size="large-book"
                                            >
                                                {awayScore}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                                            {isPosition1Winner && (<WinnerIndicator />)}
                                            <Typography
                                                ml={1}
                                                color={homeTeamTextColor}
                                                category="primary"
                                                size="large-book"
                                            >
                                                {homeScore}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                        <Box mt={1}>
                            <Box display="flex" alignItems="center">
                                <SessionIcon size={14} color={theme.appColors.blueGrey} />
                                <Link sx={{ ml: 1 }} href={sessionUrl}>
                                    <Typography color={theme.appColors.blueGrey} category="secondary" size="small-book">
                                        {sessionName}
                                    </Typography>
                                </Link>
                            </Box>
                            <Box mt={1} display="flex" alignItems="center">
                                <CalendarIcon size={14} color={theme.appColors.blueGrey} />
                                <Typography ml={1} color={theme.appColors.blueGrey} category="secondary" size="small-book">
                                    {date}
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            )}
        </>
    );
};

TeamMatchSummary.propTypes = {
    ...teamMatchSummaryPropTypes
};

TeamMatchSummary.defaultProps = {
    ...defaultTeamMatchSummaryPropTypes
};

export default TeamMatchSummary;
