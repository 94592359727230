import { ResultTypes } from './types';

const { COMPLETED, COMPLETED_WITH_PARTIAL_SCORES, DEFAULTED, WITHDREW, RETIRED, INCOMPLETE, MATCH_NOT_PLAYED } = ResultTypes;

export const SideKeys = {
    SIDE_1: 'side1',
    SIDE_2: 'side2',
};

export const MatchPositionKeys = {
    MATCH_POSITION_1: 'position1',
    MATCH_POSITION_2: 'position2',
};

export const MatchPositions = {
    POSITION_1: 1,
    POSITION_2: 2,
};

export const PlayerKeys = {
    PLAYER_1: 'player1',
    PLAYER_2: 'player2',
};

export const ValidationErrors = {
    SET_SCORE_INVALID: 'Invalid score for set ',
    SET_SCORE_INCOMPLETE: 'Incomplete score for set ',
    MISSING_TIEBREAKER_SCORE: 'Missing tiebreaker scores for set ',
    TIEBREAKER_SAME_SCORE: 'Tiebreaker scores cannot be the same',
    TIEBREAKER_MISMATCHED_SCORE: 'Tiebreaker winner must have higher set score',
    TIEBREAKER_MINIMUM_NOT_MET: 'Tiebreaker winner must have at least 7 points',
    TIEBREAKER_FAST_FOUR_MINIMUM_NOT_MET: 'Tiebreaker winner must have at least 5 points',
    TIEBREAKER_MARGIN_INVALID: 'Tiebreaker winner must win by 2 points',
    TIEBREAKER_OUT_OF_RANGE: 'Tiebreaker score is out of range for set ',
    TWO_WITH_TIEBREAKER_INVALID_SET_THREE_SCORE: 'Set 3 score must be 1-0 or 0-1',
    NO_WINNER_DETERMINED: 'Match outcome must have an evident winner',
    NO_MULTIPLE_PARTIAL_SCORES: 'Match outcome cannot have more than one set with a partial score',
    NO_SCORES_FOR_OUTCOME: 'Match outcome should not have scores entered',
    SET_SCORES_REQUIRED_FOR_OUTCOME: 'At minimum a score needs to be entered for set 1',
    UNDER_WIN_LIMIT: 'Winning team must have 2 wins for this match format',
    OVER_WIN_LIMIT: 'Winning team cannot have more than 2 wins for this match format',
    SETS_ORDERED_INCORRECTLY: 'Sets are ordered incorrectly',
    GAME_SCORE_INVALID: 'Invalid score for game ',
    GAME_SCORE_INCOMPLETE: 'Incomplete score for game ',
    GAME_SCORE_NO_TIE: 'Cannot have a tie score for game ',
    GAME_SCORES_REQUIRED_FOR_OUTCOME: 'At minimum a score needs to be entered for game 1',
};

export const isNumericScore = (score) => {
    return !Number.isNaN(parseInt(score));
};

export const hasScoresEntered = (matchScores) => {
    let hasScore = false;
    matchScores.forEach((input) => {
        const hasPosition1Score = input?.position1Score || input?.position1Score === 0;
        const hasPosition2Score = input?.position2Score || input?.position2Score === 0;
        if (hasPosition1Score || hasPosition2Score) {
            hasScore = true;
        }
    });
    return hasScore;
};

export const findPlayerInTeam = (playerId, teamMembers = []) => {
    return teamMembers.find((player) => player.playerId === playerId);
};

export const getPlayerName = (playerId, teamMembers) => {
    const player = findPlayerInTeam(playerId, teamMembers);
    return `${player?.firstName} ${player?.lastName}`;
};

export const clearMatchScores = (match) => {
    const { side1, side2 } = match || {};
    const clearedSideResult = {
        set1Score: null,
        set2Score: null,
        set3Score: null,
        set4Score: null,
        set5Score: null,
        tieBreakSet1Score: null,
        tieBreakSet2Score: null,
        tieBreakSet3Score: null,
        tieBreakSet4Score: null,
        tieBreakSet5Score: null,
    };

    const updatedMatch = {
        ...match,
        side1: { ...side1, sideResult: { ...side1.sideResult, ...clearedSideResult } },
        side2: { ...side2, sideResult: { ...side2.sideResult, ...clearedSideResult } },
    };

    return updatedMatch;
};

export const switchMatchScores = (match) => {
    const { side1, side2 } = match || {};
    const updatedMatch = {
        ...match,
        side1: { ...side1, sideResult: { ...side2.sideResult, sideId: side1.sideResult.sideId } },
        side2: { ...side2, sideResult: { ...side1.sideResult, sideId: side2.sideResult.sideId } },
    };
    return updatedMatch;
};

const getResultScore = (score) => {
    return score === '' ? null : score;
};

export const getPositionsForMatch = (match = {}, isSide1Position1) => {
    const { side1, side2 } = match;
    const position1 = isSide1Position1 ? side1 : side2;
    const position2 = isSide1Position1 ? side2 : side1;

    return { position1, position2 };
};

export const hasDuplicatePlayersInMatch = (match) => {
    const { side1, side2, isDoubles } = match;

    if (isDoubles) {
        const playerIds = [side1?.player1?.playerId, side1?.player2?.playerId, side2?.player1?.playerId, side2?.player2?.playerId].filter((playerId) => playerId);
        if (playerIds.length !== new Set(playerIds).size) {
            return true;
        }
        return false;
    }

    return side1?.player1?.playerId && side2?.player1?.playerId && side1?.player1?.playerId === side2?.player1?.playerId;
};

export const hasInvalidPlayerCount = (match, matchOutcomeId) => {
    const { side1, side2, isDoubles } = match;
    const requiredPlayersForDoublesDefault = 2;
    const requiredPlayersForSinglesDefault = 1;
    const requiredPlayersForSingles = 2;
    const requiredPlayersForDoubles = 4;
    const playerIds = [side1?.player1?.playerId, side1?.player2?.playerId, side2?.player1?.playerId, side2?.player2?.playerId].filter((playerId) => playerId);

    switch (matchOutcomeId) {
        case MATCH_NOT_PLAYED: {
            if (playerIds.length !== 0) {
                return true;
            }
            return false;
        }
        case DEFAULTED: {
            if (isDoubles && playerIds.length !== requiredPlayersForDoublesDefault) {
                return true;
            }
            if (!isDoubles && playerIds.length !== requiredPlayersForSinglesDefault) {
                return true;
            }
            return false;
        }
        case RETIRED:
        case WITHDREW:
        case COMPLETED:
        case INCOMPLETE:
        case COMPLETED_WITH_PARTIAL_SCORES: {
            if (isDoubles && playerIds.length !== requiredPlayersForDoubles) {
                return true;
            }
            if (!isDoubles && playerIds.length !== requiredPlayersForSingles) {
                return true;
            }
            return false;
        }
        default:
            return false;
    }
};

export const createDefaultedMatchResults = (match, matchOutcome, isSide1Position1) => {
    if (!match) {
        return null;
    }

    const { side1, side2 } = match;
    const clearedSideResult = {
        set1Score: null,
        set2Score: null,
        set3Score: null,
        set4Score: null,
        set5Score: null,
        tieBreakSet1Score: null,
        tieBreakSet2Score: null,
        tieBreakSet3Score: null,
        tieBreakSet4Score: null,
        tieBreakSet5Score: null,
    };
    const results = {
        winningSideId: matchOutcome.winningSideId,
        resultCompletionTypeId: DEFAULTED,
        side1Results: {
            ...clearedSideResult,
            player1MemberId: null,
            player1PlayerId: null,
            player2MemberId: null,
            player2PlayerId: null,
            sideId: side1.sideId,
        },
        side2Results: {
            ...clearedSideResult,
            player1MemberId: null,
            player1PlayerId: null,
            player2MemberId: null,
            player2PlayerId: null,
            sideId: side2.sideId,
        },
    };
    if (matchOutcome.outcomeId === MATCH_NOT_PLAYED) {
        return {
            ...results,
            winningSideId: null,
            resultCompletionTypeId: MATCH_NOT_PLAYED,
        };
    }

    const { side1Results, side2Results } = results;
    const position1 = isSide1Position1 ? side1 : side2;
    const position2 = isSide1Position1 ? side2 : side1;
    const position1Results = isSide1Position1 ? side1Results : side2Results;
    const position2Results = isSide1Position1 ? side2Results : side1Results;

    const position1Winner = position1?.sideId === matchOutcome.winningSideId;

    if (position1Winner) {
        position1Results.player1MemberId = position1?.player1?.memberId || position1?.player1?.id;
        position1Results.player1PlayerId = position1?.player1?.playerId;
        position1Results.player2MemberId = position1?.player2?.memberId || position1?.player2?.id;
        position1Results.player2PlayerId = position1?.player2?.playerId;
    } else {
        position2Results.player1MemberId = position2?.player1?.memberId || position2?.player1?.id;
        position2Results.player1PlayerId = position2?.player1?.playerId;
        position2Results.player2MemberId = position2?.player2?.memberId || position2?.player2?.id;
        position2Results.player2PlayerId = position2?.player2?.playerId;
    }

    return results;
};

export const createMatchResults = (match, sets, winningSideId, resultCompletionTypeId, isSide1Position1) => {
    const { side1, side2 } = match;
    const results = {
        winningSideId,
        resultCompletionTypeId,
        side1Results: {
            ...side1.sideResult,
            player1MemberId: null,
            player1PlayerId: null,
            player2MemberId: null,
            player2PlayerId: null,
        },
        side2Results: {
            ...side2.sideResult,
            player1MemberId: null,
            player1PlayerId: null,
            player2MemberId: null,
            player2PlayerId: null,
        },
    };
    const { side1Results, side2Results } = results;

    const position1 = isSide1Position1 ? side1 : side2;
    const position2 = isSide1Position1 ? side2 : side1;
    const position1Results = isSide1Position1 ? side1Results : side2Results;
    const position2Results = isSide1Position1 ? side2Results : side1Results;

    position1Results.player1MemberId = position1?.player1?.memberId || position1?.player1?.id;
    position1Results.player1PlayerId = position1?.player1?.playerId;
    position1Results.player2MemberId = position1?.player2?.memberId || position1?.player2?.id;
    position1Results.player2PlayerId = position1?.player2?.playerId;
    position1Results.set1Score = getResultScore(sets[0].position1Score);
    position1Results.set2Score = getResultScore(sets[1]?.position1Score);
    position1Results.set3Score = getResultScore(sets[2]?.position1Score);
    position1Results.tieBreakSet1Score = getResultScore(sets[0]?.position1Tiebreaker);
    position1Results.tieBreakSet2Score = getResultScore(sets[1]?.position1Tiebreaker);
    position1Results.tieBreakSet3Score = getResultScore(sets[2]?.position1Tiebreaker);

    position2Results.player1MemberId = position2?.player1?.memberId || position2?.player1?.id;
    position2Results.player1PlayerId = position2?.player1?.playerId;
    position2Results.player2MemberId = position2?.player2?.memberId || position2?.player2?.id;
    position2Results.player2PlayerId = position2?.player2?.playerId;
    position2Results.set1Score = getResultScore(sets[0].position2Score);
    position2Results.set2Score = getResultScore(sets[1]?.position2Score);
    position2Results.set3Score = getResultScore(sets[2]?.position2Score);
    position2Results.tieBreakSet1Score = getResultScore(sets[0]?.position2Tiebreaker);
    position2Results.tieBreakSet2Score = getResultScore(sets[1]?.position2Tiebreaker);
    position2Results.tieBreakSet3Score = getResultScore(sets[2]?.position2Tiebreaker);

    return results;
};

export const getMatchOutcomeOptions = (match, position1Team, position2Team, isSide1Position1) => {
    const { position1, position2 } = getPositionsForMatch(match, isSide1Position1) || {};
    const positionOnePlayerOne = position1?.player1?.firstName && position1?.player1?.lastName ? `${position1?.player1?.firstName} ${position1?.player1?.lastName}` : 'Not set';
    const positionTwoPlayerOne = position2?.player1?.firstName && position2?.player1?.lastName ? `${position2?.player1?.firstName} ${position2?.player1?.lastName}` : 'Not set';
    const position1SideId = position1?.sideId;
    const position2SideId = position2?.sideId;
    const isDoubles = match?.isDoubles;
    const position1Name = isDoubles ? position1Team?.name : positionOnePlayerOne;
    const position2Name = isDoubles ? position2Team?.name : positionTwoPlayerOne;

    const matchOutcomes = [
        { id: 1, outcomeId: COMPLETED, name: 'Completed' },
        { id: 2, outcomeId: COMPLETED_WITH_PARTIAL_SCORES, name: 'Completed with partial scores' },
        { id: 3, outcomeId: INCOMPLETE, name: 'Postponed' },
        { id: 4, outcomeId: DEFAULTED, winningSideId: position2SideId, name: `Defaulted - ${position1Team?.name}` },
        { id: 5, outcomeId: DEFAULTED, winningSideId: position1SideId, name: `Defaulted - ${position2Team?.name}` },
        { id: 6, outcomeId: MATCH_NOT_PLAYED, name: `Match not played` },
        { id: 7, outcomeId: RETIRED, winningSideId: position2SideId, name: `Retired - ${position1Name}` },
        { id: 8, outcomeId: RETIRED, winningSideId: position1SideId, name: `Retired - ${position2Name}` },
        { id: 9, outcomeId: WITHDREW, winningSideId: position2SideId, name: `Withdrew - ${position1Name}` },
        { id: 10, outcomeId: WITHDREW, winningSideId: position1SideId, name: `Withdrew - ${position2Name}` },
    ];

    return matchOutcomes;
};

export const hasScores = (scoreType) => {
    const hasPosition1Score = isNumericScore(scoreType?.position1Score);
    const hasPosition2Score = isNumericScore(scoreType?.position2Score);

    return hasPosition1Score && hasPosition2Score;
};

export const hasPartialScores = (scoreType) => {
    const hasPosition1Score = isNumericScore(scoreType?.position1Score);
    const hasPosition2Score = isNumericScore(scoreType?.position2Score);

    if ((hasPosition1Score && !hasPosition2Score) || (hasPosition2Score && !hasPosition1Score)) {
        return true;
    }

    if (hasPosition1Score && hasPosition2Score) {
        return false;
    }
};

export const isPosition1Winner = (contest) => {
    const position1Score = parseInt(contest?.position1Score);
    const position2Score = parseInt(contest?.position2Score);

    return position1Score > position2Score;
};

export const isPosition2Winner = (contest) => {
    const position1Score = parseInt(contest?.position1Score);
    const position2Score = parseInt(contest?.position2Score);

    return position2Score > position1Score;
};

export const getOutcomeForExistingMatch = ({ options = [], resultCompletionTypeId, winnerSideId }) => {
    let outcome;
    let outcomeIndex;
    const playerSpecificOutcomes = [RETIRED, WITHDREW, DEFAULTED];

    if (playerSpecificOutcomes.includes(resultCompletionTypeId)) {
        outcomeIndex = options?.findIndex(
            (option) => resultCompletionTypeId === option?.outcomeId && winnerSideId === option?.winningSideId
        );

        outcome = options[outcomeIndex];
    } else {
        outcomeIndex = options?.findIndex((option) => option?.outcomeId === resultCompletionTypeId);
        outcome = options[outcomeIndex];
    }

    return outcome;
};
