import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { multiRoundScheduleTypes } from '../../../utils/types';

const MultiRoundScheduleToggle = (props) => {
    const { scheduleType, disabled, onChange } = props;

    return (
        <ToggleButtonGroup
            value={scheduleType}
            exclusive
            onChange={onChange}
            aria-label="Schedule Toggle"
        >
            {multiRoundScheduleTypes.map((option) => (
                <ToggleButton disabled={disabled} key={option.id} value={option.id}>{option.description}</ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default MultiRoundScheduleToggle;
