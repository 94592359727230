import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ModalBaseSimple from './ModalBaseSimple';

const AddTeamsNotificationModal = ({ open = false, selections, teamsCount, onClose, onSendClick, onChange }) => {
    const handleOnChange = (event) => {
        onChange(event);
    };

    const teamsText = teamsCount === 1 ? 'team' : 'teams';

    return (
        <ModalBaseSimple open={open} onClose={onClose} title={`You're adding ${teamsCount} ${teamsText}`}>
            <DialogContent>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <Typography sx={{ mb: 1 }}>Would you like to notify captains or players?</Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={selections.notifyCaptains} onChange={handleOnChange} name="notifyCaptains" />}
                            label="Notify captains their team has been added"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={selections.notifyPlayers} onChange={handleOnChange} name="notifyPlayers" />}
                            label="Invite players to register for their team"
                        />
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 2, mt: 1 }}>
                <Button onClick={onSendClick} variant="dark" size="large">
                    Submit
                </Button>
            </DialogActions>
        </ModalBaseSimple>
    );
};

export default AddTeamsNotificationModal;
