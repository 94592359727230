import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { SquareAvatarWithName, Typography, DeleteIcon, EditIcon } from '@universal-tennis/ui-shared';

import PlayerAvailabilityResponse from '../../PlayerAvailabilityResponse';
import TeamMatchLink from './TeamMatchLink';
import WinnerIndicator from '../../WinnerIndicator';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import { StyledGrid } from '../../Styled';
import { scheduleMatchCardPropTypes, defaultScheduleMatchCardPropTypes } from '../../../utils/proptypes';
import { getAdjustedDate } from '../../../utils/display';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { useUpdatePlayerAvailabilityStatusMutation } from '../../../api/leaguesApi';

const ScheduleMatchCard = ({
    match,
    round,
    isLeagueDeskSchedule = false,
    onEditMatch,
    onDeleteMatch,
    matchNumber = null,
    isMultiWeekByDate = false,
}) => {
    const { teamMatchId, matchDate, location, position1Team, position2Team, winnerTeamId } = match;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { DISPLAY, PRIMARY, SECONDARY } = SharedUICategories;
    const { LARGE, MEDIUM_BOOK, MEDIUM_MEDIUM, SMALL_BOOK } = TypographySizes;
    const [userAvailabilityData, setUserAvailabilityData] = useState();
    const [isUserOnBothTeams, setIsUserOnBothTeams] = useState();
    const [showAvailability, setShowAvailability] = useState();

    const day = getAdjustedDate({ dateUtc: matchDate, format: 'D' });
    const month = getAdjustedDate({ dateUtc: matchDate, format: 'MMM' });
    const time = getAdjustedDate({ dateUtc: matchDate, format: 'h:mm A' });
    const isPosition1Winner = position1Team.teamId === winnerTeamId;
    const isPosition2Winner = position2Team.teamId === winnerTeamId;
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const teamNameGridItemWidth = '200px';
    const isMediumAndUpScreen = useMediaQuery(theme.breakpoints.up('md'));
    const maxLinkWidth = isMediumAndUpScreen ? teamNameGridItemWidth : 'auto';
    const mediumScreenMargin = isMediumScreen ? 2 : 0;
    const teamMatchLink = `/leagues/match/${teamMatchId}`;
    const roundName = round ? round?.name : match?.round?.roundName;
    const hasNoScoresPosted = position1Team?.points === 0 && position2Team?.points === 0;
    const widthOfFirstGridItem = '90px';
    const borderRightStyles = `1px solid ${theme.appColors.lightGrey}`;
    const displayMatchActions = isLeagueDeskSchedule && !match?.hasPostedScores && !isMultiWeekByDate;

    const [updatePlayerAvailability, { error: playerAvailabilityError }] = useUpdatePlayerAvailabilityStatusMutation();

    useEffect(() => {
        if (match) {
            const { requestingMemberPlayerAvailability: userAvailabilityPosition1 } = match?.position1Team || {};
            const { requestingMemberPlayerAvailability: userAvailabilityPosition2 } = match?.position2Team || {};
            const showAvailabilityOptions = !!userAvailabilityPosition1 || !!userAvailabilityPosition2;
            const isUserMemberOfBothTeams = !!userAvailabilityPosition1?.teamMemberId && !!userAvailabilityPosition2?.teamMemberId;

            if (isUserMemberOfBothTeams || !!userAvailabilityPosition1) {
                setUserAvailabilityData(userAvailabilityPosition1);
            } else if (userAvailabilityPosition2) {
                setUserAvailabilityData(userAvailabilityPosition2);
            }

            setShowAvailability(showAvailabilityOptions);
            setIsUserOnBothTeams(isUserMemberOfBothTeams);
        }
    }, [match]);

    const getWinnerFontSize = (isPositionWinner) => {
        if (isPositionWinner) {
            return MEDIUM_MEDIUM;
        }
        return MEDIUM_BOOK;
    };

    const getWinnerFontColor = (isPositionWinner) => {
        if (isPositionWinner) {
            return theme.appColors.black;
        }
        return theme.appColors.blueGrey;
    };

    const handleOnPlayerAvailabilityChange = async ({ teamMemberId, availabilityStatus }) => {
        let teamMemberIds = [teamMemberId];

        if (isUserOnBothTeams) {
            teamMemberIds = [
                position1Team?.requestingMemberPlayerAvailability?.teamMemberId,
                position2Team?.requestingMemberPlayerAvailability?.teamMemberId,
            ];
        }

        try {
            await updatePlayerAvailability({
                teamMemberIds,
                playerAvailabilityStatusId: availabilityStatus,
                teamMatchId: parseInt(teamMatchId),
            }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnEditClick = () => {
        onEditMatch(match);
    };

    const handleOnDeleteClick = () => {
        onDeleteMatch(teamMatchId, round?.name, matchNumber);
    };

    const ByeMatch = () => {
        return (
            <Box>
                <StyledGrid container columnSpacing={5} alignItems="center">
                    <Grid item xs={3} md={1.5} mb={mediumScreenMargin} pr={3}>
                        <Box minWidth={widthOfFirstGridItem} pr={1.5} borderRight={borderRightStyles}>
                            <Typography category={DISPLAY} size={LARGE} color={theme.appColors.lightGrey}>
                                &mdash;
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={9} md={1.5} display="flex" flexDirection="column" justifyContent="center" mb={mediumScreenMargin}>
                        <TeamMatchLink teamMatchLink={teamMatchLink} roundName={roundName} isMultiWeekByDate={isMultiWeekByDate} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap={1}
                        mb={mediumScreenMargin}
                        sx={{ color: theme.appColors.grey }}
                    >
                        <SquareAvatarWithName name={position1Team.name} href={`/teams/${position1Team.teamId}`} />
                        <SquareAvatarWithName disabled name="Bye" />
                    </Grid>
                    {displayMatchActions && (
                        <Grid item xs={12} md={1.5} ml="auto">
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton onClick={() => handleOnDeleteClick()} aria-label="Delete team match">
                                    <DeleteIcon color={theme.appColors.interactionBlue} size={16} />
                                </IconButton>
                            </Box>
                        </Grid>
                    )}
                </StyledGrid>
            </Box>
        );
    };

    return (
        <Box bgcolor={theme.appColors.white} mb={1}>
            {location ? (
                <Box>
                    <StyledGrid container columnSpacing={5} alignItems="center">
                        <Grid item xs={3} md={1.5} mb={mediumScreenMargin} pr={3}>
                            <Box minWidth={widthOfFirstGridItem} pr={1.5} borderRight={borderRightStyles}>
                                <Typography category={DISPLAY} size={MEDIUM_BOOK} sx={{ lineHeight: '40px', textTransform: 'uppercase' }}>
                                    {`${month} ${day}`}
                                </Typography>
                                <Typography category={PRIMARY} size={MEDIUM_BOOK}>
                                    {time}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={9} md={1.5} display="flex" flexDirection="column" justifyContent="center" mb={mediumScreenMargin}>
                            <TeamMatchLink teamMatchLink={teamMatchLink} roundName={roundName} isMultiWeekByDate={isMultiWeekByDate} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3.5}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap={1}
                            mb={mediumScreenMargin}
                        >
                            <Box display="flex" alignItems="center" justifyContent="space-between" position="relative">
                                {isPosition2Winner && <WinnerIndicator isBlack sx={{ position: 'absolute', left: -15 }} />}
                                <SquareAvatarWithName
                                    name={position2Team.name}
                                    href={`/teams/${position2Team.teamId}`}
                                    fontSize={getWinnerFontSize(isPosition2Winner)}
                                    maxLinkWidth={maxLinkWidth}
                                />
                                <Typography
                                    category={SECONDARY}
                                    color={getWinnerFontColor(isPosition2Winner)}
                                    size={getWinnerFontSize(isPosition2Winner)}
                                >
                                    {hasNoScoresPosted ? '—' : position2Team.points}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" position="relative">
                                {isPosition1Winner && <WinnerIndicator isBlack sx={{ position: 'absolute', left: -15 }} />}
                                <SquareAvatarWithName
                                    name={position1Team.name}
                                    href={`/teams/${position1Team.teamId}`}
                                    fontSize={getWinnerFontSize(isPosition1Winner)}
                                    maxLinkWidth={maxLinkWidth}
                                />
                                <Typography
                                    category={SECONDARY}
                                    color={getWinnerFontColor(isPosition1Winner)}
                                    size={getWinnerFontSize(isPosition1Winner)}
                                >
                                    {hasNoScoresPosted ? '—' : position1Team.points}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3} display="flex" flexDirection="column" justifyContent="center" mb={mediumScreenMargin}>
                            <Typography category={SECONDARY} size={SMALL_BOOK}>
                                {location.placeName}
                            </Typography>
                            <Typography category={SECONDARY} size={SMALL_BOOK}>
                                {location.formattedAddress}
                            </Typography>
                        </Grid>
                        {showAvailability && (
                            <Grid item xs={12} md={1.5}>
                                <PlayerAvailabilityResponse
                                    onAvailabilityChange={handleOnPlayerAvailabilityChange}
                                    currentUserData={userAvailabilityData}
                                    error={playerAvailabilityError}
                                />
                            </Grid>
                        )}
                        {displayMatchActions && (
                            <Grid item xs={12} md={1.5} ml="auto">
                                <Box display="flex" justifyContent="flex-end">
                                    <>
                                        <IconButton onClick={handleOnEditClick} aria-label="Edit team details" ml="auto">
                                            <EditIcon color={theme.appColors.interactionBlue} size={16} />
                                        </IconButton>
                                        <IconButton onClick={() => handleOnDeleteClick()} aria-label="Delete team match">
                                            <DeleteIcon color={theme.appColors.interactionBlue} size={16} />
                                        </IconButton>
                                    </>
                                </Box>
                            </Grid>
                        )}
                    </StyledGrid>
                </Box>
            ) : (
                <ByeMatch key={teamMatchId} />
            )}
        </Box>
    );
};

ScheduleMatchCard.propTypes = {
    ...scheduleMatchCardPropTypes,
};

ScheduleMatchCard.defaultProps = {
    ...defaultScheduleMatchCardPropTypes,
};

export default ScheduleMatchCard;
