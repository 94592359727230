const geocoder = { current: null };

export const getLocationData = async (location) => {
    const request = { placeId: location.place_id };

    if (!geocoder.current && window.google) {
        geocoder.current = new window.google.maps.Geocoder();
    }

    let geocodeResults = null;

    await geocoder.current.geocode(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
            geocodeResults = results[0];
        }
    });

    if (geocodeResults) {
        const { formatted_address, place_id: placeId, geometry } = geocodeResults;
        return {
            placeId,
            formattedAddress: formatted_address,
            placeName: location.structured_formatting.main_text,
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
        };
    }
};

export const getLocationNameFromCoordinates = async (lat, lng) => {
    if (!geocoder.current && window.google) {
        geocoder.current = new window.google.maps.Geocoder();
    }

    let geocodeResults = null;
    const latLng = { lat, lng };
    await geocoder.current.geocode({ location: latLng }, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
            geocodeResults = results[0];
        }
    });

    if (geocodeResults) {
        const { address_components } = geocodeResults;
        const city = address_components.find((comp) => comp.types.includes('locality'));
        const state = address_components.find((comp) => comp.types.includes('administrative_area_level_1'));
        return `${city?.long_name}, ${state?.short_name}`;
    }
};

export const getBrowserLocationCoordinates = async () => {
    let locationError = null;
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60000,
    };
    const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    }).catch((error) => {
        locationError = error;
    });

    return {
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
        error: locationError,
    };
};
