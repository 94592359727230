import React from 'react';
import { Typography, Button } from '@universal-tennis/ui-shared';
import { useTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';

import { SharedUICategories, TypographySizes } from '../../utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullPageModal = ({ title, children, ctaTitle, onCtaClick, isOpen, onClose, isCtaButtonDisabled = false }) => {
    const theme = useTheme();
    const { PRIMARY, DISPLAY } = SharedUICategories;
    const { MEDIUM_BOOK } = TypographySizes;

    return (
        <Dialog PaperProps={{ style: { backgroundColor: theme.appColors.coolGrey } }} fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative', backgroundColor: theme.appColors.black }}>
                <Toolbar>
                    <Container maxWidth="lg">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography category={DISPLAY} size={MEDIUM_BOOK}>
                                {title}
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
            <Container maxWidth="md">
                {children}
            </Container>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: theme.appColors.white }}>
                <Toolbar>
                    <Container maxWidth="lg">
                        <Box display="flex" justifyContent="flex-end">
                            <Button disabled={isCtaButtonDisabled} onClick={onCtaClick} category={PRIMARY}>
                                {ctaTitle}
                            </Button>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </Dialog>

    );
};

export default FullPageModal;
