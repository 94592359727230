import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector, useDispatch } from 'react-redux';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@universal-tennis/ui-shared';

import LoadingIndicator from '../LoadingIndicator';
import PasswordConfirmation from './PasswordConfirmation';
import RegistrationConfirmation from './RegistrationConfirmation';
import TeamSelection from './TeamSelection';
import USAPValidation from './USAPValidation';
import config from '../../config';
import { RegistrationStepIds, TypographySizes, SharedUICategories } from '../../utils/constants';
import { defaultRegistrationDrawerPropTypes, registrationDrawerPropTypes } from '../../utils/proptypes';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { useValidateRegistrationPinMutation, useValidateUsapMembershipMutation, useInitiateRegistrationMutation } from '../../api/leaguesApi';

const RegistrationDrawer = ({
    isOpen,
    isUsapSanctionedSession,
    onClose,
    onFreeSessionRegistration,
    onRegistrationSuccess,
    onTeamSelectionClick,
    registrationFees,
    teamData,
    teams,
    isSessionPage = false,
    ...rest
}) => {
    const { user } = useSelector((state) => state.auth);
    const theme = useTheme();
    const dispatch = useDispatch();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const [activeStep, setActiveStep] = useState(0);
    const [clientSecret, setClientSecret] = useState(null);
    const [isInvalidPin, setIsInvalidPin] = useState(false);
    const [registrationPin, setRegistrationPin] = useState(null);
    const [usapMemberId, setUsapMemberId] = useState(null);
    const [registrationSteps, setRegistrationSteps] = useState([]);
    const [stripeInstance, setStripeInstance] = useState(null);
    const [usapValidationError, setUsapValidationError] = useState(null);
    const [isInitiatingRegistration, setIsInitiatingRegistration] = useState(false);

    const userName = `${user?.firstName} ${user?.lastName}`;
    const drawerWidth = isLargeScreen ? 450 : '100%';

    const { SECONDARY } = SharedUICategories;
    const { SMALL_MEDIUM_CAP, X_SMALL_MEDIUM } = TypographySizes;
    const { hasRegistrationPin, teamId, sessionSummary, teamName } = teamData || {};
    const { TEAM_SELECTION, PASSWORD_CONFIRMATION, EVENT_QUESTIONS, CONFIRM_DETAILS } = RegistrationStepIds;

    const [validatePin, { isLoading: isLoadingPinValidation }] = useValidateRegistrationPinMutation();
    const [validateUsapMembership, { isSuccess: isUsapMembershipValid, isLoading: isLoadingMemberValidation }] = useValidateUsapMembershipMutation();
    const [initiateRegistration] = useInitiateRegistrationMutation();

    const initiateTeamRegistration = async (pin, memberId) => {
        setIsInitiatingRegistration(true);
        try {
            const registrationData = { teamId, sessionId: sessionSummary.id, registrationPin: pin, usapMemberId: memberId };
            const { clientSecret: secret, paymentAccountId, isFreeSession } = await initiateRegistration(registrationData).unwrap();
            if (isFreeSession) {
                setIsInitiatingRegistration(false);
                onFreeSessionRegistration();
                return;
            }
            if (secret && paymentAccountId) {
                const stripe = await loadStripe(config.stripeKey, { stripeAccount: paymentAccountId });
                setClientSecret(secret);
                setStripeInstance(stripe);
                setIsInitiatingRegistration(false);
            }
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
            setIsInitiatingRegistration(false);
        }
    };

    const handleNext = () => {
        setActiveStep((prev) => prev + 1);
    };

    const handleBack = () => {
        setActiveStep((prev) => (prev <= 0 ? prev : prev - 1));
    };

    const startRegistration = async ({ shouldAdvanceStep = false, pin, memberId }) => {
        if (shouldAdvanceStep) {
            handleNext();
        }

        await initiateTeamRegistration(pin, memberId);
    };

    useEffect(() => {
        const steps = [];

        if (isSessionPage) {
            steps.push({ id: TEAM_SELECTION, title: 'Team Selection' });
        }

        if (hasRegistrationPin) {
            steps.push({ id: PASSWORD_CONFIRMATION, title: 'Password Confirmation' });
        }

        if (isUsapSanctionedSession) {
            steps.push({ id: EVENT_QUESTIONS, title: 'Event Questions' });
        }

        steps.push({ id: CONFIRM_DETAILS, title: 'Confirm Details' });

        setRegistrationSteps(steps);

        const stepCount = steps?.length || 0;
        const isTeamPage = !isSessionPage;
        const hasTeamData = !!teamData;

        const hasPaymentOnly = (isTeamPage && stepCount === 1) || (hasTeamData && isSessionPage && stepCount === 2);
        const hasMultipleStepsSessionPage = hasTeamData && isSessionPage && stepCount > 2;

        if (hasPaymentOnly) {
            startRegistration({ shouldAdvanceStep: isSessionPage });
        } else if (hasMultipleStepsSessionPage) {
            handleNext();
        }
    }, [isSessionPage, hasRegistrationPin, isUsapSanctionedSession, teamData]);

    useEffect(() => {
        if (isUsapMembershipValid) {
            startRegistration({ shouldAdvanceStep: true, pin: registrationPin, memberId: usapMemberId });
        }
    }, [isUsapMembershipValid]);

    const handleOnTeamCardClick = (id) => {
        if (id === teamData?.teamId) {
            handleNext();
        } else {
            onTeamSelectionClick(id);
        }
    };

    const handleOnPinSubmitted = async (pin) => {
        setIsInvalidPin(false);

        try {
            const { isValid } = await validatePin({ teamId, pin }).unwrap();
            if (isValid) {
                setRegistrationPin(pin);
                if (isUsapSanctionedSession) {
                    handleNext();
                } else {
                    await startRegistration({ shouldAdvanceStep: true, pin });
                }
            } else {
                setRegistrationPin(null);
                setIsInvalidPin(true);
            }
        } catch (apiError) {
            setIsInvalidPin(true);
        }
    };

    const handleOnUSAPMemberIdSubmitted = async (memberId) => {
        setUsapValidationError(null);
        setUsapMemberId(memberId);
        try {
            await validateUsapMembership({ sessionId: sessionSummary?.id, usapMemberId: memberId }).unwrap();
        } catch (apiError) {
            setUsapValidationError(apiError?.message);
        }
    };

    const handleOnCloseButtonClicked = () => {
        onClose();
    };

    const showBackButton = activeStep !== 0;
    const isTeamSelectionStep = activeStep === registrationSteps.findIndex((step) => step.id === TEAM_SELECTION);
    const isPinValidationStep = activeStep === registrationSteps.findIndex((step) => step.id === PASSWORD_CONFIRMATION);
    const isEventQuestionsStep = activeStep === registrationSteps.findIndex((step) => step.id === EVENT_QUESTIONS);
    const isConfirmDetailsStep = activeStep === registrationSteps.findIndex((step) => step.id === CONFIRM_DETAILS);

    return (
        <Drawer PaperProps={{ sx: { width: drawerWidth } }} variant="temporary" anchor="right" open={isOpen}>
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                {showBackButton ? (
                    <IconButton onClick={handleBack} aria-label="back">
                        <ArrowBackIosNewIcon fontSize="small" />
                    </IconButton>
                ) : (
                    <div />
                )}
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography category={SECONDARY} size={SMALL_MEDIUM_CAP}>
                        {registrationSteps[activeStep]?.title}
                    </Typography>
                    <Typography category={SECONDARY} size={X_SMALL_MEDIUM} sx={{ color: theme.appColors.grey }}>
                        {userName}
                    </Typography>
                </Box>
                <IconButton onClick={handleOnCloseButtonClicked} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box mt={10} p={3}>
                {(teams?.length > 0 || (!isSessionPage && teamName)) && !isTeamSelectionStep ? (
                    <Typography category="secondary" size="large-medium">
                        {teamName}
                    </Typography>

                ) : null}
                {isTeamSelectionStep && isSessionPage && (
                    <TeamSelection
                        {...rest}
                        teams={teams}
                        onTeamCardClick={handleOnTeamCardClick}
                    />
                )}
                {isPinValidationStep && hasRegistrationPin && (
                    <PasswordConfirmation
                        captains={teamData?.captains}
                        isInvalidPasscode={isInvalidPin}
                        onPinSubmitted={handleOnPinSubmitted}
                        isLoading={isLoadingPinValidation || isInitiatingRegistration}
                    />
                )}
                {isEventQuestionsStep && isUsapSanctionedSession && (
                    <USAPValidation
                        onMemberIdSubmitted={handleOnUSAPMemberIdSubmitted}
                        validationError={usapValidationError}
                        isLoading={isLoadingMemberValidation || isInitiatingRegistration}
                    />
                )}
                {isConfirmDetailsStep && isInitiatingRegistration && (
                    <LoadingIndicator />
                )}
                {isConfirmDetailsStep && clientSecret && (
                    <Elements
                        stripe={stripeInstance}
                    >
                        <RegistrationConfirmation
                            clientSecret={clientSecret}
                            registrationFees={registrationFees}
                            sessionId={teamData?.sessionSummary?.id}
                            onRegistrationSuccess={onRegistrationSuccess}
                        />
                    </Elements>

                )}
            </Box>
        </Drawer>
    );
};

RegistrationDrawer.propTypes = {
    ...registrationDrawerPropTypes
};

RegistrationDrawer.defaultProps = {
    ...defaultRegistrationDrawerPropTypes
};

export default RegistrationDrawer;
