import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import ConferencesTab from './ConferencesTab';
import LoadingIndicator from '../../components/LoadingIndicator';
import { setSportTypeId } from '../../redux/conferenceDetailsSlice';

const LeagueDeskPage = ({ isLoading, leagueData, organizerLeagues, leagueId, isLeagueOrganizer, onLeagueSelected }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (leagueData) {
            dispatch(setSportTypeId(leagueData?.sportTypeId));
        }
    }, [leagueData]);

    const handleOnCreateConferenceClicked = () => {
        navigate(`/leagues/${leagueId}/conference/create`);
    };

    const handleOnManageClicked = (conferenceId) => {
        navigate(`/leagues/conference/${conferenceId}/manage`);
    };

    const handleOnLeagueChange = (event) => {
        const id = event?.target?.value;
        onLeagueSelected(id);
    };

    return (
        <Container>
            {isLoading && <LoadingIndicator />}
            {leagueData && (
                <Box mt={5}>
                    <Typography my={2} variant="h3">
                        Team League Desk
                    </Typography>
                    <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
                        {isLeagueOrganizer && organizerLeagues?.length > 1 ? (
                            <FormControl sx={{ width: 300 }}>
                                <InputLabel id="league-select">Select League</InputLabel>
                                <Select
                                    value={leagueId || ''}
                                    label="Select League"
                                    labelId="league-select"
                                    onChange={handleOnLeagueChange}
                                >
                                    {organizerLeagues?.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <Typography variant="h5">{leagueData.name}</Typography>
                        )}
                        <Box my={4}>
                            <Button onClick={handleOnCreateConferenceClicked} variant="dark">
                                Create New Conference
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <ConferencesTab
                        conferences={leagueData.conferences}
                        isLoading={isLoading}
                        onManageClicked={handleOnManageClicked}
                    />
                </Box>
            )}
        </Container>
    );
};

export default LeagueDeskPage;
