export const gtag = (name, props) => {
    if (window?.dataLayer) {
        window.dataLayer.push({
            event: name,
            ...props,
        });
    }
};

export const getClickAttrs = ({ context, location, category }) => {
    const attrs = {};
    if (category) {
        attrs['data-category'] = category;
    }
    if (context) {
        attrs['data-context'] = context;
    }
    if (location) {
        attrs['data-location'] = location;
    }
    return attrs;
};
