import React from 'react';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import { Typography } from '@universal-tennis/ui-shared';

import { isNumericScore } from '../../../../utils/postScoreShared';
import { ResultTypes } from '../../../../utils/types';
import { SharedUICategories, TypographySizes } from '../../../../utils/constants';

const SetScores = ({ position = {}, isPositionWinner = false, outcomeDescription = '', isScorelessOutcome = false, resultCompletionTypeId = null }) => {
    const theme = useTheme();
    const { DEFAULTED, WITHDREW, RETIRED } = ResultTypes;
    const hasSet1Score = isNumericScore(position?.sideResult?.set1Score);
    const hasSet2Score = isNumericScore(position?.sideResult?.set2Score);
    const hasSet3Score = isNumericScore(position?.sideResult?.set3Score);
    const hasSet1TiebreakerScore = isNumericScore(position?.sideResult?.tieBreakSet1Score);
    const hasSet2TiebreakerScore = isNumericScore(position?.sideResult?.tieBreakSet2Score);
    const hasSet3TiebreakerScore = isNumericScore(position?.sideResult?.tieBreakSet3Score);
    const fontSize = isPositionWinner ? TypographySizes.MEDIUM_MEDIUM : TypographySizes.MEDIUM_BOOK;
    const fontColor = isPositionWinner ? theme.appColors.black : theme.appColors.blueGrey;
    const displayOutcomeDescription = (resultCompletionTypeId === DEFAULTED || resultCompletionTypeId === RETIRED || resultCompletionTypeId === WITHDREW) && !isPositionWinner;
    const noScores = !displayOutcomeDescription && !hasSet1Score && !hasSet2Score && !hasSet3Score && !isScorelessOutcome;

    return (
        <Box>
            <Box display="flex" gap={2}>
                {displayOutcomeDescription && (
                    <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK} color={theme.appColors.blueGrey}>{outcomeDescription}</Typography>
                )}
                {hasSet1Score && (
                    <Box display="flex" gap="2px">
                        <Typography category={SharedUICategories.SECONDARY} size={fontSize} color={fontColor}>{position?.sideResult?.set1Score}</Typography>
                        {hasSet1TiebreakerScore && (
                            <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_BOOK} color={fontColor}>
                                {position?.sideResult?.tieBreakSet1Score}
                            </Typography>
                        )}
                    </Box>
                )}
                {hasSet2Score && (
                    <Box display="flex" gap="2px">
                        <Typography category={SharedUICategories.SECONDARY} size={fontSize} color={fontColor}>{position?.sideResult?.set2Score}</Typography>
                        {hasSet2TiebreakerScore && (
                            <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_BOOK} color={fontColor}>
                                {position?.sideResult?.tieBreakSet2Score}
                            </Typography>
                        )}
                    </Box>
                )}
                {hasSet3Score && (
                    <Box display="flex" gap="2px">
                        <Typography category={SharedUICategories.SECONDARY} size={fontSize} color={fontColor}>{position?.sideResult?.set3Score}</Typography>
                        {hasSet3TiebreakerScore && (
                            <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.XX_SMALL_BOOK} color={fontColor}>
                                {position?.sideResult?.tieBreakSet3Score}
                            </Typography>
                        )}
                    </Box>
                )}
                {noScores && (
                    <Box display="flex" gap={2}>
                        <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK} color={theme.appColors.blueGrey}>-</Typography>
                        <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK} color={theme.appColors.blueGrey}>-</Typography>
                        <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.MEDIUM_BOOK} color={theme.appColors.blueGrey}>-</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SetScores;
