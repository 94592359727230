import React from 'react';
import Box from '@mui/material/Box';

const arrowStyles = {
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid white',
    height: 0,
    width: 0
};
const rectangleStyles = {
    background: 'white',
    height: 20,
    width: 3
};

const blackArrowStyles = {
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    borderLeft: '8px solid black',
    height: 0,
    width: 0
};

const blackRectangleStyles = {
    background: 'black',
    height: 16,
    width: 3
};

const WinnerIndicator = ({ sx, isBlack }) => {
    return (
        <Box sx={sx} display="flex">
            <Box style={isBlack ? blackRectangleStyles : rectangleStyles} />
            <Box style={isBlack ? blackArrowStyles : arrowStyles} />
        </Box>
    );
};

export default WinnerIndicator;
