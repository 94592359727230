import axios from 'axios';
// import MockAdapter from 'axios-mock-adapter';
import config from '../config';

export const getProxyAxiosInstance = () => {
    return axios.create({
        baseURL: '/api',
    });
};

export const getApiAxiosInstance = () => {
    if (config.useProxy) {
        return getProxyAxiosInstance();
    }
    return axios.create({
        baseURL: config.apiHost,
        withCredentials: true,
    });
};

export const getAxiosInstance = () => {
    return axios.create({});
};

export const getLeaguesProxyAxiosInstance = () => {
    return axios.create({
        baseURL: '/leagues-api',
    });
};

export const getLeaguesApiAxiosInstance = () => {
    if (config.useProxy) {
        return getLeaguesProxyAxiosInstance();
    }
    return axios.create({
        baseURL: config.leaguesApiHost,
        withCredentials: true,
    });
};

export const getSearchProxyAxiosInstance = () => {
    return axios.create({
        baseURL: '/search-api',
    });
};

export const getSearchApiAxiosInstance = () => {
    if (config.useProxy) {
        return getSearchProxyAxiosInstance();
    }
    return axios.create({
        baseURL: config.searchApiHost,
        withCredentials: true,
    });
};

// export const axiosMock = () => {
//     const axiosInstance = axios.create({});
//     const mock = new MockAdapter(axiosInstance);
//     return {
//         mock,
//         axiosInstance,
//     };
// };
