import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SearchPage from '..';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { LeagueTypes, SportTypeIds, LeagueTierTypes } from '../../../utils/types';

const SearchWrapper = () => {
    const { user } = useSelector((state) => state.auth);
    const location = user?.location;
    const { leagueTypeId } = useParams();
    const { ADULT, JUNIOR } = LeagueTierTypes;
    const { TENNIS, PICKLEBALL } = SportTypeIds;
    const { UT_ADULT_TEAM_PICKLEBALL, UT_ADULT_TEAM_TENNIS, UT_JUNIOR_TEAM_PICKLEBALL, UT_JUNIOR_TEAM_TENNIS } = LeagueTypes;
    const [userLocation, setUserLocation] = useState(null);
    const [sportType, setSportType] = useState(null);
    const [leagueTierType, setLeagueTierType] = useState(null);

    useEffect(() => {
        if (location?.latLng?.length) {
            const [lat, lng] = location.latLng;
            setUserLocation({ lat, lng });
        }
    }, [location]);

    useEffect(() => {
        if (leagueTypeId) {
            const parsedLeagueId = parseInt(leagueTypeId);

            if (parsedLeagueId === UT_ADULT_TEAM_PICKLEBALL || parsedLeagueId === UT_JUNIOR_TEAM_PICKLEBALL) {
                setSportType(PICKLEBALL);
            } else if (parsedLeagueId === UT_ADULT_TEAM_TENNIS || parsedLeagueId === UT_JUNIOR_TEAM_TENNIS) {
                setSportType(TENNIS);
            }

            if (parsedLeagueId === UT_ADULT_TEAM_PICKLEBALL || parsedLeagueId === UT_ADULT_TEAM_TENNIS) {
                setLeagueTierType(ADULT);
            } else if (parsedLeagueId === UT_JUNIOR_TEAM_PICKLEBALL || parsedLeagueId === UT_JUNIOR_TEAM_TENNIS) {
                setLeagueTierType(JUNIOR);
            }
        }
    }, [leagueTypeId]);

    return !userLocation ? <LoadingIndicator /> : <SearchPage userLocation={userLocation} leagueTypeId={leagueTypeId} sportType={sportType} leagueTierType={leagueTierType} />;
};

export default SearchWrapper;
