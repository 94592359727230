import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getSearchApiAxiosInstance } from '../utils/network';
import { showErrorPopup } from '../redux/errorPopupSlice';
import { searchLeagueSessions, scrollLeagueSessions } from '../api/leaguesApi';
import { ResponseStatuses } from '../utils/constants';

const usePostLeaguesSearch = ({ searchFilters }) => {
    const maxResults = 20;
    const axiosInstance = getSearchApiAxiosInstance();
    const dispatch = useDispatch();

    const [apiResponse, setApiResponse] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [isFetchingResults, setIsFetchingResults] = useState(false);
    const [isEndOfResults, setIsEndOfResults] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [currentScrollId, setCurrentScrollId] = useState(null);
    const [isLoadingMoreResults, setIsLoadingMoreResults] = useState(false);

    const handleOnLoadMoreClick = () => {
        setRefetch(true);
        setIsLoadingMoreResults(true);
    };

    const handleRefetch = () => {
        setRefetch(true);
        setCurrentScrollId(null);
    };

    const clearSearchResults = () => {
        setSearchResults([]);
        setCurrentScrollId(null);
    };

    const getSearchResults = async () => {
        const requestBody = {
            pagination: { take: maxResults },
            searchProperties: { ...searchFilters },
        };
        const response = await searchLeagueSessions(axiosInstance, requestBody).catch((searchError) => {
            setIsFetchingResults(false);
            dispatch(showErrorPopup(searchError));
        });

        setApiResponse(response);
    };

    const handleInvalidScrollId = () => {
        setSearchResults([]);
        setCurrentScrollId(null);
        getSearchResults();
    };

    const getScrollResults = async (scrollId) => {
        const response = await scrollLeagueSessions(axiosInstance, { scrollId }).catch((searchError) => {
            if (searchError.response?.status === ResponseStatuses.NOT_FOUND) {
                setIsFetchingResults(false);
                handleInvalidScrollId();
                return;
            }
            dispatch(showErrorPopup(searchError));
        });

        setApiResponse(response);
    };

    useEffect(() => {
        if (apiResponse?.data) {
            const { documents, scrollId } = apiResponse.data || {};

            if (scrollId) {
                setCurrentScrollId(scrollId);
            }
            setIsFetchingResults(false);

            if (documents.length < maxResults) {
                setIsEndOfResults(true);
            } else {
                setIsEndOfResults(false);
            }

            if (isLoadingMoreResults) {
                setSearchResults([...searchResults, ...documents]);
            } else {
                setSearchResults(documents);
            }

            setRefetch(false);
            setIsLoadingMoreResults(false);
        }
    }, [apiResponse]);

    useEffect(() => {
        const handleOnSearchSubmit = async () => {
            setIsFetchingResults(true);

            const requestScrollId = isLoadingMoreResults ? currentScrollId : null;

            if (requestScrollId) {
                await getScrollResults(requestScrollId);
            } else {
                await getSearchResults();
            }
        };

        if (refetch) {
            handleOnSearchSubmit();
        }
    }, [refetch, isLoadingMoreResults]);

    return { searchResults, isFetchingResults, isEndOfResults, handleOnLoadMoreClick, handleRefetch, clearSearchResults };
};

export default usePostLeaguesSearch;
