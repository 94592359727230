import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import AddTeamContainer from '../../components/AddTeamContainer';
import ConfirmPublishSchedulePopup from '../../components/Popups/ConfirmPublishSchedulePopup';
import CopyPlayerModal from '../../components/Modals/CopyPlayerModal';
import CreateDrawModal from '../../components/Modals/CreateDrawModal';
import DetailsTab from './DetailsTab';
import DrawTab from '../../components/DrawTab';
import LoadingIndicator from '../../components/LoadingIndicator';
import MatchEditModal from '../TeamMatchPage/MatchEditModal';
import MovePlayerModal from '../../components/Modals/MovePlayerModal';
import PlayerWithdrawModal from '../../components/Modals/PlayerWithdrawModal';
import PlayersTab from '../../components/PlayerTab';
import Popup from '../../components/Popups/Popup';
import ScheduleTab from './ScheduleTab';
import TeamsTab from './TeamsTab';
import ManageSessionPageHeader from './ManageSessionPageHeader';

import useSessionSchedule from '../../hooks/useSessionSchedule';
import { BYE_ROUND, MANAGE_SESSION_PAGE_TYPE, SINGLE_ROUND_BYE } from '../../components/DrawTab/utils';
import { LeagueTabs, BreadcrumbContexts } from '../../utils/constants';
import { WithdrawTypes, playerWithdrawTypes, getTypeForId, LeagueSessionTypes, StatusTypes } from '../../utils/types';
import { getAdjustedDate } from '../../utils/display';
import { setSportTypeId } from '../../redux/conferenceDetailsSlice';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { showPopup } from '../../redux/genericPopupSlice';
import {
    useGetSessionQuery,
    useGetSessionTeamsQuery,
    useGetPlayersAvailableTeamsQuery,
    useUpdatePlayerRegistrationMutation,
    useRemoveTeamMutation,
    useMovePlayerToTeamMutation,
    useCopyPlayerToTeamMutation,
    useTogglePlayerRosterStatusMutation,
    useCreateTeamSeedsMutation,
    useUpdateTeamMatchPositionsMutation,
    useCopyTeamsToSessionMutation,
    useUpdateTeamMatchDateLocationMutation,
} from '../../api/leaguesApi';

const publishPopupMessage = 'Publishing the draw allows the public to view the draw.';
const deletePopupMessage = 'Are you sure you want to delete this Draw?';
const unpublishPopupMessage = 'Are you sure you want to Unpublish this Draw?';

const positions = {
    ONE: 1,
    TWO: 2,
};

const ManageSessionPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const theme = useTheme();

    const sessionId = parseInt(params.sessionId);
    const { data } = useSelector((state) => state.breadcrumbs);
    const { WITHDRAW, REFUND } = WithdrawTypes;
    const { DETAILS, TEAMS, PLAYERS, SCHEDULE, DRAW } = LeagueTabs;
    const { SESSION } = BreadcrumbContexts;

    const [activeTab, setActiveTab] = useState(TEAMS);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const [showDrawModal, setShowDrawModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [matchToUpdate, setMatchToUpdate] = useState(null);
    const [showEditMatchModal, setShowEditMatchModal] = useState(false);
    const [showAddTeamModal, setShowAddTeamModal] = useState(false);
    const [withdrawData, setWithdrawData] = useState({ playerId: null, teamId: null });
    const [movePlayerData, setMovePlayerData] = useState({ memberId: null, fromTeamId: null });
    const [copyPlayerData, setCopyPlayerData] = useState({ memberId: null, fromTeamId: null, isSubstitute: false });
    const [availableTeamData, setAvailableTeamData] = useState(skipToken);
    const [availablePlayerTeams, setAvailablePlayerTeams] = useState(skipToken);
    const [canPlayerBeRefunded, setCanPlayerBeRefunded] = useState(false);
    const [showPublishPopup, setShowPublishPopup] = useState(false);
    const [showUnpublishPopup, setShowUnpublishPopup] = useState(false);
    const [leagueId, setLeagueId] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedConferenceId, setSelectedConferenceId] = useState(null);
    const [sessionDetails, setSessionDetails] = useState({
        hasSessionStarted: false,
        hasSessionEnded: false,
        isMultiRoundSession: null,
    });

    const {
        data: playerTeamAvailability,
        isLoading: isLoadingAvailableTeams,
        error: availableTeamsError,
    } = useGetPlayersAvailableTeamsQuery(availableTeamData);
    const { data: session, isLoading: isLoadingSession, error: sessionError, refetch: refetchSession } = useGetSessionQuery(sessionId);
    const {
        data: sessionTeams,
        isFetching: isLoadingSessionTeams,
        error: teamsError,
        refetch: refetchSessionTeams,
    } = useGetSessionTeamsQuery(sessionId);
    const [removeTeam, { isLoading: isRemovingTeam }] = useRemoveTeamMutation();
    const [updatePlayerRegistration, { isLoading: isWithdrawing }] = useUpdatePlayerRegistrationMutation();
    const [movePlayer, { isLoading: isMovingPlayer }] = useMovePlayerToTeamMutation();
    const [copyPlayer, { isLoading: isCopyingPlayer }] = useCopyPlayerToTeamMutation();
    const [createTeamSeeds, { isLoading: isCreatingTeamSeeds }] = useCreateTeamSeedsMutation();
    const [shouldUpdateBreadcrumb, setShouldUpdateBreadcrumb] = useState(false);
    const [updateTeamMatchDateLocation, { isLoading: isUpdatingTeamMatchLocation }] = useUpdateTeamMatchDateLocationMutation();
    const [updateTeamMatchPositions] = useUpdateTeamMatchPositionsMutation();
    const [togglePlayerRosterStatus, { isLoading: isUpdatingRosterStatus }] = useTogglePlayerRosterStatusMutation();
    const [addTeams, { isLoading: isAddingTeams }] = useCopyTeamsToSessionMutation();

    const {
        scheduleId,
        drawSchedule,
        onCreateSchedule,
        onDeleteSchedule,
        isPublishingSchedule,
        isCreatingSchedule,
        onPublishSchedule,
        onUnpublishSchedule,
        refetchSessionSchedule,
    } = useSessionSchedule({ sessionId, showDraftSchedule: true, isMultiRoundSession: sessionDetails?.isMultiRoundSession });

    const isLoadingTeams = isLoadingSessionTeams || isRemovingTeam || isLoadingSession || isAddingTeams;
    const isUpdatingTeamPlayers = isWithdrawing || isMovingPlayer || isCopyingPlayer || isUpdatingRosterStatus;
    const isLoadingSeedsTable = isCreatingSchedule || isCreatingTeamSeeds;

    const { OPEN } = StatusTypes;

    const resetDrawCache = async () => {
        await refetchSession();
        await refetchSessionTeams();
        await refetchSessionSchedule();
    };

    if (availableTeamsError) {
        dispatch(showPopup({ isError: true, title: 'Error loading available teams for player', message: availableTeamsError?.message }));
    }

    if (sessionError) {
        dispatch(showErrorPopup(sessionError));
    }

    if (teamsError) {
        dispatch(showErrorPopup(teamsError));
    }

    useEffect(() => {
        if (location?.state?.activeTab) {
            setActiveTab(location?.state?.activeTab);
        }
    }, []);

    useEffect(() => {
        if (session) {
            const hasSessionStarted = moment(session.startDateUtc).isSameOrBefore(moment());
            const hasSessionEnded = moment(session.endDateUtc).isSameOrBefore(moment());
            const isMultiRoundSession = session.sessionTypeId === LeagueSessionTypes.MULTI_ROUND;
            setSessionDetails({ hasSessionStarted, hasSessionEnded, isMultiRoundSession });
            dispatch(setSportTypeId(session?.sportTypeId));
        }
    }, [session]);

    useEffect(() => {
        if (playerTeamAvailability) {
            const teamSelections = playerTeamAvailability?.availableTeams.map((team) => {
                return { id: team.teamId, description: team.teamName };
            });
            setAvailablePlayerTeams(teamSelections);
        }
    }, [playerTeamAvailability]);

    useEffect(() => {
        if (data?.session?.id === sessionId) {
            setLeagueId(data.league.id);
            setSelectedConferenceId(data.conference.id);
        }
    }, [data]);

    const handleOnAddTeam = () => {
        setShowAddTeamModal(true);
    };

    const handleOnCloseAddTeamModal = () => {
        setShowAddTeamModal(false);
    };

    const handleOnWithdrawPlayer = (playerId, playerTeamId, canBeRefunded) => {
        setCanPlayerBeRefunded(canBeRefunded);
        setShowWithdrawModal(true);
        setWithdrawData({ playerId, teamId: playerTeamId });
    };

    const handleOnCloseWithdrawModal = () => {
        setShowWithdrawModal(false);
    };

    const handleDrawCreateClick = () => {
        setShowDrawModal(!showDrawModal);
    };

    const handleOnDrawEditClick = () => {
        setShowDrawModal(!showDrawModal);
    };

    const handleOnMovePlayer = (memberId, playerTeamId) => {
        setAvailableTeamData({ sessionId, memberId });
        setShowMoveModal(true);
        setMovePlayerData({ memberId, fromTeamId: playerTeamId });
    };

    const handleOnCloseMoveModal = () => {
        setShowMoveModal(false);
        setAvailableTeamData(skipToken);
    };

    const handleOnCopyPlayer = (memberId, playerTeamId) => {
        setAvailableTeamData({ sessionId, memberId });
        setShowCopyModal(true);
        setCopyPlayerData({ memberId, fromTeamId: playerTeamId });
    };

    const handleOnCloseCopyModal = () => {
        setShowCopyModal(false);
        setAvailableTeamData(skipToken);
    };

    const handleOnClosePublishPopup = () => {
        setShowPublishPopup(false);
    };

    const handleOnPublishClick = () => {
        setShowPublishPopup(true);
    };

    const handleOnDrawUnpublishClick = () => {
        setShowUnpublishPopup(true);
    };

    const handleOnDrawDeleteClick = () => {
        setShowDeletePopup(true);
    };

    const handleOnEditMatch = (match) => {
        setMatchToUpdate(match);
        setShowEditMatchModal(true);
    };

    const handleOnViewMatch = ({ teamMatchId }) => {
        navigate(`/leagues/match/${teamMatchId}`);
    };

    const handleOnEditSubmit = async (teamMatch) => {
        setShowEditMatchModal(false);
        const { teamMatchId } = teamMatch;
        const matchDateTimeData = {
            location: teamMatch?.location,
            matchDate: teamMatch?.matchDate,
            description: teamMatch?.description,
        };

        try {
            await updateTeamMatchDateLocation({ teamMatchId, sessionId, scheduleId, data: matchDateTimeData }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnRemoveTeam = async (teamData) => {
        const { id, name } = teamData;
        try {
            await removeTeam({ teamId: id, sessionId }).unwrap();
            dispatch(showPopup({ title: 'Success', message: `${name} has been removed` }));
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleWithdrawSubmit = async (withdrawType) => {
        setShowWithdrawModal(false);
        const withdrawRequest = { sessionId: parseInt(sessionId), memberId: withdrawData.playerId };

        let isWithdrawingPlayer = false;
        let isRefunding = false;

        if (withdrawType === WITHDRAW) {
            isWithdrawingPlayer = true;
        } else if (withdrawType === REFUND) {
            isRefunding = true;
        } else {
            isWithdrawingPlayer = true;
            isRefunding = true;
        }

        withdrawRequest.withdraw = isWithdrawingPlayer;
        withdrawRequest.refund = isRefunding;

        try {
            await updatePlayerRegistration({ sessionId, teamId: withdrawData.teamId, withdrawRequest, withdrawType }).unwrap();
            const transactionType = getTypeForId(withdrawType, playerWithdrawTypes);
            dispatch(showPopup({ title: 'Success', message: `Your ${transactionType?.description?.toLowerCase()} was successful.` }));
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleMovePlayerSubmit = async (newTeamId) => {
        setShowMoveModal(false);

        const moveRequest = { ...movePlayerData, toTeamId: newTeamId };

        try {
            await movePlayer({ ...moveRequest, sessionId }).unwrap();
            dispatch(showPopup({ title: 'Success', message: 'Player moved successfully' }));
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleCopyPlayerSubmit = async (newTeamIds, isSubstitute) => {
        setShowCopyModal(false);

        const copyRequest = { ...copyPlayerData, toTeamIds: newTeamIds, isSubstitute };

        try {
            await copyPlayer({ ...copyRequest, sessionId }).unwrap();
            dispatch(showPopup({ title: 'Success', message: 'Player copied successfully' }));
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnTogglePlayerStatus = async (memberId, teamId) => {
        try {
            await togglePlayerRosterStatus({ memberId, teamId, sessionId }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnDrawSubmit = async (seeds) => {
        const formatData = seeds.map(({ teamId, seed }, index) => {
            const formatSeed = seed && index + 1 < seed ? index + 1 : seed;

            return {
                teamId,
                seed: formatSeed || index + 1,
            };
        });

        const filteredData = formatData.filter((item) => item.teamId);

        try {
            if (filteredData.length > 0) {
                await createTeamSeeds({ scheduleId, sessionId, data: { teamseeds: filteredData } }).unwrap();
            }
            if (!drawSchedule) {
                await onCreateSchedule();
            }
            await resetDrawCache();
            handleDrawCreateClick();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnAddTeams = async (requestBody) => {
        try {
            await addTeams({ sessionId, teamData: requestBody }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnDrawSkip = async () => {
        const mockTeam = {
            teamId: sessionTeams?.teams[0]?.id,
            seed: null,
        };

        try {
            await createTeamSeeds({ scheduleId, sessionId, data: { teamseeds: [mockTeam] } }).unwrap();
            await onCreateSchedule();
            await resetDrawCache();
            handleDrawCreateClick();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnChangeClick = async ({ selectedTeam, teamMatch }) => {
        const { teamMatchId } = teamMatch;
        const { position1Team, position2Team } = teamMatch;
        const { id, position } = selectedTeam;
        const formatId = !id || id === BYE_ROUND || id === null || id === undefined ? null : id;
        const hasBye = id === BYE_ROUND;

        let teamMatchPositions;
        switch (position) {
            case positions.ONE: {
                const position2TeamId = position2Team?.teamId || null;
                const hasNoByeRound = !position2TeamId && !formatId;

                teamMatchPositions = {
                    position1TeamId: formatId,
                    position2TeamId,
                    byeStatusTypeId: hasBye && !hasNoByeRound ? SINGLE_ROUND_BYE : null,
                };
                break;
            }

            case positions.TWO: {
                const position1TeamId = position1Team?.teamId || null;
                const hasNoByeRound = !position1TeamId && !formatId;

                teamMatchPositions = {
                    position1TeamId,
                    position2TeamId: formatId,
                    byeStatusTypeId: hasBye && !hasNoByeRound ? SINGLE_ROUND_BYE : null,
                };
                break;
            }
            default:
                teamMatchPositions = {
                    position1TeamId: null,
                    position2TeamId: null,
                    byeStatusTypeId: null,
                };
                break;
        }

        try {
            await updateTeamMatchPositions({ teamMatchId, teamMatchPositions, scheduleId, sessionId });
            await refetchSessionSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnConfirmPublishSchedule = async ({ isNotifyingTeams }) => {
        const publishData = {
            notifyTeams: isNotifyingTeams,
        };

        try {
            await onPublishSchedule(publishData);
            await resetDrawCache();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }

        setShowPublishPopup(false);
    };

    const handleOnConfirmDeleteSchedule = async () => {
        try {
            await onDeleteSchedule();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
        setShowDeletePopup(false);
    };

    const handleOnUnpublishSchedule = async () => {
        try {
            await onUnpublishSchedule();
            await resetDrawCache();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
        setShowUnpublishPopup(false);
    };

    const { hasSessionStarted, hasSessionEnded, isMultiRoundSession } = sessionDetails;
    const dateFormat = 'MMM Do, YYYY';
    const startDrawLabel = session?.startDateUtc && getAdjustedDate({ dateUtc: session?.startDateUtc, format: dateFormat });
    const endDrawLabel = session?.startDateUtc && getAdjustedDate({ dateUtc: session?.endDateUtc, format: dateFormat });

    const isDrawDisabled = session?.sessionStatusTypeId === OPEN || sessionTeams?.teams?.length === 0;
    const hasPostedResults = session?.hasPostedResults;

    const handleOnTabChanged = (_, newValue) => {
        setActiveTab(newValue);
    };

    const handleOnBreadcrumbUpdated = () => {
        setShouldUpdateBreadcrumb(false);
    };

    return (
        <Container disableGutters maxWidth={false} sx={{ backgroundColor: theme.appColors.coolGrey }}>
            {isLoadingSession ? (
                <LoadingIndicator />
            ) : (
                <>
                    <ManageSessionPageHeader
                        tab={activeTab}
                        sessionName={session?.name}
                        onTabChange={handleOnTabChanged}
                        isMultiRoundSession={isMultiRoundSession}
                        context={SESSION}
                        shouldUpdateBreadcrumb={shouldUpdateBreadcrumb}
                        onBreadcrumbUpdated={handleOnBreadcrumbUpdated}
                        contextId={sessionId}
                    />
                    <Container maxWidth="lg">
                        <TabContext value={activeTab}>
                            <TabPanel value={TEAMS} sx={{ backgroundColor: theme.appColors.coolGrey }}>
                                <TeamsTab
                                    teams={sessionTeams?.teams}
                                    maxTeamsCount={session?.teamsMax}
                                    sessionTypeId={session?.sessionTypeId}
                                    hasSessionStarted={hasSessionStarted || session?.hasPublishedSchedule}
                                    hasSessionEnded={hasSessionEnded}
                                    isLoading={isLoadingTeams}
                                    onRemoveTeam={handleOnRemoveTeam}
                                    onAddTeamClick={handleOnAddTeam}
                                />
                            </TabPanel>
                            <TabPanel value={DRAW}>
                                <DrawTab
                                    onDrawCreateClick={handleDrawCreateClick}
                                    onDrawEditClick={handleOnDrawEditClick}
                                    onDrawPublishClick={handleOnPublishClick}
                                    onDrawUnpublishClick={handleOnDrawUnpublishClick}
                                    onDrawDeleteClick={handleOnDrawDeleteClick}
                                    isLoadingSeedsTable={isLoadingSeedsTable}
                                    hasPublishPrivileges={drawSchedule?.hasPublishPrivileges}
                                    isDrawDisabled={isDrawDisabled}
                                    hasPostedResults={hasPostedResults}
                                    sessionId={sessionId}
                                    hasPublishedSchedule={session?.hasPublishedSchedule}
                                    selectableTeams={sessionTeams?.teams}
                                    descriptionText={`${session?.name} | ${startDrawLabel} - ${endDrawLabel}`}
                                    isLoading={isLoadingSeedsTable || isPublishingSchedule || isUpdatingTeamMatchLocation}
                                    schedule={drawSchedule}
                                    onEditMatch={handleOnEditMatch}
                                    onViewMatch={handleOnViewMatch}
                                    onChangeClick={handleOnChangeClick}
                                    pageType={MANAGE_SESSION_PAGE_TYPE}
                                />
                            </TabPanel>
                            <TabPanel value={SCHEDULE}>
                                <ScheduleTab
                                    isLoadingSession={isLoadingSession}
                                    session={session}
                                    teams={sessionTeams?.teams}
                                    maxTeamsCount={session?.teamsMax}
                                    onAddTeamClick={handleOnAddTeam}
                                    hasSessionEnded={hasSessionEnded}
                                />
                            </TabPanel>
                            <TabPanel key={PLAYERS} value={PLAYERS} sx={{ p: 0 }}>
                                <PlayersTab
                                    sessionId={sessionId}
                                    hasSessionEnded={hasSessionEnded}
                                    isManaging
                                    isUpdating={isUpdatingTeamPlayers}
                                    onWithdrawPlayer={handleOnWithdrawPlayer}
                                    onMovePlayer={handleOnMovePlayer}
                                    onCopyPlayer={handleOnCopyPlayer}
                                    onTogglePlayerStatus={handleOnTogglePlayerStatus}
                                />
                            </TabPanel>
                            <TabPanel value={DETAILS}>
                                <DetailsTab sessionDetails={session} />
                            </TabPanel>
                        </TabContext>
                    </Container>
                </>
            )}
            <PlayerWithdrawModal
                canBeRefunded={canPlayerBeRefunded}
                open={showWithdrawModal}
                onClose={handleOnCloseWithdrawModal}
                onSubmit={handleWithdrawSubmit}
            />
            {showDrawModal && (
                <CreateDrawModal
                    open={showDrawModal}
                    selectableTeams={sessionTeams?.teams}
                    onClose={handleDrawCreateClick}
                    onSubmit={handleOnDrawSubmit}
                    onSkip={handleOnDrawSkip}
                />
            )}
            <MovePlayerModal
                isLoadingAvailableTeams={isLoadingAvailableTeams}
                availableTeams={availablePlayerTeams}
                currentTeamId={movePlayerData.fromTeamId}
                open={showMoveModal}
                onClose={handleOnCloseMoveModal}
                onSubmit={handleMovePlayerSubmit}
            />
            <ConfirmPublishSchedulePopup
                onAction={handleOnConfirmPublishSchedule}
                actionLabel="Publish Draw"
                buttonLabel="Cancel"
                open={showPublishPopup}
                onClose={handleOnClosePublishPopup}
                title="Confirm publish of the draw"
                message={publishPopupMessage}
                checkboxLabel="Notify teams that the draw has been published"
            />
            <Popup
                onAction={handleOnUnpublishSchedule}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showUnpublishPopup}
                onClose={() => setShowUnpublishPopup(false)}
                title="Unpublish Draw"
                message={unpublishPopupMessage}
            />
            <Popup
                onAction={handleOnConfirmDeleteSchedule}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showDeletePopup}
                onClose={() => setShowDeletePopup(false)}
                title="Delete Draw"
                message={deletePopupMessage}
            />
            <CopyPlayerModal
                isLoadingAvailableTeams={isLoadingAvailableTeams}
                availableTeams={availablePlayerTeams}
                currentTeamId={copyPlayerData.fromTeamId}
                open={showCopyModal}
                onClose={handleOnCloseCopyModal}
                onSubmit={handleCopyPlayerSubmit}
            />
            <MatchEditModal
                isOpen={showEditMatchModal}
                onClose={() => setShowEditMatchModal(false)}
                onSubmit={handleOnEditSubmit}
                match={matchToUpdate}
                allowTeamSwap
            />
            {showAddTeamModal && (
                <AddTeamContainer
                    currentSessionTeams={sessionTeams?.teams}
                    sessionId={sessionId}
                    isOpen={showAddTeamModal}
                    leagueId={leagueId}
                    maxTeams={session?.teamsMax}
                    conferenceId={selectedConferenceId}
                    teamCount={sessionTeams?.teams?.length}
                    onClose={handleOnCloseAddTeamModal}
                    onAddTeams={handleOnAddTeams}
                />
            )}
        </Container>
    );
};

export default ManageSessionPage;
