import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography } from '@universal-tennis/ui-shared';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';

import FullPageModal from './FullPageModal';
import Input from '../Form/Input';
import { SharedUICategories, TypographySizes } from '../../utils/constants';

const MessagePlayersModal = ({ isOpen, onMessageSubmitted, onRequestClose }) => {
    const { SECONDARY } = SharedUICategories;
    const { SMALL_BOOK } = TypographySizes;
    const [shouldIncludeCaptains, setShouldIncludeCaptains] = useState(false);
    const defaultValues = { message: '' };
    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    const clearForm = () => {
        reset({ message: '' });
        setShouldIncludeCaptains(false);
    };

    const handleOnSubmit = (formValues) => {
        onMessageSubmitted(formValues.message, shouldIncludeCaptains);
        clearForm();
    };

    const handleOnRequestClose = () => {
        onRequestClose();
        clearForm();
    };

    const handleOnCheckboxChange = (event) => {
        setShouldIncludeCaptains(event.target.checked);
    };

    const handleOnSaveClick = () => {
        handleSubmit(handleOnSubmit)();
    };

    return (
        <FullPageModal
            isOpen={isOpen}
            title="Email all players"
            ctaTitle="Save"
            onClose={handleOnRequestClose}
            onCtaClick={handleOnSaveClick}
            isCtaButtonDisabled={!dirtyFields?.message}
        >
            <Paper sx={{ p: 4, mt: 10 }}>
                <Typography mb={2} category={SECONDARY} size={SMALL_BOOK}>Send an email to all players on the team</Typography>
                <Input control={control} name="message" multiline minRows={5} hasError={!!errors.message} label="Message" />
                <FormControlLabel
                    sx={{ mt: 2 }}
                    control={<Checkbox checked={shouldIncludeCaptains} onChange={handleOnCheckboxChange} name="message captains" />}
                    label={<Typography category={SECONDARY} size={SMALL_BOOK}>Include captains</Typography>}
                />
            </Paper>
        </FullPageModal>
    );
};

export default MessagePlayersModal;
