import React from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material';
import { Button, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';

import { getAgeKeys, ageDisplay, getFormattedConferenceRange } from '../../../utils/display';
import { getTypeForId, genderTypes, SportTypeIds } from '../../../utils/types';
import { teamProfileInfoPropTypes } from '../../../utils/proptypes';

const TeamProfileInfoCard = ({ teamData, registrationFees, onJoinClick, onShareClick, isRegistrationAvailable }) => {
    const theme = useTheme();
    const { conference, homeCourtLocation, sessionSummary } = teamData;
    const { minAge, maxAge, genderTypeId, conferenceRatings, sportTypeId } = conference || {};
    const { sessionEntryFee, total } = registrationFees;
    const { TENNIS } = SportTypeIds;
    const ageKeys = getAgeKeys([minAge, maxAge]);
    const ageRange = ageDisplay(ageKeys);
    const ages = ageRange?.toLowerCase() === 'all' ? 'All ages' : ageRange;
    const isTennis = sportTypeId === TENNIS;
    const rangeTitle = isTennis ? 'UTR Range' : 'UTR-P Range';
    const range = getFormattedConferenceRange({ conferenceRatings, sportTypeId, showRatingPrefix: false });
    const gender = getTypeForId(genderTypeId, genderTypes);
    const location = homeCourtLocation?.formattedAddress ? `${homeCourtLocation?.formattedAddress}` : '';
    const cost = sessionEntryFee === total ? `$${total}` : `$${sessionEntryFee}-$${total}`;
    const today = moment.utc();
    const isTodayAfterSessionEndDate = today.isAfter(sessionSummary?.endDateUtc, 'day');
    const isRegistrationDisabled = !isRegistrationAvailable || isTodayAfterSessionEndDate;

    return (
        <Card sx={{ borderRadius: 0, boxShadow: 'none' }}>
            <CardContent>
                <Box mb={1.5}>
                    <Grid container mb={1}>
                        <Grid item xs={4}>
                            <Typography category="secondary" size="xx-small-medium-cap" color={theme.appColors.blueGrey}>
                                Cost
                            </Typography>
                            <Typography category="primary" size="medium-book">
                                {cost}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography category="secondary" size="xx-small-medium-cap" color={theme.appColors.blueGrey}>
                                Gender
                            </Typography>
                            <Typography category="primary" size="medium-book">
                                {gender?.description}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography category="secondary" size="xx-small-medium-cap" color={theme.appColors.blueGrey}>
                                Age Range
                            </Typography>
                            <Typography category="primary" size="medium-book">
                                {ages}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography category="secondary" size="xx-small-medium-cap" color={theme.appColors.blueGrey}>
                                {rangeTitle}
                            </Typography>
                            <Typography category="primary" size="medium-book">
                                {range}
                            </Typography>
                        </Grid>
                        {location && (
                            <Grid item xs={8}>
                                <Typography category="secondary" size="xx-small-medium-cap" color={theme.appColors.blueGrey}>
                                    Location
                                </Typography>
                                <Typography category="primary" size="medium-book">
                                    {location}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box display="flex" flexDirection="column">
                    <LoadingButton
                        disabled={isRegistrationDisabled}
                        size="large"
                        variant="dark"
                        onClick={onJoinClick}
                        loadingIndicator={<CircularProgress color="primary" size={20} />}
                        sx={{ mb: 2 }}
                    >
                        Join This Team
                    </LoadingButton>
                    <Typography category="secondary" size="x-small-book" mb={0.5}>
                        Contact a captain to know more
                    </Typography>
                    <Button onClick={onShareClick} category="link">
                        <Typography category="secondary" size="x-small-book">
                            Share this team
                        </Typography>
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

TeamProfileInfoCard.propTypes = {
    ...teamProfileInfoPropTypes,
};

export default TeamProfileInfoCard;
