import * as queryString from 'query-string';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../redux/services/baseQuery';
import { WithdrawTypes } from '../utils/types';

import { getLeaguesApiAxiosInstance, getApiAxiosInstance } from '../utils/network';

const leaguesApiAxiosInstance = getLeaguesApiAxiosInstance();
const mainApiAxiosInstance = getApiAxiosInstance();

const { REFUND } = WithdrawTypes;

export const getBreadcrumbs = (context, contextId) => {
    const url = `/v1/leagues/breadcrumbs?type=${context}&id=${contextId}`;
    return leaguesApiAxiosInstance.get(url);
};

export const getUser = () => {
    return mainApiAxiosInstance.get(`v1/auth/current`);
};

export const getPreferences = () => {
    return mainApiAxiosInstance.get(`v1/preferences/feed`)
};

export const getActiveLeagueTeams = () => {
    const url = '/v2/leagues/member/teams?activeOnly=true';
    return leaguesApiAxiosInstance.get(url);
};

export const getTeamsForSession = (sessionId, parameters) => {
    const url = `/v1/leagues/sessions/${sessionId}/teamsforcopying`;
    return leaguesApiAxiosInstance.get(url, {
        params: parameters,
        paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'repeat' }),
    });
};

export const searchLeagueSessions = (axiosInstance, requestBody) => {
    const url = `v4/search/leagues/sessions`;
    return axiosInstance.post(url, requestBody);
};

export const scrollLeagueSessions = (axiosInstance, requestBody) => {
    const url = `v4/search/leagues/sessions/scroll`;
    return axiosInstance.post(url, requestBody);
};

export const leaguesApi = createApi({
    reducerPath: 'leaguesApi',
    baseQuery: axiosBaseQuery({
        baseUrl: '',
    }),
    tagTypes: [
        'league-summary',
        'conference',
        'conference-sessions',
        'league-fees',
        'conference-summary',
        'session',
        'session-summary',
        'session-teams',
        'session-schedule',
        'session-standings',
        'team',
        'team-summary',
        'team-schedule',
        'players',
        'registration-fees',
        'schedule',
        'match-summary',
        'player-matches',
        'member-teams',
        'player-available-teams',
        'league-conferences',
        'session-names',
        'user-leagues',
        'team-match-rosters',
        'team-match-lineups',
        'billing-countries',
        'session-schedule-by-date'
    ],
    endpoints: (build) => ({
        // GET
        getLeagueSummary: build.query({
            query: (leagueId) => ({ url: `/v1/leagues/${leagueId}/summary`, method: 'GET' }),
            providesTags: (result, error, leagueId) => [{ type: 'league-summary', id: leagueId }],
        }),
        getConference: build.query({
            query: (conferenceId) => ({ url: `/v2/leagues/conferences/${conferenceId}`, method: 'GET' }),
            providesTags: (result, error, conferenceId) => [{ type: 'conference', id: conferenceId }],
        }),
        getConferenceSessions: build.query({
            query: ({ conferenceId, pageNumber, pageSize }) => ({
                url: `/v1/leagues/conferences/${conferenceId}/sessions?pageNumber=${pageNumber}&pageSize=${pageSize}`,
                method: 'GET',
            }),
            providesTags: (result, error, conferenceId) => [{ type: 'conference-sessions', id: conferenceId }],
        }),
        getLeagueFees: build.query({
            query: (conferenceId) => ({ url: `/v1/leagues/conferences/${conferenceId}/leaguefees`, method: 'GET' }),
            providesTags: (result, error, conferenceId) => [{ type: 'league-fees', id: conferenceId }],
        }),
        getConferenceSummary: build.query({
            query: (conferenceId) => ({
                url: `/v1/leagues/conferences/${conferenceId}/summary`,
                method: 'GET',
            }),
            providesTags: (result, error, conferenceId) => [{ type: 'conference-summary', id: conferenceId }],
        }),
        getSession: build.query({
            query: (sessionId) => ({ url: `/v1/leagues/sessions/${sessionId}`, method: 'GET' }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, sessionId) => [{ type: 'session', id: sessionId }],
        }),
        getSessionSummary: build.query({
            query: (sessionId) => ({ url: `/v2/leagues/sessions/${sessionId}/summary`, method: 'GET' }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, sessionId) => [{ type: 'session-summary', id: sessionId }],
        }),
        getSessionTeams: build.query({
            query: (sessionId) => ({ url: `/v1/leagues/sessions/${sessionId}/teams`, method: 'GET' }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, sessionId) => [{ type: 'session-teams', id: sessionId }],
        }),
        getSessionSchedule: build.query({
            query: ({ sessionId, showDraftSchedule }) => ({
                url: `/v2/leagues/sessions/${sessionId}/schedule?showDraftSchedules=${showDraftSchedule}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, arg) => [{ type: 'session-schedule', id: arg.sessionId }],
        }),
        getSessionScheduleByDate: build.query({
            query: ({ sessionId, showDraftSchedule }) => ({
                url: `/v1/leagues/sessions/${sessionId}/scheduleByDate?showDraftSchedules=${showDraftSchedule}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, arg) => [{ type: 'session-schedule-by-date', id: arg.sessionId }],
        }),
        getSessionStandings: build.query({
            query: (sessionId) => ({ url: `/v1/leagues/sessions/${sessionId}/teamstandings`, method: 'GET' }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, sessionId) => [{ type: 'session-standings', id: sessionId }],
        }),
        getTeam: build.query({
            query: (teamId) => ({ url: `/v1/leagues/teams/${teamId}`, method: 'GET' }),
            providesTags: (result, error, teamId) => [{ type: 'team', id: teamId }],
        }),
        getTeamSummary: build.query({
            query: (teamId) => ({ url: `/v2/leagues/teams/${teamId}/summary`, method: 'GET' }),
            providesTags: (result, error, teamId) => [{ type: 'team-summary', id: teamId }],
        }),
        getTeamSchedule: build.query({
            query: (teamId) => ({ url: `/v1/leagues/teams/${teamId}/schedule`, method: 'GET' }),
            providesTags: (result, error, teamId) => [{ type: 'team-schedule', id: teamId }],
        }),
        getTeamScheduleByDate: build.query({
            query: (teamId) => ({ url: `/v1/leagues/teams/${teamId}/schedulebydate`, method: 'GET' }),
            providesTags: (result, error, teamId) => [{ type: 'team-schedule', id: teamId }],
        }),
        getRegistrationFees: build.query({
            query: (teamId) => ({ url: `/v1/leagues/teams/${teamId}/registration-fees`, method: 'GET' }),
            providesTags: ['registration-fees'],
        }),
        getPlayers: build.query({
            query: (teamId) => ({ url: `/v1/leagues/teams/${teamId}/members`, method: 'GET' }),
            transformResponse: (response) => {
                const players = response?.teamMembers?.map((member) => {
                    return { ...member, teamId: response?.teamId };
                });
                return players;
            },
            providesTags: (result, error, teamId) => [{ type: 'players', id: teamId }],
        }),
        getSchedule: build.query({
            query: (scheduleId) => ({ url: `/v1/leagues/schedules/${scheduleId}`, method: 'GET' }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, scheduleId) => [{ type: 'schedule', id: scheduleId }],
        }),
        getMatchSummary: build.query({
            query: (teamMatchId) => ({ url: `/v1/leagues/teammatches/${teamMatchId}/summary`, method: 'GET' }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, teamMatchId) => [{ type: 'match-summary', id: teamMatchId }],
        }),
        getPlayerMatches: build.query({
            query: (teamMatchId) => ({ url: `/v1/leagues/teammatches/${teamMatchId}/playermatches`, method: 'GET' }),
            keepUnusedDataFor: 0,
            providesTags: (result, error, teamMatchId) => [{ type: 'player-matches', id: teamMatchId }],
        }),
        getLatestTeamMatchUpdate: build.query({
            query: ({ teamMatchId, timestamp }) => {
                let pollingUrl;
                if (timestamp) {
                    pollingUrl = `/v1/leagues/teammatches/${teamMatchId}/latest?timestamp=${timestamp}`;
                } else {
                    pollingUrl = `/v1/leagues/teammatches/${teamMatchId}/latest`;
                }
                return { url: pollingUrl, method: 'GET' };
            },
        }),
        getMemberTeams: build.query({
            query: () => ({ url: `/v2/leagues/member/teams`, method: 'GET' }),
            providesTags: () => [{ type: 'member-teams' }],
        }),
        getPlayersAvailableTeams: build.query({
            query: ({ sessionId, memberId }) => ({ url: `/v1/leagues/session/${sessionId}/playeravailableteams?memberId=${memberId}`, method: 'GET' }),
            providesTags: (result, error, arg) => [{ type: 'player-available-teams', id: arg.memberId }],
            keepUnusedDataFor: 0,
        }),
        getLeagueConferences: build.query({
            query: (leagueId) => ({ url: `/v1/leagues/${leagueId}/conferences`, method: 'GET' }),
            providesTags: (result, error, leagueId) => [{ type: 'league-conferences', id: leagueId }],
        }),
        getSessionNames: build.query({
            query: (conferenceId) => ({ url: `/v1/leagues/conferences/${conferenceId}/sessionnames`, method: 'GET' }),
            providesTags: (result, error, conferenceId) => [{ type: 'session-names', id: conferenceId }],
        }),
        getUserLeagues: build.query({
            query: () => ({ url: `/v1/leagues/my-leagues`, method: 'GET' }),
            providesTags: () => [{ type: 'user-leagues' }],
        }),
        getTeamMatchRosters: build.query({
            query: (teamMatchId) => ({ url: `/v1/leagues/teammatches/${teamMatchId}/rosters`, method: 'GET' }),
            providesTags: (result, error, teamMatchId) => [{ type: 'team-match-rosters', id: teamMatchId }],
        }),
        getTeamMatchLineups: build.query({
            query: (teamMatchId) => ({ url: `/v1/leagues/teammatches/${teamMatchId}/lineups`, method: 'GET' }),
            providesTags: (result, error, teamMatchId) => [{ type: 'team-match-lineups', id: teamMatchId }],
        }),
        getBillingCountries: build.query({
            query: () => ({ url: `/v1/leagues/billingcountries`, method: 'GET' }),
            transformResponse: (response) => {
                const formattedCountries = response.map((country) => {
                    return { id: country.value, description: country.label };
                });
                return formattedCountries;
            },
            providesTags: () => [{ type: 'billing-countries' }],
        }),

        // POST

        createConference: build.mutation({
            query: (conference) => ({ url: `/v1/leagues/conferences`, method: 'POST', data: conference }),
            invalidatesTags: (result, error, arg) => [{ type: 'league-summary', id: arg.leagueId }],
        }),
        addOrganizer: build.mutation({
            query: ({ conferenceId, organizer }) => ({
                url: `/v1/leagues/conferences/${conferenceId}/organizers`,
                method: 'POST',
                data: organizer,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'conference', id: arg.conferenceId }],
        }),
        createSession: build.mutation({
            query: (session) => ({ url: `/v1/leagues/sessions`, method: 'POST', data: session }),
            invalidatesTags: (result, error, arg) => [{ type: 'conference-sessions', id: arg.conferenceId }],
        }),
        initiateRegistration: build.mutation({
            query: (registrationData) => ({ url: `/v1/leagues/sessions/initiate-registration`, method: 'POST', data: registrationData }),
        }),
        requestToCaptain: build.mutation({
            query: (sessionId) => ({ url: `/v1/leagues/sessions/${sessionId}/captainrequests`, method: 'POST' }),
            invalidatesTags: (result, error, arg) => [{ type: 'session-summary', id: arg.sessionId }],
        }),
        createEmptySchedule: build.mutation({
            query: (sessionId) => ({ url: `/v2/leagues/sessions/${sessionId}/schedules/create-empty`, method: 'POST' }),
            invalidatesTags: (result, error, arg) => [{ type: 'session-schedule', id: arg.sessionId }],
        }),
        createSchedule: build.mutation({
            query: ({ sessionId, scheduleId }) => ({ url: `/v1/leagues/sessions/${sessionId}/schedules`, method: 'POST' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'schedule', id: arg.scheduleId },
                { type: 'session-schedule', id: arg.sessionId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-summary', id: arg.sessionId },
                { type: 'session-standings', id: arg.sessionId },
                { type: 'session-teams', id: arg.sessionId },
            ],
        }),
        createTeamSeeds: build.mutation({
            query: ({ sessionId, data, scheduleId }) => ({ url: `/v1/leagues/sessions/${sessionId}/teamseeds`, method: 'POST', data }),
            invalidatesTags: (result, error, arg) => [
                { type: 'schedule', id: arg.scheduleId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-schedule', id: arg.sessionId },
                { type: 'session-summary', id: arg.sessionId },
                { type: 'session-standings', id: arg.sessionId },
                { type: 'session-teams', id: arg.sessionId },
            ],
        }),
        createTeam: build.mutation({
            query: (team) => ({ url: `/v1/leagues/teams`, method: 'POST', data: team }),
            invalidatesTags: (result, error, arg) => [{ type: 'session-teams', id: arg.sessionId }],
        }),
        addCaptain: build.mutation({
            query: ({ teamId, captain }) => ({ url: `/v1/leagues/teams/${teamId}/captains`, method: 'POST', data: captain }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team', id: arg.teamId },
                { type: 'team-summary', id: arg.teamId },
            ],
        }),
        messageTeamPlayers: build.mutation({
            query: ({ teamId, message, includeCaptains }) => ({
                url: `/v1/leagues/teams/${teamId}/message`,
                method: 'POST',
                data: { message, includeCaptains },
            }),
        }),
        validateRegistrationPin: build.mutation({
            query: ({ teamId, pin }) => ({ url: `/v1/leagues/teams/${teamId}/validate-registration-pin`, method: 'POST', data: { pin } }),
        }),
        publishSchedule: build.mutation({
            query: ({ scheduleId, sessionId, data }) => ({ url: `/v1/leagues/schedules/${scheduleId}/publish`, method: 'POST', data }),
            invalidatesTags: (result, error, arg) => [
                { type: 'schedule', id: arg.scheduleId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-summary', id: arg.sessionId },
                { type: 'session-standings', id: arg.sessionId },
                { type: 'session-teams', id: arg.sessionId },
                { type: 'session-schedule', id: arg.sessionId },
            ],
        }),
        postPlayerMatchResult: build.mutation({
            query: ({ playerMatchId, playerMatch, teamMatchId }) => ({
                url: `/v1/leagues/playermatches/${playerMatchId}/playermatchresult`,
                method: 'POST',
                data: playerMatch,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'player-matches', id: arg.teamMatchId }],
        }),
        requestToJoinSession: build.mutation({
            query: (sessionId) => ({ url: `/v1/leagues/sessions/${sessionId}/joinrequests`, method: 'POST' }),
            invalidatesTags: (result, error, arg) => [{ type: 'session-summary', id: arg.sessionId }],
        }),
        copyTeamsToSession: build.mutation({
            query: ({ sessionId, teamData }) => ({ url: `/v1/leagues/sessions/${sessionId}/copyteams`, method: 'POST', data: teamData }),
            invalidatesTags: (result, error, arg) => [{ type: 'session-teams', id: arg.sessionId }],
        }),
        validateUsapMembership: build.mutation({
            query: ({ sessionId, usapMemberId }) => ({ url: `/v1/leagues/sessions/${sessionId}/validate-usap-member`, method: 'POST', data: { usapMemberId } })
        }),

        // PUT

        addScheduleRound: build.mutation({
            query: ({ scheduleId, newRound }) => ({ url: `/v1/leagues/schedules/${scheduleId}/add-round`, method: 'PUT', data: newRound }),
            invalidatesTags: (result, error, arg) => [{ type: 'schedule', id: arg.scheduleId },],
        }),
        addTeamMatch: build.mutation({
            query: ({ scheduleId, newMatch }) => ({ url: `/v1/leagues/schedules/${scheduleId}/add-teammatch`, method: 'PUT', data: newMatch }),
            invalidatesTags: (result, error, arg) => [{ type: 'schedule', id: arg.scheduleId },],
        }),
        updateConference: build.mutation({
            query: ({ conferenceId, conference, leagueId }) => ({
                url: `/v1/leagues/conferences/${conferenceId}`,
                method: 'PUT',
                data: conference,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'conference', id: arg.conferenceId },
                { type: 'league-summary', id: arg.leagueId },
            ],
        }),
        updateSession: build.mutation({
            query: ({ sessionId, session, conferenceId }) => ({ url: `/v1/leagues/sessions/${sessionId}`, method: 'PUT', data: session }),
            invalidatesTags: (result, error, arg) => [
                { type: 'session', id: arg.sessionId },
                { type: 'conference-sessions', id: arg.conferenceId },
            ],
        }),
        updateTeam: build.mutation({
            query: ({ teamId, team, sessionId }) => ({ url: `/v1/leagues/teams/${teamId}`, method: 'PUT', data: team }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team', id: arg.teamId },
                { type: 'session-teams', id: arg.sessionId },
                { type: 'team-summary', id: arg.teamId },
            ],
        }),
        updateUnpublishSchedule: build.mutation({
            query: ({ scheduleId, sessionId }) => ({ url: `/v1/leagues/schedules/${scheduleId}/unpublish`, method: 'PUT' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team', id: arg.teamId },
                { type: 'schedule', id: arg.scheduleId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-schedule', id: arg.sessionId },
                { type: 'session-summary', id: arg.sessionId },
                { type: 'session-standings', id: arg.sessionId },
                { type: 'session-teams', id: arg.sessionId },
            ],
        }),
        updatePlayerRegistration: build.mutation({
            query: ({ sessionId, teamId, withdrawRequest, withDrawType }) => ({
                url: `/v1/leagues/teams/${teamId}/update-registration`,
                method: 'PUT',
                data: withdrawRequest,
            }),
            invalidatesTags: (result, error, arg) => {
                let tags = [
                    { type: 'team', id: arg.teamId },
                    { type: 'session-teams', id: arg.sessionId },
                ];
                if (arg.withDrawType !== REFUND) {
                    tags = [...tags, { type: 'players', id: arg.teamId }];
                }
                return tags;
            },
        }),
        updateTeamMatchSummary: build.mutation({
            query: ({ teamMatchId, sessionId, scheduleId, teamMatch }) => ({
                url: `/v1/leagues/teammatches/${teamMatchId}`,
                method: 'PUT',
                data: teamMatch,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'match-summary', id: arg.teamMatchId },
                { type: 'schedule', id: arg.scheduleId },
                { type: 'player-matches', id: arg.teamMatchId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-schedule', id: arg.sessionId },
            ],
        }),
        updateTeamMatchDateLocation: build.mutation({
            query: ({ teamMatchId, sessionId, scheduleId, data }) => ({
                url: `/v1/leagues/teammatches/${teamMatchId}/location-date`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'match-summary', id: arg.teamMatchId },
                { type: 'schedule', id: arg.scheduleId },
                { type: 'player-matches', id: arg.teamMatchId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-schedule', id: arg.sessionId },
            ],
        }),
        updateTeamMatchPositions: build.mutation({
            query: ({ teamMatchId, teamMatchPositions, scheduleId, sessionId }) => ({
                url: `/v1/leagues/teammatches/${teamMatchId}/positions`,
                method: 'PUT',
                data: teamMatchPositions,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'match-summary', id: arg.teamMatchId },
                { type: 'schedule', id: arg.scheduleId },
                { type: 'player-matches', id: arg.teamMatchId },
                { type: 'session', id: arg.sessionId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-summary', id: arg.sessionId },
                { type: 'session-standings', id: arg.sessionId },
            ],
        }),
        updatePlayerMatchResult: build.mutation({
            query: ({ playerMatchId, playerMatch, teamMatchId }) => ({
                url: `/v1/leagues/playermatches/${playerMatchId}/playermatchresult`,
                method: 'PUT',
                data: playerMatch,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'player-matches', id: arg.teamMatchId }],
        }),
        movePlayerToTeam: build.mutation({
            query: ({ memberId, fromTeamId, toTeamId, sessionId }) => ({
                url: `/v1/leagues/teammembers/move`,
                method: 'PUT',
                data: { memberId, fromTeamId, toTeamId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'member-teams' },
                { type: 'players', id: arg.fromTeamId },
                { type: 'players', id: arg.toTeamId },
                { type: 'session-teams', id: arg.sessionId },
                { type: 'team', id: arg.fromTeamId },
                { type: 'team', id: arg.toTeamId },
                { type: 'team-summary', id: arg.fromTeamId },
                { type: 'team-summary', id: arg.toTeamId },
                { type: 'player-available-teams', id: arg.memberId },
            ],
        }),
        copyPlayerToTeam: build.mutation({
            query: ({ memberId, fromTeamId, toTeamIds, isSubstitute, sessionId }) => ({
                url: `/v1/leagues/teammembers/copy`,
                method: 'PUT',
                data: { memberId, fromTeamId, toTeamIds, isSubstitute },
            }),
            invalidatesTags: (result, error, arg) => {
                const tags = [
                    { type: 'member-teams' },
                    { type: 'team-summary', id: arg.fromTeamId },
                    { type: 'players', id: arg.fromTeamId },
                    { type: 'session-teams', id: arg.sessionId },
                    { type: 'team', id: arg.fromTeamId },
                    { type: 'player-available-teams', id: arg.memberId },
                ];
                arg.toTeamIds.forEach((teamId) => {
                    const newTags = [
                        { type: 'players', id: teamId },
                        { type: 'team', id: teamId },
                        { type: 'team-summary', id: teamId },
                    ];
                    tags.push(...newTags);
                });
                return tags;
            },
        }),
        togglePlayerRosterStatus: build.mutation({
            query: ({ memberId, teamId, sessionId }) => ({
                url: `/v1/leagues/teammembers/toggle-substitute`,
                method: 'PUT',
                data: { memberId, teamId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'member-teams' },
                { type: 'team-summary', id: arg.teamId },
                { type: 'players', id: arg.teamId },
                { type: 'session-teams', id: arg.sessionId },
                { type: 'team', id: arg.teamId },
                { type: 'player-available-teams', id: arg.memberId },
            ],
        }),
        updatePlayerAvailabilityStatus: build.mutation({
            query: ({ teamMemberIds, playerAvailabilityStatusId, teamMatchId }) => ({
                url: `/v1/leagues/teammatches/${teamMatchId}/playeravailability`,
                method: 'PUT',
                data: { teamMemberIds, playerAvailabilityStatusId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team-match-rosters', id: arg.teamMatchId },
            ],
        }),
        updateTeamMatchLineups: build.mutation({
            query: ({ teamMatchId, lineups }) => ({
                url: `/v1/leagues/teammatches/${teamMatchId}/lineups`,
                method: 'PUT',
                data: lineups,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team-match-lineups', id: arg.teamMatchId },
                { type: 'player-matches', id: arg.teamMatchId },
            ],
        }),
        updatePlayerReminderStatus: build.mutation({
            query: ({ teamId, teamMemberIds, teamMatchId }) => ({
                url: `/v1/leagues/teammatches/${teamMatchId}/reminders`,
                method: 'PUT',
                data: { teamId, teamMemberIds, teamMatchId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team-match-rosters', id: arg.teamMatchId },
            ],
        }),
        updateTeamMatchLineupStatus: build.mutation({
            query: ({ teamMatchId, lineupStatusId, notifyPlayersWhenLocked }) => ({
                url: `/v1/leagues/teammatches/${teamMatchId}/setlineupstatus`,
                method: 'PUT',
                data: { lineupStatusId, notifyPlayersWhenLocked },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'player-matches', id: arg.teamMatchId },
            ],
        }),

        // DELETE

        removeOrganizer: build.mutation({
            query: ({ conferenceId, organizerId }) => ({
                url: `/v1/leagues/conferences/${conferenceId}/organizers/${organizerId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'conference', id: arg.conferenceId }],
        }),
        removeTeam: build.mutation({
            query: ({ teamId, sessionId }) => ({ url: `/v1/leagues/teams/${teamId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team', id: arg.teamId },
                { type: 'session-teams', id: arg.sessionId },
            ],
            keepUnusedDataFor: 0,
        }),
        removeCaptain: build.mutation({
            query: ({ teamId, captainId }) => ({ url: `/v1/leagues/teams/${teamId}/captains/${captainId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'team', id: arg.teamId },
                { type: 'team-summary', id: arg.teamId },
            ],
        }),
        removeSchedule: build.mutation({
            query: ({ scheduleId, sessionId }) => ({ url: `/v1/leagues/schedules/${scheduleId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'session-teams', id: arg.sessionId },
                { type: 'session', id: arg.sessionId },
                { type: 'session-schedule', id: arg.sessionId },
                { type: 'schedule', id: arg.scheduleId },
            ],
        }),
        removeSession: build.mutation({
            query: ({ sessionId, conferenceId }) => ({ url: `/v1/leagues/sessions/${sessionId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'conference-sessions', id: arg.conferenceId },
                { type: 'session-summary', id: arg.sessionId },
                { type: 'session', id: arg.sessionId },
            ],
        }),
        clearTeamMatch: build.mutation({
            query: ({teamMatchId}) => ({ url: `/v1/leagues/playermatches/${teamMatchId}/clear`, method: 'DELETE' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'player-matches', id: arg.teamMatchId },
                { type: 'team-match-lineups', id: arg.teamMatchId },
            ],
        }),
        removeScheduleRound: build.mutation({
            query: ({scheduleId, roundId}) => ({ url: `/v1/leagues/schedules/${scheduleId}/delete-round`, method: 'DELETE', data: { roundId }, }),
            invalidatesTags: (result, error, arg) => [
                { type: 'schedule', id: arg.scheduleId }
            ],
        }),
        removeTeamMatch: build.mutation({
            query: ({teamMatchId}) => ({ url: `/v1/leagues/teammatches/${teamMatchId}`, method: 'DELETE' }),
            invalidatesTags: (result, error, arg) => [
                { type: 'player-matches', id: arg.teamMatchId },
                { type: 'team-match-lineups', id: arg.teamMatchId },
            ],
        }),
    }),
});

export const {
    useAddScheduleRoundMutation,
    useAddTeamMatchMutation,
    useGetLeagueSummaryQuery,
    useGetConferenceQuery,
    useGetConferenceSessionsQuery,
    useGetLeagueFeesQuery,
    useGetConferenceSummaryQuery,
    useGetSessionQuery,
    useGetSessionSummaryQuery,
    useGetSessionTeamsQuery,
    useGetSessionScheduleQuery,
    useLazyGetSessionScheduleByDateQuery,
    useGetSessionStandingsQuery,
    useGetTeamQuery,
    useGetTeamSummaryQuery,
    useGetRegistrationFeesQuery,
    useGetPlayersQuery,
    useGetScheduleQuery,
    useGetMatchSummaryQuery,
    useGetPlayerMatchesQuery,
    useGetTeamScheduleQuery,
    useLazyGetTeamScheduleByDateQuery,
    useGetLatestTeamMatchUpdateQuery,
    useGetMemberTeamsQuery,
    useGetPlayersAvailableTeamsQuery,
    useGetLeagueConferencesQuery,
    useGetSessionNamesQuery,
    useGetUserLeaguesQuery,
    useGetTeamMatchRostersQuery,
    useGetTeamMatchLineupsQuery,
    useGetBillingCountriesQuery,
    useCreateConferenceMutation,
    useAddOrganizerMutation,
    useCreateSessionMutation,
    useInitiateRegistrationMutation,
    useRequestToCaptainMutation,
    useCreateEmptyScheduleMutation,
    useCreateScheduleMutation,
    useCreateTeamSeedsMutation,
    usePublishScheduleMutation,
    useCreateTeamMutation,
    useAddCaptainMutation,
    useMessageTeamPlayersMutation,
    useValidateRegistrationPinMutation,
    usePostPlayerMatchResultMutation,
    useRequestToJoinSessionMutation,
    useCopyTeamsToSessionMutation,
    useValidateUsapMembershipMutation,
    useUpdateConferenceMutation,
    useUpdateSessionMutation,
    useUpdateTeamMutation,
    useUpdatePlayerRegistrationMutation,
    useUpdateTeamMatchSummaryMutation,
    useUpdateTeamMatchDateLocationMutation,
    useUpdateTeamMatchPositionsMutation,
    useUpdatePlayerMatchResultMutation,
    useUpdateUnpublishScheduleMutation,
    useMovePlayerToTeamMutation,
    useCopyPlayerToTeamMutation,
    useTogglePlayerRosterStatusMutation,
    useUpdatePlayerAvailabilityStatusMutation,
    useUpdateTeamMatchLineupsMutation,
    useUpdatePlayerReminderStatusMutation,
    useUpdateTeamMatchLineupStatusMutation,
    useRemoveOrganizerMutation,
    useRemoveTeamMutation,
    useRemoveCaptainMutation,
    useRemoveScheduleMutation,
    useRemoveSessionMutation,
    useClearTeamMatchMutation,
    useRemoveScheduleRoundMutation,
    useRemoveTeamMatchMutation
} = leaguesApi;
