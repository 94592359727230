import React, { useRef, useImperativeHandle } from 'react';

const StripeInput = React.forwardRef((props, inputRef) => {
    const elementRef = useRef();
    const { component: Component, ...rest } = props;
    useImperativeHandle(inputRef, () => ({
        focus: () => elementRef.current.focus,
    }));

    const handleOnReady = (element) => {
        elementRef.current = element;
    };

    return <Component onReady={handleOnReady} {...rest} />;
});

export default StripeInput;
