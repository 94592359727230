import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showErrorPopup } from '../redux/errorPopupSlice';
import { getTeamsForSession } from '../api/leaguesApi';

export const useGetTeamsForSessions = (sessionId, sessionIds, sortBy, sortOrder, pageNumber) => {
    const pageSize = 20;
    const dispatch = useDispatch();

    const [teamSearchResults, setTeamSearchResults] = useState([]);
    const [isFetchingResults, setIsFetchingResults] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const handleRefetch = () => {
        setRefetch(true);
    };

    const clearSearchResults = () => {
        setTotalPages(null);
        setTeamSearchResults([]);
    };

    useEffect(() => {
        const handleOnSubmit = async () => {
            setIsFetchingResults(true);

            const parameters = {
                fromSessionIds: sessionIds || [],
                orderBy: sortBy,
                orderByRule: sortOrder.id,
                pageNumber,
                pageSize,
            };

            const response = await getTeamsForSession(sessionId, parameters).catch((searchError) => {
                setIsFetchingResults(false);
                dispatch(showErrorPopup(searchError));
            });

            if (response?.data) {
                const { results, totalCount } = response.data;
                const pages = Math.ceil(totalCount / pageSize);

                setTotalPages(pages);
                setTeamSearchResults(results);
                setIsFetchingResults(false);
            }

            setRefetch(false);
        };

        if (refetch) {
            handleOnSubmit();
        }
    }, [refetch]);

    return { teamSearchResults, isFetchingResults, handleRefetch, clearSearchResults, totalPages };
};
