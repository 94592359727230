import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import LoadingIndicator from '../LoadingIndicator';
import ModalBaseWithHeader from './ModalBaseWithHeader';
import MultiSelect from '../Form/MultiSelect';

const CopyPlayerModal = ({ availableTeams, onClose, open, onSubmit, isLoadingAvailableTeams }) => {
    const [isSubstituteChecked, setIsSubstituteChecked] = useState(false);

    const defaultValues = { teamIds: [] };
    const {
        handleSubmit,
        formState,
        formState: { errors },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({ teamIds: [] });
            setIsSubstituteChecked(false);
        }
    }, [formState, reset]);

    const handleFormSubmit = (values) => {
        onSubmit(values.teamIds, isSubstituteChecked);
    };

    const handleOnCheckboxChange = (event) => {
        setIsSubstituteChecked(event.target.checked);
    };

    return (
        <ModalBaseWithHeader title="Copy Player" onClose={onClose} open={open} onSubmit={onSubmit}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent sx={{ p: 4 }} dividers>
                    {isLoadingAvailableTeams && <LoadingIndicator />}
                    {!!availableTeams.length && (
                        <>
                            <Typography align="center" gutterBottom marginBottom="24px">
                                Select one or more teams to copy the player to
                            </Typography>
                            <MultiSelect
                                name="teamIds"
                                label="Teams"
                                options={availableTeams}
                                labelId="team-label"
                                isFullWidth
                                control={control}
                                hasError={!!errors.team}
                                isRequired
                                helperText="Required"
                            />
                            <FormControlLabel
                                sx={{ mt: 2 }}
                                control={
                                    <Checkbox checked={isSubstituteChecked} onChange={handleOnCheckboxChange} name="substitute player" />
                                }
                                label="Substitute Player"
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="dark" autoFocus type="submit">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </ModalBaseWithHeader>
    );
};

export default CopyPlayerModal;
