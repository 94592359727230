import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorPopup } from '../../redux/errorPopupSlice';

import AddTeamsModal from '../Modals/AddTeamsModal';
import { useGetLeagueConferencesQuery, useGetSessionNamesQuery } from '../../api/leaguesApi';
import { useGetTeamsForSessions } from '../../hooks/useGetTeamsForSessions';
import { OrderByRuleTypes, OrderByTypes } from '../../utils/types';

const AddTeamContainer = ({ sessionId, leagueId, conferenceId, isOpen, onClose, maxTeams, teamCount, onAddTeams }) => {
    const dispatch = useDispatch();
    const { ASCENDING, DESCENDING } = OrderByRuleTypes;
    const { TEAM_NAME } = OrderByTypes;
    const [selectedConferenceId, setSelectedConferenceId] = useState(null);
    const [selectedSessionIds, setSelectedSessionIds] = useState([]);
    const [originalSessionIds, setOriginalSessionIds] = useState([]);
    const [sortOrder, setSortOrder] = useState(ASCENDING);
    const [sortByProperty, setSortByProperty] = useState(TEAM_NAME);
    const [sessionNames, setSessionNames] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const { data, isFetching: isLoadingConferences, error } = useGetLeagueConferencesQuery(leagueId, { skip: !isOpen });

    const {
        data: conferenceSessionNames,
        isFetching: isLoadingSessionNames,
        error: sessionNamesError,
    } = useGetSessionNamesQuery(selectedConferenceId, { skip: !isOpen || !selectedConferenceId });

    const fetchingError = error || sessionNamesError;
    const isLoading = isLoadingConferences || isLoadingSessionNames;

    const { teamSearchResults, isFetchingResults, handleRefetch, clearSearchResults, totalPages } = useGetTeamsForSessions(
        sessionId,
        selectedSessionIds,
        sortByProperty,
        sortOrder,
        pageNumber
    );

    if (fetchingError) {
        dispatch(showErrorPopup(fetchingError));
    }

    useEffect(() => {
        if (conferenceId) {
            setSelectedConferenceId(conferenceId);
        }
    }, [conferenceId]);

    useEffect(() => {
        if (conferenceSessionNames?.results?.length) {
            const filteredSessionNames = conferenceSessionNames.results.filter((result) => result.sessionId !== sessionId);
            const sessionIds = filteredSessionNames.map((session) => session.sessionId);
            setSessionNames(filteredSessionNames);

            if (sessionIds?.length) {
                setSelectedSessionIds(sessionIds);
                setOriginalSessionIds(sessionIds);
                handleRefetch();
            } else {
                setSelectedSessionIds([]);
                setOriginalSessionIds([]);
            }
        }
    }, [conferenceSessionNames]);

    useEffect(() => {
        if (sessionNames?.length && isOpen) {
            handleRefetch();
        }
    }, [sessionNames, isOpen]);

    const handleAddTeams = async (notifications, teamIds) => {
        onClose();
        const { notifyPlayers, notifyCaptains } = notifications;
        const requestBody = {
            sessionId,
            teamIds,
            notifyPlayers,
            notifyCaptains,
        };

        onAddTeams(requestBody);
    };

    const handleOnCloseAddTeamModal = () => {
        onClose();
    };

    const handleOnConferenceChange = (id) => {
        clearSearchResults();
        setSessionNames([]);
        setPageNumber(1);
        setSelectedConferenceId(id);
    };

    const handleOnSessionIdsChange = (sessionIds) => {
        setSelectedSessionIds(sessionIds);
    };

    const handleOnApplyClick = () => {
        setPageNumber(1);
        setOriginalSessionIds(selectedSessionIds);
        handleRefetch();
    };

    const handleOnPageChange = (page) => {
        setPageNumber(page);
        handleRefetch();
    };

    const handleOnCloseSessionDropdown = () => {
        setSelectedSessionIds(originalSessionIds);
    };

    const handleOnSortChange = (property) => {
        if (property === sortByProperty) {
            const sortDirection = sortOrder === ASCENDING ? DESCENDING : ASCENDING;
            setSortOrder(sortDirection);
        } else {
            setSortOrder(ASCENDING);
        }
        setSortByProperty(property);
        handleRefetch();
    };

    return (
        <AddTeamsModal
            conferences={data?.conferences}
            currentPage={pageNumber}
            currentTeamCount={teamCount}
            isLoadingOptions={isLoading}
            isLoadingTeams={isFetchingResults}
            isOpen={isOpen}
            maxTeams={maxTeams}
            onApplyClick={handleOnApplyClick}
            onClose={handleOnCloseAddTeamModal}
            onConferenceChange={handleOnConferenceChange}
            onPageChange={handleOnPageChange}
            onSessionChange={handleOnSessionIdsChange}
            onSortChange={handleOnSortChange}
            onAddTeams={handleAddTeams}
            onCloseSessionDropdown={handleOnCloseSessionDropdown}
            pageCount={totalPages}
            selectedConferenceId={selectedConferenceId}
            selectedSessionIds={selectedSessionIds}
            sessions={sessionNames}
            sortByProperty={sortByProperty}
            sortOrder={sortOrder.value}
            teams={teamSearchResults}
        />
    );
};

export default AddTeamContainer;
