import React from 'react';
import { connect } from 'react-redux';
import Rox from 'rox-browser';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Img from '../Img';
import BackgroundImage from '../BackgroundImage';
import withFeatureFlags from '../hocs/withFeatureFlags';
import { toggleSideMenu } from '../../redux/uiSlice';
import GlobalSearch from '../GlobalSearch';

import HomeIcon from '../../assets/icons/icon-home.svg';
import PlayIcon from '../../assets/icons/icon-play.svg';
import ProfileIcon from '../../assets/icons/icon-profile.svg';

import config from '../../config';
import styles from './header.module.scss';
import { getClickAttrs } from '../../utils/analytics';
import { headerPropTypes } from '../../utils/proptypes';

const Header = (props) => {
    const { auth, featureFlags } = props;
    const showHeaderItems = !!auth.user;

    const handleLogoClick = () => {
        if (process.env.REACT_APP_CONFIG_SOURCE === 'prod') {
            window.open(`${config.appHost}`);
        } else {
            Rox.showOverrides();
        }
    };

    return (
        <div className="navbar-wrapper">
            <nav className={`navbar navbar-expand-md fixed-top navbar-light ${styles.nav}`}>
                <Container maxWidth="lg">
                    <Box display="flex" flexWrap="wrap" alignItems="center">
                        <button
                            type="button"
                            className="navbar-brand"
                            onClick={handleLogoClick}
                            {...getClickAttrs({
                                category: 'Header',
                                location: 'Header CTA',
                                context: `UTR icon`,
                            })}
                        >
                            <Img src="/img/utr_sports/utr-sports-logo.svg" />
                        </button>
                        <div className="nav-search-wrapper d-flex align-items-center">
                            <GlobalSearch auth={auth} />
                            <button type="button" className={`${styles.hamburger} d-block d-lg-none`} onClick={props.onMenuClick}>
                                <span className="sr-only">Toggle Menu</span>
                                <div className="d-flex flex-column align-items-center mr8">
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            </button>
                        </div>
                        {!auth.user && (
                            <ul className={`${styles.nav} navbar-nav d-none d-lg-flex ml-auto`}>
                                <li className="nav-item">
                                    <a
                                        href={`${config.appHost}/login`}
                                        {...getClickAttrs({
                                            category: 'Sign In',
                                            location: 'Header CTA',
                                            context: 'Sign In button click',
                                        })}
                                    >
                                        SIGN IN
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`btn btn-primary text-uppercase ${styles.joinBtn}`}
                                        href={`${config.appHost}/join`}
                                        {...getClickAttrs({
                                            category: 'Sign Up',
                                            location: 'Header CTA',
                                            context: 'Join button click',
                                        })}
                                    >
                                        JOIN
                                    </a>
                                </li>
                            </ul>
                        )}
                        {auth.user && (
                            <>
                                <div className={`${styles.nav} navbar-nav d-none d-lg-flex`}>
                                    <div className={`${styles.navLink} d-none d-lg-block ${auth.user ? styles.authed : ''}`}>
                                        <a
                                            className="underline"
                                            href={`${config.appHost}/home`}
                                            {...getClickAttrs({
                                                category: 'Header',
                                                location: 'Header CTA',
                                                context: 'Home',
                                            })}
                                        >
                                            Home
                                        </a>
                                    </div>
                                    <div className={`${styles.navLink} d-none d-lg-block ${auth.user ? styles.authed : ''}`}>
                                        <a
                                            className="underline clickable"
                                            href={`${config.appHost}/explore`}
                                            {...getClickAttrs({
                                                category: 'Header',
                                                location: 'Header CTA',
                                                context: 'Explore',
                                            })}
                                        >
                                            Explore
                                        </a>
                                    </div>
                                    {featureFlags?.messaging?.isEnabled(auth.user) && (
                                        <div className={`${styles.navLink} d-none d-lg-block ${auth.user ? styles.authed : ''}`}>
                                            <a
                                                className="underline"
                                                href="/inbox"
                                                {...getClickAttrs({
                                                    category: 'Header',
                                                    location: 'Header CTA',
                                                    context: 'Messages',
                                                })}
                                            >
                                                Messages
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className={`${styles.nav} navbar-nav nav-bar-buttons d-none d-lg-flex ml-auto aic`}>
                                    <button
                                        className={`${styles.hamburger} d-none d-lg-block ${auth.user ? styles.authed : ''} ${styles.createPlay}`}
                                        type="button"
                                        onClick={() => props.toggleSideMenu()}
                                    >
                                        <span className="sr-only">Toggle Menu</span>
                                        <div className="d-flex flex-row align-items-center">
                                            <BackgroundImage
                                                baseUrl="/v1/player/"
                                                defaultImage={ProfileIcon}
                                                images={auth.user.claimedPlayer ? auth.user.claimedPlayer.playerProfileImages : null}
                                                imageType="thumbnail"
                                                parentStylesClass="avatar"
                                            />
                                        </div>
                                    </button>
                                </div>
                            </>
                        )}
                    </Box>
                </Container>
                {showHeaderItems && (
                    <div className="container d-block d-lg-none" style={{ position: 'relative' }}>
                        <div className="col d-flex justify-space-around align-items-center h100">
                            {showHeaderItems && (
                                <a
                                    className={styles.mobileNavLink}
                                    href={`${config.appHost}/home`}
                                    {...getClickAttrs({
                                        category: 'Header',
                                        location: 'Header CTA',
                                        context: 'Home',
                                    })}
                                >
                                    <img src={HomeIcon} alt="home" />
                                    <div className="title">Home</div>
                                </a>
                            )}
                            {showHeaderItems && (
                                <a
                                    className={styles.mobileNavLink}
                                    href={`${config.appHost}/explore`}
                                    {...getClickAttrs({
                                        category: 'Header',
                                        location: 'Header CTA',
                                        context: 'Explore',
                                    })}
                                >
                                    <img src={PlayIcon} alt="explore" />
                                    <div className="title">Explore</div>
                                </a>
                            )}
                            <button type="button" className={styles.mobileNavLink} onClick={() => props.toggleSideMenu()}>
                                <Img className="avatar" src="/img/v2/icons/icon-profile.svg" />
                                <div className="title">Menu</div>
                            </button>
                        </div>
                    </div>
                )}
            </nav>
        </div>
    );
};

const mapDispatchToProps = {
    toggleSideMenu,
};

function mapStateToProps({ auth }) {
    return { auth };
}

Header.propTypes = {
    ...headerPropTypes
};

export default withFeatureFlags(connect(mapStateToProps, mapDispatchToProps)(Header));
