import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { Button, AddIcon, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { showPopup } from '../../redux/genericPopupSlice';

import ManageSessionCaptainCard from '../Cards/ManageSessionCaptainCard';
import LoadingIndicator from '../LoadingIndicator';
import PlayerSearch from '../Form/PlayerSearch';
import Popup from '../Popups/Popup';
import { mapMembersForPlayerSearch } from '../../utils/helpers';
import { manageSessionTeamCaptainPropTypes } from '../../utils/proptypes';
import { TypographySizes, SharedUICategories } from '../../utils/constants';

const ManageSessionTeamCaptain = ({
    captains = [],
    isUpdatingCaptains = false,
    isLoadingTeam = false,
    onAddCaptain,
    onRemoveCaptain,
    isRequired = false,
    maxCaptains = null,
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { SMALL_BOOK } = TypographySizes;
    const { SECONDARY } = SharedUICategories;
    const [originalCaptains, setOriginalCaptains] = useState([]);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [showCaptainSearch, setShowCaptainSearch] = useState(false);
    const [isRemovingCaptain, setIsRemovingCaptain] = useState(false);
    const [captainAwaitingAction, setCaptainAwaitingAction] = useState();
    const minimumAmountOfCaptainsNeeded = 1;
    const maximumAmountOfCaptains = maxCaptains || 3;
    const maxCaptainsAdded = captains?.length === maximumAmountOfCaptains && captains[0] !== null;

    useEffect(() => {
        if (captains?.length > 0) {
            const mappedMembers = mapMembersForPlayerSearch(captains);
            setOriginalCaptains(mappedMembers);
        }
    }, [captains]);

    const isPermittedToRemoveCaptain = () => {
        if (captains?.length === minimumAmountOfCaptainsNeeded) {
            dispatch(
                showPopup({
                    isError: true,
                    title: 'Error',
                    message: 'There must always be at least one captain',
                })
            );
            return false;
        }

        return true;
    };

    const handleOnAddCaptain = (captain) => {
        setCaptainAwaitingAction(captain);
        setIsRemovingCaptain(false);
        setShowConfirmationPopup(true);
        setShowCaptainSearch(false);
    };

    const handleOnRemoveCaptain = (captain) => {
        setCaptainAwaitingAction(captain);
        setIsRemovingCaptain(true);
        setShowConfirmationPopup(true);
    };

    const handleOnConfirmRemoval = () => {
        setShowConfirmationPopup(false);

        const isAbleToRemoveCaptain = isPermittedToRemoveCaptain();
        if (isAbleToRemoveCaptain) {
            onRemoveCaptain(captainAwaitingAction);
        }
    };

    const handleOnConfirmAddition = () => {
        setShowConfirmationPopup(false);

        if (!maxCaptainsAdded) {
            onAddCaptain(captainAwaitingAction);
        }
    };

    const handleOnAddCaptainClick = () => {
        setShowCaptainSearch((prevStatus) => !prevStatus);
    };

    const handleOnClosePopup = () => {
        setShowConfirmationPopup(false);
    };

    const action = isRemovingCaptain ? 'Remove' : 'Add';
    const popupAction = isRemovingCaptain ? handleOnConfirmRemoval : handleOnConfirmAddition;
    const popupTitle = `${action} captain`;
    const popupMessage = `Are you sure you want to ${action?.toLowerCase()} ${captainAwaitingAction?.displayName} as a captain?`;
    const displayMaxCaptainsAddedText = maxCaptainsAdded;

    return isLoadingTeam ? (
        <LoadingIndicator />
    ) : (
        <>
            <Box my={2}>
                {isUpdatingCaptains ? (
                    <LoadingIndicator />
                ) : (
                    originalCaptains?.map((captain) => (
                        <ManageSessionCaptainCard key={captain.id} captain={captain} onRemoveClick={handleOnRemoveCaptain} />
                    ))
                )}
            </Box>
            {showCaptainSearch && (
                <Paper sx={{ p: 3, mb: 1, boxShadow: 'none' }}>
                    <PlayerSearch
                        onPlayerSelected={handleOnAddCaptain}
                        searchMembersOnly
                        selectedPlayers={originalCaptains}
                        label="Add a captain"
                        isRequired={isRequired}
                    />
                </Paper>
            )}
            {!maxCaptainsAdded && (
                <Button
                    onClick={handleOnAddCaptainClick}
                    sx={{ textTransform: 'none' }}
                    startIcon={<AddIcon size={16} color={theme.appColors.interactionBlue} />}
                >
                    <Typography
                        sx={{ color: theme.appColors.interactionBlue }}
                        category="secondary"
                        size="small-book"
                        color={theme.appColors.black}
                    >
                        Add a team captain
                    </Typography>
                </Button>
            )}
            <Popup
                onAction={popupAction}
                actionLabel="Confirm"
                buttonLabel="Cancel"
                open={showConfirmationPopup}
                onClose={handleOnClosePopup}
                title={popupTitle}
                message={popupMessage}
            />
            {displayMaxCaptainsAddedText && (
                <Typography category={SECONDARY} size={SMALL_BOOK}>
                    Max captains added
                </Typography>
            )}
        </>
    );
};

ManageSessionTeamCaptain.propTypes = {
    ...manageSessionTeamCaptainPropTypes,
};

export default ManageSessionTeamCaptain;
