import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';

import { StyledTooltip } from '../../../components/Styled';
import { SharedUICategories, TypographySizes, LineupStatusType, LineupButtonLabels, LineupButtonTooltipText } from '../../../utils/constants';

const LineupControls = ({ lineupData, canUserViewLineups, hasMatchDatePassed, onShowLineupModal, onLineupStatusChange }) => {
    const { LOCKED, PUBLISHED, DRAFT } = LineupStatusType;
    const { PUBLISH, UNPUBLISH, LOCK, UNLOCK } = LineupButtonLabels;
    const { PUBLISH_TOOLTIP, LOCK_TOOLTIP, UNPUBLISH_TOOLTIP, UNLOCK_TOOLTIP } = LineupButtonTooltipText;

    const [lineupStatusId, setLineupStatusId] = useState(false);
    const [showSetLinesButton, setShowLinesButton] = useState(false);
    const [showEditLinesButton, setShowEditLinesButton] = useState(false);
    const [showLockButton, setShowLockButton] = useState(false);
    const [showPublishButton, setShowPublishButton] = useState(false);
    const [isLineupStatusButtonsDisabled, setIsLineupStatusButtonsDisabled] = useState(false);
    const [isUserCaptain, setIsUserCaptain] = useState(false);
    const [lineupButtonLabels, setLineupButtonLabels] = useState({
        lockButton: LOCK,
        publishButton: PUBLISH,
        lockTooltip: LOCK_TOOLTIP,
        publishTooltip: PUBLISH_TOOLTIP
    });

    useEffect(() => {
        if (lineupData) {
            const { isTeamCaptain, numberOfSetLines, hasPostedScores, statusId, numberOfLines } = lineupData;
            const isDraftLineup = statusId === DRAFT;
            const isLockedLineup = statusId === LOCKED;
            const isCaptainWithNoScoresPosted = isTeamCaptain && !hasPostedScores;
            const isSetLinesButtonVisible = isCaptainWithNoScoresPosted && numberOfSetLines === 0;
            const isEditLinesButtonVisible = isCaptainWithNoScoresPosted && numberOfSetLines > 0 && isDraftLineup;
            const isLockButtonVisible = isCaptainWithNoScoresPosted && (isDraftLineup || isLockedLineup) && numberOfSetLines > 0;
            const isPublishButtonVisible = isCaptainWithNoScoresPosted && numberOfSetLines > 0;
            const isStatusButtonsDisabled = isTeamCaptain && isDraftLineup && numberOfSetLines < numberOfLines;
            const lockButtonLabel = isDraftLineup ? LOCK : UNLOCK;
            const lockTooltipText = isDraftLineup ? LOCK_TOOLTIP : UNLOCK_TOOLTIP;
            const publishButtonLabel = isDraftLineup || isLockedLineup ? PUBLISH : UNPUBLISH;
            const publishTooltipText = isDraftLineup || isLockedLineup ? PUBLISH_TOOLTIP : UNPUBLISH_TOOLTIP;

            setLineupStatusId(statusId);
            setShowLinesButton(isSetLinesButtonVisible);
            setShowEditLinesButton(isEditLinesButtonVisible);
            setShowLockButton(isLockButtonVisible);
            setShowPublishButton(isPublishButtonVisible);
            setIsUserCaptain(isTeamCaptain);
            setIsLineupStatusButtonsDisabled(isStatusButtonsDisabled);
            setLineupButtonLabels({
                lockButton: lockButtonLabel,
                publishButton: publishButtonLabel,
                lockTooltip: lockTooltipText,
                publishTooltip: publishTooltipText
            });
        }
    }, [lineupData]);

    const handleOnShowLineupModal = () => {
        onShowLineupModal();
    };

    const handleOnToggleLock = () => {
        let updatedStatus;

        if (lineupStatusId === DRAFT || lineupStatusId === PUBLISHED) {
            updatedStatus = LOCKED;
        } else {
            updatedStatus = DRAFT;
        }

        onLineupStatusChange(updatedStatus);
    };

    const handleOnTogglePublish = () => {
        let updatedStatus;

        if (lineupStatusId === DRAFT || lineupStatusId === LOCKED) {
            updatedStatus = PUBLISHED;
        } else if (lineupStatusId === PUBLISHED) {
            updatedStatus = LOCKED;
        }

        onLineupStatusChange(updatedStatus);
    };

    return (
        <Box>
            {showSetLinesButton && (
                <Box display="flex" justifyContent="center" alignContent="center">
                    <Button onClick={handleOnShowLineupModal} size="small" category="primary">Set Lines</Button>
                </Box>
            )}
            {isUserCaptain && (
                <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Box display="flex">
                        {showLockButton && (
                            isLineupStatusButtonsDisabled ? (
                                <Button category="primary" sx={{ mr: 1 }} disabled={isLineupStatusButtonsDisabled}>{lineupButtonLabels.lockButton}</Button>
                            ) : (
                                <StyledTooltip placement="top-start" title={lineupButtonLabels.lockTooltip} arrow>
                                    <Box>
                                        <Button category="primary" onClick={handleOnToggleLock} sx={{ mr: 1 }}>{lineupButtonLabels.lockButton}</Button>
                                    </Box>
                                </StyledTooltip>
                            )

                        )}
                        {showPublishButton && (
                            isLineupStatusButtonsDisabled ? (
                                <Button category="primary" disabled={isLineupStatusButtonsDisabled} onClick={handleOnTogglePublish}>{lineupButtonLabels.publishButton}</Button>
                            ) : (
                                <StyledTooltip placement="top" title={lineupButtonLabels.publishTooltip} arrow>
                                    <Box>
                                        <Button category="primary" onClick={handleOnTogglePublish}>{lineupButtonLabels.publishButton}</Button>
                                    </Box>
                                </StyledTooltip>
                            )
                        )}
                    </Box>
                    {showEditLinesButton && (
                        <Box ml="auto">
                            <Button onClick={handleOnShowLineupModal} size="large" category="secondary">Edit Lineups</Button>
                        </Box>
                    )}
                </Box>
            )}
            {!isUserCaptain && !canUserViewLineups && !hasMatchDatePassed && (
                <Box display="flex" justifyContent="center" alignContent="center">
                    <Typography category={SharedUICategories.SECONDARY} size={TypographySizes.SMALL_BOOK}>Lines have not been published</Typography>
                </Box>
            )}
        </Box>
    );
};

export default LineupControls;
