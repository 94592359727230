import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBreadcrumbs } from '../../api/leaguesApi';

export const getBreadcrumbData = createAsyncThunk('ui/getBreadcrumbData', async (data) => {
    const { context, contextId } = data || {};
    try {
        const response = await getBreadcrumbs(context, contextId);
        return response.data;
    } catch (err) {
        const customError = {
            name: 'Error loading breadcrumbs',
            message: err.response.data?.message || err.response.statusText,
        };
        throw customError;
    }
});
