import React, { useState } from 'react';

import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

const FilterButton = ({ buttonTitle, children, isFilterApplied, isOpen = false, onOpen, onClose }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        onOpen();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        onClose();
        setAnchorEl(null);
    };

    const id = isOpen ? 'button-popover' : undefined;

    return (
        <>
            <Button aria-describedby={id} variant={isFilterApplied ? 'filter-selected' : 'filter'} onClick={handleClick}>
                {buttonTitle}
            </Button>
            <Popover
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </>
    );
};

export default FilterButton;
