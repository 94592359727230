import React from 'react';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import LoadingIndicator from '../../../components/LoadingIndicator';
import ConferencesTable from '../../../components/Tables/ConferencesTable';

const ConferencesTab = ({ conferences = [], onManageClicked, isLoading }) => {
    const handleOnManageClicked = (conferenceId) => {
        onManageClicked(conferenceId);
    };

    return (
        <>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <Box mt={4}>
                    {!conferences.length ? (
                        <Typography align="center" variant="h5">
                            No conferences have been created for this League.
                        </Typography>
                    ) : (
                        <ConferencesTable
                            leagueConferences={conferences}
                            isLoading={isLoading}
                            onManageClicked={handleOnManageClicked}
                        />
                    )}
                </Box>
            )}
        </>
    );
};

export default ConferencesTab;
