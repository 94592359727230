import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import CreateTeamCaptainManager from '../../../components/CreateTeamCaptainManager';
import FullPageModal from '../../../components/Modals/FullPageModal';
import Input from '../../../components/Form/Input';
import Location from '../../../components/Form/Location';
import PasswordInput from '../../../components/Form/PasswordInput';
import TimePicker from '../../../components/Form/TimePicker';
import Select from '../../../components/Form/Select';
import { CustomSwitch } from '../../../components/Styled';

import { defaultGridSpacing, SharedUICategories, TypographySizes } from '../../../utils/constants';
import { LeagueSessionTypes, StatusTypes, weekdays } from '../../../utils/types';
import { getLocationData } from '../../../utils/location';
import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { defaultCreateTeamModalPropTypes } from '../../../utils/proptypes';

import { useCreateTeamMutation } from '../../../api/leaguesApi';

const CreateTeamModal = ({ isOpen = false, onClose, onSaveClick, sessionTypeId }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const sessionId = params?.sessionId;
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_MEDIUM_CAP, SMALL_BOOK } = TypographySizes;
    const { MULTI_ROUND } = LeagueSessionTypes;
    const { OPEN, CLOSED } = StatusTypes;
    const [homeLocation, setHomeLocation] = useState('');
    const [teamRegistrationStatus, setTeamRegistrationStatus] = useState(true);
    const [updatedTeamRegistrationStatus, setUpdatedTeamRegistrationStatus] = useState(OPEN);
    const registrationStatusDescription = teamRegistrationStatus ? 'Open' : 'Closed';
    const [teamCaptain, setTeamCaptain] = useState();

    const [createTeam, { isLoading }] = useCreateTeamMutation();

    const defaultValues = {
        name: '',
        sessionId,
        registrationPin: '',
        teamRegistrationAvailabilityStatusTypeId: 1,
        dayOfWeek: '',
        location: '',
        matchStartTimeUtc: moment(),
    };

    const {
        handleSubmit,
        formState: { errors, isValid, isDirty },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    const disableCtaButton = !isValid || !teamCaptain || !isDirty;

    const handleFormSubmit = async (formValues) => {
        const team = { ...formValues };
        const { matchStartTimeUtc, location } = formValues;
        const formattedLocation = await getLocationData(location);

        if (sessionTypeId === MULTI_ROUND) {
            const matchTime = matchStartTimeUtc.clone();
            const hours = matchTime.get('hour');
            const minutes = matchTime.get('minutes');

            team.hourOfDay = hours;
            team.minuteOfHour = minutes;
        }

        try {
            team.homeCourtLocation = formattedLocation;
            team.teamRegistrationAvailabilityStatusTypeId = parseInt(updatedTeamRegistrationStatus);
            team.teamCaptainMemberId = teamCaptain.memberId;
            await createTeam(team).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }

        setTeamRegistrationStatus(OPEN);
        setTeamCaptain(null);
        reset(defaultValues);
    };

    const handleOnSave = () => {
        handleSubmit(handleFormSubmit)();
        onSaveClick();
    };

    const handleOnClose = () => {
        reset(defaultValues);
        setTeamCaptain(null);
        setTeamRegistrationStatus(OPEN);
        onClose();
    };

    const handleOnAddTeamCaptain = (player) => {
        setTeamCaptain(player);
    };

    const handleOnRemoveTeamCaptain = () => {
        setTeamCaptain(null);
    };

    const handleOnLocationChange = (e) => {
        setHomeLocation(e.target.value);
    };

    const onHandleRegistrationStatusSwitch = () => {
        const newId = updatedTeamRegistrationStatus === OPEN ? CLOSED : OPEN;
        setTeamRegistrationStatus((prevStatus) => !prevStatus);
        setUpdatedTeamRegistrationStatus(newId);
    };

    return (
        <FullPageModal
            isOpen={isOpen}
            isCtaButtonDisabled={disableCtaButton}
            title="Create a team"
            ctaTitle="Save"
            onClose={handleOnClose}
            onCtaClick={handleOnSave}
        >
            <Grid justifyContent="center" mt={3} mb={10}>
                <form>
                    <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                        Details
                    </Typography>
                    <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                        <Grid container spacing={defaultGridSpacing}>
                            <Grid item xs={12} lg={6}>
                                <Input name="name" label="Team Name" hasError={!!errors.name} control={control} isRequired />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Location
                                    name="location"
                                    label="Home Court"
                                    error={!!errors.location}
                                    control={control}
                                    isRequired
                                    value={homeLocation}
                                    onChange={handleOnLocationChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Box mb={4.5}>
                        <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            Team Captains
                        </Typography>
                        <CreateTeamCaptainManager
                            isLoadingTeam={isLoading}
                            captains={teamCaptain}
                            onAddCaptain={handleOnAddTeamCaptain}
                            onRemoveCaptain={handleOnRemoveTeamCaptain}
                            isRequired
                        />
                    </Box>
                    {sessionTypeId === MULTI_ROUND && (
                        <>
                            <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                                League Play Time Slot
                            </Typography>
                            <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                                <Grid container spacing={defaultGridSpacing}>
                                    <Grid item xs={12} lg={6}>
                                        <Select
                                            name="dayOfWeek"
                                            label="Day"
                                            options={weekdays}
                                            labelId="day-label"
                                            isFullWidth
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <TimePicker name="matchStartTimeUtc" label="Match Start Time" control={control} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </>
                    )}
                    <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                        Registration Settings
                    </Typography>
                    <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Typography category={SECONDARY} size={SMALL_BOOK}>
                                Team Player Registration
                            </Typography>
                            <Box display="flex" gap={1} alignItems="center">
                                <Typography category={SECONDARY} size={SMALL_BOOK}>
                                    {registrationStatusDescription}
                                </Typography>
                                <CustomSwitch
                                    control={control}
                                    checked={teamRegistrationStatus}
                                    inputProps={{ 'aria-label': `Switch` }}
                                    onChange={onHandleRegistrationStatusSwitch}
                                />
                            </Box>
                        </Grid>
                    </Paper>
                    <Typography mb={1} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                        Password
                    </Typography>
                    <Paper sx={{ p: 3, mb: 4.5, boxShadow: 'none' }}>
                        <Typography mb={2.5} category={SECONDARY} size={SMALL_BOOK}>
                            You can set an optional password that your players will need in order to register for this team.
                        </Typography>
                        <PasswordInput name="registrationPin" label="Password" control={control} />
                    </Paper>
                </form>
            </Grid>
        </FullPageModal>
    );
};

CreateTeamModal.propTypes = {
    ...defaultCreateTeamModalPropTypes,
};

export default CreateTeamModal;
