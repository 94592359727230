import React from 'react';
import parsePhoneNumber from 'libphonenumber-js';

import { AvatarWithName, Typography, DeleteIcon, MessageIcon } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { messageUser, getPlayerAvatarImage, getPlayerProfileUrl } from '../../../utils/helpers';
import { managerCardPropTypes } from '../../../utils/proptypes';

const ManagerCard = ({ managers = [], isUserLoggedIn = false, isCaptainView, onRemoveClick }) => {
    const theme = useTheme();

    const handleOnMessageClick = (memberId, displayName) => {
        messageUser(memberId, displayName);
    };

    const handleOnRemoveClick = (managerData) => {
        onRemoveClick(managerData);
    };

    return managers.map(({ id, firstName, lastName, memberId, phone, email, playerId, profileImage }) => {
        const displayName = `${firstName} ${lastName}`;
        const parsedNumber = parsePhoneNumber(phone || '');
        const phoneNumber = parsedNumber?.formatInternational();
        const href = playerId && getPlayerProfileUrl(playerId);

        return (
            <Box mb={1} key={memberId} backgroundColor={theme.appColors.white} p={1}>
                <Grid container direction="row" alignItems="center" justifyContent="space-between" position="relative">
                    <Grid item xs={12} md={4}>
                        <AvatarWithName
                            avatarHeight="36px"
                            avatarWidth="36px"
                            href={href}
                            playerName={displayName}
                            imgSrc={getPlayerAvatarImage(profileImage)}
                        />
                    </Grid>
                    {isUserLoggedIn ? (
                        <>
                            {phoneNumber ? (
                                <Grid item xs={12} md={3} sx={{ pl: { xs: '45px', md: '0px' } }}>
                                    <Link href={`tel:${phone}`}>
                                        <Typography category="secondary" size="small-book">
                                            {phoneNumber}
                                        </Typography>
                                    </Link>
                                </Grid>
                            ) : (
                                <Grid item xs={12} md={3} sx={{ pl: { xs: '45px', md: '0px' } }} />
                            )}
                            {email && (
                                <Grid item xs={12} md={3} sx={{ pl: { xs: '45px', md: '0px' }, overflow: 'hidden' }}>
                                    <Link href={`mailto:${email}`}>
                                        <Typography
                                            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            category="secondary"
                                            size="small-book"
                                        >
                                            {email}
                                        </Typography>
                                    </Link>
                                </Grid>
                            )}
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} md={4} sx={{ pl: { xs: '45px', md: '0px' } }}>
                                <Typography category="secondary" size="small-book">
                                    ***-***-****
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={3} sx={{ pl: { xs: '45px', md: '0px' } }}>
                                <Typography category="secondary" size="small-book">
                                    ******@*****.com
                                </Typography>
                            </Grid>
                        </>
                    )}
                    <Grid
                        item
                        xs={12}
                        md={2}
                        sx={{ position: { xs: 'absolute', md: 'static' }, top: { xs: 0 }, right: { xs: 0 } }}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <IconButton onClick={() => handleOnMessageClick(memberId, displayName)}>
                            <MessageIcon size={23} color={theme.appColors.interactionBlue} />
                        </IconButton>
                        {isCaptainView && (
                            <IconButton onClick={() => handleOnRemoveClick({ captainId: id, displayName })}>
                                <DeleteIcon size={23} color={theme.appColors.red} />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    });
};

ManagerCard.propTypes = {
    ...managerCardPropTypes,
};

export default ManagerCard;
