import React from 'react';
import { Typography, SquareAvatarWithName } from '@universal-tennis/ui-shared';
import { useTheme } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import GenericTable from '../GenericTable';
import { SportTypeIds } from '../../../utils/types';
import { sessionStandingsHeadCells, SharedUICategories, TypographySizes } from '../../../utils/constants';
import { getPowerIndex } from '../../../utils/helpers';
import { teamTableRowPropTypes, sessionStandingsTablePropTypes } from '../../../utils/proptypes';

const SessionStandingsTable = ({ sessionStandings = [], isLoading, sportTypeId }) => {
    const theme = useTheme();
    const { SECONDARY, MEDIUM_MEDIUM } = SharedUICategories;
    const { SMALL_BOOK, SMALL_MEDIUM } = TypographySizes;
    const TeamTableRow = ({ rowData, labelId }) => {
        const {
            id,
            teamName,
            teamMatchWins,
            teamMatchLosses,
            playerMatchPoints,
            playerMatchGameWinPercentage,
            teamId,
            rank,
            teamPowerIndex,
        } = rowData;

        const gamesWonPercentage = (playerMatchGameWinPercentage * 100).toFixed(2);
        const teamUrl = `/teams/${teamId}`;

        TeamTableRow.propTypes = {
            ...teamTableRowPropTypes,
        };

        return (
            <TableRow tabIndex={-1} key={id} sx={{ backgroundColor: 'white' }}>
                <TableCell
                    sx={{ color: theme.appColors.black, border: 'none' }}
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="normal"
                    align="center"
                >
                    <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK}>
                        {rank}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    <SquareAvatarWithName
                        fontSize={SMALL_MEDIUM}
                        name={teamName}
                        href={teamUrl}
                        avatarWidth="36px"
                        avatarHeight="36px"
                        avatarFontSize={MEDIUM_MEDIUM}
                        maxLinkWidth="200px"
                    />
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK}>
                        {getPowerIndex(teamPowerIndex?.singlesRating, teamPowerIndex?.isSinglesEstimated)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK}>
                        {getPowerIndex(teamPowerIndex?.doublesRating, teamPowerIndex?.isDoublesEstimated)}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK}>
                        {teamMatchWins}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK}>
                        {teamMatchLosses}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK}>
                        {playerMatchPoints}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="center">
                    <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK}>{`${gamesWonPercentage}%`}</Typography>
                </TableCell>
            </TableRow>
        );
    };

    const percentageLabel = sportTypeId === SportTypeIds.TENNIS ? 'Games Won %' : 'Pts won %';
    const standingsHeadCells = [...sessionStandingsHeadCells, { id: 'playerMatchGameWinPercentage', label: percentageLabel }];

    return (
        <Container disableGutters maxWidth={false}>
            <GenericTable
                sortBy="rank"
                tableData={sessionStandings}
                headCells={standingsHeadCells}
                isLoading={isLoading}
                rowComponent={TeamTableRow}
            />
            <Typography color={theme.appColors.black} category={SECONDARY} size={SMALL_BOOK} sx={{ mt: 2.5 }}>
                Standings are determined using win/loss record. For more details,{' '}
                <Link
                    target="_blank"
                    rel="noopener"
                    href="https://support.universaltennis.com/support/solutions/articles/9000237324-team-schedule-and-team-standings"
                >
                    click here
                </Link>
                .
            </Typography>
        </Container>
    );
};

SessionStandingsTable.propTypes = {
    ...sessionStandingsTablePropTypes,
};

export default SessionStandingsTable;
