import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Button } from '@universal-tennis/ui-shared';
import { useForm, useFieldArray } from 'react-hook-form';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';

import LineupAlternatesCard from '../Cards/LineupAlternatesCard';
import LineupCard from '../Cards/LineupCard';

import { SharedUICategories, TypographySizes } from '../../utils/constants';
import { MatchPositions } from '../../utils/postScoreShared';
import { getAlternateIds, validateLineups } from '../../utils/helpers';
import { useUpdateTeamMatchLineupsMutation, useGetTeamMatchLineupsQuery } from '../../api/leaguesApi';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import LoadingIndicator from '../LoadingIndicator';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const POSITION_1_ALTERNATES = 'position1Alternates';
const POSITION_2_ALTERNATES = 'position2Alternates';
const APPENDED_ALTERNATE = { teamMemberId: '' };

const SetLineupModal = ({
    onClose,
    isOpen,
    position1Team,
    position2Team,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { teamMatchId } = useParams();
    const { DISPLAY, PRIMARY } = SharedUICategories;
    const { MEDIUM_BOOK, } = TypographySizes;
    const [matchLineups, setMatchLineups] = useState([]);
    const [position1AlternatePlayers, setPosition1AlternatePlayers] = useState([]);
    const [position2AlternatePlayers, setPosition2AlternatePlayers] = useState([]);
    const [isAlternatesDisabled, setIsAlternatesDisabled] = useState(false);

    const { data: lineupData, isFetching, error } = useGetTeamMatchLineupsQuery(teamMatchId);
    const [updateLineups] = useUpdateTeamMatchLineupsMutation();
    const isPosition1Lineup = lineupData?.canEditPosition1 && !lineupData?.canEditPosition2;

    if (error) {
        dispatch(showErrorPopup(error));
    }

    const defaultValues = {
        position1Alternates: [{ teamMemberId: '' }],
        position2Alternates: [{ teamMemberId: '' }]
    };
    const { register, control, handleSubmit, formState: { errors }, getValues, resetField } = useForm({ defaultValues, mode: 'all' });
    const { fields: position1Fields, append: position1Append, remove: position1Remove } = useFieldArray({
        control,
        name: POSITION_1_ALTERNATES,
        rules: { minLength: 1 }
    });
    const { fields: position2Fields, append: position2Append, remove: position2Remove } = useFieldArray({
        control,
        name: POSITION_2_ALTERNATES,
        rules: { minLength: 1 }
    });

    const mappedAlternateIds = (alternates) => {
        return alternates?.map((alternate) => ({ teamMemberId: alternate?.teamMemberId }));
    };

    useEffect(() => {
        if (lineupData) {
            setMatchLineups([...lineupData.singlesLineups, ...lineupData.doublesLineups]);

            if (lineupData?.position1Alternates?.alternates?.length) {
                const position1Ids = mappedAlternateIds(lineupData.position1Alternates.alternates);
                resetField(POSITION_1_ALTERNATES, { defaultValue: position1Ids });
            }
            if (lineupData?.position2Alternates?.alternates?.length) {
                const position2Ids = mappedAlternateIds(lineupData.position2Alternates.alternates);
                resetField(POSITION_2_ALTERNATES, { defaultValue: position2Ids });
            }
        }
    }, [lineupData]);

    useEffect(() => {
        if (position1Team && position2Team) {
            setPosition1AlternatePlayers(position1Team?.teamMembers);
            setPosition2AlternatePlayers(position2Team?.teamMembers);
        }
    }, [position1Team, position2Team]);

    useEffect(() => {
        if (matchLineups) {
            setIsAlternatesDisabled(true);
            const lineupTeamMemberIds = matchLineups.reduce((lineupIds, lineup) => {
                if (!lineupIds?.position1Ids && !lineupIds?.position2Ids) {
                    lineupIds.position1Ids = [];
                    lineupIds.position2Ids = [];
                }

                if (lineupIds?.position1Ids?.length || lineupIds?.position2Ids?.length) {
                    setIsAlternatesDisabled(false);
                }

                if (lineup.position1?.player1TeamMemberId) {
                    lineupIds.position1Ids.push(lineup.position1?.player1TeamMemberId);
                }
                if (lineup.position1?.player2TeamMemberId) {
                    lineupIds.position1Ids.push(lineup.position1?.player2TeamMemberId);
                }

                if (lineup.position2?.player1TeamMemberId) {
                    lineupIds.position2Ids.push(lineup.position2?.player1TeamMemberId);
                }
                if (lineup.position2?.player2TeamMemberId) {
                    lineupIds.position2Ids.push(lineup.position2?.player2TeamMemberId);
                }

                return lineupIds;
            }, {});

            const position1Alternates = position1Team?.teamMembers?.filter((player) => !lineupTeamMemberIds?.position1Ids?.includes(player.teamMemberId));
            const position2Alternates = position2Team?.teamMembers?.filter((player) => !lineupTeamMemberIds?.position2Ids?.includes(player.teamMemberId));
            setPosition1AlternatePlayers(position1Alternates);
            setPosition2AlternatePlayers(position2Alternates);
        }
    }, [matchLineups]);

    const handleOnFormSubmit = async (formValues) => {
        const position1AlternateIds = getAlternateIds(formValues?.position1Alternates);
        const position2AlternateIds = getAlternateIds(formValues?.position2Alternates);

        const lineups = {
            lineups: matchLineups,
            position1TeamAlternatePlayers: {
                teamId: position1Team?.teamId,
                teamMemberIds: position1AlternateIds
            },
            position2TeamAlternatePlayers: {
                teamId: position2Team?.teamId,
                teamMemberIds: position2AlternateIds
            },
        };

        const validationError = validateLineups(lineups);

        if (validationError) {
            dispatch(showErrorPopup({ message: validationError }));
            return;
        }

        try {
            await updateLineups({ teamMatchId, lineups }).unwrap();
            onClose();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnClose = () => {
        onClose();
    };

    const handleOnPlayerChange = ({ updatedLineup, playerTeamMemberId, teamId }) => {
        const foundIndex = matchLineups.findIndex((lineup) => lineup?.playerMatchId === updatedLineup?.playerMatchId);
        const isExistingLineup = foundIndex !== -1;
        const updatedMatchLineups = [...matchLineups];
        const alternates = getValues();

        if (isExistingLineup) {
            updatedMatchLineups[foundIndex] = updatedLineup;
            setMatchLineups(updatedMatchLineups);
        } else {
            const updatedLineups = [...updatedMatchLineups, updatedLineup];
            setMatchLineups(updatedLineups);
        }

        if (teamId === position1Team?.teamId) {
            const position1AlternateIds = getAlternateIds(alternates?.position1Alternates);
            if (position1AlternateIds.includes(playerTeamMemberId)) {
                const playerIndex = alternates?.position1Alternates?.findIndex((alternate) => alternate.teamMemberId === playerTeamMemberId);
                position1Remove(playerIndex);

                if (playerIndex === 0) {
                    position1Append(APPENDED_ALTERNATE);
                }
            }
        } else if (teamId === position2Team?.teamId) {
            const position2AlternateIds = getAlternateIds(alternates?.position2Alternates);
            if (position2AlternateIds.includes(playerTeamMemberId)) {
                const playerIndex = alternates?.position2Alternates?.findIndex((alternate) => alternate.teamMemberId === playerTeamMemberId);
                position2Remove(playerIndex);

                if (playerIndex === 0) {
                    position2Append(APPENDED_ALTERNATE);
                }
            }
        }
    };

    const handleOnAddAlternate = (teamId) => {
        const isPosition1Team = teamId === position1Team?.teamId;

        if (isPosition1Team) {
            position1Append(APPENDED_ALTERNATE);
        } else {
            position2Append(APPENDED_ALTERNATE);
        }
    };

    const handleOnRemoveAlternate = (indexToRemove, teamId) => {
        const isPosition1Team = teamId === position1Team?.teamId;

        if (isPosition1Team) {
            position1Remove(indexToRemove);
        } else {
            position2Remove(indexToRemove);
        }
    };

    const alternatePositionFields = isPosition1Lineup ? position1Fields : position2Fields;
    const alternateError = isPosition1Lineup ? !!errors.position1Alternates : !!errors.position2Alternates;
    const alternateFieldName = isPosition1Lineup ? POSITION_1_ALTERNATES : POSITION_2_ALTERNATES;
    const alternateRoster = isPosition1Lineup ? position1AlternatePlayers : position2AlternatePlayers;
    const alternateTeamName = isPosition1Lineup ? position1Team?.name : position2Team?.name;
    const alternateTeamId = isPosition1Lineup ? position1Team?.teamId : position2Team?.teamId;

    return (
        <Dialog scroll="body" PaperProps={{ style: { backgroundColor: theme.appColors.coolGrey } }} fullScreen open={isOpen} onClose={handleOnClose} TransitionComponent={Transition}>
            {isFetching ? (
                <LoadingIndicator />
            ) : (
                <form onSubmit={handleSubmit(handleOnFormSubmit)}>
                    <AppBar sx={{ position: 'relative', backgroundColor: theme.appColors.black }}>
                        <Toolbar>
                            <Container maxWidth="md">
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography category={DISPLAY} size={MEDIUM_BOOK}>
                                        Set lines
                                    </Typography>
                                    <IconButton edge="start" color="inherit" onClick={handleOnClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Container>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="md">
                        <Box mt={2}>
                            {lineupData?.singlesLineups?.map((lineup) => (
                                <Box key={lineup?.playerMatchId} mb={4}>
                                    <LineupCard
                                        onPlayerChange={handleOnPlayerChange}
                                        lineup={lineup}
                                        canEditPosition1={lineupData?.canEditPosition1}
                                        canEditPosition2={lineupData?.canEditPosition2}
                                        position1Team={position1Team}
                                        position2Team={position2Team}
                                    />
                                </Box>
                            ))}
                            {lineupData?.doublesLineups?.map((lineup) => (
                                <Box key={lineup?.playerMatchId} mb={4}>
                                    <LineupCard
                                        isDoubles
                                        onPlayerChange={handleOnPlayerChange}
                                        lineup={lineup}
                                        canEditPosition1={lineupData?.canEditPosition1}
                                        canEditPosition2={lineupData?.canEditPosition2}
                                        position1Team={position1Team}
                                        position2Team={position2Team}
                                    />
                                </Box>
                            ))}
                            <Box mb={20}>
                                {lineupData?.canEditPosition1 && lineupData?.canEditPosition2 ? (
                                    <Box>
                                        <LineupAlternatesCard
                                            register={register}
                                            fields={position2Fields}
                                            error={!!errors.position2Alternates}
                                            name={POSITION_2_ALTERNATES}
                                            teamId={position2Team?.teamId}
                                            position={MatchPositions.POSITION_2}
                                            roster={position2AlternatePlayers}
                                            teamName={position2Team?.name}
                                            onAddAlternate={handleOnAddAlternate}
                                            onRemoveAlternate={handleOnRemoveAlternate}
                                            isDisabled={isAlternatesDisabled}
                                        />
                                        <Box mt={4}>
                                            <LineupAlternatesCard
                                                register={register}
                                                fields={position1Fields}
                                                error={!!errors.position1Alternates}
                                                name={POSITION_1_ALTERNATES}
                                                teamId={position1Team?.teamId}
                                                position={MatchPositions.POSITION_1}
                                                roster={position1AlternatePlayers}
                                                teamName={position1Team?.name}
                                                onAddAlternate={handleOnAddAlternate}
                                                onRemoveAlternate={handleOnRemoveAlternate}
                                                isDisabled={isAlternatesDisabled}
                                            />
                                        </Box>
                                    </Box>
                                ) : (
                                    <LineupAlternatesCard
                                        register={register}
                                        fields={alternatePositionFields}
                                        error={alternateError}
                                        name={alternateFieldName}
                                        teamId={alternateTeamId}
                                        roster={alternateRoster}
                                        teamName={alternateTeamName}
                                        onAddAlternate={handleOnAddAlternate}
                                        onRemoveAlternate={handleOnRemoveAlternate}
                                        isDisabled={isAlternatesDisabled}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Container>
                    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: theme.appColors.white }}>
                        <Toolbar>
                            <Container maxWidth="md">
                                <Box display="flex" justifyContent="flex-end">
                                    <Button category={PRIMARY} type="submit">
                                        Save Lineups
                                    </Button>
                                </Box>
                            </Container>
                        </Toolbar>
                    </AppBar>
                </form>
            )}
        </Dialog>
    );
};

export default SetLineupModal;
