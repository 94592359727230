import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography, Button } from '@universal-tennis/ui-shared';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { confirmPublishSchedulePopupPropTypes, defaultConfirmPublishSchedulePopupPropTypes } from '../../utils/proptypes';

const ConfirmPublishSchedulePopup = ({ open, onClose, title, message, checkboxLabel, errorMessage, buttonLabel, onAction, actionLabel }) => {
    const [isNotifyingTeams, setIsNotifyingTeams] = useState(false);

    const handleActionButtonClick = () => {
        onAction({ isNotifyingTeams });
    };

    const handleOnNotifyChange = (event) => {
        setIsNotifyingTeams(event.target.checked);
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Box p={3}>
                {!!title && (
                    <Typography category="secondary" size="large-medium">
                        {title}
                    </Typography>
                )}

                <Typography category="secondary" size="medium-book" pt={1}>
                    {message}
                </Typography>
                {!!errorMessage && (
                    <DialogContentText className="text-error" align="center" id="alert-dialog-error" pt={1}>
                        {errorMessage}
                    </DialogContentText>
                )}
                <Typography category="secondary" size="medium-book" pt={2} pb={2}>
                    <FormControlLabel control={<Checkbox checked={isNotifyingTeams} onChange={handleOnNotifyChange} />} label={checkboxLabel} />
                </Typography>
                <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                    {buttonLabel && (
                        <Button category="secondary" onClick={onClose}>
                            {buttonLabel}
                        </Button>
                    )}
                    {onAction && (
                        <Button category="primary" onClick={handleActionButtonClick}>
                            {actionLabel}
                        </Button>
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    );
};

ConfirmPublishSchedulePopup.propTypes = {
    ...confirmPublishSchedulePopupPropTypes
};

ConfirmPublishSchedulePopup.defaultProps = {
    ...defaultConfirmPublishSchedulePopupPropTypes
};

export default ConfirmPublishSchedulePopup;
