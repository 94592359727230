import React from 'react';
import MuiSlider from '@mui/material/Slider';
import { Controller } from 'react-hook-form';

import { ageSliderDictionary } from '../../../utils/constants';
import { ageDisplay } from '../../../utils/display';
import { SliderTypes } from '../../../utils/types';

const Slider = ({ label, field, control, isLabelHidden, sliderType }) => {
    const { AGE, UTR, UTRP } = SliderTypes;

    const getSliderData = (value) => {
        let sliderLength;
        let display;
        let step = 1;

        switch (sliderType) {
            case AGE:
                sliderLength = Object.keys(ageSliderDictionary).length;
                display = ageDisplay(value);
                break;
            case UTR:
                sliderLength = 16;
                display = `${value[0]?.toFixed(1)} - ${value[1]?.toFixed(1)}`;
                step = 0.5;
                break;
            case UTRP:
                sliderLength = 10;
                display = `${value[0]?.toFixed(2)} - ${value[1]?.toFixed(2)}`;
                step = 0.5;
                break;
            default:
                sliderLength = '';
                display = '';
        }
        return { sliderLength, display, step };
    };

    return (
        <Controller
            control={control}
            name={field}
            render={({ field: { onChange, value } }) => {
                const { sliderLength, display, step } = getSliderData(value);
                return (
                    <div className="form-group form-group-xl slider-small">
                        <div className="d-flex jcsb">
                            <label htmlFor={field} className={isLabelHidden ? 'sr-only' : ''}>
                                {label}
                            </label>
                            <div className="utr text-dark">{display}</div>
                        </div>
                        <MuiSlider value={value} onChange={onChange} max={sliderLength} min={1} step={step} />
                    </div>
                );
            }}
        />
    );
};

export default Slider;
