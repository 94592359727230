import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LoadingIndicator from '../../LoadingIndicator';
import PlayersTable from '../../Tables/PlayersTable/PlayersTable';

import { showErrorPopup } from '../../../redux/errorPopupSlice';
import { useGetPlayersQuery } from '../../../api/leaguesApi';
import { groupPlayersByStatus } from '../../../utils/teams';
import { SharedUICategories, TypographySizes } from '../../../utils/constants';
import { playersTabPropTypes } from '../../../utils/proptypes';

const PlayersTab = ({ teamId = '', isCaptainView = false, onMessageAllPlayers = () => {} }) => {
    const dispatch = useDispatch();
    const [teamPlayers, setTeamPlayers] = useState({});
    const { data: players, isLoading, error } = useGetPlayersQuery(teamId);

    if (error) {
        dispatch(showErrorPopup(error));
    }

    useEffect(() => {
        if (players?.length) {
            const groupedPlayers = groupPlayersByStatus(players);
            setTeamPlayers(groupedPlayers);
        }
    }, [players]);

    const handleOnMessageAllClick = () => {
        onMessageAllPlayers();
    };

    return (
        <Box>
            {isLoading && <LoadingIndicator />}
            {isCaptainView && !isLoading && (
                <Button onClick={handleOnMessageAllClick} sx={{ mb: 2 }} category={SharedUICategories.PRIMARY}>
                    Email All
                </Button>
            )}
            {!!teamPlayers?.rostered?.length && (
                <Box>
                    <PlayersTable teamPlayers={teamPlayers.rostered} />
                </Box>
            )}
            {!!teamPlayers?.substitutes?.length && (
                <Box mt={5}>
                    <PlayersTable teamPlayers={teamPlayers.substitutes} isSubstitutePlayers />
                </Box>
            )}
            {!isLoading && !teamPlayers?.rostered?.length && !teamPlayers?.substitutes?.length && (
                <Typography align="center" category={SharedUICategories.SECONDARY} size={TypographySizes.SMALL_BOOK}>
                    {' '}
                    No players have registered for this team
                </Typography>
            )}
        </Box>
    );
};

PlayersTab.propTypes = {
    ...playersTabPropTypes,
};

export default PlayersTab;
