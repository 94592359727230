import React, { useEffect, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';

import LeaguesTable from '../LeaguesTableWrapper';
import { getPlayerProfileUrl } from '../../../utils/helpers';
import { StyledTableRow, MemberTeamTableLink } from '../../Styled';

const headCells = [
    { id: 'team', label: 'Team' },
    { id: 'record', label: 'Record' },
    { id: 'session', label: 'Session' },
    { id: 'captain', label: 'Captain' },
    { id: 'actions' },
];

const MemberTeamsTable = ({ memberTeams = [], isLoading, onShareClicked }) => {
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const formattedTeams = memberTeams.map((team) => {
            const { teamId, teamName, teamMatchWins, teamMatchLosses, sessionId, sessionName, captains } = team;
            const [teamCaptain] = captains;
            return {
                teamId,
                teamName,
                record: `${teamMatchWins} - ${teamMatchLosses}`,
                sessionName,
                sessionId,
                captainName: `${teamCaptain.firstName} ${teamCaptain.lastName}`,
                captainPlayerId: teamCaptain.playerId,
            };
        });
        setTeams(formattedTeams);
    }, [memberTeams]);

    const TeamTableRow = ({ rowData, labelId }) => {
        const { teamName, teamId, record, sessionName, sessionId, captainName, captainPlayerId } = rowData;

        const handleOnShareClicked = (event) => {
            event.preventDefault();
            onShareClicked(teamId);
        };

        return (
            <StyledTableRow tabIndex={-1} key={teamId}>
                <TableCell component="th" id={labelId} scope="row" width="30%" padding="normal">
                    <MemberTeamTableLink href={`/teams/${teamId}`}>{teamName}</MemberTeamTableLink>
                </TableCell>
                <TableCell width="10%">
                    <Typography>{record}</Typography>
                </TableCell>
                <TableCell width="30%">
                    <MemberTeamTableLink href={`/leagues/session/${sessionId}`}>{sessionName}</MemberTeamTableLink>
                </TableCell>
                <TableCell width="20%">
                    <MemberTeamTableLink target="_blank" rel="noopener" href={getPlayerProfileUrl(captainPlayerId)}>
                        {captainName}
                    </MemberTeamTableLink>
                </TableCell>
                <TableCell width="10%">
                    <Button onClick={handleOnShareClicked} startIcon={<ShareIcon />} aria-label="share-team">
                        Share
                    </Button>
                </TableCell>
            </StyledTableRow>
        );
    };

    return <LeaguesTable tableData={teams} headCells={headCells} isLoading={isLoading} rowComponent={TeamTableRow} isSortingDisabled />;
};

export default MemberTeamsTable;
