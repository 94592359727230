import { isEqual } from 'lodash';
import { TennisScoringFormatTypes, ResultTypes } from './types';
import { isNumericScore, hasScores, hasPartialScores, ValidationErrors, isPosition1Winner, isPosition2Winner, getPositionsForMatch } from './postScoreShared';

const _ = { isEqual };
const { BEST_OF_THREE, TWO_SETS_WITH_TIEBREAKER, SINGLE_SET, TIME_BASED, FAST_FOUR } = TennisScoringFormatTypes;
const { COMPLETED, COMPLETED_WITH_PARTIAL_SCORES, DEFAULTED, WITHDREW, RETIRED } = ResultTypes;

export const DefaultSets = [
    { id: 1, position1Score: '', position2Score: '', position1Tiebreaker: '', position2Tiebreaker: '' },
    { id: 2, position1Score: '', position2Score: '', position1Tiebreaker: '', position2Tiebreaker: '' },
    { id: 3, position1Score: '', position2Score: '', position1Tiebreaker: '', position2Tiebreaker: '' },
];

export const isSetTied = (set, scoringFormatTypeId) => {
    const hasPosition1Score = isNumericScore(set?.position1Score);
    const hasPosition2Score = isNumericScore(set?.position2Score);
    const hasTiebreakerScore = set.position1Tiebreaker !== '' || set.position2Tiebreaker !== '';

    let isTieScore;

    switch (scoringFormatTypeId) {
        case BEST_OF_THREE:
            isTieScore =
                hasPosition1Score &&
                hasPosition2Score &&
                ((set?.position1Score === 7 && set?.position2Score === 6) || (set?.position1Score === 6 && set?.position2Score === 7));
            break;
        case TWO_SETS_WITH_TIEBREAKER:
            isTieScore =
                hasPosition1Score &&
                hasPosition2Score &&
                ((set?.position1Score === 7 && set?.position2Score === 6) ||
                    (set?.position1Score === 6 && set?.position2Score === 7) ||
                    (set.id === 3 && Math.abs(set?.position1Score - set?.position2Score) === 1));
            break;
        case SINGLE_SET:
            isTieScore =
                hasPosition1Score &&
                hasPosition2Score &&
                ((set.position1Score >= 6 && set.position2Score > 6) || (set.position1Score > 6 && set.position2Score >= 6)) &&
                Math.abs(set.position1Score - set.position2Score) === 1;
            break;
        case FAST_FOUR:
            isTieScore =
                hasPosition1Score &&
                hasPosition2Score &&
                ((set?.position1Score === 4 && set?.position2Score === 3) || (set?.position1Score === 3 && set?.position2Score === 4));
            break;
        case TIME_BASED:
            isTieScore = hasPosition1Score && hasPosition2Score && Math.abs(set?.position1Score - set?.position2Score) === 1;
            break;
        default:
            break;
    }

    return isTieScore || hasTiebreakerScore;
};

export const switchSetScores = (sets) => {
    const switchedSets = sets.map((set) => {
        const { id, position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
        return {
            id,
            position1Score: position2Score,
            position2Score: position1Score,
            position1Tiebreaker: position2Tiebreaker,
            position2Tiebreaker: position1Tiebreaker,
        };
    });

    return switchedSets;
};

export const getValidatedValueForSet = (setId, value, scoringFormatTypeId, isTieBreaker) => {
    const maxScoreForRegularSets = 7;
    const maxScoreForProSets = 11;
    const maxScoreForFastFourSets = 4;
    const maxScoreForMatchTiebreakerSet = 1;
    let score = parseInt(value);

    score %= 100;

    switch (scoringFormatTypeId) {
        case BEST_OF_THREE:
            if (score > maxScoreForRegularSets && !isTieBreaker) {
                score = maxScoreForRegularSets;
            } else if (score < 0) {
                score = 0;
            }
            break;
        case TWO_SETS_WITH_TIEBREAKER:
            if (setId === 1 || setId === 2) {
                if (score > maxScoreForRegularSets && !isTieBreaker) {
                    score = maxScoreForRegularSets;
                }
            } else if (score > maxScoreForMatchTiebreakerSet && !isTieBreaker) {
                score = maxScoreForMatchTiebreakerSet;
            } else if (score < 0) {
                score = 0;
            }
            break;
        case SINGLE_SET:
            if (score > maxScoreForProSets && !isTieBreaker) {
                score = maxScoreForProSets;
            } else if (score < 0) {
                score = 0;
            }
            break;
        case TIME_BASED:
            if (score > maxScoreForProSets && !isTieBreaker) {
                score = maxScoreForProSets;
            } else if (score < 0) {
                score = 0;
            }
            break;
        case FAST_FOUR:
            if (score > maxScoreForFastFourSets && !isTieBreaker) {
                score = maxScoreForFastFourSets;
            } else if (score < 0) {
                score = 0;
            }
            break;
        default:
            break;
    }

    return Number.isNaN(score) ? '' : score;
};

const hasUnorderedScoringSets = (sets) => {
    const scoringSets = sets.filter((set) => hasScores(set));
    const skippedFirstSetIds = [2, 3];
    const skippedSecondSetIds = [1, 3];
    const sortedSetIds = scoringSets.map((set) => set.id).sort();

    return _.isEqual(sortedSetIds, skippedFirstSetIds) || _.isEqual(sortedSetIds, skippedSecondSetIds);
};

const validateTiebreaker = (set) => {
    const tiebreakerVictoryMargin = 1;
    const { position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;

    let tiebreakerError = null;

    const isTiebreakerMismatch =
            (position1Score > position2Score && position2Tiebreaker > position1Tiebreaker) ||
            (position2Score > position1Score && position1Tiebreaker > position2Tiebreaker);

    if (position1Tiebreaker === position2Tiebreaker) {
        tiebreakerError = ValidationErrors.TIEBREAKER_SAME_SCORE;
    } else if (isTiebreakerMismatch) {
        tiebreakerError = ValidationErrors.TIEBREAKER_MISMATCHED_SCORE;
    } else if (Math.abs(position1Tiebreaker - position2Tiebreaker) < tiebreakerVictoryMargin) {
        tiebreakerError = ValidationErrors.TIEBREAKER_MARGIN_INVALID;
    }

    return tiebreakerError;
};

export const validateCompletedBestOfThree = (set) => {
    const { position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
    const hasTiebreakerScore = position1Tiebreaker !== '' || position2Tiebreaker !== '';
    const minPointsAllowed = 6;
    const maxPointsAllowed = 7;
    const maxPointsAllowedForSevenPointWinner = 5;
    const hasNotMetMinimumAmountOfPoints = position1Score < minPointsAllowed && position2Score < minPointsAllowed;

    const isTiebreakerScore =
        (position1Score === maxPointsAllowed && position2Score === minPointsAllowed) ||
        (position2Score === maxPointsAllowed && position1Score === minPointsAllowed);

    const hasTieScore =
        (position1Score === minPointsAllowed && position2Score === minPointsAllowed) ||
        (position1Score === maxPointsAllowed && position2Score === maxPointsAllowed);

    const hasOutOfRangeScore =
        (position1Score === maxPointsAllowed && position2Score < maxPointsAllowedForSevenPointWinner) ||
        (position2Score === maxPointsAllowed && position1Score < maxPointsAllowedForSevenPointWinner) ||
        (position1Score === minPointsAllowed && position2Score === maxPointsAllowedForSevenPointWinner) ||
        (position2Score === minPointsAllowed && position1Score === maxPointsAllowedForSevenPointWinner);

    let error = null;

    if (hasNotMetMinimumAmountOfPoints || hasTieScore || hasOutOfRangeScore) {
        error = `${ValidationErrors.SET_SCORE_INVALID} ${set.id}`;
    }

    if (isTiebreakerScore && !hasTiebreakerScore) {
        error = `${ValidationErrors.MISSING_TIEBREAKER_SCORE} ${set.id}`;
    }

    if (!error && hasTiebreakerScore) {
        error = validateTiebreaker(set);
    }

    return error;
};

export const validateCompletedTwoSetWithTiebreaker = (set) => {
    const { position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
    const hasTiebreakerScore = position1Tiebreaker !== '' || position2Tiebreaker !== '';
    const minPointsAllowed = 6;
    const maxPointsAllowed = 7;
    const maxPointsAllowedForSevenPointWinner = 5;
    const maxThirdSetPointsAllowed = 1;
    const minThirdSetPointsAllowed = 0;

    const isTiebreakerSet3Score =
        (position1Score === maxThirdSetPointsAllowed && position2Score === minThirdSetPointsAllowed) ||
        (position2Score === maxThirdSetPointsAllowed && position1Score === minThirdSetPointsAllowed);

    const isTiebreakerScore =
        (position1Score === maxPointsAllowed && position2Score === minPointsAllowed) ||
        (position2Score === maxPointsAllowed && position1Score === minPointsAllowed);

    const hasTieScore =
        (position1Score === minPointsAllowed && position2Score === minPointsAllowed) ||
        (position1Score === maxPointsAllowed && position2Score === maxPointsAllowed);

    const hasOutOfRangeScore =
        (position1Score === maxPointsAllowed && position2Score < maxPointsAllowedForSevenPointWinner) ||
        (position2Score === maxPointsAllowed && position1Score < maxPointsAllowedForSevenPointWinner) ||
        (position1Score === minPointsAllowed && position2Score === maxPointsAllowedForSevenPointWinner) ||
        (position2Score === minPointsAllowed && position1Score === maxPointsAllowedForSevenPointWinner);

    const hasNotMetMinimumAmountOfPoints = position1Score < minPointsAllowed && position2Score < minPointsAllowed;
    const hasThirdSetTie = position1Score === position2Score;
    const hasOutOfRangeThirdSetScore = position1Score > maxThirdSetPointsAllowed || position2Score > maxThirdSetPointsAllowed;

    let error = null;

    if (set.id !== 3) {
        if (hasNotMetMinimumAmountOfPoints || hasTieScore || hasOutOfRangeScore) {
            error = `${ValidationErrors.SET_SCORE_INVALID} ${set.id}`;
        }
    } else if (hasThirdSetTie || hasOutOfRangeThirdSetScore) {
        error = ValidationErrors.TWO_WITH_TIEBREAKER_INVALID_SET_THREE_SCORE;
    }

    if ((isTiebreakerScore || isTiebreakerSet3Score) && !hasTiebreakerScore) {
        error = `${ValidationErrors.MISSING_TIEBREAKER_SCORE} ${set.id}`;
    }

    if (!error && hasTiebreakerScore) {
        error = validateTiebreaker(set);
    }

    return error;
};

export const validateCompletedSingleSet = (set) => {
    const { position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
    const hasTiebreakerScore = position1Tiebreaker !== '' || position2Tiebreaker !== '';
    const maxPointsAllowedForElevenPointWinner = 9;
    const maxPointsAllowedForSevenPointWinner = 5;
    const maxProSetPointsAllowed = 11;
    const maxRegularSetPointsAllowed = 7;
    const minPointsAllowed = 0;
    const minPointsNeededForWin = 6;
    const tiebreakerMargin = 1;

    const isTiebreakerScore =
        ((position1Score >= minPointsAllowed && position1Score <= maxProSetPointsAllowed) ||
            (position2Score >= minPointsAllowed && position2Score <= maxProSetPointsAllowed)) &&
        Math.abs(position1Score - position2Score) === tiebreakerMargin;

    const hasOutOfRangeRegularSetScore =
        (position1Score === maxRegularSetPointsAllowed && position2Score < maxPointsAllowedForSevenPointWinner) ||
        (position2Score === maxRegularSetPointsAllowed && position1Score < maxPointsAllowedForSevenPointWinner);

    const hasOutOfRangeProSetScore =
        (position1Score === maxProSetPointsAllowed && position2Score < maxPointsAllowedForElevenPointWinner) ||
        (position2Score === maxProSetPointsAllowed && position1Score < maxPointsAllowedForElevenPointWinner);

    const hasOutOfRangeScore =
        position1Score > maxProSetPointsAllowed ||
        position2Score > maxProSetPointsAllowed ||
        position1Score < minPointsAllowed ||
        position2Score < minPointsAllowed;

    const hasNotMetMinimumAmountOfPoints = position1Score < minPointsNeededForWin && position2Score < minPointsNeededForWin;

    const hasTieScore = position1Score === position2Score;

    let error = null;

    if (hasNotMetMinimumAmountOfPoints || hasTieScore || hasOutOfRangeProSetScore || hasOutOfRangeRegularSetScore || hasOutOfRangeScore) {
        error = `${ValidationErrors.SET_SCORE_INVALID} ${set.id}`;
    }

    if (isTiebreakerScore && !hasTiebreakerScore) {
        error = `${ValidationErrors.MISSING_TIEBREAKER_SCORE} ${set.id}`;
    }

    if (!error && hasTiebreakerScore) {
        error = validateTiebreaker(set);
    }

    return error;
};

export const validateCompletedFastFour = (set) => {
    const { position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
    const hasTiebreakerScore = position1Tiebreaker !== '' || position2Tiebreaker !== '';
    const maxPointsAllowed = 4;
    const minTiebreakerPoints = 3;
    const hasNotMetMinimumAmountOfPoints = position1Score < maxPointsAllowed && position2Score < maxPointsAllowed;

    const isTiebreakerScore =
        (position1Score === maxPointsAllowed && position2Score === minTiebreakerPoints) ||
        (position2Score === maxPointsAllowed && position1Score === minTiebreakerPoints);

    const hasTieScore = position1Score === position2Score;

    let error = null;

    if (hasNotMetMinimumAmountOfPoints || hasTieScore) {
        error = `${ValidationErrors.SET_SCORE_INVALID} ${set.id}`;
    }

    if (isTiebreakerScore && !hasTiebreakerScore) {
        error = `${ValidationErrors.MISSING_TIEBREAKER_SCORE} ${set.id}`;
    }

    if (!error && hasTiebreakerScore) {
        error = validateTiebreaker(set);
    }

    return error;
};

export const validateTimeBasedSet = (set) => {
    const { position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
    const hasTiebreakerScore = position1Tiebreaker !== '' || position2Tiebreaker !== '';
    const maxSetPointsAllowed = 11;
    const minPointsAllowed = 0;
    let error = null;

    const hasTieScore = position1Score === position2Score && set.id === 1;
    const hasOutOfRangeScore =
        position1Score > maxSetPointsAllowed ||
        position2Score > maxSetPointsAllowed ||
        position1Score < minPointsAllowed ||
        position2Score < minPointsAllowed;

    if (hasTieScore || hasOutOfRangeScore) {
        error = `${ValidationErrors.SET_SCORE_INVALID} ${set.id}`;
    }

    if (!error && hasTiebreakerScore) {
        error = validateTiebreaker(set);
    }

    return error;
};

export const isValidCompletedSetScore = (set, scoringFormatTypeId) => {
    const hasSetScores = hasScores(set);
    const hasPartialSetScores = hasPartialScores(set);
    let error = null;

    if (hasSetScores) {
        switch (scoringFormatTypeId) {
            case BEST_OF_THREE: {
                const validationError = validateCompletedBestOfThree(set);
                if (validationError) {
                    error = validationError;
                }
                break;
            }
            case TWO_SETS_WITH_TIEBREAKER: {
                const validationError = validateCompletedTwoSetWithTiebreaker(set);
                if (validationError) {
                    error = validationError;
                }
                break;
            }
            case SINGLE_SET: {
                const validationError = validateCompletedSingleSet(set);
                if (validationError) {
                    error = validationError;
                }
                break;
            }
            case FAST_FOUR: {
                const validationError = validateCompletedFastFour(set);
                if (validationError) {
                    error = validationError;
                }
                break;
            }
            default:
                break;
        }
    } else if (hasPartialSetScores) {
        error = `${ValidationErrors.SET_SCORE_INCOMPLETE} ${set.id}`;
    }

    return { isValidCompletedSet: !error, error };
};

export const isValidPartialSetScore = (set, scoringFormatTypeId) => {
    const { position1Score, position2Score, position1Tiebreaker, position2Tiebreaker } = set;
    const hasTiebreakerScore = position1Tiebreaker !== '' || position2Tiebreaker !== '';
    const hasSetScores = hasScores(set);
    const hasPartialSetScores = hasPartialScores(set);
    const minScoreForSet = 0;
    const maxScoreForRegularSet = 7;
    const minTieScoreForRegularSet = 6;
    const maxScoreForProSet = 11;
    const maxScoreForFastFourSet = 4;
    const minTieScoreForFastFourSet = 3;
    const maxScoreFastFourTiebreaker = 5;
    const maxPointsAllowedForSevenPointWinner = 5;
    const minScoreNeededForTiebreaker = 6;
    const errorMessage = `${ValidationErrors.SET_SCORE_INVALID} ${set.id}`;
    let error = null;

    if (hasSetScores) {
        switch (scoringFormatTypeId) {
            case BEST_OF_THREE:
            case TWO_SETS_WITH_TIEBREAKER: {
                const isPosition1ScoreInvalid =
                    position1Score < minScoreForSet ||
                    position1Score > maxScoreForRegularSet ||
                    (position1Score === maxScoreForRegularSet && position2Score < maxPointsAllowedForSevenPointWinner);
                const isPosition2ScoreInvalid =
                    position2Score < minScoreForSet ||
                    position2Score > maxScoreForRegularSet ||
                    (position2Score === maxScoreForRegularSet && position1Score < maxPointsAllowedForSevenPointWinner);
                const hasMissingTiebreakerScore =
                    (position1Score === maxScoreForRegularSet && position2Score === minTieScoreForRegularSet && !hasTiebreakerScore) ||
                    (position2Score === maxScoreForRegularSet && position1Score === minTieScoreForRegularSet && !hasTiebreakerScore);

                if (isPosition1ScoreInvalid || isPosition2ScoreInvalid) {
                    error = errorMessage;
                    break;
                }

                if (hasMissingTiebreakerScore) {
                    error = `${ValidationErrors.MISSING_TIEBREAKER_SCORE} ${set.id}`;
                    break;
                }
                break;
            }
            case SINGLE_SET: {
                const isPosition1ScoreInvalid = position1Score < minScoreForSet || position1Score > maxScoreForProSet;
                const isPosition2ScoreInvalid = position2Score < minScoreForSet || position2Score > maxScoreForProSet;
                const hasMissingTiebreakerScore =
                ((position1Score === minScoreNeededForTiebreaker && position2Score > minScoreNeededForTiebreaker) ||
                (position2Score === minScoreNeededForTiebreaker && position1Score > minScoreNeededForTiebreaker)) && Math.abs(position1Score - position2Score) === 1;

                if (isPosition1ScoreInvalid || isPosition2ScoreInvalid) {
                    error = errorMessage;
                    break;
                }

                if (hasMissingTiebreakerScore) {
                    error = `${ValidationErrors.MISSING_TIEBREAKER_SCORE} ${set.id}`;
                    break;
                }
                break;
            }
            case FAST_FOUR: {
                const isPosition1ScoreInvalid = position1Score < minScoreForSet || position1Score > maxScoreForFastFourSet;
                const isPosition2ScoreInvalid = position2Score < minScoreForSet || position2Score > maxScoreForFastFourSet;
                const hasMissingTiebreakerScore =
                    (position1Score === maxScoreForFastFourSet && position2Score === minTieScoreForFastFourSet && !hasTiebreakerScore) ||
                    (position2Score === maxScoreForFastFourSet && position1Score === minTieScoreForFastFourSet && !hasTiebreakerScore);

                if (isPosition1ScoreInvalid || isPosition2ScoreInvalid) {
                    error = errorMessage;
                    break;
                }
                if (hasMissingTiebreakerScore) {
                    error = `${ValidationErrors.MISSING_TIEBREAKER_SCORE} ${set.id}`;
                    break;
                }
                break;
            }
            default:
                break;
        }
    } else if (hasPartialSetScores) {
        error = `${ValidationErrors.SET_SCORE_INCOMPLETE} ${set.id}`;
    }
    if (hasTiebreakerScore) {
        const isPosition1TiebreakerInvalid = position1Tiebreaker < minScoreForSet;
        const isPosition2TiebreakerInvalid = position2Tiebreaker < minScoreForSet;

        if (isPosition1TiebreakerInvalid || isPosition2TiebreakerInvalid) {
            error = errorMessage;
        }
        if (scoringFormatTypeId === FAST_FOUR) {
            if (position1Tiebreaker > maxScoreFastFourTiebreaker || position2Tiebreaker > maxScoreFastFourTiebreaker) {
                error = errorMessage;
            }
        }
    }

    return { isValidPartialScore: !error, error };
};

export const getPartialScoreWinnerForTimeBasedSets = (sets) => {
    const scoringSets = sets.filter((set) => hasScores(set));
    let position1GamesWon = 0;
    let position2GamesWon = 0;
    let isPosition1FirstSetWinner;
    let validationError = null;

    for (let index = 0; index < scoringSets.length; index += 1) {
        const set = sets[index];
        const { position1Score, position2Score } = set;
        const isFirstSet = set.id === 1;

        validationError = validateTimeBasedSet(set);

        if (validationError) {
            break;
        } else {
            position1GamesWon += parseInt(position1Score);
            position2GamesWon += parseInt(position2Score);

            if (isFirstSet) {
                isPosition1FirstSetWinner = isPosition1Winner(set);
            }
        }
    }

    const isGamesWonTied = position1GamesWon === position2GamesWon;
    let isWinnerPosition1;

    if (isGamesWonTied) {
        isWinnerPosition1 = isPosition1FirstSetWinner;
    } else {
        isWinnerPosition1 = position1GamesWon > position2GamesWon;
    }

    return { validationError, isWinnerPosition1 };
};

export const getWinnerForSets = (sets, matchOutcome, scoringFormatTypeId) => {
    const maxAmountOfPartialSets = 1;
    let partialSetCount = 0;
    let validationError = null;
    let isWinnerPosition1 = false;
    let position1CompletedWins = 0;
    let position2CompletedWins = 0;
    let position1PartialWins = 0;
    let position2PartialWins = 0;
    let invalidSetId = null;

    if (hasUnorderedScoringSets(sets)) {
        validationError = ValidationErrors.SETS_ORDERED_INCORRECTLY;
    } else {
        if (scoringFormatTypeId === TIME_BASED && matchOutcome === COMPLETED_WITH_PARTIAL_SCORES) {
            return getPartialScoreWinnerForTimeBasedSets(sets);
        }

        sets.forEach((set) => {
            const hasSetScores = hasScores(set);
            const hasPartialSetScores = hasPartialScores(set);

            switch (matchOutcome) {
                case COMPLETED: {
                    const { error } = isValidCompletedSetScore(set, scoringFormatTypeId);
                    if (error) {
                        validationError = error;
                        break;
                    }

                    if (isPosition1Winner(set)) {
                        position1CompletedWins += 1;
                    } else if (isPosition2Winner(set)) {
                        position2CompletedWins += 1;
                    }
                    break;
                }
                case COMPLETED_WITH_PARTIAL_SCORES:
                    {
                        if (invalidSetId === set.id && hasSetScores) {
                            validationError = ValidationErrors.NO_MULTIPLE_PARTIAL_SCORES;
                            break;
                        }

                        const { isValidCompletedSet } = isValidCompletedSetScore(set, scoringFormatTypeId);

                        if (isValidCompletedSet) {
                            if (isPosition1Winner(set)) {
                                position1CompletedWins += 1;
                            } else if (isPosition2Winner(set)) {
                                position2CompletedWins += 1;
                            }
                        } else if (!isValidCompletedSet && partialSetCount <= maxAmountOfPartialSets) {
                            const { isValidPartialScore, error } = isValidPartialSetScore(set, scoringFormatTypeId);

                            if (isValidPartialScore) {
                                partialSetCount += 1;
                                invalidSetId = set.id + 1;

                                if (isPosition1Winner(set)) {
                                    position1PartialWins += 1;
                                } else if (isPosition2Winner(set)) {
                                    position2PartialWins += 1;
                                }
                            } else {
                                validationError = error;
                                break;
                            }
                        }
                    }
                    break;
                case WITHDREW:
                case DEFAULTED:
                    if (hasSetScores || hasPartialSetScores) {
                        validationError = ValidationErrors.NO_SCORES_FOR_OUTCOME;
                        break;
                    }
                    break;
                case RETIRED: {
                    if (invalidSetId === set.id && hasSetScores) {
                        validationError = ValidationErrors.NO_MULTIPLE_PARTIAL_SCORES;
                        break;
                    }

                    if (!hasSetScores && set.id === 1) {
                        validationError = ValidationErrors.SET_SCORES_REQUIRED_FOR_OUTCOME;
                        break;
                    }

                    const { isValidCompletedSet, error: validSetError } = isValidCompletedSetScore(set, scoringFormatTypeId);
                    if (isValidCompletedSet) {
                        break;
                    } else if (!isValidCompletedSet && partialSetCount <= maxAmountOfPartialSets) {
                        const { isValidPartialScore, error } = isValidPartialSetScore(set, scoringFormatTypeId);
                        if (isValidPartialScore) {
                            partialSetCount += 1;
                            invalidSetId = set.id + 1;
                            break;
                        } else {
                            validationError = error;
                            break;
                        }
                    } else {
                        validationError = validSetError;
                    }
                    break;
                }
                default:
                    break;
            }
        });
    }

    if (validationError) {
        return { validationError };
    }

    const totalPosition1Wins = position1CompletedWins + position1PartialWins;
    const totalPosition2Wins = position2CompletedWins + position2PartialWins;
    isWinnerPosition1 = totalPosition1Wins > totalPosition2Wins;

    const twoWinMaxFormats = [BEST_OF_THREE, TWO_SETS_WITH_TIEBREAKER, FAST_FOUR];

    if (twoWinMaxFormats.includes(scoringFormatTypeId) && matchOutcome === COMPLETED) {
        const winsRequired = 2;
        const isUnderWinRequirement =
            (isWinnerPosition1 && totalPosition1Wins < winsRequired) || (!isWinnerPosition1 && totalPosition2Wins < winsRequired);
        const isOverWinRequirement =
            (isWinnerPosition1 && totalPosition1Wins > winsRequired) || (!isWinnerPosition1 && totalPosition2Wins > winsRequired);

        if (isUnderWinRequirement) {
            validationError = ValidationErrors.UNDER_WIN_LIMIT;
        } else if (isOverWinRequirement) {
            validationError = ValidationErrors.OVER_WIN_LIMIT;
        }
    } else if (matchOutcome === COMPLETED_WITH_PARTIAL_SCORES) {
        if (position1CompletedWins > position2CompletedWins) {
            isWinnerPosition1 = true;
        } else if (position1CompletedWins < position2CompletedWins) {
            isWinnerPosition1 = false;
        } else if (totalPosition1Wins > totalPosition2Wins) {
            isWinnerPosition1 = true;
        } else if (totalPosition1Wins < totalPosition2Wins) {
            isWinnerPosition1 = false;
        } else if (totalPosition1Wins === totalPosition2Wins) {
            validationError = ValidationErrors.NO_WINNER_DETERMINED;
        }
    } else if (totalPosition1Wins === totalPosition2Wins && matchOutcome === COMPLETED) {
        validationError = ValidationErrors.NO_WINNER_DETERMINED;
    }

    return { validationError, isWinnerPosition1 };
};

export const getExistingMatchSets = (match, isSide1Position1) => {
    const { position1, position2 } = getPositionsForMatch(match, isSide1Position1);
    const position1Result = position1?.sideResult;
    const position2Result = position2?.sideResult;

    const hasSet1Position1Score = isNumericScore(position1Result?.set1Score);
    const hasSet1Position2Score = isNumericScore(position2Result?.set1Score);
    const hasSet1Position1TiebreakerScore = isNumericScore(position1Result?.tieBreakSet1Score);
    const hasSet1Position2TiebreakerScore = isNumericScore(position2Result?.tieBreakSet1Score);

    const hasSet2Position1Score = isNumericScore(position1Result?.set2Score);
    const hasSet2Position2Score = isNumericScore(position2Result?.set2Score);
    const hasSet2Position1TiebreakerScore = isNumericScore(position1Result?.tieBreakSet2Score);
    const hasSet2Position2TiebreakerScore = isNumericScore(position2Result?.tieBreakSet2Score);

    const hasSet3Position1Score = isNumericScore(position1Result?.set3Score);
    const hasSet3Position2Score = isNumericScore(position2Result?.set3Score);
    const hasSet3Position1TiebreakerScore = isNumericScore(position1Result?.tieBreakSet3Score);
    const hasSet3Position2TiebreakerScore = isNumericScore(position2Result?.tieBreakSet3Score);

    return [
        {
            id: 1,
            position1Score: hasSet1Position1Score ? position1Result?.set1Score : '',
            position2Score: hasSet1Position2Score ? position2Result?.set1Score : '',
            position1Tiebreaker: hasSet1Position1TiebreakerScore ? position1Result?.tieBreakSet1Score : '',
            position2Tiebreaker: hasSet1Position2TiebreakerScore ? position2Result?.tieBreakSet1Score : '',
        },
        {
            id: 2,
            position1Score: hasSet2Position1Score ? position1Result?.set2Score : '',
            position2Score: hasSet2Position2Score ? position2Result?.set2Score : '',
            position1Tiebreaker: hasSet2Position1TiebreakerScore ? position1Result?.tieBreakSet2Score : '',
            position2Tiebreaker: hasSet2Position2TiebreakerScore ? position2Result?.tieBreakSet2Score : '',
        },
        {
            id: 3,
            position1Score: hasSet3Position1Score ? position1Result?.set3Score : '',
            position2Score: hasSet3Position2Score ? position2Result?.set3Score : '',
            position1Tiebreaker: hasSet3Position1TiebreakerScore ? position1Result?.tieBreakSet3Score : '',
            position2Tiebreaker: hasSet3Position2TiebreakerScore ? position2Result?.tieBreakSet3Score : '',
        },
    ];
};
