import React from 'react';
import { useLocation } from 'react-router-dom';

import { getClickAttrs } from '../../utils/analytics';

import styles from './menu.module.scss';

const MenuLink = (props) => {
    const location = useLocation();
    const { path, text, trackedContent } = props || {};
    const { pathname } = location || {};

    return (
        <a
            href={path}
            className={`${styles.menuItem} ${pathname === path ? styles.active : ''}`}
            {...getClickAttrs({
                category: 'Menu',
                context: trackedContent || `Menu Item - ${text}`,
                location: 'Menu',
            })}
        >
            {text}
        </a>
    );
};

export default MenuLink;
